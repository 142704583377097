<template>
    <AuthContainer :title="$t('signUp.createAccount')">
        <SignUpForm></SignUpForm>
    </AuthContainer>
</template>

<script>
import AuthContainer from './AuthContainer.vue';
import SignUpForm from './SignUpForm.vue';

export default {
    name: 'SignUp',
    components: { AuthContainer, SignUpForm },
}
</script>

<style lang="scss" scoped>
</style>