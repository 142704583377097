<template>
    <div class="project-breakdown" ref="projectBreakdown">
        <!--First page-->
        <ProjectPage :pagesQuantity="pagesTotal">
            <template v-if="systems.length && perSystemCalculations.length" v-slot:additional-data>
                <SystemInfo :system="systems[0]" :calculationResults="perSystemCalculations[0]"/>
            </template>
        </ProjectPage>
        <!--Systems breakdown-->
        <template v-for="(system, index) in systems" :key="index">
            <SystemBreakdown
                :system="system"
                :calculationResults="perSystemCalculations[index]"
                :withSystemSummary="index > 0"
                :pagesAmount="pagesTotal"
                :drawFooter="index === systems.length - 1"
                :withProductInventoryTable="false"
                v-if="perSystemCalculations[index]"
            ></SystemBreakdown>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectPage from './ProjectPage.vue';
import SystemBreakdown from '@/components/systems/pdf/SystemBreakdown.vue';
import SystemInfo from '@/components/systems/pdf/SystemInfo.vue';

export default {
    name: 'ProjectBreakdown',
    components: {
        ProjectPage,
        SystemBreakdown,
        SystemInfo,
    },
    data() {
        return {
            pagesTotal: 1,
        };
    },
    updated() {
        this.pagesTotal = window.document.querySelectorAll('.page').length;
    },
    computed: {
        ...mapGetters('systemsModule', ['systems', 'perSystemCalculations']),
    },
}
</script>

<style lang="scss" scoped>
.project-breakdown {
    // position: absolute;
    // top: 0;
    // left: -10000px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: #FFFFFF;
}
</style>
