import InputAbstractClassesTransformer from '../../transformers/input/abstract-classes';
import * as AbstractClassesService from '../../services/abstract-classes';

export default {
    namespaced: true,
    state: () => ({
        abstractClasses: [],
    }),
    getters: {
        imagesMap({ abstractClasses }) {
            const map = {};
            abstractClasses.forEach((abstractClass) => {
                map[abstractClass.id] = abstractClass.imageDataURL || abstractClass.imageURL;
                map[abstractClass.name] = abstractClass.imageDataURL || abstractClass.imageURL;
            });

            return map;
        }
    },
    mutations: {
        setAbstractClasses(state, abstractClasses) {
            state.abstractClasses = abstractClasses;
        },
    },
    actions: {
        async getAbstractClasses({ state, commit }, { rewrite = false } = {}) {
            if (state.abstractClasses.length && !rewrite) {
                return;
            }
            const abstractClasses = await AbstractClassesService.getAbstractClasses();
            commit('setAbstractClasses', InputAbstractClassesTransformer.transform(abstractClasses));
        },
    },
}