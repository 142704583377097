<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div class="col">
                <div class="row p-0">
                    <div class="col">
                        <div class="row p-0">{{ productCode || $t('rectSilencerModal.silencerDetailsView.addRectSilencer') }}</div>
                        <div class="row p-0 text-small sub-header">{{ $t('rectSilencerModal.silencerDetailsView.specifySilencerDetails') }}</div>
                    </div>
                    <div v-if="shape === 'bend'" class="col items-end">
                        <div class="image-preview" :style="{ 'background-image': `url(${bendSilencerSchematic})` }"></div>
                    </div>
                </div>
                <div class="row p-0 mt-2">
                    <div class="tabs-header">
                        <div class="tab-header active-tab-header">{{ $t('octaveBandAdjustmentModal.general') }}</div>
                        <div class="tab-header disabled-header">{{ $t('projectBreakdown.soundPower') }}</div>
                        <div class="tab-header disabled-header">{{ $t('projectBreakdown.attenuation') }}</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:modal-content>
            <div class="silencer-details-wrapper">
                <SilencerDetails/>
            </div>
        </template>
        <template v-slot:modal-actions>
            <div class="row p-0">
                <Button outlined size="medium" class="mr-4" @click="closeModal">{{ $t('commonControls.cancel') }}</Button>
                <Button size="medium" :disabled="!canProceed || requestPending" @click="proceed">
                    <div v-if="requestPending" class="loader small-loader"></div>
                    <span v-else>{{ $t('commonControls.next') }}</span>
                </Button>
            </div>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Modal from '../../../general/Modal.vue';
import SilencerDetails from './SilencerDetails.vue';
import Button from '../../../general/Button.vue';

// const BEND_HORIZONTAL_SILENCER_SCHEMATIC = 'https://storage.googleapis.com/category-images/silencers/rectangular/bend/MPKI_horizontal_schematic.png';
// const BEND_VERTICAL_SILENCER_SCHEMATIC = 'https://storage.googleapis.com/category-images/silencers/rectangular/bend/MPKI_vertical_schematic.png';
const BEND_HORIZONTAL_SILENCER_SCHEMATIC = 'https://storage.googleapis.com/category-images/silencers/rectangular/schematics/MPK_horizontal.png';
const BEND_VERTICAL_SILENCER_SCHEMATIC = 'https://storage.googleapis.com/category-images/silencers/rectangular/schematics/MPK_vertical.png';
const BEND_HORIZONTAL_INSULATED_SILENCER_SCHEMATIC = 'https://storage.googleapis.com/category-images/silencers/rectangular/schematics/MPKI_horizontal.png';
const BEND_VERTICAL_INSULATED_SILENCER_SCHEMATIC = 'https://storage.googleapis.com/category-images/silencers/rectangular/schematics/MPKI_vertical.png';


export default {
    name: 'SilencerDetailsView',
    components: {
        Modal,
        SilencerDetails,
        Button,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue', 'close', 'optionSelectionRequested', 'switchTab'],
    computed: {
        ...mapState('rectSilencerModule', ['shape', 'orientation', 'requestPending', 'outerCover']),
        ...mapGetters('rectSilencerModule', ['productCode', 'allDimensionsValid']),
        insulated() {
            return this.outerCover !== 'none';
        },
        canProceed() {
            return this.allDimensionsValid;
        },
        bendHorizontalSilencerSchematic() {
            return this.insulated
                ? BEND_HORIZONTAL_INSULATED_SILENCER_SCHEMATIC
                : BEND_HORIZONTAL_SILENCER_SCHEMATIC;
        },
        bendVerticalSilencerSchematic() {
            return this.insulated
                ? BEND_VERTICAL_INSULATED_SILENCER_SCHEMATIC
                : BEND_VERTICAL_SILENCER_SCHEMATIC;
        },
        bendSilencerSchematic() {
            return this.orientation === 'horizontal'
                ? this.bendHorizontalSilencerSchematic
                : this.bendVerticalSilencerSchematic;

        },
    },
    methods: {
        ...mapActions('rectSilencerModule', ['calculateOptions']),
        async proceed() {
            try {
                if (!this.canProceed) {
                    return;
                }
                await this.calculateOptions();
                this.$emit('optionSelectionRequested');
            } catch (error) {
                console.error(error);
            }
        },
        closeModal() {
            this.$emit('update:modelValue', false);
            this.$emit('close');
        },
        switchTab(tabName) {
            this.$emit('switchTab', tabName);
        },
    },
}
</script>

<style lang="scss" scoped>
.sub-header {
    color: #737372;
    font-weight: bold;
}

.modal-header .col {
    &:first-of-type {
        min-width: 325px;
    }
}

.image-preview {
    width: 200px;
    height: 130px;
    // background-position: center center;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
}

.tabs-header {
    margin-bottom: -16px;
}

.loader.small-loader {
    width: 12px !important;
    height: 12px !important;
    border: .2em solid #4D91D1 !important;
    border-bottom-color: transparent !important;
}

.silencer-details-wrapper {
    max-width: 700px;
    min-height: 450px;
}
</style>

<style lang="scss">
.rect-silencer-modal {
    .modal-header {
        padding-bottom: 0;
    }
}

.tab-header.disabled-header {
    color: #D5D6D5;
}
</style>