<template>
    <div class="system-breakdown">
        <!-- Project summary -->
        <!-- <ProjectPage v-if="withProjectData" :pagesQuantity="pagesQuantity">
            <template v-if="withSystemSummary" v-slot:additional-data>
                <SystemInfo :system="system" :calculationResults="calculationResults"/>
            </template>
        </ProjectPage> -->

        <!--System summary-->
        <Page v-if="withSystemSummary" :showFooter="drawFooter && !selectedProducts.length" :pagesTotal="pagesQuantity">
            <template v-slot:header>
                <div class="row section-header pt-1 header-title">{{ $t('projectOverview.summaries.systemSummary') }}</div>
                <div class="row section-header pb-4 header-project-code">{{ system.name || $t('projectBreakdown.yourSystem') }}</div>
            </template>
            <template v-slot:content>
                <ProjectInfo v-if="withProjectData"/>
                <SystemInfo :system="system" :calculationResults="calculationResults"/>
            </template>
        </Page>

        <!--Product inventory table pages-->
        <template v-if="withProductInventoryTable">
            <ProductInventoryPages
                :title="system.name"
                :products="selectedProducts"
                :calculationResults="calculationResults"
                :pagesTotal="pagesQuantity"
            />
        </template>

        <!--Product pages-->
        <template v-for="(product, index) in selectedProducts" :key="product.id">
            <Page :showFooter="drawFooter && index === selectedProducts.length - 1" :pagesTotal="pagesQuantity">
                <template v-slot:header>
                    <div class="row section-header pt-1 header-project-code">{{ system.name }}</div>
                    <div class="row section-header pb-4 header-project-code">{{ system.number }}</div>
                </template>
                <template v-slot:content>
                    <ProductData
                        :product="product"
                        :productIndex="index"
                        :calculationResults="calculationResults"
                        :inputSoundPowerLevel="system.inputSoundPowerLevel"
                    />
                </template>
            </Page>
        </template>
    </div>
</template>

<script>
import Page from '@/components/pdf/general/Page.vue';
// import ProjectPage from '../../projects/pdf/ProjectPage.vue';
import ProjectInfo from '../../projects/pdf/ProjectInfo.vue';
import ProductInventoryPages from './product/ProductInventoryPages.vue';
import ProductData from './product/ProductData.vue';
import SystemInfo from './SystemInfo.vue';

export default {
    name: 'SystemBreakdown',
    components: {
        Page,
        // ProjectPage,
        ProjectInfo,
        ProductInventoryPages,
        ProductData,
        SystemInfo,
    },
    props: {
        system: {
            type: Object,
            required: true,
        },
        calculationResults: {
            type: Object,
            required: true,
        },
        withProjectData: {
            type: Boolean,
            default: true,
        },
        withProductInventoryTable: {
            type: Boolean,
            default: true,
        },
        withSystemSummary: {
            type: Boolean,
            default: true,
        },
        pagesAmount: Number,
        drawFooter: {
            type: Boolean,
            default: false,
        },
    },
    updated() {
        this.pagesTotal = window.document.querySelectorAll('.page').length;
    },
    mounted() {
        this.pagesTotal = window.document.querySelectorAll('.page').length;
    },
    data() {
        return {
            pagesTotal: 1,
        }
    },
    computed: {
        selectedProducts() {
            return this.system.selectedProducts || [];
        },
        pagesQuantity() {
            return this.pagesAmount || this.pagesTotal;
        },
    },
}
</script>

<style scoped lang="scss">
@use 'src/assets/css/pdf/page.scss';
@use 'src/assets/css/pdf/page-content.scss';
</style>

<style lang="scss">
@import 'src/assets/css/pdf/_variables.scss';
$regular-font-size: 10pt;
$title-font-size: 12px;

.system-breakdown {
    // position: absolute;
    // top: 0;
    // left: -10000px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: #FFFFFF;
}
</style>
