<template>
    <Page :pageNumber="pageNumber" :pagesTotal="pagesQuantity">
        <template v-slot:header>
            <div class="row section-header pt-1 header-title">{{ $t('projectOverview.summaries.projectSummary') }}</div>
            <div class="row section-header pb-4 header-project-code">{{ name || $t('projectBreakdown.yourProject') }}</div>
        </template>
        <template v-slot:content>
            <ProjectInfo/>
            <slot name="additional-data"></slot>
        </template>
    </Page>
</template>

<script>
import Page from '@/components/pdf/general/Page.vue';
import ProjectInfo from './ProjectInfo.vue';
// import Footer from './Footer.vue';

export default {
    name: 'ProjectPage',
    components: {
        Page,
        ProjectInfo,
    },
}
</script>
