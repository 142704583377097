<template>
    <div class="product-properties">
        <div class="product-name">{{ product.name }}</div>
        <div class="property">
            <div class="property-name">{{ $t('productsList.productSpecs.diameter') }} D:</div>
            <div class="property-value">{{ product.nominalSize }} mm</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('productsList.productSpecs.weight') }}:</div>
            <div class="property-value">{{ product.weight }} kg</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DiffuserProperties',
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>

<style lang="scss">
</style>
