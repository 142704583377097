<template>
    <InputField :title="$t('signUp.name')" :placeholder="$t('signUp.fullName')" class="mb-6"></InputField>
    <InputField :title="$t('signUp.email')" placeholder="youremail@mail.com" class="mb-6"></InputField>
    <InputField :title="$t('signUp.password')" :placeholder="$t('signUp.password')" type="password" class="mb-10"></InputField>
    <div class="row mb-8 p-0">
        <Button>{{ $t('signUp.register') }}</Button>
    </div>
    <div class="row p-0">
        <span>{{ $t('signUp.alreadyHaveAccount') }}</span>&nbsp;<a href="javascript:void(0)" @click="toSignIn" class="link">{{ $t('signUp.signInHere') }}</a>
    </div>
</template>

<script>
import InputField from '@/components/general/InputField.vue';
import Button from '@/components/general/Button.vue';

export default {
    name: 'SignUpForm',
    components: { InputField, Button },
    methods: {
        toSignIn() {
            this.$router.push({ name: 'sign-in' });
        },
    },
}
</script>

<style lang="scss" scoped>
</style>