<template>
    <BackgroundImage></BackgroundImage>
    <div class="page-content justify-start pb-8" v-if="dataReceived">
        <SystemsList v-if="systems.length" :systems="systems" @onCreateSystem="showCreateSystemModal = true"></SystemsList>
        <div v-else>
            <div class="no-system-placeholder">
                <img src="@/assets/no_project_placeholder.png" alt="No System">
            </div>
            <div class="actions-container mt-8 mb-8">
                <Button type="primary" outlined class="mr-4" @click="showSpecificationModal = true">{{ $t('systemsPage.quickCalculation') }}</Button>
                <Button type="primary" @click="showCreateSystemModal = true">{{ $t('systemsPage.createNewSystem') }}</Button>
            </div>
        </div>
        <SystemSpecificationModal size="medium" v-model="showSpecificationModal"></SystemSpecificationModal>
        <CreateSystemModal size="medium" v-model="showCreateSystemModal"></CreateSystemModal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BackgroundImage from './BackgroundImage.vue';
import SystemsList from './SystemsList.vue';
import SystemSpecificationModal from './SystemSpecificationModal.vue';
import CreateSystemModal from './CreateSystemModal.vue';
import Button from '../general/Button.vue';

export default {
    name: 'Systems',
    components: {
        BackgroundImage,
        SystemsList,
        SystemSpecificationModal,
        CreateSystemModal,
        Button,
    },
    async created() {
        try {
            if (!this.projectId) {
                this.$router.push({ name: 'projects' });
                return;
            }
            await this.getSystems({ projectId: this.projectId });
        } catch (error) {
            console.error(error);
        } finally {
            this.dataReceived = true;
        }
  },
    data() {
        return {
            dataReceived: false,
            showSystemsList: true,
            showSpecificationModal: false,
            showCreateSystemModal: false,
        }
    },
    computed: {
        ...mapState('activeProjectModule', {
            'projectId': 'id',
        }),
        ...mapGetters('systemsModule', ['systems']),
        ...mapGetters('userModule', ['userAuthorized']),
    },
    methods: {
        ...mapActions('systemsModule', ['getSystems']),
    }
}
</script>

<style>
.page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    padding: 0 120px;
}

.no-system-placeholder {
    display: flex;
    width: 100%;
    min-height: 260px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.no-system-placeholder div {
    color: #737372;
}

.actions-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
</style>
