<template>
    <div>
        <div class="card silencer-options">
            <div class="col">
                <div class="row">{{ $t('rectSilencerData.option') }}</div>
                <div class="row">{{ $t('rectSilencerData.pressureDrop') }}</div>
                <div class="row">{{ $t('rectSilencerData.attenuation') }}</div>
                <div class="row">{{ $t('rectSilencerData.soundPowerAfter') }}</div>
                <div class="row">{{ $t('rectSilencerData.aFilter') }}</div>
                <div class="row mt-4 items-center">
                    <span>{{ $t('commonControls.select') }}</span>
                    <Tooltip class="ml-1 mt-1">
                        <i class="pi pi-info-circle" />
                        <template v-slot:tooltip-heading>Option selection</template>
                        <template v-slot:tooltip-content>
                            <OptionSelectionTooltipContent />
                        </template>
                    </Tooltip>
                </div>
            </div>
            <div class="col align-items-center" v-for="(option, index) in options" :key="index">
                <div class="row">{{ index + 1 }}</div>
                <div
                    class="row option-data"
                    :class="{
                        'highlighted': option.pressureDrop === optionRecommendation.minPressureDrop,
                        'has-similarities': optionHasSimilarities(option, index),
                    }"
                >{{ roundValue(option.pressureDrop) }}</div>
                <div
                    class="row option-data"
                    :class="{
                        'highlighted': option.totalAttenuationWithAirflow === optionRecommendation.maxAttenuation,
                        'has-similarities': optionHasSimilarities(option, index),
                    }"
                >{{ roundValue(option.totalAttenuationWithAirflow) }}</div>
                <div
                    class="row option-data"
                    :class="{
                        'highlighted': option.resultAfterLw === optionRecommendation.minResultAfterLw,
                        'has-similarities': optionHasSimilarities(option, index),
                    }"
                >{{ roundValue(option.resultAfterLw) }}</div>
                <div
                    class="row option-data"
                    :class="{
                        'highlighted': option.resultAfterAFilter === optionRecommendation.minResultAfterAFilter,
                        'has-similarities': optionHasSimilarities(option, index),
                    }"
                >{{ roundValue(option.resultAfterAFilter) }}</div>
                <div class="row mt-5">
                    <RadioButton :value="index" v-model="selectedOptionIndex" @update:modelValue="setOption"/>
                </div>
            </div>
            <div class="col align-items-center">
                <div class="row">Unit</div>
                <div class="row">Pa</div>
                <div class="row">dB</div>
                <div class="row">dB</div>
                <div class="row">dB(A)</div>
                <div class="row mt-4"></div>
            </div>
        </div>
        <div class="card silencer-properties mt-4">
            <div class="col mr-4">
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.cassetteWidth') }}</div>
                    <div class="col">{{ optionData.cassetteThickness }}</div>
                    <div class="col">mm</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.cassetteGap') }}</div>
                    <div class="col">{{ roundValue(optionData.cassetteSpacing) }}</div>
                    <div class="col">mm</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.edgeGap') }}</div>
                    <div class="col">{{ roundValue(optionData.edgeGap) }}</div>
                    <div class="col">mm</div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.numberOfCassettes') }}</div>
                    <div class="col">{{ optionData.amountOfCassettes }}</div>
                    <div class="col">pc</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.edgeSpeed') }}</div>
                    <div class="col">{{ toTenths(optionData.silencerEndSpeed) }}</div>
                    <div class="col">m/s</div>
                </div>

                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.gapSpeed') }}</div>
                    <div class="col">{{ toTenths(optionData.silencerGapSpeed) }}</div>
                    <div class="col">m/s</div>
                </div>
            </div>
            <!-- <div class="col mr-4">
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.cassetteWidth') }}</div>
                    <div class="col">{{ optionData.cassetteThickness }}</div>
                    <div class="col">mm</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.cassetteGap') }}</div>
                    <div class="col">{{ roundValue(optionData.cassetteSpacing) }}</div>
                    <div class="col">mm</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.edgeGap') }}</div>
                    <div class="col">{{ roundValue(optionData.edgeGap) }}</div>
                    <div class="col">mm</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.numberOfCassettes') }}</div>
                    <div class="col">{{ optionData.amountOfCassettes }}</div>
                    <div class="col">pc</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.pressureDrop') }}</div>
                    <div class="col">{{ roundValue(optionData.pressureDrop) }}</div>
                    <div class="col">Pa</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.edgeSpeed') }}</div>
                    <div class="col">{{ toTenths(optionData.silencerEndSpeed) }}</div>
                    <div class="col">m/s</div>
                </div>
            </div> -->
            <!-- <div class="col">
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.shape') }}</div>
                    <div class="col">{{ shapeText }}</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.shellInsulation') }}</div>
                    <div class="col">{{ shellInsulation }} mm</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.shellMaterial') }}</div>
                    <div class="col">{{ outerCoverText }}</div>
                </div>
                <div class="row">
                    <div class="col">{{ $t('rectSilencerData.absorptionMaterial') }}</div>
                    <div class="col">{{ cassetteMaterialText }}</div>
                </div>
            </div> -->
        </div>
        <div class="row mt-3 mb-3 ml-2">
            <InputSoundPowerContainer
                v-if="mode === 'modelling'"
                :title="$t('rectSilencerData.noiseLevelsBefore')"
            ></InputSoundPowerContainer>
            <OctaveBandContainer
                v-else
                :powerLevelKit="inputSoundPower"
                :title="$t('rectSilencerData.noiseLevelsBefore')"
                :lwa="totalSoundPowerLwA"
                lwaTitle="db(A)"
            ></OctaveBandContainer>
        </div>
        <div class="row mt-3 mb-3 ml-2">
            <OctaveBandContainer
                :powerLevelKit="soundAttenuation"
                :title="$t('rectSilencerData.attenuation')"
                :lwa="'    '"
                lwaTitle="    "
            ></OctaveBandContainer>
        </div>
        <div class="row mt-3 mb-3 ml-2">
            <OctaveBandContainer
                v-if="optionData"
                :powerLevelKit="resultSoundPowerAfterLw"
                :title="$t('rectSilencerData.result')"
                :lwa="roundValue(optionData.resultAfterAFilter)"
                lwaTitle="db(A)"
            ></OctaveBandContainer>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import RadioButton from '../../../general/RadioButton.vue';
import OctaveBandContainer from '../../product/OctaveBandContainer.vue';
import InputSoundPowerContainer from '@/components/pages/modelling/rect-silencer/InputSoundPowerContainer.vue';
import { emptyOctaveBandRange, roundOctaveBandRange } from '@/utils';
import Tooltip from '@/components/general/Tooltip.vue';
import OptionSelectionTooltipContent from '@/components/products/rect-silencers/modal-parts/tooltips-content/OptionSelection.vue';

export default {
    name: 'SilencerOptions',
    components: {
        RadioButton,
        OctaveBandContainer,
        InputSoundPowerContainer,
        Tooltip,
        OptionSelectionTooltipContent,
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: 'regular',
        },
    },
    emits: ['selectedOptionChanged'],
    data() {
        return {
            selectedOptionIndex: -1,
        };
    },
    computed: {
        ...mapState('rectSilencerModule', [
            'options',
            'optionData',
            'shape',
            'shellInsulation',
            'outerCover',
            'cassetteMaterial',
            'inputSoundPower',
        ]),
        ...mapState('activeSystemModule', ['calculationResults']),
        ...mapGetters('rectSilencerModule', ['optionRecommendation']),
        soundAttenuation() {
            if (!this.optionData || !this.optionData.soundAttenuationWithAirflow) {
                return emptyOctaveBandRange();
            }

            return roundOctaveBandRange(this.optionData.soundAttenuationWithAirflow, 1);
        },
        resultSoundPowerAfterLw() {
            if (!this.optionData || !this.optionData.resultSoundPowerAfterLw) {
                return emptyOctaveBandRange();
            }

            return roundOctaveBandRange(this.optionData.resultSoundPowerAfterLw, 1);
        },
        manufactureMaterials() {
            return {
                'none': this.$t('rectSilencerData.manufactureMaterial.none'),
                'galvanizedSteel': `${this.$t('rectSilencerData.manufactureMaterial.galvanizedSteel')} ZN275`,
                'stainlessSteel': `${this.$t('rectSilencerData.manufactureMaterial.stainlessSteel')} AISI 316`,
                'magnelis': `${this.$t('rectSilencerData.manufactureMaterial.magnelis')} ZM310`,
            };
        },
        insulationMaterials() {
            return {
                'polyester': this.$t('rectSilencerData.cassetteMaterial.polyester'),
                'mineralWool': this.$t('rectSilencerData.cassetteMaterial.mineralWool'),
                'cleantec': this.$t('rectSilencerData.cassetteMaterial.cleantec'),
            };
        },
        shapes() {
            return {
                'straight': this.$t('rectSilencerData.shape.straight'),
                'bend': this.$t('rectSilencerData.shape.bend'),
            };
        },
        shapeText() {
            if (!(this.shape in this.shapes)) {
                return '';
            }

            return this.shapes[this.shape];
        },
        outerCoverText() {
            if (!(this.outerCover in this.manufactureMaterials)) {
                return '';
            }

            return this.manufactureMaterials[this.outerCover];
        },
        cassetteMaterialText() {
            if (!(this.cassetteMaterial in this.insulationMaterials)) {
                return '';
            }

            return this.insulationMaterials[this.cassetteMaterial];
        },
        optionSimilarities() {
            const result = {};
            this.options.forEach((option, index) => {
                const key = `${option.cassetteThickness}x${option.amountOfCassettes}`;
                if (key in result) {
                    return;
                }
                result[key] = index;
            });

            return result;
        },
        recommendedOptionIndex() {
            return this.optionRecommendation.recommendedOptionIndex;
        },
        totalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.calculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return 0;
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA) || 0;
        },
    },
    methods: {
        ...mapActions('rectSilencerModule', ['setOption']),
        roundValue(value) {
            return Math.round(Number(value));
        },
        toTenths(value) {
            return Number(Number(value)).toFixed(1);
        },
        optionHasSimilarities(option, index) {
            const key = `${option.cassetteThickness}x${option.amountOfCassettes}`;

            return (key in this.optionSimilarities) && this.optionSimilarities[key] !== index;
        },
    },
    watch: {
        selectedOptionIndex(index) {
            this.setOption(index);
            this.$emit('selectedOptionChanged', { index });
        },
        recommendedOptionIndex: {
            handler(index) {
                if (index < 0) {
                    this.selectedOptionIndex = -1;
                } else {
                    this.selectedOptionIndex = index;
                }
            },
            immediate: true,
            flush: 'post',
        },
    }
}
</script>

<style lang="scss" scoped>
.row {
    &.option-data {
        width: 100%;
        justify-content: center;
    }
}

.col {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 150px;

    &:first-of-type {
        min-width: 200px;
    }

    .row {
        padding: 0;
    }
}

.octave-band-container {
    width: 100%;
}

.font-bold {
    font-family: Helvetica Neue;
    // font-family: Helvetica Neue Bold;
    // color: #737372;
}

.silencer-options {
    .col {
        font-size: 16px;
        line-height: 24px;
    }
}

.silencer-properties {
    .col {
        font-size: 14px;
        line-height: 21px;
    }
}

.highlighted {
    font-weight: bold;
    color: green;
}

.has-similarities {
    background-color: #D5D6D5;

    &:first-of-type {
        background-color: none;
    }
}
</style>