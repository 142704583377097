<template>
    <div class="page-content">
        <Breadcrumbs class="mt-4" :items="breadcrumbs"></Breadcrumbs>
        <div class="column w-full">
            <div class="card column mt-4 filter-card">
                <div class="search-container">
                    <InputField :placeholder="$t('productsList.searchProducts')" v-model="filters.searchString"></InputField>
                </div>
                <div class="filters-container mt-5">
                    <div class="dropdowns-container flex w-full items-end">
                        <MultiSelect
                            :options="silencerTypes"
                            :label="$t('productsList.productSpecs.silencerType')"
                            :placeholder="$t('productsList.filters.showAll')"
                            :modelValue="selectedSilencerTypes"
                            explicitSelection
                            @update:modelValue="toggleSilencerTypesSelection"
                            class="flex-15 mr-8"
                        ></MultiSelect>
                        <MultiSelect
                            class="flex-15 mr-8"
                            :options="silencerFilterOptions.shapes"
                            :label="$t('productsList.productSpecs.shape')"
                            :placeholder="$t('productsList.filters.showAll')"
                            :disabled="!silencerFilterOptions.shapes.length"
                            explicitSelection
                            v-model="filters.shape"
                        ></MultiSelect>
                        <MultiSelect
                            class="flex-15 mr-8"
                            :options="silencerFilterOptions.attenuationMaterials"
                            :label="$t('productsList.productSpecs.attenuationMaterial')"
                            :placeholder="$t('productsList.filters.showAll')"
                            :disabled="!silencerFilterOptions.attenuationMaterials.length"
                            explicitSelection
                            v-model="filters.attenuationMaterial"
                        ></MultiSelect>
                        <MultiSelect
                            class="flex-15 mr-4"
                            :options="silencerFilterOptions.nominalSizes"
                            :label="$t('productsList.productSpecs.nominalSize')"
                            :placeholder="$t('productsList.filters.showAll')"
                            :disabled="!silencerFilterOptions.nominalSizes.length"
                            explicitSelection
                            v-model="filters.nominalSize"
                        ></MultiSelect>
                        <span class="text-small mb-3 mr-4">mm</span>
                        <MultiSelect
                            class="flex-15 mr-4"
                            :options="silencerFilterOptions.lengths"
                            :label="$t('productsList.productSpecs.length')"
                            :placeholder="$t('productsList.filters.showAll')"
                            :disabled="!silencerFilterOptions.lengths.length"
                            explicitSelection
                            v-model="filters.length"
                        ></MultiSelect>
                        <span class="text-small mb-3 mr-4">mm</span>
                        <MultiSelect
                            class="flex-15 mr-4"
                            :options="silencerFilterOptions.openable"
                            :label="$t('productsList.productSpecs.openable')"
                            :placeholder="$t('productsList.filters.showAll')"
                            :disabled="!silencerFilterOptions.openable.length"
                            explicitSelection
                            v-model="filters.openable"
                        ></MultiSelect>
                    </div>
                    <div class="flex flex-15 flex-col items-center justify-start">
                        <div class="row switch-container p-0 justify-between items-center w-full mb-1">
                            <!-- <div class="title">{{ $t('productsList.controls.showDiffusers') }}</div> -->
                            <div class="title m-0">{{ $t('productsList.controls.showDiffusers') }}</div>
                            <Switch v-model="showDiffusers"></Switch>
                        </div>
                        <div class="row switch-container p-0 justify-between items-center w-full mb-1">
                            <div class="title m-0">{{ $t('productsList.controls.showOther') }}</div>
                            <Switch v-model="showOther"></Switch>
                        </div>
                        <div class="row switch-container p-0 justify-between items-center w-full">
                            <div class="title m-0">{{ $t('productsList.controls.showCustom') }}</div>
                            <Switch v-model="showCustomProducts"></Switch>
                        </div>
                    </div>
                </div>
            </div>

            <div class="small-title products-count mt-8 mb-8 flex w-full justify-between">
                <div><b>{{ $t('productsList.controls.showingProductsAmount', [silencers.length, displayableSilencers.length]) }}</b></div>
                <LayoutControls v-model="layout"/>
            </div>

            <div class="card column mb-8 diffuser-filters" v-if="showDiffusers">
                <div class="row heading-small-bold mb-4 p-0">{{ $t('productsList.filters.filterDiffusers') }}</div>
                <div class="filters-container">
                    <MultiSelect
                        :options="diffuserTypes"
                        :label="$t('productsList.productSpecs.diffuserType')"
                        explicitSelection
                        v-model="selectedDiffuserTypes"
                        class="flex-15 mr-8"
                    ></MultiSelect>
                    <MultiSelect
                        class="flex-15 mr-4"
                        :options="diffuserFilterOptions.nominalSizes"
                        :label="$t('productsList.productSpecs.nominalSize')"
                        :disabled="!diffuserFilterOptions.nominalSizes.length"
                        explicitSelection
                        v-model="selectedDiffuserFilters.nominalSize"
                    ></MultiSelect>
                    <span class="text-small mt-6 mr-4">mm</span>
                </div>
            </div>
            <DiffusersList v-if="showDiffusers" :diffusers="displayableDiffusers" :layout="layout"></DiffusersList>

            <template v-if="showOther">
                <hr class="w-full mt-6 mb-12">
                <AbstractProductsFilter @filtered="displayableAbstractProducts = $event"/>
                <AbstractProductsList :abstractProducts="displayableAbstractProducts" :layout="layout"></AbstractProductsList>
            </template>

            <template v-if="showCustomProducts">
                <hr class="w-full mt-6 mb-12">
                <CustomProductsList :products="customProducts" :layout="layout">
                    <template v-slot:products-list-start>
                        <CustomProductInsertion :direction="layout === 'grid' ? 'vertical' : 'horizontal'" />
                    </template>
                </CustomProductsList>
            </template>

            <hr v-if="showDiffusers || showCustomProducts || showOther" class="w-full mt-6 mb-12">
            <RectSilencersList :silencers="rectSilencers" :layout="layout">
                <template v-slot:products-list-end>
                    <RectSilencerInsertion :direction="layout === 'grid' ? 'vertical' : 'horizontal'"></RectSilencerInsertion>
                </template>
            </RectSilencersList>

            <hr class="w-full mt-6 mb-12">
            <SilencersList :silencers="displayableSilencers" :layout="layout"></SilencersList>
        </div>

        <CalculationsPanel class="mt-16" :navigation="true"></CalculationsPanel>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InputField from '../general/InputField.vue';
import MultiSelect from '../general/MultiSelect.vue';
import Switch from '../general/Switch.vue';
import Breadcrumbs from '../general/Breadcrumbs.vue';
import SilencersList from './silencers/SilencersList.vue';
import RectSilencersList from './rect-silencers/RectSilencersList.vue';
import DiffusersList from './diffusers/DiffusersList.vue';
import AbstractProductsList from './abstract/AbstractProductsList.vue';
import AbstractProductsFilter from './abstract/AbstractProductsFilter.vue';
import CalculationsPanel from '../systems/calculations/CalculationsPanel.vue';
import RectSilencerInsertion from '@/components/products/rect-silencers/RectSilencerInsertion.vue';
import CustomProductInsertion from '@/components/products/custom/CustomProductInsertion.vue';
import CustomProductsList from '@/components/products/custom/CustomProductsList.vue';
import LayoutControls from '@/components/products/product/LayoutControls.vue';

export default {
    name: 'ProductsList',
    components: {
        Breadcrumbs,
        InputField,
        MultiSelect,
        Switch,
        SilencersList,
        RectSilencersList,
        DiffusersList,
        AbstractProductsList,
        AbstractProductsFilter,
        CalculationsPanel,
        RectSilencerInsertion,
        CustomProductInsertion,
        CustomProductsList,
        LayoutControls,
    },
    inject: ['resolveTranslationMapValue'],
    async created() {
        this.applyFilters();
        this.applyDiffuserFilters();
        await this.calculateSummary();
    },
    data() {
        return {
            layout: 'linear',
            showDiffusers: false,
            showOther: false,
            showCustomProducts: false,
            displayableSilencers: [],
            displayableDiffusers: [],
            displayableAbstractProducts: [],
            selectedDiffuserTypes: [],
            filters: {
                searchString: '',
                shape: [],
                attenuationMaterial: [],
                nominalSize: [],
                length: [],
                openable: [],
            },
            selectedDiffuserFilters: {
                nominalSize: [],
            }
        };
    },
    computed: {
        ...mapState('activeSystemModule', {
            'selectedProducts': 'selectedProducts',
            'systemName': 'name',
        }),
        ...mapState('activeProjectModule', {
            'projectName': 'name',
        }),
        ...mapState('silencersModule', {
            'silencers': 'silencers',
            'silencerFilters': 'filters',
        }),
        ...mapState('diffusersModule', {
            'diffusers': 'diffusers',
            'diffuserFilters': 'filters',
        }),
        ...mapState('diffuserClassesModule', ['diffuserClasses']),
        ...mapGetters('silencerClassesModule', ['silencerClasses', 'selectedSilencerClasses']),
        ...mapGetters('activeSystemModule', ['selectedProductsMap']),
        breadcrumbs() {
            return [
              {
                title: this.$t('productsList.breadcrumbs.home'),
                link: { name: 'projects' },
              },
              {
                title: this.projectName || this.$t('breadcrumbs.activeProject'),
                link: { name: 'systems' },
              },
              {
                title: this.systemName || this.$t('breadcrumbs.activeSystem'),
                link: { name: 'silencers' },
              },
              {
                title: this.$t('productsList.breadcrumbs.productsList'),
              }
            ];
        },
        silencerTypes() {
            return this.silencerClasses.map((silencerClass) => ({
                title: this.resolveTranslationMapValue(silencerClass.title),
                value: silencerClass.name, // .id
            }));
        },
        diffuserTypes() {
            return this.diffuserClasses.map((diffuserClass) => ({
                title: this.resolveTranslationMapValue(diffuserClass.title),
                value: diffuserClass.name,
            }));
        },
        selectedSilencerTypes() {
            return this.selectedSilencerClasses.map((silencerClass) => silencerClass.name);
        },
        abstractProducts() {
            return Object.values(this.selectedProductsMap).filter((product) => product.type === 'abstract');
        },
        customProducts() {
            return Object.values(this.selectedProductsMap).filter((product) => product.type === 'custom');
        },
        selectedDiffusers() {
            return Object.values(this.selectedProductsMap).filter((product) => product.type === 'diffuser');
        },
        rectSilencers() {
            return Object.values(this.selectedProductsMap).filter((product) => product.type === 'rectSilencer');
        },
        silencerFilterOptions() {
            const filterOptions = {
                shapes: new Set(),
                attenuationMaterials: new Set(),
                nominalSizes: new Set(),
                lengths: new Set(),
                openable: new Set(),
            };
            const filters = [];
            const silencerTypes = this.selectedSilencerTypes.length
                ? this.selectedSilencerTypes
                : this.silencerClasses.map((silencerClass) => silencerClass.name);
            silencerTypes.forEach((silencerClass) => {
                if (!(silencerClass in this.silencerFilters)) {
                    return;
                }
                filters.push(this.silencerFilters[silencerClass]);
            });
            filters.forEach((filter) => {
                filterOptions.shapes.add(filter.shape);
                filterOptions.attenuationMaterials.add(filter.attenuationMaterial);
                filterOptions.openable.add(filter.openable);
                filter.nominalSizes.forEach(filterOptions.nominalSizes.add, filterOptions.nominalSizes);
                filter.lengths.forEach(filterOptions.lengths.add, filterOptions.lengths);
            });

            return {
                shapes: Array.from(filterOptions.shapes)
                             .map((shape) => ({ title: this.$t(`productAttributes.shape.${shape.replace(" ", "")}`), value: shape })),
                attenuationMaterials: Array.from(filterOptions.attenuationMaterials)
                                            .map((attenuationMaterial) => ({ title: this.$t(`productAttributes.attenuationMaterial.${attenuationMaterial.replace(" ", "")}`), value: attenuationMaterial })),
                nominalSizes: Array.from(filterOptions.nominalSizes)
                                   .sort(this.ascendingSort)
                                   .map((nominalSize) => ({ title: String(nominalSize), value: Number(nominalSize) })),
                lengths: Array.from(filterOptions.lengths)
                              .sort(this.ascendingSort)
                              .map((length) => ({ title: String(length), value: Number(length) })),
                openable: Array.from(filterOptions.openable)
                               .map((openable) => ({ title: openable ? this.$t('productAttributes.openable') : this.$t('productAttributes.notOpenable'), value: openable })),
            };
        },
        diffuserFilterOptions() {
            const filterOptions = {
                nominalSizes: new Set(),
            };
            const filters = [];
            this.selectedDiffuserTypes.forEach((diffuserClass) => {
                if (!(diffuserClass in this.diffuserFilters)) {
                    return;
                }
                filters.push(this.diffuserFilters[diffuserClass]);
            });
            filters.forEach((filter) => {
                filter.nominalSizes.forEach(filterOptions.nominalSizes.add, filterOptions.nominalSizes);
            });

            return {
                nominalSizes: Array.from(filterOptions.nominalSizes)
                                   .sort(this.ascendingSort)
                                   .map((nominalSize) => ({ title: String(nominalSize), value: nominalSize })),
            };
        },
    },
    methods: {
        ...mapActions('silencerClassesModule', [
            'toggleSilencerClassSelection',
            'clearSelection',
        ]),
        ...mapActions('activeSystemModule', ['calculateSummary']),
        toggleSilencerTypesSelection(selectedTypes) {
            if (!selectedTypes.length) {
                this.clearSelection();
                return;
            }
            const typesToToggle = this.symmetricDifference(this.selectedSilencerTypes, selectedTypes);
            if (!typesToToggle.length) {
                return;
            }
            typesToToggle.forEach((type) => this.toggleSilencerClassSelection({ identity: type }));
        },
        applyFilters() {
            const filtered = this.silencers.filter((silencer) => {
                if (this.selectedSilencerTypes.length && !this.selectedSilencerTypes.includes(silencer.class)) {
                    return false;
                }
                if (this.filters.searchString.length >= 2 && silencer.name.toLowerCase().indexOf(this.filters.searchString.toLowerCase()) < 0) {
                    return false;
                }
                if (this.filters.shape.length && !this.filters.shape.includes(silencer.shape)) {
                    return false;
                }
                if (this.filters.attenuationMaterial.length && !this.filters.attenuationMaterial.includes(silencer.attenuationMaterial)) {
                    return false;
                }
                if (this.filters.nominalSize.length && !this.filters.nominalSize.includes(silencer.nominalSizeDiameter)) {
                    return false;
                }
                if (this.filters.length.length && !this.filters.length.includes(silencer.length)) {
                    return false;
                }
                if (this.filters.openable.length && !this.filters.openable.includes(silencer.openable)) {
                    return false;
                }

                return true;
            });
            const filteredGroups = {};
            filtered.forEach((product) => {
                if (product.class in filteredGroups) {
                    filteredGroups[product.class].push(product);
                    return;
                }
                filteredGroups[product.class] = [ product ];
            });
            // eslint-disable-next-line no-unused-vars
            Object.entries(filteredGroups).forEach(([ productClass, products ]) => {
                products.sort(this.ascendingProductsSort);
            });

            this.displayableSilencers = [].concat(...Object.values(filteredGroups));
        },
        applyDiffuserFilters() {
            const filtered = this.diffusers.filter((diffuser) => {
                if (!this.selectedDiffuserTypes.includes(diffuser.class)) {
                    return false;
                }
                if (this.selectedDiffuserFilters.nominalSize.length && !this.selectedDiffuserFilters.nominalSize.includes(diffuser.nominalSize)) {
                    return false;
                }

                return true;
            });
            const filteredGroups = {};
            filtered.forEach((product) => {
                if (product.class in filteredGroups) {
                    filteredGroups[product.class].push(product);
                    return;
                }
                filteredGroups[product.class] = [ product ];
            });
            // eslint-disable-next-line no-unused-vars
            Object.entries(filteredGroups).forEach(([ productClass, products ]) => {
                products.sort(this.ascendingProductsSort);
            });

            this.displayableDiffusers = [].concat(...Object.values(filteredGroups));
        },
        symmetricDifference(array1, array2) {
            return array1
                .filter((item) => !array2.includes(item))
                .concat(array2.filter((item) => !array1.includes(item)));
        },
        capitalize(value) {
            const str = String(value);
            
            return `${str[0].toUpperCase()}${str.substring(1)}`;
        },
        ascendingSort(a, b) {
            return a < b ? -1 : 1;
        },
        ascendingProductsSort(left, right) {
            const leftDiameter = left.nominalSize || left.nominalSizeDiameter || 0;
            if (!leftDiameter) {
                return 0;
            }
            const rightDiameter = right.nominalSize || right.nominalSizeDiameter || 0;
            if (!rightDiameter) {
                return 0;
            }
            if (leftDiameter < rightDiameter) {
                return -1;
            }
            if (leftDiameter > rightDiameter) {
                return 1;
            }

            return (left.length || 0) < (right.length || 0) ? -1 : 1;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.applyFilters();
            },
        },
        selectedDiffuserFilters: {
            deep: true,
            handler() {
                this.applyDiffuserFilters();
            },
        },
        selectedSilencerTypes() {
            this.applyFilters();
        },
        selectedDiffuserTypes() {
            this.applyDiffuserFilters();
        },
        silencers() {
            this.applyFilters();
        },
    }
}
</script>

<style lang="scss">
@use 'src/assets/css/product/productsList.scss';
</style>

<style scoped>
.card .search-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
}

.card .filters-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.card .search-container .input-container {
    flex: 1 1 auto;
    margin-right: 6px;
}

.card .search-container .btn {
    background: #4C5055;
    border-radius: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 8px 32px;
    min-height: 32px;
    box-sizing: border-box;
}

.card .filters-container .input-container {
    flex: 1 1 auto;
    margin-right: 6px;
}

.card .filters-container .input-container:last-of-type {
    margin-right: 0;
}

.small-title {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
    line-height: 14px;
    color: #737372;
}

.switch-container .title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4C5055;
    user-select: none;
}

.w-90 {
    max-width: 90px;
    width: 90px;
}

.flex-15 {
    flex: 0 1 15% !important;
}

.products-count {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 12px;
    line-height: 14px;
    color: #737372;
}

.filter-card {
    position: sticky;
    top: 60px;
    z-index: 10;
}

.diffuser-filters {
    z-index: 1;
}
</style>

<style lang="scss" scoped>
    .heading-small-bold {
        font-size: 18px;
        text-transform: uppercase;
        color: #737372;
    }

    .switch {
        width: 75px;
        min-width: 75px;
    }
</style>