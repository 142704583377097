<template>
    <div class="product-properties">
        <div class="product-name">{{ product.name }} {{ resolveTranslationMapValue(product.nameAppendix) }}</div>
        <div class="property">
            <div class="property-name">{{ $t('productsList.productSpecs.diameter') }}, D:</div>
            <div class="property-value">{{ product.nominalSizeDiameter }} mm</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('productsList.productSpecs.length') }}, L:</div>
            <div class="property-value">{{ product.length }} mm</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('productsList.productSpecs.absorptionMaterial') }}:</div>
            <div class="property-value">{{ $t(`productAttributes.attenuationMaterial.${product.attenuationMaterial.replace(" ", "")}`) }}</div>
        </div>
        <div class="property" v-if="product.inputAirflow !== undefined">
            <div class="property-name">{{ $t('rectSilencerData.inputAirflow') }}, l/s:</div>
            <div class="property-value">{{ Math.round(product.inputAirflow) }}</div>
        </div>
        <div class="property" v-if="product.pressureDrop !== undefined">
            <div class="property-name">{{ $t('rectSilencerData.pressureDrop') }}, Pa:</div>
            <div class="property-value">{{ Math.round(product.pressureDrop) }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SilencerProperties',
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
    },
    inject: ['resolveTranslationMapValue'],
}
</script>

<style lang="scss">
</style>
