<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal" size="medium">
        <template v-slot:modal-header>
            <div class="mr-4">{{ product.name || title || '' }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="tabs">
                <div class="tabs-header">
                    <!-- <div class="tab-header" :class="{ 'active-tab-header': activeTab === 'general' }" @click="activeTab = 'general'">General</div> -->
                    <div class="tab-header" :class="{ 'active-tab-header': activeTab === 'soundPower' }" @click="activeTab = 'soundPower'">{{ $t('projectBreakdown.soundPower') }}</div>
                    <div class="tab-header" :class="{ 'active-tab-header': activeTab === 'soundAttenuation' }" @click="activeTab = 'soundAttenuation'">{{ $t('projectBreakdown.attenuation') }}</div>
                </div>
                <div class="tabs-content mt-2">
                    <!-- <div class="tab" :class="{ 'active-tab': activeTab === 'general' }">
                    </div> -->
                    <div class="tab" :class="{ 'active-tab': activeTab === 'soundPower' }">
                        <OctaveBandRangeEditor :modelValue="product.soundPowerLevel" @update:modelValue="onSoundPowerChanged">
                            <template v-slot:title>
                                <div class="row">
                                    <div class="title justify-start">{{ $t('octaveBandAdjustmentModal.octaveBand') }} (Hz)</div>
                                </div>
                            </template>
                        </OctaveBandRangeEditor>
                    </div>
                    <div class="tab" :class="{ 'active-tab': activeTab === 'soundAttenuation' }">
                        <OctaveBandRangeEditor :modelValue="product.soundAttenuation" @update:modelValue="onSoundAttenuationChanged">
                            <template v-slot:title>
                                <div class="row">
                                    <div class="title justify-start">{{ $t('octaveBandAdjustmentModal.octaveBand') }} (Hz)</div>
                                </div>
                            </template>
                        </OctaveBandRangeEditor>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:modal-actions>
            <div class="column">
                <div class="row p-0 justify-start">
                    <InlineDialog type="success">{{ $t('octaveBandAdjustmentModal.projectLevelChanges') }}</InlineDialog>
                </div>
            </div>
            <div class="column">
                <div class="row justify-end pr-0">
                    <Button type="secondary" class="mr-4" outlined size="medium" @click="closeModal">{{ $t('commonControls.close') }}</Button>
                    <Button type="primary" size="medium" class="flex-50" @click="nextTab">{{ $t('commonControls.next') }}</Button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Button from '../../general/Button.vue';
import Modal from '../../general/Modal.vue';
import OctaveBandRangeEditor from '@/components/products/product/OctaveBandRangeEditor.vue';
import InlineDialog from '@/components/general/InlineDialog.vue';

export default {
    name: 'EditSilencerModal',
    components: {
        OctaveBandRangeEditor,
        InlineDialog,
        Button,
        Modal,
    },
    props: {
        modelValue: Boolean,
        product: Object,
        title: String,
    },
    emits: [
        'update:modelValue',
        'confirm',
        'close',
        'onSoundPowerChanged',
        'onSoundAttenuationChanged',
    ],
    data() {
        return {
            activeTab: 'soundPower',
        };
    },
    methods: {
        closeModal() {
            this.$emit('update:modelValue', false);
            this.$emit('close');
        },
        nextTab() {
            // if (this.activeTab === 'general') {
            //     this.activeTab = 'soundPower';
            //     return;
            // }
            if (this.activeTab === 'soundPower') {
                this.activeTab = 'soundAttenuation';
                return;
            }
            if (this.activeTab === 'soundAttenuation') {
                this.closeModal();
                return;
            }
        },
        onSoundPowerChanged(event) {
            this.$emit('onSoundPowerChanged', event);
        },
        onSoundAttenuationChanged(event) {
            this.$emit('onSoundAttenuationChanged', event);
        },
    },
}
</script>

<style scoped lang="scss">
.header-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    border-top: 1px solid #CC0000;
    border-bottom: 1px solid #CC0000;
    padding: 8px 0;
}

.header-container div {
    flex: 1 1 auto;
    color: #737372;
}

.tabs-content {
    margin: 20px 0;
    min-height: 150px;
}
</style>