import { emptyTranslationMap, transformTranslationMap } from "../__general";

function transformSilencerClass(silencerClass) {
    if (!silencerClass._id || !silencerClass.name) {
        return null;
    }

    return {
        id: silencerClass._id,
        name: silencerClass.name,
        parentClassId: silencerClass.parentClassId || null,
        title: silencerClass.title ? transformTranslationMap(silencerClass.title) : emptyTranslationMap(),
        imageURL: silencerClass.imageURL,
        content: silencerClass.content,
        selected: false,
        groupingClass: silencerClass.name.indexOf('/') >= 0,
    };
}

export default {
    transform(silencerClasses) {
        const result = {};
        silencerClasses.forEach((silencerClass) => {
            const transformed = transformSilencerClass(silencerClass);
            if (!transformed) {
                return;
            }
            result[transformed.id] = transformed;
        });
        Object.keys(result).forEach((classId) => {
            const silencerClass = result[classId];
            silencerClass.parent = null;
            if (!silencerClass.parentClassId) {
                return;
            }
            silencerClass.parent = result[silencerClass.parentClassId] || null;
        });

        return Object.values(result);
    }
}
