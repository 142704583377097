<template>
    <div class="p-2">
        <p v-html="$t('rectSilencerModal.tooltips.outerCover')"></p>
    </div>
</template>

<script>
export default {
    name: 'OuterCover',
}
</script>