function emptyOctaveBandRange() {
    return {
        octaveBand63Hz: 0,
        octaveBand125Hz: 0,
        octaveBand250Hz: 0,
        octaveBand500Hz: 0,
        octaveBand1000Hz: 0,
        octaveBand2000Hz: 0,
        octaveBand4000Hz: 0,
        octaveBand8000Hz: 0,
    };
}

function resolveNumber(value, fallback = 0) {
    if (isNaN(value)) {
        return fallback;
    }

    return Number(value);
}

function transformAbstractProduct(abstractProduct) {
    if (!abstractProduct._id || !abstractProduct.class || !abstractProduct.name) {
        return null;
    }
    const transformed = {
        id: abstractProduct._id,
        type: 'abstract',
        name: abstractProduct.name,
        class: abstractProduct.class,
        imageURL: abstractProduct.imageURL || null,
        quantity: 0,
    };
    const { soundAttenuation = {} } = abstractProduct;
    transformed.defaultSoundAttenuation = {
        octaveBand63Hz: resolveNumber(soundAttenuation.octaveBand63Hz, 0),
        octaveBand125Hz: resolveNumber(soundAttenuation.octaveBand125Hz, 0),
        octaveBand250Hz: resolveNumber(soundAttenuation.octaveBand250Hz, 0),
        octaveBand500Hz: resolveNumber(soundAttenuation.octaveBand500Hz, 0),
        octaveBand1000Hz: resolveNumber(soundAttenuation.octaveBand1000Hz, 0),
        octaveBand2000Hz: resolveNumber(soundAttenuation.octaveBand2000Hz, 0),
        octaveBand4000Hz: resolveNumber(soundAttenuation.octaveBand4000Hz, 0),
        octaveBand8000Hz: resolveNumber(soundAttenuation.octaveBand8000Hz, 0),
    };
    transformed.soundAttenuation = { ...transformed.defaultSoundAttenuation };
    transformed.defaultSoundPowerLevel = emptyOctaveBandRange();
    transformed.soundPowerLevel = emptyOctaveBandRange();

    return transformed;
}

export default {
    transform(abstractProducts) {
        const result = [];
        abstractProducts.forEach((abstractProduct) => {
            const transformed = transformAbstractProduct(abstractProduct);
            if (!transformed) {
                return;
            }
            result.push(transformed);
        });

        return result;
    }
}