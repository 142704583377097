<template>
    <div class="viewport" v-if="canProceed">
        <NoContent v-if="noContent || !activeSystem" />
        <SystemBreakdown
            v-else
            :system="activeSystem"
            :calculationResults="activeSystem.calculationResults"
            :drawFooter="true"
            :withProductInventoryTable="false"
        ></SystemBreakdown>
    </div>
    <div v-else class="loader-container">
        <div class="loader big-loader"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SystemBreakdown from '@/components/systems/pdf/SystemBreakdown.vue';
import NoContent from './NoContent.vue';
import * as SystemService from '@/services/systems';

export default {
    name: 'PrintSystem',
    components: {
        SystemBreakdown,
        NoContent,
    },
    data() {
        return {
            noContent: false,
            canProceed: false,
        };
    },
    async created() {
        try {
            const { query: {
                systemId = '',
                objectId = '',
                autoPrint = '1',
                locale = '',
            } = {} } = this.$route;
            let currentLocale = locale || localStorage.getItem('locale');
            if (!currentLocale) {
                currentLocale = 'english';
                localStorage.setItem('locale', currentLocale);
            }
            this.$i18n.locale = currentLocale;
            if (objectId) {
                await this.obtainBasicData();
                await this.processObjectId({
                    objectId,
                    autoPrint: (autoPrint === '1' || autoPrint === 'true'),
                });
                return;
            }
            if (systemId) {
                await this.processSystemId({
                    systemId,
                    autoPrint: (autoPrint === '1' || autoPrint === 'true'),
                });
                return;
            }
            this.noContent = true;
        } catch (error) {
            console.error(error);
        } finally {
            this.canProceed = true;
        }
    },
    computed: {
        ...mapGetters('activeSystemModule', ['activeSystem']),
    },
    methods: {
        ...mapActions('silencersModule', ['getSilencers']),
        ...mapActions('diffusersModule', ['getDiffusers']),
        ...mapActions('abstractProductsModule', ['getAbstractProducts']),
        ...mapActions('silencerClassesModule', ['getSilencerClasses']),
        ...mapActions('diffuserClassesModule', ['getDiffuserClasses']),
        ...mapActions('abstractClassesModule', ['getAbstractClasses']),
        ...mapActions('activeProjectModule', ['setProject']),
        ...mapActions('activeSystemModule', ['setSystem', 'calculateSummaryImmediately']),
        async obtainBasicData() {
            await Promise.all([
                this.getSilencers(),
                this.getDiffusers(),
                this.getAbstractProducts(),
                this.getSilencerClasses(),
                this.getDiffuserClasses(),
                this.getAbstractClasses(),
            ]);
        },
        async processObjectId({ objectId, autoPrint }) {
            try {
                if (!objectId) {
                    console.error('Object id is empty');
                    this.noContent = true;
                    return;
                }
                const objectUrl = `blob:${window.location.origin}/${objectId}`;
                await fetch(objectUrl).then(r => r.blob());
                const blob = await fetch(objectUrl);
                // const text = await (new Response(blob)).text();
                const text = await blob.text();
                const data = JSON.parse(text) || {};
                const { project = null, system = null } = data;
                if (!project || !system) {
                    console.error('Project or system has not been parsed');
                    this.noContent = true;
                    return;
                }
                await this.setProject(project);
                await this.setSystem(system);
                await this.calculateSummaryImmediately();
                if (autoPrint) {
                    setTimeout(() => {
                        window.print();
                    }, 1000);
                }
            } catch (error) {
                console.log(error);
                this.noContent = true;
            } finally {
                // URL.revokeObjectURL(objectUrl);
            }
            // const obj = {hello: 'world'};
            // const blob = new Blob([JSON.stringify(obj, null, 2)], {type : 'application/json'});
            // // const text = await (new Response(blob)).text();
            // const url = URL.createObjectURL(blob);

            // const stored = await fetch(url)
        },
        async processSystemId({ systemId, autoPrint }) {
            try {
                const system = await SystemService.getSystem(systemId);
                if (!system || !system.project) {
                    this.noContent = true;
                    return;
                }
                await this.setProject(system.project);
                await this.setSystem(system);
                await this.calculateSummaryImmediately();
                if (autoPrint) {
                    setTimeout(() => {
                        window.print();
                    }, 1000);
                }
            } catch (error) {
                console.log(error);
                this.noContent = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    .viewport {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        width:  fit-content;
        height: fit-content;
        padding: 0px;
        margin-top: -56px !important;
    }
</style>

<style lang="scss">
    // @use 'src/assets/css/main.scss';
    /* html, body {
        width:  fit-content;
        height: fit-content;
        padding: 0px;
        margin: 0px;
    } */
</style>