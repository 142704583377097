<template>
    <div v-if="generatingPDF" class="loader-container-overlap">
        <div class="loader big-loader"></div>
    </div>
    <BackgroundImage></BackgroundImage>
    <div class="page-content justify-start pb-8" v-if="dataReceived">
        <ProjectsList 
            v-if="projects.length" 
            :projects="projects" 
            @onCreateProject="showCreateProjectModal = true"
            @onPrintPDF="doPrinting"
        >
            <template v-slot:actions>
                <div class="flex justify-between">
                    <Button type="primary"  size="medium" outlined class="mr-4" @click="showQuickCalculationModal = true">{{ $t('projectsPage.quickCalculation') }}</Button>
                    <Button v-if="userAuthorized" type="primary" size="medium" @click="showCreateProjectModal = true">{{ $t('projectsPage.createNewProject') }}</Button>
                </div>
            </template>
        </ProjectsList>
        <div v-else class="no-project-placeholder">
            <img src="@/assets/no_project_placeholder.png" alt="No Project">
            <div class="title mt-5" v-if="!userAuthorized">{{ $t('projectsPage.loginToSaveProjects') }}</div>
        </div>
        <div v-if="!projects.length" class="actions-container mt-8 mb-8">
            <Button type="primary" outlined class="mr-4" @click="showQuickCalculationModal = true">{{ $t('projectsPage.quickCalculation') }}</Button>
            <Button v-if="userAuthorized" type="primary" @click="showCreateProjectModal = true">{{ $t('projectsPage.createNewProject') }}</Button>
        </div>
        <QuickCalculationModal size="medium" v-model="showQuickCalculationModal"></QuickCalculationModal>
        <CreateProjectModal size="medium" v-model="showCreateProjectModal"></CreateProjectModal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BackgroundImage from './BackgroundImage.vue';
import ProjectsList from './ProjectsList.vue';
import QuickCalculationModal from './QuickCalculationModal.vue';
import CreateProjectModal from './CreateProjectModal.vue';
import Button from '../general/Button.vue';

export default {
    name: 'Projects',
    components: {
        BackgroundImage,
        ProjectsList,
        QuickCalculationModal,
        CreateProjectModal,
        Button,
    },
    async created() {
    try {
      await this.getProjects();
      this.dataReceived = true;
    } catch (error) {
      console.error(error);
    }
  },
    data() {
        return {
            dataReceived: false,
            showProjectsList: true,
            showQuickCalculationModal: false,
            showCreateProjectModal: false,
            generatingPDF: false,
        };
    },
    computed: {
        ...mapState('activeProjectModule', { 'activeProjectId': 'id' }),
        ...mapGetters('projectsModule', ['projects']),
        ...mapGetters('userModule', ['userAuthorized']),
    },
    methods: {
        ...mapActions('projectsModule', ['getProjects']),
        doPrinting() {
            if (!this.activeProjectId) {
                return;
            }
            const url = `/printing/project?projectId=${this.activeProjectId}`;
            window.open(url, 'print-project', 'popup=0,width=595,height=842,toolbar=0,menubar=0,location=0').focus();
        },
    }
}
</script>

<style>
/* .breakdown-wrapper {
    display: flex;
    height: 0;
    position: relative;
    overflow: hidden;
} */

.page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    padding: 0 120px;
}

.no-project-placeholder {
    display: flex;
    width: 100%;
    min-height: 260px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.no-project-placeholder div {
    color: #737372;
}

.actions-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
</style>
