<template>
    <Header :show-auth-options="false"/>
    <router-view></router-view>
    <div v-if="$route && $route.name === 'modelling'" class="loader-container">
        <div class="loader big-loader"></div>
    </div>
    <Footer/>
</template>

<script>
import { mapActions } from 'vuex';
import Header from '../../general/Header.vue';
import Footer from '../../general/Footer.vue';
import { webAppLoaded } from '@/plugins/essential-objects/EOApiPlugin';

export default {
    name: 'ModellingPage',
    components: {
        Header,
        Footer,
    },
    async created() {
        try {
            await Promise.all([
                this.getSilencers(),
                this.getSilencerClasses(),
            ]);
            // Informing plugin about readiness:
            webAppLoaded();
            // Waiting for a message from plugin for some time, then
            await new Promise((resolve) => {
                window.firstPluginMessageTimeout = setTimeout(() => {
                    resolve();
                    if (this.$route && this.$route.name !== 'modelling') {
                        return;
                    }
                    this.$router.push({ name: 'silencer-classes-selector' });
                }, 4000);
            });
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        ...mapActions('silencersModule', ['getSilencers']),
        ...mapActions('silencerClassesModule', ['getSilencerClasses']),
    },
}
</script>

<style lang="scss">
@use 'src/assets/css/main.scss';

.row {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
}

.column {
    display: flex;
    flex-direction: column;
}

// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('./assets/fonts/HelveticaNeue.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Helvetica Neue Bold';
//   src: url('./assets/fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
// }

// div, p, span {
//   font-family: Helvetica Neue Bold;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 20px;
// }

#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    max-width: 100vw;
    padding-top: 56px;
}

body {
    margin: 0;
    position: relative;
    overflow-x: hidden;
}
</style>
