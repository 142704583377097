<template>
    <div :class="{ detailed: detailed }" class="w-full">
    <div class="products-list" :class="layoutClass">
      <slot name="products-list-start"></slot>
      <template v-for="product in products" :key="product.id">
        <ProductCard class="mb-6" :direction="cardDirection">
            <template v-slot:product-image>
                <CustomImageDropdown @imageSelected="onImageSelected(product, $event)">
                    <template v-slot:main-image>
                        <img :src="imageURL(product)" alt="Custom Product Image">
                    </template>
                </CustomImageDropdown>
            </template>
            <template v-slot:product-properties>
                <div class="product-properties mb-3">
                    <InputField :readOnly="readOnly" :placeholder="$t('productsList.productSpecs.productName')" :modelValue="product.name" @update:modelValue="updateAbstractAttribute(product, 'name', $event)"></InputField>
                    <InputField :readOnly="readOnly" :placeholder="$t('productsList.productSpecs.productCode')" :modelValue="product.code" @update:modelValue="updateAbstractAttribute(product, 'code', $event)" class="mt-3"></InputField>
                </div>
            </template>
            <template v-slot:product-sound-adjustment>
                <div class="action-link"></div>
            </template>
            <template v-slot:product-actions>
                <div class="column align-items-center">
                    <ProductQuantitySelector :modelValue="productQuantity(product)" @increased="onSelectProduct(product)" @decreased="onUnselectProduct(product)"></ProductQuantitySelector>
                    <div class="action-link mt-2" @click="openSilencerEditingModal(product)">{{ $t('productsList.productSpecs.soundData') }}</div>
                </div>
            </template>
        </ProductCard>
      </template>
      <slot name="products-list-end"></slot>
    </div>
    <EditSoundDataModal
        v-model="editSoundDataModalOpened"
        title="Custom product"
        :product="adjustingProduct"
        @onSoundPowerChanged="onSoundPowerChanged"
        @onSoundAttenuationChanged="onSoundAttenuationChanged"
        @close="onSilencerEditingModalClosed"
    ></EditSoundDataModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ProductCard from '@/components/products/product/ProductCard.vue';
import ProductQuantitySelector from '@/components/products/product/ProductQuantitySelector.vue';
import InputField from '@/components//general/InputField.vue';
import CustomImageDropdown from '@/components/products/custom/CustomImageDropdown.vue';
import EditSoundDataModal from '@/components/products/abstract/EditSoundDataModal.vue';

const DEFAULT_IMAGE_URL = require(`@/assets/abstract_product.svg`);

export default {
    name: 'CustomProductsList',
    components: {
        ProductCard,
        ProductQuantitySelector,
        InputField,
        EditSoundDataModal,
        CustomImageDropdown,
    },
    props: {
        products: {
            type: Array,
            default: () => ([]),
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        detailed: {
            type: Boolean,
            default: false,
        },
        layout: {
            type: String,
            default: 'linear',
            validator: (value) => (['linear', 'grid'].includes(value)),
        },
    },
    data() {
        return {
            editSoundDataModalOpened: false,
            adjustingProduct: null,
            readOnlyMode: false,
        };
    },
    computed: {
        ...mapState('activeSystemModule', ['productQuantitiesMap']),
        layoutClass() {
            return {
                'linear-layout': this.layout === 'linear',
                'grid-layout': this.layout === 'grid',
            };
        },
        cardDirection() {
            return this.layout === 'linear' ? 'horizontal' : 'vertical';
        },
    },
    methods: {
        ...mapActions('activeSystemModule', [
            'addProduct',
            'removeProductById',
            'calculateSummary',
            'setAbstractProductAttribute',
        ]),
        async onSoundPowerChanged(octaveBandRange) {
            if (!this.adjustingProduct || !this.adjustingProduct.id) {
                return;
            }
            await this.setAbstractProductAttribute({
                productId: this.adjustingProduct.id,
                attributeName: 'soundPowerLevel',
                attributeValue: octaveBandRange,
            });
            await this.calculateSummary();
        },
        async onSoundAttenuationChanged(octaveBandRange) {
            if (!this.adjustingProduct || !this.adjustingProduct.id) {
                return;
            }
            await this.setAbstractProductAttribute({
                productId: this.adjustingProduct.id,
                attributeName: 'soundAttenuation',
                attributeValue: octaveBandRange,
            });
            await this.calculateSummary();
        },
        onSelectProduct(product) {
            if (!product) {
                return;
            }
            this.addProduct({ product });
        },
        onUnselectProduct(product) {
            if (!product || !product.id) {
                return;
            }
            this.removeProductById({ productId: product.id });
        },
        onImageSelected(product, imageURL) {
            if (!product || !imageURL) {
                return;
            }
            this.updateAbstractAttribute(product, 'imageURL', imageURL);
        },
        updateAbstractAttribute(product, attributeName, attributeValue) {
            this.setAbstractProductAttribute({ productId: product.id, attributeName, attributeValue });
        },
        imageURL(product) {
            if (product.imageURL === '') {
                return DEFAULT_IMAGE_URL;
            }
            return product.imageURL;
        },
        productQuantity(product) {
            if (!product || !product.id || !this.productQuantitiesMap) {
                return 0;
            }
            if (product.id in this.productQuantitiesMap) {
                return this.productQuantitiesMap[product.id];
            }

            return 0;
        },
        openSilencerEditingModal(product) {
            if (!product) {
                return;
            }
            this.adjustingProduct = product;
            this.editSoundDataModalOpened = true;
        },
        onSilencerEditingModalClosed() {
            this.adjustingProduct = null;
        },
    },
}
</script>
