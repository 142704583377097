<template>
  <div :class="{ detailed: detailed }" class="w-full">
    <div v-if="selectedProducts.length" class="products-list" :class="layoutClass">
      <template v-for="(product, index) in selectedProducts" :key="`${product.id}:${index}`">
            <ProductCard
                v-if="product.type === 'silencer'"
                class="mb-6"
                :direction="cardDirection"
                :allowInsertionBefore="index === 0"
                allowInsertionAfter
                @insertBefore="onInsert({ index, type: 'before' })"
                @insertAfter="onInsert({ index, type: 'after' })">
                <template v-slot:product-image>
                    <img :src="resolveSilencerClassImage(product.class)" alt="Silencer Image">
                </template>
                <template v-slot:product-properties>
                    <SilencerProperties :product="product" class="mb-3"/>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="action-link" @click="adjustSoundPower(product)">{{ $t('productsList.controls.adjustSoundPower') }}</div>
                    <div class="action-link" @click="adjustSoundAttenuation(product)">{{ $t('productsList.controls.attenuation') }}</div>
                </template>
                <template v-slot:product-actions>
                    <div class="flex flex-col justify-between">
                        <div class="icon icon-large icon-trashbin mb-8" @click="removeProduct({ index })"></div>
                        <div v-if="index > 0" class="icon icon-large icon-arrow-up mb-8" @click="swapProducts({ sourceIndex: index, targetIndex: index - 1  })"></div>
                        <div v-if="index < selectedProducts.length - 1" class="icon icon-large icon-arrow-down" @click="swapProducts({ sourceIndex: index, targetIndex: index + 1  })"></div>
                    </div>
                </template>
            </ProductCard>
            <ProductCard
                v-else-if="product.type === 'diffuser'"
                class="mb-6"
                :direction="cardDirection"
                :allowInsertionBefore="index === 0"
                allowInsertionAfter
                @insertBefore="onInsert({ index, type: 'before' })"
                @insertAfter="onInsert({ index, type: 'after' })">
                <template v-slot:product-image>
                    <img :src="resolveDiffuserClassImage(product.class)" alt="Diffuser Image">
                </template>
                <template v-slot:product-properties>
                    <DiffuserProperties :product="product" class="mb-3"/>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="action-link" @click="adjustSoundPower(product)">{{ $t('productsList.controls.adjustSoundPower') }}</div>
                    <div class="action-link" @click="adjustSoundAttenuation(product)">{{ $t('productsList.controls.attenuation') }}</div>
                </template>
                <template v-slot:product-actions>
                    <div class="flex flex-col justify-between">
                        <div class="icon icon-large icon-trashbin mb-8" @click="removeProduct({ index })"></div>
                        <div v-if="index > 0" class="icon icon-large icon-arrow-up mb-8" @click="swapProducts({ sourceIndex: index, targetIndex: index - 1  })"></div>
                        <div v-if="index < selectedProducts.length - 1" class="icon icon-large icon-arrow-down" @click="swapProducts({ sourceIndex: index, targetIndex: index + 1  })"></div>
                    </div>
                </template>
            </ProductCard>
            <ProductCard
                v-else-if="product.type === 'rectSilencer'"
                class="mb-6"
                :direction="cardDirection"
                :allowInsertionBefore="index === 0"
                allowInsertionAfter
                @insertBefore="onInsert({ index, type: 'before' })"
                @insertAfter="onInsert({ index, type: 'after' })">
                <template v-slot:product-image>
                    <img :src="resolveRectSilencerClassImage(product.class, product.orientation)" alt="Rect Silencer Image">
                </template>
                <template v-slot:product-properties>
                    <RectSilencerProperties :product="product" class="mb-3"/>
                </template>
                <template v-slot:product-sound-adjustment>
                    <!-- <div class="action-link" @click="adjustSoundPower(product)">{{ $t('productsList.controls.adjustSoundPower') }}</div> -->
                    <div class="action-link" @click="adjustSoundAttenuation(product)">{{ $t('productsList.controls.attenuation') }}</div>
                </template>
                <template v-slot:product-actions>
                    <div class="flex flex-col justify-between">
                        <div class="icon icon-large icon-trashbin mb-8" @click="removeProduct({ index })"></div>
                        <div class="icon icon-large icon-pencil mb-8" @click="editRectSilencer(product, index)"></div>
                        <div v-if="index > 0" class="icon icon-large icon-arrow-up mb-8" @click="swapProducts({ sourceIndex: index, targetIndex: index - 1  })"></div>
                        <div v-if="index < selectedProducts.length - 1" class="icon icon-large icon-arrow-down" @click="swapProducts({ sourceIndex: index, targetIndex: index + 1  })"></div>
                    </div>
                </template>
            </ProductCard>
            <ProductCard
                v-else-if="product.type === 'abstract'"
                class="mb-6"
                :direction="cardDirection"
                :allowInsertionBefore="index === 0"
                allowInsertionAfter
                @insertBefore="onInsert({ index, type: 'before' })"
                @insertAfter="onInsert({ index, type: 'after' })">
                <template v-slot:product-image>
                    <img :src="abstractImageURL(product)" alt="Abstract Product Image">
                </template>
                <template v-slot:product-properties>
                    <div class="product-properties mb-3">
                        <InputField readOnly :placeholder="$t('productsList.productSpecs.productName')" :modelValue="product.name"></InputField>
                        <InputField readOnly :placeholder="$t('productsList.productSpecs.productCode')" :modelValue="product.code" @update:modelValue="updateAbstractAttribute(product, 'code', $event)" class="mt-3"></InputField>
                    </div>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="action-link" @click="adjustSoundPower(product)">{{ $t('productsList.controls.adjustSoundPower') }}</div>
                    <div class="action-link" @click="adjustSoundAttenuation(product)">{{ $t('productsList.controls.attenuation') }}</div>
                </template>
                <template v-slot:product-actions>
                    <div class="flex flex-col justify-between">
                        <div class="icon icon-large icon-trashbin mb-8" @click="removeProduct({ index })"></div>
                        <div v-if="index > 0" class="icon icon-large icon-arrow-up mb-8" @click="swapProducts({ sourceIndex: index, targetIndex: index - 1  })"></div>
                        <div v-if="index < selectedProducts.length - 1" class="icon icon-large icon-arrow-down" @click="swapProducts({ sourceIndex: index, targetIndex: index + 1  })"></div>
                    </div>
                </template>
            </ProductCard>
            <ProductCard
                v-else-if="product.type === 'custom'"
                class="mb-6"
                :direction="cardDirection"
                :allowInsertionBefore="index === 0"
                allowInsertionAfter
                @insertBefore="onInsert({ index, type: 'before' })"
                @insertAfter="onInsert({ index, type: 'after' })">
                <template v-slot:product-image>
                    <img :src="abstractImageURL(product)" alt="Custom Product Image">
                </template>
                <template v-slot:product-properties>
                    <div class="product-properties mb-3">
                        <InputField readOnly :placeholder="$t('productsList.productSpecs.productName')" :modelValue="product.name"></InputField>
                        <InputField readOnly :placeholder="$t('productsList.productSpecs.productCode')" :modelValue="product.code" @update:modelValue="updateAbstractAttribute(product, 'code', $event)" class="mt-3"></InputField>
                    </div>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="action-link" @click="adjustSoundPower(product)">{{ $t('productsList.controls.adjustSoundPower') }}</div>
                    <div class="action-link" @click="adjustSoundAttenuation(product)">{{ $t('productsList.controls.attenuation') }}</div>
                </template>
                <template v-slot:product-actions>
                    <div class="flex flex-col justify-between">
                        <div class="icon icon-large icon-trashbin mb-8" @click="removeProduct({ index })"></div>
                        <div v-if="index > 0" class="icon icon-large icon-arrow-up mb-8" @click="swapProducts({ sourceIndex: index, targetIndex: index - 1  })"></div>
                        <div v-if="index < selectedProducts.length - 1" class="icon icon-large icon-arrow-down" @click="swapProducts({ sourceIndex: index, targetIndex: index + 1  })"></div>
                    </div>
                </template>
            </ProductCard>
        </template>
    </div>
    <OctaveBandAdjustmentModal
        v-model="adjustmentModalOpened"
        :title="adjustmentModalTitle"
        :productId="adjustingProductId"
        :productName="adjustingProductName"
        :defaultOctaveBandRange="adjustingDefaultOctaveBandRange"
        :octaveBandRange="adjustingOctaveBandRange"
        :readOnly="readOnlyMode"
        @close="onAdjustmentModalClosed"
        @change="onOctaveBandRangeChanged"
    ></OctaveBandAdjustmentModal>
    <RectSilencerModal v-model="rectSilencerModalOpened" mode="edit"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductCard from '@/components/products/product/ProductCard.vue';
import SilencerProperties from '@/components/products/silencers/SilencerProperties.vue';
import DiffuserProperties from '@/components/products/diffusers/DiffuserProperties.vue';
import RectSilencerProperties from '@/components/products/rect-silencers/RectSilencerProperties.vue';
import InputField from '@/components//general/InputField.vue';
import OctaveBandAdjustmentModal from '@/components/products/OctaveBandAdjustmentModal.vue';
import RectSilencerModal from '@/components/products/rect-silencers/RectSilencerModal.vue';
import { isOctaveBandRangeEmpty, resolveRectSilencerImage } from '@/utils';

const DEFAULT_ABSTRACT_IMAGE_URL = require(`@/assets/abstract_product.svg`);

export default {
    name: 'SelectedProductsList',
    components: {
        ProductCard,
        SilencerProperties,
        DiffuserProperties,
        RectSilencerProperties,
        InputField,
        OctaveBandAdjustmentModal,
        RectSilencerModal,
    },
    props: {
        detailed: {
            type: Boolean,
            default: false,
        },
        layout: {
            type: String,
            default: 'linear',
            validator: (value) => (['linear', 'grid'].includes(value)),
        },
    },
    data() {
        return {
            adjustmentModalOpened: false,
            rectSilencerModalOpened: false,
            adjustmentModalTitle: '',
            adjustmentType: '',
            adjustingProduct: null,
            readOnlyMode: false,
        };
    },
    computed: {
        ...mapState('activeSystemModule', ['selectedProducts']),
        ...mapGetters('activeSystemModule', ['soundPowerLevelDataByIndex']),
        ...mapGetters('silencerClassesModule', { 'silencerImagesMap': 'imagesMap' }),
        ...mapGetters('diffuserClassesModule', { 'diffuserImagesMap': 'imagesMap' }),
        adjustingDefaultOctaveBandRange() {
            if (!this.adjustingProduct || !this.adjustmentType) {
                return null;
            }

            return this.adjustmentType === 'soundPower'
                ? this.adjustingProduct.defaultSoundPowerLevel
                : this.adjustingProduct.defaultSoundAttenuation;
        },
        adjustingOctaveBandRange() {
            if (!this.adjustingProduct || !this.adjustmentType) {
                return null;
            }
            
            return this.adjustmentType === 'soundPower'
                ? this.adjustingProduct.soundPowerLevel
                : this.adjustingProduct.soundAttenuation;
        },
        adjustingProductId() {
            if (!this.adjustingProduct) {
                return '';
            }

            return this.adjustingProduct.id;
        },
        adjustingProductName() {
            if (!this.adjustingProduct) {
                return '';
            }

            return this.adjustingProduct.name;
        },
        layoutClass() {
            return {
                'linear-layout': this.layout === 'linear',
                'grid-layout': this.layout === 'grid',
            };
        },
        cardDirection() {
            return this.layout === 'linear' ? 'horizontal' : 'vertical';
        },
    },
    methods: {
        ...mapActions('activeSystemModule', [
            'calculateSummary',
            'saveSystem',
            'setAbstractProductAttribute',
            'setProductInsertionParams',
            'removeProduct',
            'swapProducts',
        ]),
        ...mapActions('rectSilencerModule', {
            'setRectSilencerData': 'setData',
            'setInputSoundPower': 'setInputSoundPower',
        }),
        adjustSoundPower(product) {
            this.adjustingProduct = product;
            this.adjustmentModalTitle = this.$t('octaveBandAdjustmentModal.adjustSoundPower');
            this.adjustmentType = 'soundPower';
            this.readOnlyMode = false;
            this.adjustmentModalOpened = true;
        },
        adjustSoundAttenuation(product) {
            this.adjustingProduct = product;
            this.adjustmentModalTitle = this.$t('octaveBandAdjustmentModal.attenuation');
            this.adjustmentType = 'attenuation';
            this.readOnlyMode = !isOctaveBandRangeEmpty(this.adjustingDefaultOctaveBandRange);
            this.adjustmentModalOpened = true;
        },
        onAdjustmentModalClosed() {
            this.adjustingProduct = null;
            this.adjustmentModalTitle = '';
            this.readOnlyMode = false;
            this.adjustmentType = '';
        },
        async onOctaveBandRangeChanged({ productId, octaveBandRange }) {
            if (this.adjustmentType === 'soundPower') {
                await this.setAbstractProductAttribute({
                    productId,
                    attributeName: 'soundPowerLevel',
                    attributeValue: octaveBandRange
                });
            }
            else {
                await this.setAbstractProductAttribute({
                    productId,
                    attributeName: 'soundAttenuation',
                    attributeValue: octaveBandRange
                });
            }
            await this.saveSystem();
            await this.calculateSummary();
        },
        resolveSilencerClassImage(silencerClass) {
            return this.silencerImagesMap[silencerClass] || '';
        },
        resolveRectSilencerClassImage(silencerClass, orientation) {
            return resolveRectSilencerImage(silencerClass, orientation);
        },
        resolveDiffuserClassImage(diffuserClass) {
            return this.diffuserImagesMap[diffuserClass] || '';
        },
        abstractImageURL(product) {
            if (product.imageURL === '') {
                return DEFAULT_ABSTRACT_IMAGE_URL;
            }
            return product.imageURL;
        },
        async onInsert({ index, type = 'after' }) {
            await this.setProductInsertionParams({
                productIndex: index,
                type,
            });
            this.$router.push({ name: 'products-list' });
        },
        async editRectSilencer(product, index) {
            await this.setRectSilencerData(product);
            const soundPowerLevelData = this.soundPowerLevelDataByIndex(index);
            await this.setInputSoundPower(soundPowerLevelData.soundPowerLevel);
            this.rectSilencerModalOpened = true;
        },
    },
}
</script>

<style lang="scss">
@use 'src/assets/css/product/productsList.scss';

.icon-trashbin, .icon-pencil, .icon-arrow-up, .icon-arrow-down {
    display: block;
    cursor: pointer;
}

.icon-trashbin:before, .icon-pencil:before, .icon-arrow-up:before, .icon-arrow-down:before {
    width: 24px;
    height: 24px;
}
</style>
