<template>
    <div class="search-box mt-4 mb-4">
        <div class="row p-0 items-start">
            <div class="flex flex-80 items-center mr-4">
                <div class="flex flex-25 items-center justify-start mr-4">
                    <div class="navigation text-small" @click="navigateToProjectsList"><i class="icon icon-small icon-chevron-left mr-2"></i>{{ $t('projectsPage.yourProjects') }}</div>
                </div>
                <div class="flex flex-100 items-center justify-center">
                    <InputField :placeholder="$t('systemsList.searchSystems')" :modelValue="searchString" @update:modelValue="setSearchString"></InputField>
                </div>
            </div>
            <div class="controls flex-25">
                <div class="row p-0 items-center justify-end mb-2">
                    <div class="btn download-pdf mr-2" @click="printProject">PDF<i class="download-icon"></i></div>
                    <ProjectDropdownMenu
                        hidePdfOptions
                        size="small-rect"
                        type="secondary-light"
                        iconSize="icon-x-large"
                        :outlined="false"
                        @onEditProject="editProjectModalOpened = true" 
                        @onDeleteProject="deleteProjectModalOpened = true"
                    ></ProjectDropdownMenu>
                </div>
                <div class="row p-0 items-center justify-end">
                    <div class="btn download-pdf" @click="printBillOfMaterials">{{ $t('systemsPage.billOfMaterials') }}</div>
                </div>
            </div>
        </div>
        <div class="row p-0 items-end">
            <div class="project-data flex-25 mr-4">
                <div class="attribute-name">{{ $t('projectsList.projectName') }}</div>
                <div class="attribute-value">{{ projectName || '-' }}</div>
            </div>
            <div class="project-data flex-25 mr-4">
                <div class="attribute-name">{{ $t('projectSpecificationModal.projectNr') }}</div>
                <div class="attribute-value">{{ projectNumber || '-' }}</div>
            </div>
            <div class="project-data flex-25 mr-4">
                <div class="attribute-name">{{ $t('projectSpecificationModal.customer') }}</div>
                <div class="attribute-value">{{ projectCustomer || '-' }}</div>
            </div>
            <div class="controls flex-25">
                <div class="row p-0 items-center justify-end">
                    <Button type="primary" size="medium" @click="$emit('onCreateSystem')">{{ `+ ${$t('systemsList.addSystem')}` }}</Button>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-3" v-for="system in systemsList" :key="system.id || `${system.number}-${index}`">
        <div class="system-data">
            <!-- <div class="column">
                <div class="btn download-pdf">PDF<i class="download-icon"></i></div>
            </div> -->
            <!-- <div class="column">
                <div class="title">{{ $t('systemsList.productClass') }}</div>
                <div class="content">{{ system.productClass }}</div>
            </div> -->
            <div class="column">
                <div class="title">{{ $t('systemsList.systemName') }}</div>
                <div class="content">{{ system.name ? capitalize(system.name) : '-' }}</div>
            </div>
            <div class="column">
                <div class="title">{{ $t('systemsList.createdAt') }}</div>
                <div class="content">{{ convertToDateString(system.createdAt || system.date) || '-' }}</div>
            </div>
            <div class="column">
                <div class="title">{{ $t('systemsList.systemNr') }}</div>
                <div class="content">{{ system.number || '-' }}</div>
            </div>
            <div class="column">
                <div class="row p-0">
                    <Button type="secondary" outlined size="small" class="mr-6" @click="setSystemAndNavigate(system)">{{ $t('systemsList.details') }}</Button>
                    <SystemDropdownMenu
                        size="small-rect"
                        type="secondary-light"
                        iconSize="icon-x-large"
                        :outlined="false"
                        @onEditSystem="onEditSystem(system)"
                        @onDeleteSystem="onDeleteSystem(system)"
                        @onPrintPDF="printSystem(system)"
                        @onCopySystem="onCopySystem(system)"
                    ></SystemDropdownMenu>
                </div>
            </div>
        </div>
    </div>
    <DeleteConfirmationModal
        size="small" 
        v-model="deleteProjectModalOpened"
        @onConfirm="confirmDeleting"
        @onDecline="deleteProjectModalOpened = false"
    ></DeleteConfirmationModal>
    <DeleteSystemConfirmationModal
        size="small" 
        v-model="deleteSystemModalOpened"
        @onConfirm="confirmSystemDeletion"
        @onDecline="deleteSystemModalOpened = false"
    ></DeleteSystemConfirmationModal>
    <EditProjectModal size="medium" v-model="editProjectModalOpened"></EditProjectModal>
    <EditSystemModal size="medium" v-model="editSystemModalOpened"></EditSystemModal>
    <div v-if="requestPending" class="loader-container-overlap">
        <div class="loader big-loader"></div>
    </div>
</template>

<script>
import * as dayjs from 'dayjs';
import { mapState, mapActions, mapGetters } from 'vuex';
import Button from '../general/Button.vue';
import InputField from '../general/InputField.vue';
import EditProjectModal from '../projects/EditProjectModal.vue';
import DeleteConfirmationModal from '../projects/DeleteConfirmationModal.vue';
import DeleteSystemConfirmationModal from '../systems/DeleteConfirmationModal.vue';
import EditSystemModal from '../systems/EditSystemModal.vue';
import SystemDropdownMenu from '../systems/SystemDropdownMenu.vue';
import ProjectDropdownMenu from '../projects/ProjectDropdownMenu.vue';


const debounce = require('lodash.debounce');

export default {
    name: 'SystemsList',
    components: {
        Button,
        InputField,
        DeleteConfirmationModal,
        DeleteSystemConfirmationModal,
        SystemDropdownMenu,
        ProjectDropdownMenu,
        EditProjectModal,
        EditSystemModal,
    },
    props: {
        systems: {
            type: Array,
            required: true,
        },
    },
    emits: ['onCreateSystem'],
    data() {
        return {
            searchString: '',
            deleteProjectModalOpened: false,
            editProjectModalOpened: false,
            editSystemModalOpened: false,
            deleteSystemModalOpened: false,
            requestPending: false,
        };
    },
    computed: {
        ...mapState('activeProjectModule', {
            'activeProjectId': 'id',
            'projectName': 'name',
            'projectNumber': 'number',
            'projectCustomer': 'contactData',
        }),
        ...mapGetters('activeSystemModule', ['activeSystem']),
        ...mapGetters('activeProjectModule', ['activeProject']),
        systemsList() {
            const systems = !this.searchString 
                ? this.systems
                : this.systems.filter((system) => `${system.name} ${system.number}`.indexOf(this.searchString) >= 0);
            
            return systems.sort((left, right) => (new Date(left.createdAt)) < (new Date(right.createdAt)) ? 1 : -1);
        },
    },
    methods: {
        ...mapActions('systemsModule', ['copySystem']),
        ...mapActions('activeSystemModule', [
            'setSystem',
            'deleteSystem',
            'resetData',
            'calculateSummary',
        ]),
        ...mapActions('activeProjectModule', ['deleteProject']),
        async setSystemAndNavigate(system) {
            await this.setSystem(system);
            await this.calculateSummary();
            this.$router.push({ name: 'project-overview' });
        },
        convertToDateString(date) {
            if (!date) {
                return '';
            }

            return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
        },
        setSearchString: debounce(function (searchString) {
            if (!searchString.length) {
                this.searchString = '';
                return;
            }
            if (searchString.length <= 2) {
                return;
            }
            this.searchString = searchString;
        }, 400),
        capitalize(value) {
            const str = String(value);

            return `${str[0].toUpperCase()}${str.substring(1)}`;
        },
        navigateToProjectsList() {
            this.$router.push({ name: 'projects' });
        },
        async confirmDeleting() {
            await this.deleteProject();
            this.navigateToProjectsList();
        },
        printSystem(system) {
            if (!this.activeProject || !system) {
                return;
            }
            const data = {
                project: this.activeProject,
                system,
            };
            const blob = new Blob([JSON.stringify(data, null, 2)], {type : 'application/json'});
            const objectUrl = URL.createObjectURL(blob);
            const objectId = objectUrl.split('/').pop();
            const url = `/printing/system?objectId=${objectId}`;
            window.open(url, 'print-system', 'popup=0,width=595,height=842,toolbar=0,menubar=0,location=0').focus();
        },
        printProject() {
            if (!this.activeProjectId) {
                return;
            }
            const url = `/printing/project?projectId=${this.activeProjectId}`;
            window.open(url, 'print-project', 'popup=0,width=595,height=842,toolbar=0,menubar=0,location=0').focus();
        },
        printBillOfMaterials() {
            if (!this.activeProjectId) {
                return;
            }
            const url = `/printing/materials?projectId=${this.activeProjectId}`;
            window.open(url, 'print-materials', 'popup=0,width=595,height=842,toolbar=0,menubar=0,location=0').focus();
        },
        async onCopySystem(system) {
            try {
                if (!system || !this.activeProjectId) {
                    return;
                }
                this.requestPending = true;
                await this.copySystem({
                    projectId: this.activeProjectId,
                    system,
                });
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },
        async onEditSystem(system) {
            if (!system) {
                return;
            }
            await this.setSystem(system);
            this.editSystemModalOpened = true;
        },
        async onDeleteSystem(system) {
            if (!system) {
                return;
            }
            await this.setSystem(system);
            this.deleteSystemModalOpened = true;
        },
        async confirmSystemDeletion() {
            try {
                if (!this.activeSystem) {
                    return;
                }
                this.requestPending = true;
                await this.deleteSystem();
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },
    },
    watch: {
        editSystemModalOpened(value) {
            if (value) {
                return;
            }
            setTimeout(() => {
                this.resetData();
            }, 0);
        },
        deleteSystemModalOpened(value) {
            if (value) {
                return;
            }
            setTimeout(() => {
                this.resetData();
            }, 0);
        },
    },
}
</script>

<style lang="scss">
.search-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: #F8F8F8;
    border-radius: 8px;
    padding: 24px 28px;

    .row {
        width: 100%;
    }

    .input-container {
        flex: 1 1 50vw;
    }
}

.title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    // font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #4C5055;
    margin-bottom: 12px;
}

.card {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 28px;
    box-sizing: border-box;
}

.card .system-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.system-data .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 1 25%;
}

.system-data .column:last-of-type {
    align-items: flex-end;
}

.system-data .column .title {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #737372;
    margin-bottom: 0;
}

.system-data .column .content {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    // font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #737372;
    margin-top: 4px;
}

.system-data .download-pdf {
    background: #DFEBF7;
    border: 1px solid #9DC3E6;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #4D91D1;
}

.system-data .download-pdf i {
    margin-left: 10px;
    width: 12px;
    height: 12px;
    background-image: url('../../assets/icons/singular/download.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.system-data .details {
    background: #4C5055;
    border-radius: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    padding: 8px 32px;
    min-height: 32px;
    box-sizing: border-box;
}

.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
}
</style>

<style lang="scss" scoped>
    .flex-25 {
        flex: 1 1 25%;
    }

    .flex-50 {
        flex: 1 1 50%;
    }

    .flex-80 {
        flex: 1 1 80%;
    }

    .flex-100 {
        flex: 1 1 100%;
    }

    .project-data {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 75px;
        background: #FFFFFF;
        border: 1px solid #D5D6D5;
        border-radius: 8px;
        padding: 16px;

        .attribute-name {
            font-family: Helvetica Neue;
            font-size: 14px;
            line-height: 150%;
            color: #737372;
        }

        .attribute-value {
            font-family: Helvetica Neue Bold;
            font-size: 16px;
            line-height: 18px;
            color: #737372;
        }
    }

    .controls {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .download-pdf {
        font-family: Helvetica Neue Bold;
        background: #DFEBF7;
        border: 1px solid #9DC3E6;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 8px;
        font-style: normal;
        // font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #4D91D1;
    }

    .download-pdf i {
        margin-left: 10px;
        width: 12px;
        height: 12px;
        background-image: url('../../assets/icons/singular/download.svg');
        background-repeat: no-repeat;
        background-position: center center;
    }

    .icon-trashbin::before {
        color: #EB5757;
    }
</style>