export default {
    transform(data) {
        return {
            id: data.id || '',
            name: data.name || '',
            number: data.number || '',
            address: data.address || '',
            email: data.email || '',
            phone: data.phone || '',
            contactData: data.contactData || '',
            date: data.date || new Date(),
        };
    },
};
