<template>
  <div class="w-full">
    <div class="products-list" :class="layoutClass">
      <template v-for="(product, index) in selectedProducts" :key="`${product.id}:${index}`">
            <ProductCard
                v-if="product.type === 'silencer'"
                class="mb-6"
                :direction="cardDirection"
            >
                <template v-slot:product-image>
                    <img :src="resolveSilencerClassImage(product.class)" alt="Silencer Image">
                </template>
                <template v-slot:product-properties>
                    <SilencerProperties :product="product" class="mb-3"/>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="flex flex-col w-full">
                        <OctaveBandContainer
                            :title="$t('projectBreakdown.soundPowerLevel')"
                            :powerLevelKit="product.soundPowerLevel"
                        />
                        <OctaveBandContainer
                            :title="$t('productsList.controls.attenuation')"
                            :powerLevelKit="product.soundAttenuation"
                        />
                    </div>
                </template>
            </ProductCard>
            <ProductCard
                v-else-if="product.type === 'diffuser'"
                class="mb-6"
                :direction="cardDirection"
            >
                <template v-slot:product-image>
                    <img :src="resolveDiffuserClassImage(product.class)" alt="Diffuser Image">
                </template>
                <template v-slot:product-properties>
                    <DiffuserProperties :product="product" class="mb-3"/>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="flex flex-col w-full">
                        <OctaveBandContainer
                            :title="$t('projectBreakdown.soundPowerLevel')"
                            :powerLevelKit="product.soundPowerLevel"
                        />
                        <OctaveBandContainer
                            :title="$t('productsList.controls.attenuation')"
                            :powerLevelKit="product.soundAttenuation"
                        />
                    </div>
                </template>
            </ProductCard>
            <ProductCard
                v-else-if="product.type === 'rectSilencer'"
                class="mb-6"
                :direction="cardDirection"
            >
                <template v-slot:product-image>
                    <img :src="resolveRectSilencerClassImage(product.class, product.orientation)" alt="Rect Silencer Image">
                </template>
                <template v-slot:product-properties>
                    <RectSilencerProperties :product="product" class="mb-3"/>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="flex flex-col w-full">
                        <OctaveBandContainer
                            :title="$t('projectBreakdown.soundPowerLevel')"
                            :powerLevelKit="product.soundPowerLevel"
                        />
                        <OctaveBandContainer
                            :title="$t('productsList.controls.attenuation')"
                            :powerLevelKit="product.soundAttenuation"
                        />
                    </div>
                </template>
            </ProductCard>
            <ProductCard
                v-else-if="product.type === 'abstract'"
                class="mb-6"
                :direction="cardDirection"
            >
                <template v-slot:product-image>
                    <img :src="abstractImageURL(product)" alt="Abstract Product Image">
                </template>
                <template v-slot:product-properties>
                    <div class="product-properties mb-3">
                        <InputField readOnly :placeholder="$t('productsList.productSpecs.productName')" :modelValue="product.name"></InputField>
                        <InputField readOnly :placeholder="$t('productsList.productSpecs.productCode')" :modelValue="product.code" @update:modelValue="updateAbstractAttribute(product, 'code', $event)" class="mt-3"></InputField>
                    </div>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="flex flex-col w-full">
                        <OctaveBandContainer
                            :title="$t('projectBreakdown.soundPowerLevel')"
                            :powerLevelKit="product.soundPowerLevel"
                        />
                        <OctaveBandContainer
                            :title="$t('productsList.controls.attenuation')"
                            :powerLevelKit="product.soundAttenuation"
                        />
                    </div>
                </template>
            </ProductCard>
            <ProductCard
                v-else-if="product.type === 'custom'"
                class="mb-6"
                :direction="cardDirection"
            >
                <template v-slot:product-image>
                    <img :src="abstractImageURL(product)" alt="Custom Product Image">
                </template>
                <template v-slot:product-properties>
                    <div class="product-properties mb-3">
                        <InputField readOnly :placeholder="$t('productsList.productSpecs.productName')" :modelValue="product.name"></InputField>
                        <InputField readOnly :placeholder="$t('productsList.productSpecs.productCode')" :modelValue="product.code" @update:modelValue="updateAbstractAttribute(product, 'code', $event)" class="mt-3"></InputField>
                    </div>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="flex flex-col w-full">
                        <OctaveBandContainer
                            :title="$t('projectBreakdown.soundPowerLevel')"
                            :powerLevelKit="product.soundPowerLevel"
                        />
                        <OctaveBandContainer
                            :title="$t('productsList.controls.attenuation')"
                            :powerLevelKit="product.soundAttenuation"
                        />
                    </div>
                </template>
            </ProductCard>
        </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProductCard from '@/components/products/product/ProductCard.vue';
import SilencerProperties from '@/components/products/silencers/SilencerProperties.vue';
import DiffuserProperties from '@/components/products/diffusers/DiffuserProperties.vue';
import RectSilencerProperties from '@/components/products/rect-silencers/RectSilencerProperties.vue';
import InputField from '@/components//general/InputField.vue';
import OctaveBandContainer from '@/components/products/product/OctaveBandContainer.vue';
import { resolveRectSilencerImage } from '@/utils';

const DEFAULT_ABSTRACT_IMAGE_URL = require(`@/assets/abstract_product.svg`);

export default {
    name: 'DetailedSelectedProductsList',
    components: {
        ProductCard,
        SilencerProperties,
        DiffuserProperties,
        RectSilencerProperties,
        InputField,
        OctaveBandContainer,
    },
    props: {
        layout: {
            type: String,
            default: 'linear',
            validator: (value) => (['linear', 'grid'].includes(value)),
        },
    },
    computed: {
        ...mapState('activeSystemModule', ['selectedProducts']),
        ...mapGetters('silencerClassesModule', { 'silencerImagesMap': 'imagesMap' }),
        ...mapGetters('diffuserClassesModule', { 'diffuserImagesMap': 'imagesMap' }),
        layoutClass() {
            return {
                'linear-layout': this.layout === 'linear',
                'grid-layout': this.layout === 'grid',
            };
        },
        cardDirection() {
            return this.layout === 'linear' ? 'horizontal' : 'vertical';
        },
    },
    methods: {
        resolveSilencerClassImage(silencerClass) {
            return this.silencerImagesMap[silencerClass] || '';
        },
        resolveRectSilencerClassImage(silencerClass, orientation) {
            return resolveRectSilencerImage(silencerClass, orientation);
        },
        resolveDiffuserClassImage(diffuserClass) {
            return this.diffuserImagesMap[diffuserClass] || '';
        },
        abstractImageURL(product) {
            if (product.imageURL === '') {
                return DEFAULT_ABSTRACT_IMAGE_URL;
            }
            return product.imageURL;
        },
    },
}
</script>

<style lang="scss" scoped>
@use 'src/assets/css/product/productsList.scss';
</style>
