<template>
    <label class="custom-radio-button-label">
        <input type="radio" class="custom-radio-button" :value="value" :modelValue="modelValue" :checked="value === modelValue" @change="toggle">
        <span>{{ label }}</span>
    </label>
</template>

<script>
export default {
    name: 'RadioButton',
    props: {
        modelValue: {
            required: true,
        },
        value: {
            required: true,
        },
        label: String,
    },
    emits: ['change', 'update:modelValue'],
    methods: {
        toggle() {
            this.$emit('update:modelValue', this.value);
            this.$emit('change', this.value);
        }
    },
}
</script>

<style>
.custom-radio-button-label {
    display: flex;
    align-items: center;
}

.custom-radio-button-label span {
    display: inline-flex;
    user-select: none;
    cursor: pointer;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4C5055;
}

.custom-radio-button + span::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #D5D6D5;
    border-radius: 50%;
    margin-right: 8px;
}

.custom-radio-button {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-radio-button:checked + span::before {
    background: #FFFFFF;
    border: 4px solid #CC0000;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(204, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-position: center center;
}

.custom-radio-button:not(:disabled):not(:checked) + span:hover::before,
.custom-radio-button:not(:disabled):active + span::before,
.custom-radio-button:focus + span::before {
    box-shadow: 0px 2px 8px rgba(204, 0, 0, 0.2);
}

.custom-radio-button:not(:disabled):active + span::before {
    background: #CC0000;
}
</style>