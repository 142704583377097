<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ $t('systemsPage.quickCalculation') }}</div>
        </template>
        <template v-slot:modal-content>
            <!-- Project data -->
            <EditActiveProjectData></EditActiveProjectData>
            <hr class="mt-4 mb-4">
            <!-- System data -->
            <EditActiveSystemData :withDate="false"></EditActiveSystemData>
        </template>
        <template v-slot:modal-actions>
            <div class="noise-generator-sound-power">
                <div class="heading-small-bold mr-1">Lw: </div>
                <div class="text-medium mr-2">{{ noiseGeneratorTotalSoundPowerLw }}</div>
                <div class="heading-small-bold mr-1">{{ filterCorrectionUnit }}: </div>
                <div class="text-medium">{{ noiseGeneratorTotalSoundPowerLwA }}</div>
            </div>
            <Button type="secondary" size="medium" outlined class="mr-16" @click="resetAllData">{{ $t('systemSpecificationModal.clear') }}</Button>
            <Button type="primary" size="medium" @click="saveAndProceed">{{ $t('systemSpecificationModal.next') }}</Button>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Button from '../general/Button.vue';
import Modal from '../general/Modal.vue';
import EditActiveProjectData from './modal-parts/EditActiveProjectData.vue';
import EditActiveSystemData from '../systems/model-parts/EditActiveSystemData.vue';

export default {
    name: 'CreateSystemModal',
    components: {
        Button,
        Modal,
        EditActiveProjectData,
        EditActiveSystemData,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    computed: {
        ...mapState('activeProjectModule', {
            'projectId': 'id',
        }),
        ...mapState('activeSystemModule', ['calculationResults']),
        ...mapGetters('activeSystemModule', ['filterCorrectionUnit']),
        noiseGeneratorTotalSoundPowerLw() {
            const { noiseGeneratorTotalSoundPowerLw } = this.calculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLw) {
                return '-';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLw);
        },
        noiseGeneratorTotalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.calculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return '-';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA);
        },
    },
    methods: {
        ...mapActions('activeProjectModule', {
            'resetProjectData': 'resetData',
        }),
        ...mapActions('activeProjectModule', ['saveProject']),
        ...mapActions('activeSystemModule', {
            'resetSystemData': 'resetData',
        }),
        ...mapActions('activeSystemModule', [
            'resetProductsData',
            'resetNoiseGeneratorData',
            'saveSystem',
            'setProjectId',
        ]),
        ...mapActions('silencerClassesModule', [
            'clearSelection',
        ]),
        closeModal() {
            this.$emit('update:modelValue', false);
        },
        openSilencersPage() {
            this.resetProductsData();
            this.clearSelection();
            this.closeModal();
            this.$router.push({ name: 'silencers' });
        },
        updateInputSoundPowerLevel(bandRange, soundPower) {
            this.setInputSoundPowerLevel({ bandRange, soundPower });
        },
        async saveAndProceed() {
            // if (!this.projectId) {
            //     this.$router.push({ name: 'projects' });
            //     return;
            // }
            // await this.setProjectId(this.projectId);
            // await this.saveSystem();
            await this.saveProject();
            if (this.projectId) {
                await this.setProjectId(this.projectId);
                await this.saveSystem();
            }
            this.openSilencersPage();
        },
        async resetAllData() {
            await this.resetProjectData();
            await this.resetSystemData();
            await this.resetProductsData();
            await this.resetNoiseGeneratorData();
            await this.clearSelection();
        },
    },
    watch: {
        modelValue(value) {
            if (!value) {
                return;
            }
            this.resetProjectData();
            this.resetSystemData();
        }
    },
}
</script>

<style>
.group-title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 22px;
    color: #737372;
}
</style>
