<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
// @use 'src/assets/css/main.scss';

// .row {
//     display: flex;
//     flex-direction: row;
//     padding-left: 20px;
//     padding-right: 20px;
// }

// .column {
//     display: flex;
//     flex-direction: column;
// }
// // @font-face {
// //   font-family: 'Helvetica Neue';
// //   src: url('./assets/fonts/HelveticaNeue.ttf') format('truetype');
// // }

// // @font-face {
// //   font-family: 'Helvetica Neue Bold';
// //   src: url('./assets/fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
// // }

// // div, p, span {
// //   font-family: Helvetica Neue Bold;
// //   font-style: normal;
// //   font-weight: bold;
// //   font-size: 16px;
// //   line-height: 20px;
// // }

// #app {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   min-height: 100vh;
//   max-width: 100vw;
//   padding-top: 56px;
// }

// body {
//   margin: 0;
//   position: relative;
//   overflow-x: hidden;
// }
</style>
