<template>
    <div v-if="requestPending" class="loader-container">
        <div class="loader big-loader"></div>
    </div>
    <BackgroundImage></BackgroundImage>
    <div v-if="dataReceived" class="page-content justify-start pt-8 pb-8">
        <template v-if="externalDataValid">
            <div class="row p-0 justify-space-between items-center">
                <div class="column">
                    <div class="row items-center">
                        <i class="icon icon-arrow-left"></i><span class="action-link back-link" @click="navigateToWebshop">{{ $t('systemsExport.navigation.backToWebshop') }}</span>
                    </div>
                </div>
                <div class="column"></div>
            </div>
            <template v-if="projects.length">
                <div class="card mt-4 mb-4 justify-space-between items-center">
                    <div class="column">
                        <Select
                            label="Project"
                            :options="projectOptions"
                            :modelValue="selectedProjectId"
                            @update:modelValue="selectProject"
                        ></Select>
                    </div>
                    <div class="column">
                        <InlineDialog type="info">
                            <span>{{ $t('systemsExport.info.chooseSystemFromList') }}</span>
                        </InlineDialog>
                    </div>
                </div>
                <template v-if="systems.length">
                    <template v-for="system in systems" :key="system.id">
                        <div class="card card-outlined mt-4">
                            <div class="system-data">
                                <div class="column">
                                    <!-- <Checkbox
                                        :modelValue="system.id in selectedSystemsMap"
                                        @update:modelValue="toggleSystemSelection(system, $event)"
                                    /> -->
                                    <Button v-if="system.id in exportedSystemsMap" type="primary" size="small" disabled>
                                        <span>{{ $t('systemsExport.captions.exported') }}</span>
                                    </Button>
                                    <Button v-else type="primary" size="small" :disabled="requestPending" @click="exportOneSystem(system)">
                                        <div v-if="requestPending" class="loader small-loader"></div>
                                        <span v-else>{{ $t('systemsExport.captions.export') }}</span>
                                    </Button>
                                </div>
                                <div class="column">
                                    <div class="title">{{ $t('projectsList.projectName') }}</div>
                                    <div class="content">{{ selectedProject.name }}</div>
                                </div>
                                <div class="column">
                                    <div class="title">{{ $t('systemsList.systemName') }}</div>
                                    <div class="content">{{ system.name ? capitalize(system.name) : '-' }}</div>
                                </div>
                                <div class="column">
                                    <div class="title">{{ $t('systemsList.systemNr') }}</div>
                                    <div class="content">{{ system.number || '-' }}</div>
                                </div>
                                <div class="column">
                                    <div class="title">{{ $t('systemsList.createdAt') }}</div>
                                    <div class="content">{{ convertToDateString(system.createdAt || system.date) || '-' }}</div>
                                </div>
                                <div class="column"></div>
                            </div>
                        </div>
                    </template>
                </template>
                <div v-else>
                    <div class="no-data-placeholder">
                        <img src="@/assets/no_project_placeholder.png" alt="No Systems">
                    </div>
                    <div class="actions-container mt-8 mb-8">
                        <p class="title">{{ $t('systemsExport.info.noSystems') }}</p>
                    </div>
                </div>
            </template>
            <div v-else>
                <div class="no-data-placeholder">
                    <img src="@/assets/no_project_placeholder.png" alt="No Projects">
                </div>
                <div class="actions-container mt-8 mb-8">
                    <p class="title">{{ $t('systemsExport.info.noProjects') }}</p>
                </div>
            </div>
            <div class="row mb-12"></div>
        </template>
        <div v-else>
            <div class="no-data-placeholder">
                <img src="@/assets/no_project_placeholder.png" alt="No Data">
            </div>
            <div class="actions-container mt-8 mb-8">
                <p class="title">{{ $t('systemsExport.info.externalProjectIdInvalid') }}:<br>"{{ externalProjectId }}"</p>
            </div>
        </div>
    </div>
    <div v-else class="row justify-center items-center">
        <div class="loader big-loader"></div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState, mapGetters } from 'vuex';
import Select from '@/components/general/Select.vue';
import BackgroundImage from '@/components/systems/BackgroundImage.vue';
import InlineDialog from '@/components/general/InlineDialog.vue';
import Button from '@/components/general/Button.vue';
// import Checkbox from '@/components/general/Checkbox.vue';
import * as WebshopService from '@/services/webshop/export';
import * as AuthorizationService from '@/services/auth/sso';

export default {
    name: 'BulkExport',
    components: {
        BackgroundImage,
        InlineDialog,
        Button,
        Select,
        // Checkbox,
    },
    async created() {
        try {
            this.redirectUrl = this.defaultRedirectUrl;
            const { externalProjectId } = this.$route.query;
            if (!externalProjectId) {
                alert('External project id has to be specified as URL query parameter');
                return;
            }
            this.externalProjectId = externalProjectId;
            const { returnUrl } = await WebshopService.getProjectData(this.externalProjectId);
            if (returnUrl) {
                this.redirectUrl = returnUrl;
            }
            await this.getProjects();
            if (!this.projects.length) {
                return;
            }
            this.selectedProject = this.projects[0];
            this.selectedProjectId = this.selectedProject.id;
            this.externalDataValid = true;
        } catch (error) {
            // alert('External project\'s data have not been obtained');
            console.error(error);
        } finally {
            this.dataReceived = true;
        }
  },
    data() {
        return {
            externalProjectId: '',
            redirectUrl: '',
            defaultRedirectUrl: 'https://my.etsnord.com/redirect/externalconfigurator/feec9b97-275c-4717-8898-bb481af68a98',
            dataReceived: false,
            externalDataValid: false,
            selectedSystemsMap: {},
            requestPending: false,
            selectedProject: null,
            selectedProjectId: '',
            exportedSystemsMap: {},
        }
    },
    computed: {
        ...mapState('projectsModule', ['projects']),
        ...mapGetters('userModule', ['userAuthorized']),
        projectOptions() {
            return this.projects.map((project, index) => ({
                title: project.name || project.number || `Project ${index + 1}`,
                value: project.id,
            }));
        },
        systems() {
            if (!this.selectedProject) {
                return [];
            }

            return this.selectedProject.systems;
        },
        selectedSystems() {
            return Object.values(this.selectedSystemsMap);
        },
    },
    methods: {
        ...mapActions('projectsModule', ['getProjects']),
        convertToDateString(date) {
            if (!date) {
                return '';
            }

            return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
        },
        toggleSystemSelection(system, selected) {
            if (!system || !system.id) {
                return;
            }
            if (selected) {
                this.selectedSystemsMap[system.id] = system;
            } else {
                delete this.selectedSystemsMap[system.id];
            }
        },
        async exportOneSystem(system) {
            this.selectedSystemsMap[system.id] = system;
            await this.onExport();
            this.selectedSystemsMap = {};
        },
        async onExport() {
            try {
                if (!this.externalProjectId || !this.redirectUrl) {
                    return;
                }
                this.requestPending = true;
                await WebshopService.exportSystems(this.externalProjectId, this.selectedSystems);
                this.selectedSystems.forEach((system) => this.exportedSystemsMap[system.id] = system);
                // alert('Systems have been exported. You will be redirected to Webshop.');
                alert('Export has been successfully completed.');
                this.selectedSystemsMap = {};
                // await this.navigateToWebshop();
            } catch (error) {
                console.error(error);
                alert('An error occurred during the export of selected systems');
            } finally {
                this.requestPending = false;
            }
        },
        capitalize(value) {
            const str = String(value);

            return `${str[0].toUpperCase()}${str.substring(1)}`;
        },
        async navigateToWebshop() {
            if (!this.redirectUrl) {
                return;
            }
            this.requestPending = true;
            if (this.userAuthorized) {
                await AuthorizationService.flush();
            }
            window.location.href = this.redirectUrl;
        },
        selectProject(projectId) {
            this.selectedProjectId = projectId;
            this.selectedProject = this.projects.find((project) => project.id === projectId) || null;
        },
    }
}
</script>

<style lang="scss" scoped>
.row {
    width: 100%;
}

.page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}

.no-data-placeholder {
    display: flex;
    width: 100%;
    min-height: 260px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.no-data-placeholder div {
    color: #737372;
}

.actions-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.direction-column {
    flex-direction: column;
}

.floating-panel {
    position: sticky;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 800px;
    min-height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 0px 0px;
    padding: 25px;
    bottom: 2vh;
    left: 30vw;
    z-index: 1;
    // .calculation-result {
    //     display: flex;
    //     flex-direction: column;
    //     flex: 1 1 20%;
    //     max-height: 45px;
    //     border-right: none;
    //     &.bordered {
    //         border-left: 1px solid #D5D6D5;
    //         border-right: 1px solid #D5D6D5;
    //     }
    //     .title {
    //         font-family: Helvetica Neue;
    //         font-weight: normal;
    //         font-size: 12px;
    //         line-height: 14px;
    //         color: #737372;
    //     }
    //     .value {
    //         font-family: Helvetica Neue Bold;
    //         font-style: normal;
    //         // font-weight: bold;
    //         font-size: 14px;
    //         line-height: 17px;
    //     }
    //     .empty-value {
    //         color: #737372;
    //     }
    // }
}

.loader-container {
    position: fixed;
    background-color: #a3a3a3;
    opacity: 0.5;
    width: 100vw;
    height: 100vh;
    z-index: 15000;
}

.icon-arrow-left:before {
    width: 32px;
    height: 32px;
}

.items-center {
    align-items: center;
}

.back-link {
    text-decoration: none;
    color: #CC0000;
    margin-left: 10px;
}

.card-outlined {
    box-shadow: none;
    border: 1px solid #C4C4C4;
}

.select-container {
    width: 190px;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}
</style>
