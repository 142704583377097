<template>
    <div class="layout-controls">
        <div class="layout-option layout-grid mr-4" :class="{ 'selected': modelValue === 'grid' }" @click="selectGridLayout">
            <div class="icon icon-large icon-grid"></div>
        </div>
        <div class="layout-option layout-linear" :class="{ 'selected': modelValue === 'linear' }" @click="selectLinearLayout">
            <div class="icon icon-large icon-list"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LayoutControls',
    props: {
        modelValue: {
            type: String,
            default: 'grid',
            validate: (value) => (['grid', 'linear'].includes(value)),
        }
    },
    emits: ['update:modelValue'],
    methods: {
        selectGridLayout() {
            this.$emit('update:modelValue', 'grid');
        },
        selectLinearLayout() {
            this.$emit('update:modelValue', 'linear');
        },
    },
}
</script>

<style lang="scss">
.layout-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.layout-option {
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background: transparent;
    cursor: pointer;
    justify-content: center;

    &.selected {
        background: #D5D6D5;
    }
}

.icon-grid:before, .icon-list:before {
    width: 24px;
    height: 24px;
}
</style>