import * as SilencersService from '../../services/silencers';
import SilencersInputTransformer from '../../transformers/input/silencers';
import * as CalculationService from '@/services/calculations';

export default {
    namespaced: true,
    state: () => ({
        silencers: [],
        silencersMap: {},
        filters: {},
    }),
    mutations: {
        setSilencers(state, silencers) {
            state.silencers = silencers;
            state.silencersMap = {};
            silencers.forEach((silencer) => {
                state.silencersMap[silencer.id] = silencer;
            });
        },
        setFilters(state, filters) {
            state.filters = filters;
        },
        setSoundPowerLevel({ silencers }, { silencerId, soundPowerLevel }) {
            const silencer = silencers.find((item) => item.id === silencerId);
            if (!silencer) {
                return;
            }
            silencer.soundPowerLevel = soundPowerLevel;
        },
        setSoundAttenuation({ silencers }, { silencerId, soundAttenuation }) {
            const silencer = silencers.find((item) => item.id === silencerId);
            if (!silencer) {
                return;
            }
            silencer.soundAttenuation = soundAttenuation;
        },
        setInputAirflow(state, { silencer, inputAirflow }) {
            if (!(silencer.id in state.silencersMap)) {
                return;
            }
            silencer.inputAirflow = Number(inputAirflow);
        },
        setPressureDrop(state, { silencer, pressureDrop }) {
            if (!(silencer.id in state.silencersMap)) {
                return;
            }
            silencer.pressureDrop = Number(pressureDrop);
        },
    },
    actions: {
        async getSilencers({ state, commit }, { rewrite = false } = {}) {
            if (state.silencers.length && !rewrite) {
                return;
            }
            const silencers = await SilencersService.getSilencers();
            const transformed = SilencersInputTransformer.transform(silencers);
            commit('setSilencers', transformed.silencers);
            commit('setFilters', transformed.filters);
        },
        setSoundPowerLevel({ commit }, { silencerId, soundPowerLevel }) {
            commit('setSoundPowerLevel', { silencerId, soundPowerLevel });
        },
        setSoundAttenuation({ commit }, { silencerId, soundAttenuation }) {
            commit('setSoundAttenuation', { silencerId, soundAttenuation });
        },
        async setInputAirflow({ commit, dispatch }, { silencer, inputAirflow }) {
            commit('setInputAirflow', { silencer, inputAirflow });
            await dispatch('calculatePressureDrop', { silencer });
        },
        async calculatePressureDrop({ state, commit, dispatch }, { silencer }) {
            if (!silencer || silencer.type !== 'silencer') {
                return;
            }
            if (silencer.class === 'NRFV' || silencer.class === 'NRPV') {
                return;
            }
            if (!(silencer.id in state.silencersMap)) {
                return;
            }
            const result = await CalculationService.calculatePressureDrop({
                silencerClass: silencer.class,
                diameter: silencer.nominalSizeDiameter,
                length: silencer.length,
                inputAirflow: silencer.inputAirflow,
            });
            const { pressureDrop } = result;
            if (pressureDrop === undefined) {
                return;
            }
            commit('setPressureDrop', { silencer, pressureDrop });
            await dispatch('activeSystemModule/saveSystem', {}, { root: true });
        },
    }
}