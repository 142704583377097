function getGeometryAttribute(silencer, attribute) {
    if (!silencer || !attribute || !silencer.geometry) {
        return '';
    }
    if (!(attribute in silencer.geometry)) {
        return '';
    }

    return silencer.geometry[attribute] || '';
}

function getGeometryModelId(silencer) {
    const modelId = getGeometryAttribute(silencer, 'modelId');
    // const modelVersion = getGeometryAttribute(silencer, 'modelVersion');
    // if (!modelId || !modelVersion) {
    if (!modelId) {
        return '';
    }

    // return `${modelId}_${modelVersion}`;
    return `${modelId}_`;
}

// suits for NKE, NKL, NKU, NTF, NTP
function getNKEGeometry(silencer) {
    const dimensions = silencer.dimensions || { A: 0, B: 0 };

    return {
        modelId: getGeometryModelId(silencer),
        modelParameters: `D=${silencer.nominalSizeDiameter || 0};L=${silencer.length || 0};CB=${dimensions.A};CH=${dimensions.B}`,
        modelVersion: '',
    };
}

// suits for NRF50, NRP50, NRF100, NRP100
function getNRFGeometry(silencer) {
    const insulation = silencer.class.indexOf('50') >= 0 ? 50 : 100;

    return {
        modelId: getGeometryModelId(silencer),
        modelParameters: `D=${silencer.nominalSizeDiameter || 0};L=${silencer.length || 0};S=${insulation};BV=0`,
        modelVersion: '',
    };
}

// suits for NRFB100, NRPB100
function getNRFBGeometry(silencer) {
    const insulation = silencer.nominalSizeDiameter >= 1400 ? 150 : 100;

    return {
        modelId: getGeometryModelId(silencer),
        modelParameters: `D=${silencer.nominalSizeDiameter || 0};L=${silencer.length || 0};S=${insulation};BV=1`,
        modelVersion: '',
    };
}

// suits for NRFV, NRPV
function getNRFVGeometry(silencer) {
    const length = silencer.length
        ? silencer.length || 0
        : (silencer.dimensions ? (silencer.dimensions.L || silencer.dimensions.l || 0) : 0);

    return {
        modelId: 'ETSNORD_NRFV_NRPV_',
        modelParameters: `D=${silencer.nominalSizeDiameter || 0};L=${length};S=50`,
        modelVersion: '',
    };
}

function getMSKGeometry(silencer) {
    const optionData = silencer.optionData || {};

    return {
        modelId: 'ETSNORD_MSC_MSK_MSP_',
        modelParameters: `B=${silencer.width || 0};H=${silencer.height || 0};L=${silencer.length || 0};SB=0;SH=0;F=${optionData.cassetteThickness || 0};NM=${optionData.amountOfCassettes || 0}`,
        modelVersion: '',
    };
}

function getMSKIGeometry(silencer) {
    let [ shellInsulation = 0 ] = (silencer.shellInsulation || '').match(/[0-9]+/) || [];
    shellInsulation = (shellInsulation > 100) ? 100 : shellInsulation;
    const optionData = silencer.optionData || {};

    return {
        modelId: 'ETSNORD_MSKI_MSPI_',
        modelParameters: `B=${silencer.width || 0};H=${silencer.height || 0};L=${silencer.length};SB=${shellInsulation};SH=${shellInsulation};F=${optionData.cassetteThickness || 0};NM=${optionData.amountOfCassettes || 0}`,
        modelVersion: '',
    };
}

function getMRKAGeometry(silencer) {
    const optionData = silencer.optionData || {};

    return {
        modelId: 'ETSNORD_MRCA_MRKA_MRPA_',
        modelParameters: `B=${silencer.width || 0};H=${silencer.height || 0};L=${silencer.length};SB=60;SH=60;F=${optionData.cassetteThickness || 0};NM=${optionData.amountOfCassettes || 0}`,
        modelVersion: '',
    };
}


export function getGeometryForSilencer(silencer) {
    const productClass = silencer.productClass || silencer.class || '';
    switch (productClass) {
        case 'NKE':
        case 'NKL':
        case 'NKU':
        case 'NTF':
        case 'NTP':
        case 'NTFA':
        case 'NTPA':
            return getNKEGeometry(silencer);
        case 'NRF50':
        case 'NRP50':
        case 'NRF100':
        case 'NRP100':
            return getNRFGeometry(silencer);
        case 'NRFB100':
        case 'NRPB100':
            return getNRFBGeometry(silencer);
        case 'NRFV':
        case 'NRPV':
            return getNRFVGeometry(silencer);
        case 'MSK':
        case 'MSP':
        case 'MSC':
            return getMSKGeometry(silencer);
        case 'MSKI':
        case 'MSPI':
        case 'MSCI':
            return getMSKIGeometry(silencer);
        case 'MRKA':
        case 'MRPA':
        case 'MRCA':
            return getMRKAGeometry(silencer);
        default:
            return null;
    }
}
