import { render } from "./Page.vue?vue&type=template&id=2276b003&scoped=true"
import script from "./Page.vue?vue&type=script&lang=js"
export * from "./Page.vue?vue&type=script&lang=js"

import "./Page.vue?vue&type=style&index=0&id=2276b003&lang=scss"
import "./Page.vue?vue&type=style&index=1&id=2276b003&scoped=true&lang=css"
import "./Page.vue?vue&type=style&index=2&id=2276b003&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2276b003"

export default script