<template>
    <div class="row">
        <div class="navigation text-small mt-4" @click="navigateBack"><i class="icon icon-small icon-chevron-left mr-2"></i>{{ $t('silencersPage.selectSilencerTypes') }}</div>
    </div>
    <div class="page-content rect-silencer-modelling pt-8 pb-8">
        <SilencerDetailsView
            v-if="activeTab === 'general' && showDetailsView"
            @optionSelectionRequested="showDetailsView = false"
            @switchTab="activeTab = $event"
        />
        <SilencerOptionsView
            v-else-if="activeTab === 'general' && !showDetailsView"
            @detailsSelectionRequested="showDetailsView = true"
            @switchTab="activeTab = $event"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import SilencerDetailsView from './views/SilencerDetailsView.vue';
import SilencerOptionsView from './views/SilencerOptionsView.vue';

export default {
    name: 'RectSilencer',
    components: {
        SilencerDetailsView,
        SilencerOptionsView,
    },
    data() {
        return {
            showDetailsView: true,
            activeTab: 'general',
        };
    },
    methods: {
        ...mapActions('rectSilencerModule', ['resetData']),
        closeModal() {
            this.$emit('update:modelValue', false);
            this.$emit('close');
            this.resetData();
            this.showDetailsView = true;
            this.activeTab = 'general';
        },
        navigateBack() {
            this.$router.push({ name: 'silencer-classes-selector' });
        },
    },
}
</script>

<style lang="scss">
@use 'src/assets/css/main.scss';

.row {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
}

.column {
    display: flex;
    flex-direction: column;
}
// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('./assets/fonts/HelveticaNeue.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Helvetica Neue Bold';
//   src: url('./assets/fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
// }

// div, p, span {
//   font-family: Helvetica Neue Bold;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 20px;
// }

.rect-silencer-modelling {
    .modal-background {
        position: relative;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        z-index: 1;
        width: auto;
        height: auto;
    }
}
</style>