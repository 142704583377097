import * as DiffusersService from '../../services/diffusers';
import DiffusersInputTransformer from '../../transformers/input/diffusers';

export default {
    namespaced: true,
    state: () => ({
        diffusers: [],
        diffusersMap: {},
        filters: {},
    }),
    mutations: {
        setDiffusers(state, diffusers) {
            state.diffusers = diffusers;
            state.diffusersMap = {};
            diffusers.forEach((diffuser) => {
                state.diffusersMap[diffuser.id] = diffuser;
            });
        },
        setFilters(state, filters) {
            state.filters = filters;
        },
        setSoundPowerLevel({ diffusers }, { diffuserId, soundPowerLevel }) {
            const diffuser = diffusers.find((item) => item.id === diffuserId);
            if (!diffuser) {
                return;
            }
            diffuser.soundPowerLevel = soundPowerLevel;
        },
        setSoundAttenuation({ diffusers }, { diffuserId, soundAttenuation }) {
            const diffuser = diffusers.find((item) => item.id === diffuserId);
            if (!diffuser) {
                return;
            }
            diffuser.soundAttenuation = soundAttenuation;
        },
    },
    actions: {
        async getDiffusers({ state, commit }, { rewrite = false } = {}) {
            if (state.diffusers.length && !rewrite) {
                return;
            }
            const diffusers = await DiffusersService.getDiffusers();
            const transformed = DiffusersInputTransformer.transform(diffusers);
            commit('setDiffusers', transformed.diffusers);
            commit('setFilters', transformed.filters);
        },
        setSoundPowerLevel({ commit }, { diffuserId, soundPowerLevel }) {
            commit('setSoundPowerLevel', { diffuserId, soundPowerLevel });
        },
        setSoundAttenuation({ commit }, { diffuserId, soundAttenuation }) {
            commit('setSoundAttenuation', { diffuserId, soundAttenuation });
        },
    }
}