import { apiService } from '../general/APIService';
import SystemTransformer from '../../transformers/input/systems';

const PROJECTS_ENDPOINT = 'projects';
const SYSTEMS_ENDPOINT = 'systems';

export async function getSystems(projectId) {
    const response = await apiService.get(`${PROJECTS_ENDPOINT}/${projectId}/${SYSTEMS_ENDPOINT}`, {
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    });

    return response.data.data;
}

export async function getSystem(systemId) {
    if (!systemId) {
        throw new Error('System id is required');
    }
    const response = await apiService.get(`${SYSTEMS_ENDPOINT}/${systemId}`, {
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    });

    return SystemTransformer.transform([response.data.data])[0] || null;
}

export async function createSystem(projectId, data) {
    const response = await apiService.post(`${PROJECTS_ENDPOINT}/${projectId}/${SYSTEMS_ENDPOINT}`, data, {
        withCredentials: true,
        credentials: 'include',
    });

    return response.data.data;
}

export async function saveSystem(projectId, data) {
    const systemId = data.id;
    if (!systemId) {
        return;
    }
    const response = await apiService.put(`${PROJECTS_ENDPOINT}/${projectId}/${SYSTEMS_ENDPOINT}/${systemId}`, data, {
        withCredentials: true,
        credentials: 'include',
    });

    return response.data.data;
}

export async function deleteSystem(projectId, systemId) {
    if (!systemId) {
        return;
    }
    await apiService.delete(`${PROJECTS_ENDPOINT}/${projectId}/${SYSTEMS_ENDPOINT}/${systemId}`, {
        withCredentials: true,
        credentials: 'include',
    });
}

export async function copySystem(projectId, systemId) {
    if (!projectId) {
        throw new Error('Project id is required');
    }
    if (!systemId) {
        throw new Error('System id is required');
    }

    const response = await apiService.post(`${PROJECTS_ENDPOINT}/${projectId}/${SYSTEMS_ENDPOINT}/${systemId}/copy`, {}, {
        withCredentials: true,
        credentials: 'include',
    });
    if (!response || response.status !== 201 || !response.data || !response.data.data) {
        throw new Error('System has not been copied');
    }

    return response.data.data;
}
