<template>
    <div class="product-card-wrapper">
        <div class="insertion-control-container" :class="classes" v-if="allowInsertionBefore">
            <div class="insertion-button" @click="onInsertBefore">
                <div class="icon icon-medium icon-plus-small"></div>
            </div>
        </div>
        <div class="product" :class="classes">
            <div class="product-data-container">
                <div v-if="direction === 'vertical'" class="row p-0 justify-between">
                    <div class="product-image">
                        <slot name="product-image"></slot>
                    </div>
                    <slot name="product-actions"></slot>
                </div>
                <div v-else class="product-image">
                    <slot name="product-image"></slot>
                </div>
                <slot name="product-properties"></slot>
            </div>
            <div class="product-actions-container">
                <div class="action-line">
                    <slot name="product-sound-adjustment"></slot>
                    <slot name="product-actions" v-if="direction === 'horizontal'"></slot>
                </div>
            </div>
        </div>
        <div class="insertion-control-container" :class="classes" v-if="allowInsertionAfter">
            <div class="insertion-button" @click="onInsertAfter">
                <div class="icon icon-medium icon-plus-small"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductCard',
    props: {
        direction: {
            type: String,
            default: 'horizontal',
            validator: (value) => (['horizontal', 'vertical'].includes(value)) 
        },
        allowInsertionBefore: {
            type: Boolean,
            default: false,
        },
        allowInsertionAfter: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['insertBefore', 'insertAfter'],
    computed: {
        classes() {
            return {
                'horizontal': this.direction === 'horizontal',
                'vertical': this.direction === 'vertical',
            };
        },
    },
    methods: {
        onInsertBefore() {
            this.$emit('insertBefore');
        },
        onInsertAfter() {
            this.$emit('insertAfter');
        },
    },
}
</script>

<style lang="scss">
@use 'src/assets/css/product/productsList.scss';

.insertion-control-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;

    &.horizontal {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &.vertical {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .insertion-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: 1px solid #737372;
        border-radius: 50%;
    }
}

.icon-plus-small:before {
    width: 10px;
    height: 10px;
}
</style>