export default {
    transform(data) {
        if (!(data.userId || data._id) || !data.fullName || !data.email) {
            throw new Error('User data is invalid');
        }

        return {
            id: data.userId || data._id,
            fullName: data.fullName,
            email: data.email,
        };
    },
};