import ClassTransformer from '../diffuser-classes';

function emptyOctaveBandRange() {
    return {
        octaveBand63Hz: 0,
        octaveBand125Hz: 0,
        octaveBand250Hz: 0,
        octaveBand500Hz: 0,
        octaveBand1000Hz: 0,
        octaveBand2000Hz: 0,
        octaveBand4000Hz: 0,
        octaveBand8000Hz: 0,
    };
}

function resolveNumber(value, fallback = 0) {
    if (isNaN(value)) {
        return fallback;
    }

    return Number(value);
}

function transformClassData(data) {
    if (!data) {
        return null;
    }

    return ClassTransformer.transform([data])[0] || null;
}

function transformDiffuser(diffuser) {
    if (!diffuser._id || !diffuser.class) {
        return null;
    }
    const transformed = {
        id: diffuser._id,
        type: 'diffuser',
        name: diffuser.vendorCode,
        class: diffuser.class,
        classData: transformClassData(diffuser.classData),
        vendorCode: diffuser.vendorCode,
        weight: Number(diffuser.weight),
        nominalSize: isNaN(diffuser.nominalSize) ? diffuser.nominalSize : Number(diffuser.nominalSize),
        quantity: 0,
    };
    const { soundAttenuation = {} } = diffuser;
    transformed.defaultSoundAttenuation = {
        octaveBand63Hz: resolveNumber(soundAttenuation.octaveBand63Hz, 0),
        octaveBand125Hz: resolveNumber(soundAttenuation.octaveBand125Hz, 0),
        octaveBand250Hz: resolveNumber(soundAttenuation.octaveBand250Hz, 0),
        octaveBand500Hz: resolveNumber(soundAttenuation.octaveBand500Hz, 0),
        octaveBand1000Hz: resolveNumber(soundAttenuation.octaveBand1000Hz, 0),
        octaveBand2000Hz: resolveNumber(soundAttenuation.octaveBand2000Hz, 0),
        octaveBand4000Hz: resolveNumber(soundAttenuation.octaveBand4000Hz, 0),
        octaveBand8000Hz: resolveNumber(soundAttenuation.octaveBand8000Hz, 0),
    };
    transformed.soundAttenuation = { ...transformed.defaultSoundAttenuation };
    transformed.defaultSoundPowerLevel = emptyOctaveBandRange();
    transformed.soundPowerLevel = emptyOctaveBandRange();

    return transformed;
}

function composeFilters(diffusers) {
    const result = {};
    diffusers.forEach((diffuser) => {
        let filter = result[diffuser.class];
        if (!filter) {
            filter = {
                nominalSizes: new Set(),
            };
            result[diffuser.class] = filter;
        }
        filter.nominalSizes.add(diffuser.nominalSize);
    });

    return result;
}

export default {
    transform(diffusers) {
        const result = [];
        diffusers.forEach((diffuser) => {
            const transformed = transformDiffuser(diffuser);
            if (!transformed) {
                return;
            }
            result.push(transformed);
        });

        return {
            diffusers: result,
            filters: composeFilters(result),
        };
    }
}