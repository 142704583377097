<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ $t('systemSpecificationModal.systemSpecification') }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="row">
                <Select :options="attenuationConstants" :label="$t('systemSpecificationModal.attenuationConstant')" :modelValue="attenuationConstantType" @update:modelValue="setAttenuationConstantType" autoSelection class="mr-8"></Select>
                <Select :options="correctionFilters" :label="$t('systemSpecificationModal.filterCorrection')" :modelValue="filterCorrectionType" @update:modelValue="setFilterCorrectionType" autoSelection></Select>
            </div>
            <OctaveBandRangeEditor
                :modelValue="inputSoundPowerLevel"
                @octaveBand63HzChanged="updateInputSoundPowerLevel('octaveBand63Hz', $event)"
                @octaveBand125HzChanged="updateInputSoundPowerLevel('octaveBand125Hz', $event)"
                @octaveBand250HzChanged="updateInputSoundPowerLevel('octaveBand250Hz', $event)"
                @octaveBand500HzChanged="updateInputSoundPowerLevel('octaveBand500Hz', $event)"
                @octaveBand1000HzChanged="updateInputSoundPowerLevel('octaveBand1000Hz', $event)"
                @octaveBand2000HzChanged="updateInputSoundPowerLevel('octaveBand2000Hz', $event)"
                @octaveBand4000HzChanged="updateInputSoundPowerLevel('octaveBand4000Hz', $event)"
                @octaveBand8000HzChanged="updateInputSoundPowerLevel('octaveBand8000Hz', $event)"
            >
                <template v-slot:title>
                    <div class="row mt-8">
                        <div class="title">{{ $t('systemSpecificationModal.noiseGenerator') }} (Hz)</div>
                    </div>
                </template>
            </OctaveBandRangeEditor>
            <!-- <div class="row mt-20">
                <div class="title">{{ $t('systemSpecificationModal.noiseGenerator') }} (Hz)</div>
            </div>
            <div class="row mt-12">
                <InputField title="63" :modelValue="inputSoundPowerLevel.octaveBand63Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand63Hz', $event)" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="125" :modelValue="inputSoundPowerLevel.octaveBand125Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand125Hz', $event)" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="250" :modelValue="inputSoundPowerLevel.octaveBand250Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand250Hz', $event)" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="500" :modelValue="inputSoundPowerLevel.octaveBand500Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand500Hz', $event)" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="1000" :modelValue="inputSoundPowerLevel.octaveBand1000Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand1000Hz', $event)" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="2000" :modelValue="inputSoundPowerLevel.octaveBand2000Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand2000Hz', $event)" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="4000" :modelValue="inputSoundPowerLevel.octaveBand4000Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand4000Hz', $event)" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="8000" :modelValue="inputSoundPowerLevel.octaveBand8000Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand8000Hz', $event)" placeholder="0" type="number"></InputField>
            </div> -->
        </template>
        <template v-slot:modal-actions>
            <div class="noise-generator-sound-power">
                <div class="heading-small-bold mr-1">Lw: </div>
                <div class="text-medium mr-2">{{ noiseGeneratorTotalSoundPowerLw }}</div>
                <div class="heading-small-bold mr-1">{{ filterCorrectionUnit }}: </div>
                <div class="text-medium">{{ noiseGeneratorTotalSoundPowerLwA }}</div>
            </div>
            <Button type="secondary" size="medium" outlined class="mr-16" @click="resetData">{{ $t('systemSpecificationModal.clear') }}</Button>
            <Button type="primary" size="medium" @click="saveAndProceed">{{ $t('systemSpecificationModal.next') }}</Button>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import InputField from '../general/InputField.vue';
import Button from '../general/Button.vue';
import Select from '../general/Select.vue';
import Modal from '../general/Modal.vue';
import OctaveBandRangeEditor from '@/components/products/product/OctaveBandRangeEditor.vue';

export default {
    name: 'SystemSpecificationModal',
    components: {
        // InputField,
        Button,
        Modal,
        Select,
        OctaveBandRangeEditor,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            attenuationConstants: [
                {
                    title: this.$t('projectBreakdown.constants.noConstant'),
                    value: 'NoConstant',
                },
                {
                    title: 'LpA10m2',
                    value: 'T1',
                },
            ],
            correctionFilters: [
                {
                    title: this.$t('projectBreakdown.filters.noFilter'),
                    value: 'NoFilter',
                },
                {
                    title: 'A - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'AFilter',
                },
                {
                    title: 'B - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'BFilter',
                },
                {
                    title: 'C - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'CFilter',
                },
            ],
        };
    },
    computed: {
        ...mapState('activeSystemModule', [
            'attenuationConstantType',
            'filterCorrectionType',
            'inputSoundPowerLevel',
            'calculationResults',
        ]),
        ...mapGetters('activeSystemModule', ['filterCorrectionUnit']),
        noiseGeneratorTotalSoundPowerLw() {
            const { noiseGeneratorTotalSoundPowerLw } = this.calculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLw) {
                return '-';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLw);
        },
        noiseGeneratorTotalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.calculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return '-';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA);
        },
    },
    methods: {
        ...mapActions('activeSystemModule', [
            'setAttenuationConstantType',
            'setFilterCorrectionType',
            'setInputSoundPowerLevel',
            'resetData',
            'resetProductsData',
            'saveSystem'
        ]),
        ...mapActions('silencerClassesModule', [
            'clearSelection',
        ]),
        closeModal() {
            this.$emit('update:modelValue', false);
        },
        openSilencersPage() {
            this.resetProductsData();
            this.clearSelection();
            this.closeModal();
            this.$router.push({ name: 'silencers' });
        },
        updateInputSoundPowerLevel(bandRange, soundPower) {
            this.setInputSoundPowerLevel({ bandRange, soundPower });
        },
        async saveAndProceed() {
            await this.saveSystem();
            this.openSilencersPage();
        },
    },
    watch: {
        modelValue(value) {
            if (!value) {
                return;
            }
            this.resetData();
        }
    },
}
</script>

<style>
.noise-generator-sound-power {
    display: flex;
    align-items: flex-end;
    flex: 1 1 auto;
}
</style>
