import * as RectSilencerTransformer from '../rect-silencer';

function transformItem(item) {
    if (!item.id || !item.class || !item.type) {
        return null;
    }
    if (!item.soundPowerLevel || !item.soundAttenuation) {
        return null;
    }
    if (item.type === 'rectSilencer') {
        return RectSilencerTransformer.transform(item);
    }
    
    const result = {
        productId: item.id,
        productClass: item.class,
        productType: item.type,
        productName: item.name,
        productCode: item.code || '',
        productImageURL: item.imageURL || '',
        soundPowerLevel: item.soundPowerLevel,
        soundAttenuation: item.soundAttenuation,
    };

    if (item.type === 'silencer') {
        result.inputAirflow = item.inputAirflow;
        result.pressureDrop = item.pressureDrop;
    }

    return result;
}

function transformItems(items) {
    if (!Array.isArray(items) || !items.length) {
        return [];
    }
    const result = [];
    items.forEach((item) => {
        const transformed = transformItem(item);
        if (!transformed) {
            return;
        }
        result.push(transformed);
    });

    return result;
}

export default {
    transform(data) {
        return {
            id: data.id || '',
            name: data.name || '',
            number: data.number || '',
            comment: data.comment || '',
            date: data.date || new Date(),
            attenuationConstantType: data.attenuationConstantType || '',
            filterCorrectionType: data.filterCorrectionType || '',
            inputSoundPowerLevel: {
                octaveBand63Hz: data.inputSoundPowerLevel.octaveBand63Hz,
                octaveBand125Hz: data.inputSoundPowerLevel.octaveBand125Hz,
                octaveBand250Hz: data.inputSoundPowerLevel.octaveBand250Hz,
                octaveBand500Hz: data.inputSoundPowerLevel.octaveBand500Hz,
                octaveBand1000Hz: data.inputSoundPowerLevel.octaveBand1000Hz,
                octaveBand2000Hz: data.inputSoundPowerLevel.octaveBand2000Hz,
                octaveBand4000Hz: data.inputSoundPowerLevel.octaveBand4000Hz,
                octaveBand8000Hz: data.inputSoundPowerLevel.octaveBand8000Hz,
            },
            items: transformItems(data.selectedProducts),
        };
    },
};
