<template>
    <div class="row product-data mt-8">
        <div class="left-side column">
            <div class="image-container">
                <img v-if="product.type === 'abstract' || product.type === 'custom'" :src="abstractImageURL(product)" alt="Abstract Product image" class="abstract-product-image">
                <img v-else :src="resolveProductImage(product)" alt="Product image" class="product-image">
            </div>
            <div class="row justify-center mt-12">
                <div v-if="product.diagrams && resolveDiagramsArray(product.diagrams).length" class="diagrams">
                    <img :src="resolveDiagramsArray(product.diagrams)[0]" alt="Product Diagram" class="diagram">
                </div>
                <template v-if="product.type === 'rectSilencer'">
                    <div class="column">
                        <div class="diagrams">
                            <img v-for="(imageUrl, index) in getRectSilencerDiagrams(product.class, product.orientation)" :key="index" :src="imageUrl" alt="Rect Silencer Diagram" class="diagram"/>
                        </div>
                        <div v-if="product.shape === 'bend'" class="row justify-center mt-8 mb-4">x={{ product.dimensionX }} mm, y={{ product.dimensionY }} mm</div>
                    </div>
                </template>
            </div>
        </div>
        <div class="right-side">
            <div class="product-data-title text-bold">{{ product.name }} {{ resolveTranslationMapValue(product.nameAppendix) }}</div>
            <RectSilencerProperties v-if="product.type === 'rectSilencer'" :product="product"/>
            <div v-else-if="product.type === 'abstract' || product.type === 'custom'" class="product-attributes">
                <div class="product-attribute">
                    <div class="attribute-title text-regular">Class:</div>
                    <div class="attribute-value text-regular">{{ product.class || '' }}</div>
                </div>
                <div class="product-attribute">
                    <div class="attribute-title text-regular">{{ $t('projectBreakdown.productSpecs.code') }}:</div>
                    <div class="attribute-value text-regular">{{ product.code || '' }}</div>
                </div>
            </div>
            <div v-else class="product-attributes">
                <div class="product-attribute">
                    <div class="attribute-title text-regular">{{ $t('projectBreakdown.productSpecs.diameter') }}:</div>
                    <div class="attribute-value text-regular">{{ product.nominalSizeDiameter || product.nominalSize }} mm</div>
                </div>
                <div class="product-attribute" v-if="product.dimensions && product.dimensions.A && product.dimensions.B">
                    <div class="attribute-title text-regular">{{ $t('rectSilencerCard.dimensions') }} (A, B):</div>
                    <div class="attribute-value text-regular">{{ product.dimensions.A }} mm, {{ product.dimensions.B }} mm</div>
                </div>
                <div class="product-attribute" v-if="product.length">
                    <div class="attribute-title text-regular">{{ $t('projectBreakdown.productSpecs.length') }}:</div>
                    <div class="attribute-value text-regular">{{ product.length }} mm</div>
                </div>
                <div class="product-attribute" v-if="product.weight">
                    <div class="attribute-title text-regular">{{ $t('projectBreakdown.productSpecs.weight') }}:</div>
                    <div class="attribute-value text-regular">{{ product.weight }} kg</div>
                </div>
                <div class="product-attribute" v-if="product.attenuationMaterial">
                    <div class="attribute-title text-regular">{{ $t('projectBreakdown.productSpecs.absorptionMaterial') }}:</div>
                    <div class="attribute-value text-regular">{{ $t(`productAttributes.attenuationMaterial.${product.attenuationMaterial.replace(" ", "")}`) }}</div>
                </div>
                <div class="product-attribute" v-if="'inputAirflow' in product">
                    <div class="attribute-title text-regular">{{ $t('rectSilencerData.inputAirflow') }}:</div>
                    <div class="attribute-value text-regular">{{ parseNumber({ value: product.inputAirflow }) }} l/s</div>
                </div>
                <div class="product-attribute" v-if="'pressureDrop' in product">
                    <div class="attribute-title text-regular">{{ $t('rectSilencerData.pressureDrop') }}:</div>
                    <!-- <div class="attribute-value text-regular">{{ parseNumber({ value: product.pressureDrop, toFixed: 3 }) }} Pa</div> -->
                    <div class="attribute-value text-regular">{{ roundNumber(product.pressureDrop) }} Pa</div>
                </div>
            </div>
            <div class="column mt-4">
                <!-- TODO: move "resolveIntermediaryCalculations" into the SoundPowerChange component -->
                <SoundPowerChange :calculations="resolveIntermediaryCalculations(product, productIndex)"></SoundPowerChange>
            </div>
        </div>
    </div>

    

    <div class="filler"></div>

    <div class="row result-wrapper p-0 mb-8">
        <div class="product-calculations final">
            <div class="calculation-result">
                <div class="calculation-title text-regular">{{ $t('projectBreakdown.soundPower') }}: Lw</div>
                <div class="calculation-value text-bold">{{ resolveSoundPowerLw(productIndex) }} db</div>
            </div>
            <div class="calculation-result">
                <div class="calculation-title text-regular">{{ $t('projectBreakdown.soundPower') }}: {{ filterCorrectionUnit }}</div>
                <div class="calculation-value text-bold">{{ resolveSoundPowerLwA(productIndex) }} db</div>
            </div>
            <div class="calculation-result">
                <div class="calculation-title text-regular">{{ $t('projectBreakdown.soundPower') }}: LpA10m2</div> <!-- calculation-title large -->
                <div class="calculation-value text-bold">{{ resolveSoundPowerLpA10m2(productIndex) }} db</div> <!-- calculation-value large -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SoundPowerChange from '@/components/projects/pdf/product/SoundPowerChange';
import RectSilencerProperties from './RectSilencerProperties.vue';
import { resolveRectSilencerImage } from '@/utils';

const DEFAULT_ABSTRACT_IMAGE_URL = require(`@/assets/abstract_product.svg`);
const BEND_HORIZONTAL_SILENCER_SCHEMATIC = 'https://storage.googleapis.com/category-images/silencers/rectangular/bend/MPKI_horizontal_schematic.png';
const BEND_VERTICAL_SILENCER_SCHEMATIC = 'https://storage.googleapis.com/category-images/silencers/rectangular/bend/MPKI_vertical_schematic.png';


export default {
    name: 'ProductData',
    components: {
        SoundPowerChange,
        RectSilencerProperties,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        productIndex: {
            type: Number,
            required: true,
        },
        calculationResults: {
            type: Object,
            required: true,
        },
        inputSoundPowerLevel: {
            type: Object,
            required: true,
        },
    },
    inject: ['resolveTranslationMapValue'],
    data() {
        return {
            bendHorizontalSilencerSchematic: BEND_HORIZONTAL_SILENCER_SCHEMATIC,
            bendVerticalSilencerSchematic: BEND_VERTICAL_SILENCER_SCHEMATIC,
        }
    },
    computed: {
        ...mapGetters('silencerClassesModule', ['imagesMap']),
        ...mapGetters('diffuserClassesModule', { 'diffuserImagesMap': 'imagesMap' }),
        ...mapGetters('activeSystemModule', ['filterCorrectionUnit']),
        perProductCalculations() {
            const { perProductCalculations = [] } = this.calculationResults || {};

            return perProductCalculations;
        },
    },
    methods: {
        resolveProductImage(product) {
            if (product.classData) {
                return product.classData.imageDataURL || product.classData.imageURL;
            }
            if (product.type === 'silencer') {
                return this.resolveSilencerClassImage(product);
            }
            if (product.type === 'diffuser') {
                return this.resolveDiffuserClassImage(product);
            }
            if (product.type === 'rectSilencer') {
                return this.resolveRectSilencerClassImage(product);
            }

            return '';
        },
        resolveSilencerClassImage(product) {
            return this.imagesMap[product.class] || '';
        },
        resolveDiffuserClassImage(product) {
            return this.diffuserImagesMap[product.class] || '';
        },
        resolveRectSilencerClassImage(product) {
            return resolveRectSilencerImage(product.class, product.orientation);
        },
        resolveSoundPowerLw(productIndex) {
            const calculationResult = this.perProductCalculations[productIndex];
            if (!calculationResult) {
                return '';
            }

            return Math.round(calculationResult.totalSoundPowerToRoomLw);
        },
        resolveSoundPowerLwA(productIndex) {
            const calculationResult = this.perProductCalculations[productIndex];
            if (!calculationResult) {
                return '';
            }

            return Math.round(calculationResult.totalSoundPowerToRoomLwA);
        },
        resolveSoundPowerLpA10m2(productIndex) {
            const calculationResult = this.perProductCalculations[productIndex];
            if (!calculationResult) {
                return '';
            }

            return Math.round(calculationResult.totalSoundPowerToRoomLpA10m2);
        },
        isOctaveBandRangeEmpty(octaveBandRange) {
            return Object.values(octaveBandRange).every((octaveBand) => octaveBand === 0);
        },
        resolveIntermediaryCalculations(product, productIndex) {
            const result = {
                input: null,
                attenuation: null,
                soundPower: null,
                output: null,
            };
            if (!product || !product.id) {
                return result; 
            }
            if (!(productIndex in this.perProductCalculations)) {
                return result;
            }
            const { calculationSteps = [] } = this.perProductCalculations[productIndex] || {};
            if (!calculationSteps.length) {
                return result;
            }
            const finalCalculationStep = calculationSteps[calculationSteps.length - 1];
            let previousProductFinalCalculationStep = null;
            // const initialStepIndex = calculationSteps.findIndex((step) => step.id === product.id);
            // if (initialStepIndex > 0) {
            //     previousProductFinalCalculationStep = calculationSteps[initialStepIndex - 1];
            // }
            if (productIndex > 0) {
                previousProductFinalCalculationStep = calculationSteps[productIndex - 1];
            }
            result.input = previousProductFinalCalculationStep
                ? previousProductFinalCalculationStep.soundPowerLevel || previousProductFinalCalculationStep.attenuation
                : this.inputSoundPowerLevel;
            result.attenuation = product.soundAttenuation;
            result.soundPower = !this.isOctaveBandRangeEmpty(product.soundPowerLevel) ? product.soundPowerLevel : null;
            result.output = finalCalculationStep.soundPowerLevel || finalCalculationStep.attenuation;

            return result;
        },
        abstractImageURL(product) {
            if (product.imageURL === '') {
                return DEFAULT_ABSTRACT_IMAGE_URL;
            }
            return product.imageURL;
        },
        resolveDiagramsArray(diagramsMap) {
            if (!diagramsMap || !Object.keys(diagramsMap).length) {
                return [];
            }

            return diagramsMap[this.$i18n.locale] || [];
        },
        parseNumber({ value, defaultValue = '-', toFixed = 0 }) {
            if (Number.isNaN(Number(value))) {
                return defaultValue;
            }

            return (toFixed > 0) ? Number(value).toFixed(toFixed) : Number(value);
        },
        roundNumber(value) {
            if (Number.isNaN(Number(value))) {
                return 0;
            }

            return Math.round(value);
        },
        getRectSilencerDiagrams(productClass, orientation = 'horizontal') {
            if (!productClass) {
                return [];
            }
            switch (productClass) {
                case 'MPK':
                case 'MPP':
                case 'MPC':
                    return orientation === 'horizontal'
                        ? ['https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MPK_MPP_MPC_horizontal.png']
                        : ['https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MPK_MPP_MPC_vertical.png'];
                case 'MPKI':
                case 'MPPI':
                case 'MPCI':
                    return orientation === 'horizontal'
                        ? ['https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MPKI_MPPI_MPCI_horizontal.png']
                        : ['https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MPKI_MPPI_MPCI_vertical.png'];
                case 'MRKA':
                case 'MRPA':
                case 'MRCA':
                    return [
                        'https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MRKA_MRPA_MRCA_1.png',
                        'https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MRKA_MRPA_MRCA_2.png',
                    ];
                case 'MSK':
                case 'MSP':
                case 'MSC':
                    return [
                        'https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MSK_MSP_MSC_1.png',
                        'https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MSK_MSP_MSC_2.png',
                    ];
                case 'MSKI':
                case 'MSPI':
                case 'MSCI':
                    return [
                        'https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MSKI_MSPI_MSCI_1.png',
                        'https://storage.googleapis.com/category-images/silencers/rectangular/diagrams/MSKI_MSPI_MSCI_2.png',
                    ];
                default:
                    return [];
            }
        }
    },
}
</script>

<style scoped lang="scss">
@use 'src/assets/css/pdf/page.scss';
@use 'src/assets/css/pdf/page-content.scss';
</style>

<style lang="scss">
@import 'src/assets/css/pdf/_variables.scss';
$regular-font-size: 10pt;
$title-font-size: 12px;

.page {

    .octave-band-container {
        width: 100%;

        .octave-band-data-title {
            font-family: $font-bold;
            font-size: $regular-font-size;
            color: black !important;
            margin-top: 0;
            padding-right: 10px;
        }

        .octave-band-range {
            margin-top: 0;
            width: auto;
            flex: 1;

            .octave-value {
                font-family: $font-bold;
                font-size: $regular-font-size;
            }
        }
    }

    .octave-band-data-title {
        color: black !important;
    }

    .octave-title {
        color: black !important;
        font-size: $regular-font-size;
    }

    .octave-value {
        color: black !important;
    }

    .octave-band-range {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
    }

    .octave-band-range .octave-band {
        display: flex;
        flex-direction: column;
        // flex: 0 1 12.5%;
        flex: 1 1 auto !important;
    }

    .p-0 {
        padding: 0 !important;
    }

    .right-side .row {
        padding: 0;
        margin-top: 0.5rem;
    }
}
</style>
