<template>
    <div class="card row calculation-params">
        <div class="row input-airflow-container p-0 ml-2 mt-4">
            <p class="title">{{ $t('rectSilencerData.inputAirflow') }} (l/s)</p>
            <InputField
                class="mt-4"
                placeholder="l/s"
                type="number"
                :modelValue="inputAirflow"
                @update:modelValue="onUpdateInputAirflow"
            ></InputField>
        </div>
        <div class="row input-sound-power-container">
            <p class="title mr-4">{{ $t('rectSilencerData.noiseLevelsBefore') }} (Hz)</p>
            <InputSoundPowerEditor />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import InputField from '@/components/general/InputField.vue';
import InputSoundPowerEditor from '@/components/pages/modelling/rect-silencer/InputSoundPowerEditor.vue';

const debounce = require('lodash.debounce');

export default {
    name: 'CalculationParamsPanel',
    components: {
        InputField,
        InputSoundPowerEditor,
    },
    inject: ['resolveTranslationMapValue'],
    computed: {
        ...mapState('mcadCalculationsModule', ['inputSoundPower', 'inputAirflow']),
    },
    methods: {
        ...mapActions('mcadCalculationsModule', ['setInputSoundPower', 'setInputAirflow']),
        ...mapActions('rectSilencerModule', {
            'rectSilencerSetInputSoundPower': 'setInputSoundPower',
            'rectSilencerSetInputAirflow': 'setInputAirflow',
        }),
        onUpdateInputAirflow: debounce(function (value) {
            this.setInputAirflow(value);
            this.rectSilencerSetInputAirflow(value);
        }, 800),
        onUpdateInputSoundPower: debounce(function (value) {
            this.setInputSoundPower(value);
            this.rectSilencerSetInputSoundPower(value);
        }, 800),
    },
}
</script>

<style lang="scss">
@use 'src/assets/css/product/productsList.scss';
</style>

<style scoped>
.title {
    width: 450px;
    text-align: right;
}

.calculation-params {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-airflow-container {
    max-width: 250px;
}

.input-airflow-container .title {
    width: 120px;
    text-align: left;
}

.input-airflow-container .input-container {
    width: 100px;
}

.input-airflow-container {
    justify-content: flex-start;
    align-items: end;
}

.input-sound-power-container {
    align-items: end;
    max-width: 1000px;
}
</style>
