<template>
    <ProductCard class="mb-6" :direction="direction">
        <template v-slot:product-image>
            <img :src="rectSilencerImageURL" class="rect-silencer-image" alt="Rect Silencer Image">
        </template>
        <template v-slot:product-properties>
            <div class="product-properties">
                <div class="product-name">{{ $t('rectSilencerCard.title') }}</div>
            </div>
        </template>
        <template v-slot:product-sound-adjustment>
            <div>{{ $t('rectSilencerCard.pressAddToConfigure') }}</div>
        </template>
        <template v-slot:product-actions>
            <Button @click="openRectSilencerModal" size="medium" class="insertion-button mb-4">{{ $t('commonControls.add') }}</Button>
        </template>
    </ProductCard>
    <RectSilencerModal v-model="rectSilencerModalOpened"></RectSilencerModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProductCard from '@/components/products/product/ProductCard.vue';
import RectSilencerModal from '@/components/products/rect-silencers/RectSilencerModal.vue';
import Button from '@/components/general/Button.vue';

const DEFAULT_RECT_SILENCER_IMAGE_URL = require(`@/assets/rect_silencer_2.png`); // @/assets/rect_silencer.svg

export default {
    name: 'RectSilencerInsertion',
    components: {
        ProductCard,
        RectSilencerModal,
        Button,
    },
    props: {
        direction: {
            type: String,
            default: 'horizontal',
            validator: (value) => (['horizontal', 'vertical'].includes(value)) 
        },
    },
    data() {
        return {
            rectSilencerModalOpened: false,
        };
    },
    computed: {
        ...mapGetters('activeSystemModule', ['soundPowerLevelData']),
        rectSilencerImageURL() {
            return DEFAULT_RECT_SILENCER_IMAGE_URL;
        },
    },
    methods: {
        ...mapActions('rectSilencerModule', ['setInputSoundPower']),
        async openRectSilencerModal() {
            await this.setInputSoundPower(this.soundPowerLevelData.soundPowerLevel);
            this.rectSilencerModalOpened = true;
        },
    },
}
</script>

<style lang="scss" scoped>
.rect-silencer-image {
    width: 128px !important;
    height: auto !important;
}

.grid-layout {
    .insertion-button {
        height: 40px;
    }
}
</style>