<template>
    <div v-if="generatingPDF" class="loader-container-overlap">
        <div class="loader big-loader"></div>
    </div>
    <div class="page-content">
        <Breadcrumbs class="mt-4" :items="breadcrumbs"></Breadcrumbs>
        <div class="card column mt-4">
            <div class="card-title">
                <div class="text-block">
                    <div class="navigation text-small" @click="navigateToProductList"><i class="icon icon-small icon-chevron-left mr-2"></i>{{ $t('productsList.breadcrumbs.productsList') }}</div>
                    <div class="header mt-3">{{ $t('projectOverview.system') }}</div>
                    <div class="description mt-1">{{ $t('projectOverview.youWillSeeCalculationResult') }}</div>
                </div>
                <div class="actions">
                    <div class="btn download-pdf mr-3" @click="doPrinting" v-if="!generatingPDF">PDF<i class="download-icon"></i></div>
                    <div class="btn download-pdf mr-3" v-else>
                        PDF<div class="loader small-loader"></div>
                    </div>
                    <SystemDropdownMenu 
                        @onEditSystem="editSystemModalOpened = true" 
                        @onDeleteSystem="deleteSystemModalOpened = true"
                    ></SystemDropdownMenu>
                    <!-- <Button type="secondary" outlined size="small" class="mr-3" @click="editProjectModalOpened = true">{{ $t('projectOverview.controls.editProject') }}</Button>
                    <Button type="secondary" outlined size="small" class="mr-3" @click="editSystemModalOpened = true">{{ $t('projectOverview.controls.editSystem') }}</Button>
                    <Button type="primary" outlined size="small" class="mr-3" @click="deleteProjectAndNavigate" v-if="userAuthorized">{{ $t('projectOverview.controls.deleteProject') }}</Button>
                    <Button type="primary" outlined size="small" @click="deleteSystemAndNavigate" v-if="userAuthorized">{{ $t('projectOverview.controls.deleteSystem') }}</Button> -->
                </div>
            </div>
            <div class="card-content column">
                <div class="row mt-12">
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.projectName') }}</div>
                        <div class="attribute-value link-medium">{{ projectName || '-' }}</div>
                    </div>
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.projectNr') }}</div>
                        <div class="attribute-value link-medium">{{ projectNr || '-' }}</div>
                    </div>
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.contactData') }}</div>
                        <div class="attribute-value link-medium">{{ contactData || '-' }}</div>
                    </div>
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.systemNr') }}</div>
                        <div class="attribute-value link-medium">{{ systemNr || '-' }}</div>
                    </div>
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectSpecificationModal.comment') }}</div>
                        <div class="attribute-value link-medium">{{ comment || '-' }}</div>
                    </div>
                </div>
                <div class="row mt-12">
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.email') }}</div>
                        <div class="attribute-value link-medium">{{ email || '-' }}</div>
                    </div>
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.phoneNumber') }}</div>
                        <div class="attribute-value link-medium">{{ phone || '-' }}</div>
                    </div>
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.address') }}</div>
                        <div class="attribute-value link-medium">{{ address || '-' }}</div>
                    </div>
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.filterCorrection') }}</div>
                        <div class="attribute-value link-medium">{{ resolveCorrectionFilter(filterCorrectionType) }}</div>
                    </div>
                </div>
                <div class="row mt-12">
                    <div class="project-attribute">
                        <div class="attribute-title small-title">{{ $t('projectOverview.attenuationConstant') }}</div>
                        <div class="attribute-value link-medium">{{ resolveAttenuationConstant(attenuationConstantType) }}</div>
                    </div>
                    <div class="project-attribute octave-band-range">
                        <div class="attribute-title small-title">{{ $t('projectOverview.noiseGenerator') }} (Hz)</div>
                        <div class="attribute-title small-title right-side">{{ $t('projectOverview.noiseGenerator') + " " + ($t('projectBreakdown.result')).toLowerCase() }}</div>
                        <div class="attribute-value">
                            <div class="octave-band">
                                <div class="text-medium">63</div>
                                <div class="link-medium">{{ inputSoundPowerLevel.octaveBand63Hz }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">125</div>
                                <div class="link-medium">{{ inputSoundPowerLevel.octaveBand125Hz }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">250</div>
                                <div class="link-medium">{{ inputSoundPowerLevel.octaveBand250Hz }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">500</div>
                                <div class="link-medium">{{ inputSoundPowerLevel.octaveBand500Hz }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">1000</div>
                                <div class="link-medium">{{ inputSoundPowerLevel.octaveBand1000Hz }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">2000</div>
                                <div class="link-medium">{{ inputSoundPowerLevel.octaveBand2000Hz }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">4000</div>
                                <div class="link-medium">{{ inputSoundPowerLevel.octaveBand4000Hz }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">8000</div>
                                <div class="link-medium">{{ inputSoundPowerLevel.octaveBand8000Hz }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">Lw</div>
                                <div class="link-medium">{{ noiseGeneratorTotalSoundPowerLw }}</div>
                            </div>
                            <div class="octave-band">
                                <div class="text-medium">{{ filterCorrectionUnit }}</div>
                                <div class="link-medium">{{ noiseGeneratorTotalSoundPowerLwA }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="userAuthorized" class="project-attribute items-end">
                        <Button type="primary" @click="createSystemModalOpened = true" size="small">{{ $t('systemsPage.createNewSystem') }}</Button>
                    </div>
                </div>
            </div>
        </div>

        <div class="tabs mt-8">
            <div class="tabs-header">
                <div class="tab-header" :class="{ 'active-tab-header': activeTab === 'product' }" @click="activeTab = 'product'">{{ $t('projectOverview.summaries.products') }}</div>
                <div class="tab-header" :class="{ 'active-tab-header': activeTab === 'calculation' }" @click="activeTab = 'calculation'">{{ $t('projectOverview.summaries.detailedCalculations') }}</div>
            </div>
            <div class="tabs-content">
                <div class="tab" :class="{ 'active-tab': activeTab === 'product' }">
                    <!-- <ProductListItems readOnly :silencers="selectedSilencers" :diffusers="selectedDiffusers" :abstractProducts="selectedAbstractProducts"></ProductListItems> -->
                    <div class="w-full flex justify-end mb-4">
                        <LayoutControls v-model="layout"/>
                    </div>
                    <SelectedProductsList :layout="layout"/>
                </div>
                <div class="tab" :class="{ 'active-tab': activeTab === 'calculation' }" v-if="calculationStepsSplitted.length">
                    <div class="row mb-8 flex-col">
                        <div class="calculation-results">
                            <div class="calculation-column">
                                <div class="calculation-row title odd">{{ $t('octaveBandAdjustmentModal.octaveBand') }} [Hz]</div>
                                <div class="calculation-row bold" :class="{ 'even': index % 2 === 0, 'odd': index % 2 !== 0 }" v-for="(octaveBand, index) in octaveBandRange" :key="octaveBand">{{ octaveBand }}</div>
                            </div>
                            <div class="calculation-column-group">
                                <div class="calculation-column">
                                    <div class="calculation-row title odd">{{ $t('projectOverview.noiseGenerator') }}</div>
                                    <div class="calculation-row bold" :class="{ 'even': index % 2 === 0, 'odd': index % 2 !== 0 }" v-for="(octaveBand, index) in octaveBandRange" :key="octaveBand">{{ inputSoundPowerLevel['octaveBand' + octaveBand + 'Hz'] }}</div>
                                </div>
                                <div class="calculation-column" v-for="(calculationStep, index) in calculationStepsSplitted" :key="index">
                                    <div class="calculation-row title odd">{{ calculationStep.name }}</div>
                                    <div class="calculation-row" :class="{ 'even': index % 2 === 0, 'odd': index % 2 !== 0 }" v-for="(value, index) in octaveBandRangeToArray(calculationStep.soundPowerLevel)" :key="index">{{ value }}</div>
                                </div>
                            </div>
                            <div class="calculation-column">
                                <div class="calculation-row title odd">{{ $t('projectOverview.filterCorrection') + ' (' + resolveCorrectionFilter(filterCorrectionType) + ')' }}</div>
                                <!-- filterCorrectionFactor -->
                                <div class="calculation-row" :class="{ 'even': index % 2 === 0, 'odd': index % 2 !== 0 }" v-for="(value, index) in filterCorrectionFactor" :key="index">{{ value }}</div>
                            </div>
                            <div class="calculation-column">
                                <div class="calculation-row title odd">{{ filterCorrectionUnit }}</div>
                                <div class="calculation-row" :class="{ 'even': index % 2 === 0, 'odd': index % 2 !== 0 }" v-for="(value, index) in soundPowerLevelToRoomLwA" :key="index">{{ value }}</div>
                            </div>
                            <div class="calculation-column">
                                <div class="calculation-row title odd">LpA10m2</div>
                                <div class="calculation-row" :class="{ 'even': index % 2 === 0, 'odd': index % 2 !== 0 }" v-for="(value, index) in soundPowerLevelToRoomLpA10m2" :key="index">{{ value }}</div>
                            </div>
                        </div>
                        <p class="footnote">
                            * ATT - {{$t('projectBreakdown.attenuation')}}, SPL - {{$t('projectBreakdown.soundPowerLevel')}}
                        </p>
                    </div>
                    <!-- <ProductListItems readOnly detailed :silencers="selectedSilencers" :diffusers="selectedDiffusers" :abstractProducts="selectedAbstractProducts"></ProductListItems> -->
                    <DetailedSelectedProductsList/>
                </div>
            </div>
        </div>
        <CalculationsPanel class="mt-16"></CalculationsPanel>
        <EditSystemModal size="medium" v-model="editSystemModalOpened"></EditSystemModal>
        <CreateSystemModal size="medium" v-model="createSystemModalOpened"></CreateSystemModal>
        <DeleteConfirmationModal
            size="small"
            v-model="deleteSystemModalOpened"
            @onConfirm="confirmDeleting"
        ></DeleteConfirmationModal>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import SelectedProductsList from '@/components/projects/SelectedProductsList.vue';
import DetailedSelectedProductsList from '@/components/projects/DetailedSelectedProductsList.vue';
import CalculationsPanel from '../systems/calculations/CalculationsPanel.vue';
import EditSystemModal from '../systems/EditSystemModal.vue';
import CreateSystemModal from '../systems/CreateSystemModal.vue';
import SystemDropdownMenu from '../systems/SystemDropdownMenu.vue';
import DeleteConfirmationModal from '../systems/DeleteConfirmationModal.vue';
import LayoutControls from '@/components/products/product/LayoutControls.vue';
import Button from '@/components/general/Button.vue';
import Breadcrumbs from '@/components/general/Breadcrumbs.vue';

export default {
    name: 'ProjectOverview',
    components: {
        SelectedProductsList,
        DetailedSelectedProductsList,
        CalculationsPanel,
        EditSystemModal,
        CreateSystemModal,
        SystemDropdownMenu,
        DeleteConfirmationModal,
        LayoutControls,
        Button,
        Breadcrumbs,
    },
    data() {
        return {
            layout: 'linear',
            editSystemModalOpened: false,
            createSystemModalOpened: false,
            generatingPDF: false,
            octaveBandRange: [
                63,
                125,
                250,
                500,
                1000,
                2000,
                4000,
                8000,
            ],
            activeTab: 'product',
            deleteSystemModalOpened: false,
        };
    },
    async created() {
        await this.getPerSystemCalculations();
    },
    computed: {
        ...mapState('activeProjectModule', {
            'projectName': 'name',
            'projectNr': 'number',
            'address': 'address',
            'email': 'email',
            'phone': 'phone',
            'contactData': 'contactData',
        }),
        ...mapState('activeSystemModule', {
            'systemName': 'name',
            'systemNr': 'number',
            'comment': 'comment',
            'attenuationConstantType': 'attenuationConstantType',
            'filterCorrectionType': 'filterCorrectionType',
            'inputSoundPowerLevel': 'inputSoundPowerLevel',
            'calculationResults': 'calculationResults',
        }),
        ...mapState('silencersModule', ['silencers']),
        ...mapState('diffusersModule', ['diffusers']),
        ...mapGetters('activeSystemModule', ['activeSystem', 'selectedProductsMap', 'calculationStepsSplitted', 'filterCorrectionUnit']),
        ...mapGetters('userModule', ['userAuthorized']),
        ...mapGetters('activeProjectModule', ['activeProject']),
        ...mapState('systemsModule', ['systems']),
        selectedSilencers() {
            return this.silencers.filter((silencer) => silencer.id in this.selectedProductsMap);
        },
        selectedDiffusers() {
            return this.diffusers.filter((diffuser) => diffuser.id in this.selectedProductsMap);
        },
        selectedAbstractProducts() {
            return Object.values(this.selectedProductsMap).filter((product) => product.type === 'abstract');
        },
        soundPowerLevelToRoomLwA() {
            const { soundPowerLevelToRoomLwA } = this.calculationResults;
            if (!soundPowerLevelToRoomLwA) {
                return [];
            }

            return this.octaveBandRangeToArray(soundPowerLevelToRoomLwA);
        },
        soundPowerLevelToRoomLpA10m2() {
            const { soundPowerLevelToRoomLpA10m2 } = this.calculationResults;
            if (!soundPowerLevelToRoomLpA10m2) {
                return [];
            }

            return this.octaveBandRangeToArray(soundPowerLevelToRoomLpA10m2);
        },
        noiseGeneratorTotalSoundPowerLw() {
            const { noiseGeneratorTotalSoundPowerLw } = this.calculationResults;
            if (!noiseGeneratorTotalSoundPowerLw) {
                return '-';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLw);
        },
        noiseGeneratorTotalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.calculationResults;
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return '-';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA);
        },
        filterCorrectionFactor() {
            const { filterCorrectionFactor } = this.calculationResults;
            if (!filterCorrectionFactor) {
                return [];
            }

            return this.octaveBandRangeToArray(filterCorrectionFactor);
        },
        breadcrumbs() {
            return [
              {
                title: this.$t('productsList.breadcrumbs.home'),
                link: { name: 'projects' },
              },
              {
                title: this.projectName || this.$t('breadcrumbs.activeProject'),
                link: { name: 'systems' },
              },
              {
                title: this.systemName || this.$t('breadcrumbs.activeSystem'),
                link: { name: 'silencers' },
              },
              {
                title: this.$t('breadcrumbs.overview'),
              }
            ];
        },
    },
    methods: {
        ...mapActions('activeProjectModule', ['deleteProject']),
        ...mapActions('activeSystemModule', ['deleteSystem']),
        ...mapActions('systemsModule', ['getPerSystemCalculations']),
        async removeProject() {
            await this.deleteProject();
            this.$router.push({ name: 'projects' });
        },
        octaveBandRangeToArray(octaveBandRange) {
            if (!octaveBandRange || !Object.keys(octaveBandRange).length) {
                return [];
            }

            return Object.values(octaveBandRange).map(value => Math.round(value));
        },
        resolveAttenuationConstant(value) {
            if (!value) {
                return '-';
            }

            return value === 'T1'
                ? 'LpA10m2'
                : this.$t('projectBreakdown.constants.noConstant');
        },
        resolveCorrectionFilter(value) {
            if (!value) {
                return '-';
            }
            switch (value) {
                case 'NoFilter':
                    return this.$t('projectBreakdown.filters.noFilter');
                case 'AFilter':
                    return 'A - ' + this.$t('projectBreakdown.filters.filter');
                case 'BFilter':
                    return 'B - ' + this.$t('projectBreakdown.filters.filter');
                case 'CFilter':
                    return 'C - ' + this.$t('projectBreakdown.filters.filter');
                default:
                    return this.$t('projectBreakdown.filters.noFilter');
            }
        },
        navigateToProductList() {
            this.$router.push({ name: 'products-list' });
        },
        async deleteProjectAndNavigate() {
            await this.deleteProject();
            this.$router.push({ name: 'projects' });
        },
        async deleteSystemAndNavigate() {
            await this.deleteSystem();
            this.$router.push({ name: 'systems' });
        },
        async confirmDeleting() {
            await this.deleteSystemAndNavigate();
        },
        doPrinting() {
            const data = {
                project: this.activeProject,
                system: this.activeSystem,
            };
            const blob = new Blob([JSON.stringify(data, null, 2)], {type : 'application/json'});
            const objectUrl = URL.createObjectURL(blob);
            const objectId = objectUrl.split('/').pop();
            const url = `/printing/system?objectId=${objectId}`;
            window.open(url, 'print-system', 'popup=0,width=595,height=842,toolbar=0,menubar=0,location=0').focus();
        },
        // saveAsPDF() {
        //     this.$refs.iframe.contentWindow.focus();
        //     this.$refs.iframe.contentWindow.print();
        // },
    }
}
</script>

<style lang="scss" scoped>
.page-content {
    position: relative;
}

// .breakdown-wrapper {
//     display: flex;
//     height: 0;
//     position: relative;
//     overflow: hidden;
// }

.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.text-block .header {
    font-family: Helvetica Neue;
    font-size: 28px;
    line-height: 33px;
    color: #737372;
}

.text-block .description {
    font-family: Helvetica Neue;
    font-size: 16px;
    line-height: 19px;
    color: #737372;
}

.download-pdf {
    font-family: Helvetica Neue Bold;
    background: #DFEBF7;
    border: 1px solid #9DC3E6;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px;
    font-style: normal;
    // font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #4D91D1;
}

.download-pdf i {
    margin-left: 10px;
    width: 12px;
    height: 12px;
    background-image: url('../../assets/icons/singular/download.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.card-content .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
}

.card-content.column {
    flex-direction: column;
}

.card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.project-attribute {
    display: flex;
    flex-direction: column;
    flex: 0 1 20%;
    &.octave-band-range {
        flex: 0 1 60%;
        position: relative;

    }
    .small-title {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 100;
        font-size: 14px;
        line-height: 14px;
        color: #737372;
        &.right-side {
            position: absolute;
            left: 80%;
        }
    }
    .attribute-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        .octave-band {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 0 1 10%;
        }
    }
}

.octave-band .text-medium, .octave-band .link-medium, .attribute-value.link-medium {
    font-size: 14px;
    line-height: 17px;
    color: #737372;
}

.link-medium, .heading-small-bold, .text-small, .text-medium {
    color: #737372;
}

.calculation-results {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
}

.footnote {
    font-size: 13px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.calculation-column-group {
    display: flex;
    flex: 1 1 auto;
    overflow-x: auto;
}

.calculation-column-group .calculation-column {
    flex: 1 0 10%;
}

.calculation-column {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.calculation-row {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    font-family: Helvetica Neue;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #4C5055;
    padding: 0 20px;
}

.calculation-row.title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    // font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #737372;
    height: 45px;
    max-height: 45px;
}

.calculation-row.even {
    background: #FFFFFF;
}

.calculation-row.odd {
    background: #F8F8F8;
}

.calculation-row.bold {
    font-family: Helvetica Neue Bold;
    // font-weight: bold;
}

.row {
    width: 100%;
}

.tabs {
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}

.tabs-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #D5D6D5;
    width: 100%;
}

.tab-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue;
    font-size: 16px;
    line-height: 19px;
    color: #737372;
    padding: 12px 32px;
    border-bottom: none;
    user-select: none;
    cursor: pointer;
}

.active-tab-header {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    // font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #CC0000;
    border-bottom: 1px solid #CC0000;
}

.tabs-content {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 40px;
}

.tab {
    display: none;
    opacity: 0;
    width: 100%;
}

.active-tab {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity ease-in 0.3s;
}

.actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.loader.small-loader {
    margin-left: 10px;
    width: 12px !important;
    height: 12px !important;
    border: .2em solid #4D91D1 !important;
    border-bottom-color: transparent !important;
}
</style>
