import xml2js from 'xml2js';
import { getGeometryForSilencer } from './geometry';
// import uuid from 'uuid';

function getDimData(silencer) {
    return {
        flow_min_ls: silencer.dimData && silencer.dimData.minInputAirflow ? silencer.dimData.minInputAirflow : 0,
        flow_max_ls: silencer.dimData && silencer.dimData.maxInputAirflow ? silencer.dimData.maxInputAirflow : 0,
        dp_min_pa: 1,
        dp_max_pa: 100,
        D1: silencer.type === 'rectSilencer' ? silencer.width || 0 : silencer.nominalSizeDiameter || 0,
        D2: silencer.type === 'rectSilencer' ? silencer.height || 0 : 0,
        function: 110,
    };
}

function getAttenuation(silencer) {
    return {
        flow_ls: 0,
        dp_pa: 0,
        lw63: Math.round(silencer.soundAttenuation.octaveBand63Hz),
        lw125: Math.round(silencer.soundAttenuation.octaveBand125Hz),
        lw250: Math.round(silencer.soundAttenuation.octaveBand250Hz),
        lw500: Math.round(silencer.soundAttenuation.octaveBand500Hz),
        lw1000: Math.round(silencer.soundAttenuation.octaveBand1000Hz),
        lw2000: Math.round(silencer.soundAttenuation.octaveBand2000Hz),
        lw4000: Math.round(silencer.soundAttenuation.octaveBand4000Hz),
        lw8000: Math.round(silencer.soundAttenuation.octaveBand8000Hz),
    };
}

function getProductCode(code) {
    return {
        $: {
            area: 'ALL',
            value: code,
        },
    };
}

function getFormattedClassNameForRegularSilencer(silencer, withDiameter = true) {
    if (!silencer || !silencer.class) {
        return '';
    }
    const match = silencer.class.match(/\d+/);
    if (withDiameter) {
        return (match && match.length)
            ? `${silencer.class.substr(0, match.index)} ${match[0]}`
            : `${silencer.class} ${silencer.nominalSizeDiameter || ''}`.trim();
    }

    return (match && match.length)
        ? silencer.class.substr(0, match.index)
        : silencer.class;
}

function payloadToXml(payload) {
    const builder = new xml2js.Builder({ rootName: 'silencer' });

    return builder.buildObject(payload);
}

// TODO: separate for round and rect silencers
export function mapRectSilencerData(silencer) {
    if (!silencer || !silencer.id) {
        throw new Error('Silencer is not provided');
    }
    const geometry = getGeometryForSilencer(silencer);
    // const silencerUuid = uuid();
    // const silencerUuid = silencer.id;
    const data = {
        // id: silencerUuid,
        class: 'dSilencerType',
        product_codes: {
            // product_code: getProductCode(`productALL-${silencer.class}`),
            product_code: getProductCode(silencer.class),
        },
        manufacturer: 'ETS NORD',
        geometry_model_id: geometry ? geometry.modelId : '',
        // air_device_dim_data: getDimData(silencer),
        variants: {
            variant: {
                $: {
                    selected: 1,
                },
                // id: silencerUuid,
                product_codes: {
                    product_code: getProductCode(silencer.code),
                },
                geometry_model_params: geometry ? geometry.modelParameters : '',
                air_device_dim_data: getDimData(silencer),
                dp_curve: {
                    flow_ref_ls: silencer.inputAirflow || 0,
                    dp_ref_pa: silencer.optionData.pressureDrop || 0,
                },
                attenuation: getAttenuation(silencer),
            },
        },
    };
    console.log(payloadToXml(data));

    return payloadToXml(data);
}

export function mapRegularSilencerData(silencer) {
    if (!silencer || !silencer.id) {
        throw new Error('Silencer is not provided');
    }
    // const silencerUuid = uuid();
    // const silencerUuid = silencer.id;
    const geometry = getGeometryForSilencer(silencer);
    const data = {
        // id: silencerUuid,
        class: 'dSilencerType',
        product_codes: {
            // product_code: getProductCode(`productALL-${silencer.class}`),
            product_code: getProductCode(getFormattedClassNameForRegularSilencer(silencer)),
        },
        manufacturer: 'ETS NORD',
        geometry_model_id: geometry ? geometry.modelId : '',
        // air_device_dim_data: getDimData(silencer),
        variants: {
            variant: {
                $: {
                    selected: 1,
                },
                // id: silencerUuid,
                product_codes: {
                    // product_code: getProductCode(silencer.class),
                    product_code: getProductCode(`${getFormattedClassNameForRegularSilencer(silencer, false)} ${silencer.nominalSizeDiameter}-${silencer.length}`),
                },
                geometry_model_params: geometry ? geometry.modelParameters : '',
                air_device_dim_data: getDimData(silencer),
                dp_curve: {
                    flow_ref_ls: silencer.inputAirflow || 0,
                    dp_ref_pa: silencer.pressureDrop || 0,
                },
                attenuation: getAttenuation(silencer),
            },
        },
    };
    console.log(payloadToXml(data));

    return payloadToXml(data);
}