<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ $t('systemSpecificationModal.newSystem') }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="row">
                <InputField class="mr-8" :title="$t('systemSpecificationModal.systemName')" :placeholder="$t('systemSpecificationModal.systemName')" v-model="name"></InputField>
                <InputField :title="$t('systemSpecificationModal.systemNr')" :placeholder="$t('systemSpecificationModal.systemNr')" v-model="number"></InputField>
            </div>
            <div class="row mt-12">
                <!-- <InputField class="mr-8 w-full" :title="$t('systemSpecificationModal.author')" :placeholder="$t('systemSpecificationModal.author')" :model-value="author" @update:modelValue="setAuthor"></InputField> -->
                <DateInputField class="w-full mr-8" :title="$t('systemSpecificationModal.date')" :placeholder="$t('systemSpecificationModal.date')" v-model="date"></DateInputField>
                <TextareaField class="w-full" :title="$t('systemSpecificationModal.comment')" :placeholder="$t('systemSpecificationModal.comment')" v-model="comment"></TextareaField>
            </div>
            <div class="row mt-8 mb-6">
                <div class="group-title">{{ $t('systemSpecificationModal.systemSpecification') }}</div>
            </div>
            <div class="row mt-12">
                <Select :options="attenuationConstants" :label="$t('systemSpecificationModal.attenuationConstant')" v-model="attenuationConstantType" autoSelection class="mr-8"></Select>
                <Select :options="correctionFilters" :label="$t('systemSpecificationModal.filterCorrection')" v-model="filterCorrectionType" autoSelection></Select>
            </div>
            <OctaveBandRangeEditor
                :modelValue="inputSoundPowerLevel"
                @octaveBand63HzChanged="updateInputSoundPowerLevel('octaveBand63Hz', $event)"
                @octaveBand125HzChanged="updateInputSoundPowerLevel('octaveBand125Hz', $event)"
                @octaveBand250HzChanged="updateInputSoundPowerLevel('octaveBand250Hz', $event)"
                @octaveBand500HzChanged="updateInputSoundPowerLevel('octaveBand500Hz', $event)"
                @octaveBand1000HzChanged="updateInputSoundPowerLevel('octaveBand1000Hz', $event)"
                @octaveBand2000HzChanged="updateInputSoundPowerLevel('octaveBand2000Hz', $event)"
                @octaveBand4000HzChanged="updateInputSoundPowerLevel('octaveBand4000Hz', $event)"
                @octaveBand8000HzChanged="updateInputSoundPowerLevel('octaveBand8000Hz', $event)"
            >
                <template v-slot:title>
                    <div class="row mt-6">
                        <div class="group-title">{{ $t('systemSpecificationModal.noiseGenerator') }} (Hz)</div>
                    </div>
                </template>
            </OctaveBandRangeEditor>
        </template>
        <template v-slot:modal-actions>
            <div class="noise-generator-sound-power">
                <div class="heading-small-bold mr-1">Lw: </div>
                <div class="text-medium mr-2">{{ noiseGeneratorTotalSoundPowerLw }}</div>
                <div class="heading-small-bold mr-1">{{ filterCorrectionUnit }}: </div>
                <div class="text-medium">{{ noiseGeneratorTotalSoundPowerLwA }}</div>
            </div>
            <Button type="secondary" size="medium" outlined class="mr-16" @click="resetSystemData">{{ $t('systemSpecificationModal.clear') }}</Button>
            <Button type="primary" size="medium" @click="saveAndProceed">{{ $t('systemSpecificationModal.next') }}</Button>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InputField from '../general/InputField.vue';
import DateInputField from '../general/DateInputField.vue';
import TextareaField from '../general/TextareaField.vue';
import Button from '../general/Button.vue';
import Select from '../general/Select.vue';
import Modal from '../general/Modal.vue';
import OctaveBandRangeEditor from '@/components/products/product/OctaveBandRangeEditor.vue';
import { emptyOctaveBandRange } from '@/utils';
import * as CalculationsService from '@/services/calculations';

export default {
    name: 'CreateSystemModal',
    components: {
        InputField,
        DateInputField,
        TextareaField,
        Button,
        Modal,
        Select,
        OctaveBandRangeEditor,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            attenuationConstants: [
                {
                    title: this.$t('projectBreakdown.constants.noConstant'),
                    value: 'NoConstant',
                },
                {
                    title: 'LpA10m2',
                    value: 'T1',
                },
            ],
            correctionFilters: [
                {
                    title: this.$t('projectBreakdown.filters.noFilter'),
                    value: 'NoFilter',
                },
                {
                    title: 'A - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'AFilter',
                },
                {
                    title: 'B - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'BFilter',
                },
                {
                    title: 'C - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'CFilter',
                },
            ],
            name: '',
            number: '',
            date: new Date(),
            comment: '',
            attenuationConstantType: 'T1',
            filterCorrectionType: 'AFilter',
            inputSoundPowerLevel: emptyOctaveBandRange(),
            calculationResults: {},
        };
    },
    computed: {
        ...mapState('activeProjectModule', {
            'projectId': 'id',
        }),
        ...mapGetters('activeSystemModule', ['filterCorrectionUnit']),
        noiseGeneratorTotalSoundPowerLw() {
            const { noiseGeneratorTotalSoundPowerLw } = this.calculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLw) {
                return '-';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLw);
        },
        noiseGeneratorTotalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.calculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return '-';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA);
        },
    },
    methods: {
        ...mapActions('activeSystemModule', [
            'setName',
            'setNumber',
            'setDate',
            'setProjectId',
            'setComment',
            'setAttenuationConstantType',
            'setFilterCorrectionType',
            'setInputSoundPowerLevel',
            'resetData',
            'resetProductsData',
            // 'resetNoiseGeneratorData',
            'saveSystem',
        ]),
        ...mapActions('silencerClassesModule', [
            'clearSelection',
        ]),
        closeModal() {
            this.$emit('update:modelValue', false);
        },
        openSilencersPage() {
            this.resetProductsData();
            this.clearSelection();
            this.closeModal();
            this.$router.push({ name: 'silencers' });
        },
        async updateInputSoundPowerLevel(bandRange, soundPower) {
            if (Number.isNaN(Number(soundPower))) {
                return;
            }
            if (!(bandRange in this.inputSoundPowerLevel)) {
                return;
            }
            // this.setInputSoundPowerLevel({ bandRange, soundPower });
            this.inputSoundPowerLevel[bandRange] = Number(soundPower);
            await this.calculateSummary();
        },
        async saveAndProceed() {
            if (!this.projectId) {
                this.$router.push({ name: 'projects' });
                return;
            }
            await this.resetData();
            await Promise.all([
                this.setName(this.name),
                this.setNumber(this.number),
                this.setDate(this.date),
                this.setComment(this.comment),
                this.setAttenuationConstantType(this.attenuationConstantType),
                this.setFilterCorrectionType(this.filterCorrectionType),
                this.setInputSoundPowerLevel({
                    bandRange: 'octaveBand63Hz',
                    soundPower: this.inputSoundPowerLevel.octaveBand63Hz,
                }),
                this.setInputSoundPowerLevel({
                    bandRange: 'octaveBand125Hz',
                    soundPower: this.inputSoundPowerLevel.octaveBand125Hz,
                }),
                this.setInputSoundPowerLevel({
                    bandRange: 'octaveBand250Hz',
                    soundPower: this.inputSoundPowerLevel.octaveBand250Hz,
                }),
                this.setInputSoundPowerLevel({
                    bandRange: 'octaveBand500Hz',
                    soundPower: this.inputSoundPowerLevel.octaveBand500Hz,
                }),
                this.setInputSoundPowerLevel({
                    bandRange: 'octaveBand1000Hz',
                    soundPower: this.inputSoundPowerLevel.octaveBand1000Hz,
                }),
                this.setInputSoundPowerLevel({
                    bandRange: 'octaveBand2000Hz',
                    soundPower: this.inputSoundPowerLevel.octaveBand2000Hz,
                }),
                this.setInputSoundPowerLevel({
                    bandRange: 'octaveBand4000Hz',
                    soundPower: this.inputSoundPowerLevel.octaveBand4000Hz,
                }),
                this.setInputSoundPowerLevel({
                    bandRange: 'octaveBand8000Hz',
                    soundPower: this.inputSoundPowerLevel.octaveBand8000Hz,
                }),
            ]);
            await this.setProjectId(this.projectId);
            await this.saveSystem();
            this.openSilencersPage();
        },
        resetSystemData() {
            this.name = '';
            this.number = '';
            this.date = new Date();
            this.comment = '';
            this.attenuationConstantType = 'T1';
            this.filterCorrectionType = 'AFilter';
            this.inputSoundPowerLevel = emptyOctaveBandRange();
            this.calculationResults = {};
            // await this.resetData();
        },
        async calculateSummary() {
            this.calculationResults = await CalculationsService.calculateSoundPower({
                inputSoundPowerLevel: this.inputSoundPowerLevel,
                attenuationConstant: this.attenuationConstantType,
                filterCorrectionType: this.filterCorrectionType,
                products: [],
            });
        },
    },
    watch: {
        async modelValue(value) {
            if (!value) {
                return;
            }
            await this.resetSystemData();
        }
    },
}
</script>

<style>
.group-title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 22px;
    color: #737372;
}
</style>
