<template>
    <div class="dropdown-menu-overlay" tabindex="0" @blur="menuOpened = false">
        <Button
            class="menu-trigger"
            :size="size"
            :type="type"
            :outlined="outlined"
            @click="menuOpened = !menuOpened"
        >
            <div class="icon icon-large icon-kebab"></div>
        </Button>
        <div class="dropdown-menu" v-show="menuOpened">
            <DropdownMenu v-model="menuOpened">
                <DropdownMenuItem @click="editProject">
                    <template v-slot:item-prefix>
                        <div class="icon icon-medium icon-pencil mr-2"></div>
                    </template>
                    <template v-slot:item-content>
                        <div class="text">{{ $t('commonControls.edit') }}</div>
                    </template>
                </DropdownMenuItem>
                <DropdownMenuItem @click="deleteProject">
                    <template v-slot:item-prefix>
                        <div class="icon icon-medium icon-bin mr-2 highlighted-red"></div>
                    </template>
                    <template v-slot:item-content>
                        <div class="text highlighted-red">{{ $t('commonControls.delete') }}</div>
                    </template>
                </DropdownMenuItem>
                <DropdownMenuItem v-if="!hidePdfOptions" @click="printPDF">
                    <template v-slot:item-prefix>
                        <div class="icon icon-small icon-download mr-3 highlighted-blue"></div>
                    </template>
                    <template v-slot:item-content>
                        <div class="text highlighted-blue">{{ $t('commonControls.printPDF') }}</div>
                    </template>
                </DropdownMenuItem>
            </DropdownMenu>
        </div>
    </div>
</template>

<script>
import Button from '../general/Button.vue';
import DropdownMenu from '../general/DropdownMenu.vue';
import DropdownMenuItem from '../general/DropdownMenuItem.vue';

export default {
    name: 'ProjectDropdownMenu',
    components: {
        Button,
        DropdownMenu,
        DropdownMenuItem,
    },
    props: {
        modelValue: Boolean,
        hidePdfOptions: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'small',
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'secondary',
        },
        iconSize: {
            type: String,
            default: 'icon-large',
        },
    },
    emits: ['update:modelValue', 'onEditProject', 'onDeleteProject'],
    data() {
        return {
            menuOpened: false,
        };
    },
    methods: {
        closeMenu() {
            this.$emit('update:modelValue', false);
        },
        editProject() {
            this.$emit('onEditProject');
            this.menuOpened = false;
        },
        deleteProject() {
            this.$emit('onDeleteProject');
            this.menuOpened = false;
        },
        printPDF() {
            this.$emit('onPrintPDF');
            this.menuOpened = false;
        },
    },
}
</script>

<style scoped>
.highlighted-red {
    color: red;
}

.highlighted-blue {
    color: #4D91D1;
}

.dropdown-menu-overlay {
    display: flex;
    position: relative;
}

.dropdown-menu {
    display: flex;
    position: absolute;
    z-index: 10;
    top: 40px;
}

.menu-trigger {
    width: 32px;
    height: 32px;
}
</style>
