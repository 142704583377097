const CUSTOM_IMAGE_URLS = [
    "https://storage.googleapis.com/category-images/ducts/NTO.png",
    "https://storage.googleapis.com/category-images/ducts/NV.png",
    "https://storage.googleapis.com/category-images/ducts/NTK.png",
    "https://storage.googleapis.com/category-images/ducts/NFCV.png",
    "https://storage.googleapis.com/category-images/rects/EKT.png",
    "https://storage.googleapis.com/category-images/rects/EKP.png",
    "https://storage.googleapis.com/category-images/rects/EKK.png",
    "https://storage.googleapis.com/category-images/rects/EKM.png",
    "https://storage.googleapis.com/category-images/rects/EKD.png",
    "https://storage.googleapis.com/category-images/fires/FDMR.png",
    "https://storage.googleapis.com/category-images/fires/FDMS.png",
    "https://storage.googleapis.com/category-images/fires/FDMB.jpg",
    "https://storage.googleapis.com/category-images/fires/SEDS.jpg",
    "https://storage.googleapis.com/category-images/fires/SEDS-R.png",
    "https://storage.googleapis.com/category-images/dampers/KRTS4.jpg",
    "https://storage.googleapis.com/category-images/dampers/KRI.jpg",
    "https://storage.googleapis.com/category-images/dampers/KR.jpg",
    "https://storage.googleapis.com/category-images/dampers/RPM-V.jpg",
    "https://storage.googleapis.com/category-images/roofs/ULS.jpg",
    "https://storage.googleapis.com/category-images/roofs/UHC.jpg",
    "https://storage.googleapis.com/category-images/roofs/ULV2.jpg",
    "https://storage.googleapis.com/category-images/roofs/UVK.jpg",
    "https://storage.googleapis.com/category-images/roofs/NTH.jpg",
    "https://storage.googleapis.com/category-images/roofs/UVE.jpg",
    "https://storage.googleapis.com/category-images/roofs/ULK.jpg",
    "https://storage.googleapis.com/category-images/externals/RV.jpg",
    "https://storage.googleapis.com/category-images/externals/RVA.jpg",
    "https://storage.googleapis.com/category-images/externals/RVS.jpg",
    "https://storage.googleapis.com/category-images/externals/RVR.jpg",
    "https://storage.googleapis.com/category-images/externals/RVC.jpg",
    "https://storage.googleapis.com/category-images/externals/NPC.jpg",
    "https://storage.googleapis.com/category-images/externals/RVD.jpg",
    "https://storage.googleapis.com/category-images/externals/RVDS.jpg",
    "https://storage.googleapis.com/category-images/externals/RVDV.jpg",
    "https://storage.googleapis.com/category-images/grilles/RSV.png",
    "https://storage.googleapis.com/category-images/grilles/RSP.png",
    "https://storage.googleapis.com/category-images/grilles/RSI.png",
    "https://storage.googleapis.com/category-images/grilles/RPV.png",
    "https://storage.googleapis.com/category-images/filters/BGR.jpg",
    "https://storage.googleapis.com/category-images/filters/BFR.jpg",
    "https://storage.googleapis.com/category-images/filters/BFK.jpg",
    "https://storage.googleapis.com/category-images/silencers/NRF50_NRP50.png",
    "https://storage.googleapis.com/category-images/silencers/NTP_NTF.png",
]

export default CUSTOM_IMAGE_URLS;
