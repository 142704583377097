<template>
  <div :class="{ detailed: detailed }" class="w-full">
    <div v-if="diffusers.length" class="products-list" :class="layoutClass">
      <template v-for="product in diffusers" :key="product.id">
            <ProductCard class="mb-6" :direction="cardDirection">
                <template v-slot:product-image>
                    <img :src="resolveDiffuserClassImage(product.class)" alt="Diffuser Image">
                </template>
                <template v-slot:product-properties>
                    <DiffuserProperties :product="product" class="mb-3"/>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="action-link" @click="adjustSoundPower(product)">{{ $t('productsList.controls.adjustSoundPower') }}</div>
                    <div class="action-link" @click="adjustSoundAttenuation(product)">{{ $t('productsList.controls.attenuation') }}</div>
                </template>
                <template v-slot:product-actions>
                    <ProductQuantitySelector :modelValue="productQuantity(product)" @increased="onSelectProduct(product)" @decreased="onUnselectProduct(product)"></ProductQuantitySelector>
                </template>
            </ProductCard>
        </template>
    </div>
    <OctaveBandAdjustmentModal
        v-model="adjustmentModalOpened"
        :title="adjustmentModalTitle"
        :productId="adjustingProductId"
        :productName="adjustingProductName"
        :defaultOctaveBandRange="adjustingDefaultOctaveBandRange"
        :octaveBandRange="adjustingOctaveBandRange"
        :readOnly="readOnlyMode"
        @close="onAdjustmentModalClosed"
        @change="onOctaveBandRangeChanged"
    ></OctaveBandAdjustmentModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductCard from '@/components/products/product/ProductCard.vue';
import ProductQuantitySelector from '@/components/products/product/ProductQuantitySelector.vue';
import DiffuserProperties from '@/components/products/diffusers/DiffuserProperties.vue';
import OctaveBandAdjustmentModal from '@/components/products/OctaveBandAdjustmentModal.vue';
import { isOctaveBandRangeEmpty } from '@/utils';

export default {
    name: 'DiffusersList',
    components: {
        ProductCard,
        ProductQuantitySelector,
        DiffuserProperties,
        OctaveBandAdjustmentModal,
    },
    props: {
        diffusers: {
            type: Array,
            default: () => ([]),
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        detailed: {
          type: Boolean,
          default: false,
        },
        layout: {
            type: String,
            default: 'linear',
            validator: (value) => (['linear', 'grid'].includes(value)),
        },
    },
    data() {
        return {
            adjustmentModalOpened: false,
            adjustmentModalTitle: '',
            adjustmentType: '',
            adjustingProduct: null,
            readOnlyMode: false,
        };
    },
    computed: {
        ...mapState('activeSystemModule', ['productQuantitiesMap']),
        ...mapGetters('diffuserClassesModule', ['imagesMap']),
        adjustingDefaultOctaveBandRange() {
            if (!this.adjustingProduct || !this.adjustmentType) {
                return null;
            }
            
            return this.adjustmentType === 'soundPower'
                ? this.adjustingProduct.defaultSoundPowerLevel
                : this.adjustingProduct.defaultSoundAttenuation;
        },
        adjustingOctaveBandRange() {
            if (!this.adjustingProduct || !this.adjustmentType) {
                return null;
            }
            
            return this.adjustmentType === 'soundPower'
                ? this.adjustingProduct.soundPowerLevel
                : this.adjustingProduct.soundAttenuation;
        },
        adjustingProductId() {
            if (!this.adjustingProduct) {
                return '';
            }

            return this.adjustingProduct.id;
        },
        adjustingProductName() {
            if (!this.adjustingProduct) {
                return '';
            }

            return this.adjustingProduct.name;
        },
        layoutClass() {
            return {
                'linear-layout': this.layout === 'linear',
                'grid-layout': this.layout === 'grid',
            };
        },
        cardDirection() {
            return this.layout === 'linear' ? 'horizontal' : 'vertical';
        },
    },
    methods: {
        ...mapActions('diffusersModule', ['setSoundPowerLevel', 'setSoundAttenuation']),
        ...mapActions('activeSystemModule', [
            'addProduct',
            'removeProductById',
            'calculateSummary',
            'saveSystem',
        ]),
        adjustSoundPower(product) {
            this.adjustingProduct = product;
            this.adjustmentModalTitle = this.$t('octaveBandAdjustmentModal.adjustSoundPower');
            this.adjustmentType = 'soundPower';
            this.readOnlyMode = false;
            this.adjustmentModalOpened = true;
        },
        adjustSoundAttenuation(product) {
            this.adjustingProduct = product;
            this.adjustmentModalTitle = this.$t('octaveBandAdjustmentModal.attenuation');
            this.adjustmentType = 'attenuation';
            this.readOnlyMode = !isOctaveBandRangeEmpty(this.adjustingDefaultOctaveBandRange);
            this.adjustmentModalOpened = true;
        },
        onAdjustmentModalClosed() {
            this.adjustingProduct = null;
            this.adjustmentModalTitle = '';
            this.readOnlyMode = false;
            this.adjustmentType = '';
        },
        async onOctaveBandRangeChanged({ productId, octaveBandRange }) {
            if (!this.adjustmentType) {
                return;
            }
            if (this.adjustmentType === 'soundPower') {
                await this.setSoundPowerLevel({ diffuserId: productId, soundPowerLevel: octaveBandRange });
            }
            else {
                await this.setSoundAttenuation({ diffuserId: productId, soundAttenuation: octaveBandRange });
            }
            await this.saveSystem();
            await this.calculateSummary();
        },
        onSelectProduct(product) {
            if (!product) {
                return;
            }
            this.addProduct({ product });
        },
        onUnselectProduct(product) {
            if (!product || !product.id) {
                return;
            }
            this.removeProductById({ productId: product.id });
        },
        resolveDiffuserClassImage(diffuserClass) {
            return this.imagesMap[diffuserClass] || '';
        },
        productQuantity(product) {
            if (!product || !product.id || !this.productQuantitiesMap) {
                return 0;
            }
            if (product.id in this.productQuantitiesMap) {
                return this.productQuantitiesMap[product.id];
            }

            return 0;
        },
    },
}
</script>

<style lang="scss">
</style>
