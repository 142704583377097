<template>
    <div class="result-wrapper mb-8">
        <div class="row info-title section-title">
            {{ $t('projectBreakdown.result') }}
        </div>
        <div class="row p-0 mt-8">
            <div class="product-calculations final">
                <div class="calculation-result">
                    <div class="calculation-title text-regular">{{ $t('projectBreakdown.soundPower') }}: Lw</div>
                    <div class="calculation-value text-bold">{{ totalSoundPowerLw }} db</div>
                </div>
                <div class="calculation-result">
                    <div class="calculation-title text-regular">{{ $t('projectBreakdown.soundPower') }}: {{ filterCorrectionUnit }}</div>
                    <div class="calculation-value text-bold">{{ totalSoundPowerLwA }} db</div>
                </div>
                <div class="calculation-result">
                    <div class="calculation-title text-regular">{{ $t('projectBreakdown.soundPower') }}: LpA10m2</div> <!-- calculation-title large -->
                    <div class="calculation-value text-bold">{{ totalSoundPowerLpA10m2 }} db</div> <!-- calculation-value large -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CalculationResults',
    props: {
        calculationResults: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('activeSystemModule', ['filterCorrectionUnit']),
        totalSoundPowerLw() {
            if (!this.calculationResults || isNaN(this.calculationResults.totalSoundPowerToRoomLw)) {
                return '';
            }

            return Math.round(this.calculationResults.totalSoundPowerToRoomLw);
        },
        totalSoundPowerLwA() {
            if (!this.calculationResults || isNaN(this.calculationResults.totalSoundPowerToRoomLwA)) {
                return '';
            }

            return Math.round(this.calculationResults.totalSoundPowerToRoomLwA);
        },
        totalSoundPowerLpA10m2() {
            if (!this.calculationResults || isNaN(this.calculationResults.totalSoundPowerToRoomLpA10m2)) {
                return '';
            }

            return Math.round(this.calculationResults.totalSoundPowerToRoomLpA10m2);
        },
    },
}
</script>

<style scoped lang="scss">
@use 'src/assets/css/pdf/page.scss';
@use 'src/assets/css/pdf/page-content.scss';

.p-0 {
    padding: 0 !important;
}
</style>
