<template>
    <div class="page">
        <div class="row page-header">
            <img src="../../../assets/logo_small_white.svg" alt="ETS Logo" class="logo">
        </div>
        <p class="page-number section-title">{{ pageNumber || pageNr }}/{{ pagesTotal || pagesQuantity }}</p>
        <p class="date section-title">{{ todayDate }}</p>
        <slot name="header"></slot>
        <slot name="content"></slot>
        <Footer v-if="showFooter"/>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import Footer from './Footer.vue';

export default {
    name: 'Page',
    components: {
        Footer,
    },
    props: {
        pageNumber: Number,
        pagesTotal: Number,
        showFooter: {
            type: Boolean,
            default: false,
        }
    },
    updated() {
        this.$nextTick(() => {
            const pages = window.document.querySelectorAll('.page');
            pages.forEach((page, index) => {
                if (page !== this.$el) {
                    return;
                }
                this.pageNr = index + 1;
            });
            this.pagesQuantity = pages.length;
        });
    },
    data() {
        return {
            pageNr: 1,
            pagesQuantity: 1,
        };
    },
    computed: {
        todayDate() {
            return dayjs().format('DD.MM.YYYY');
        },
    },
}
</script>

<style scoped lang="scss">
    @use 'src/assets/css/pdf/page.scss';
    @use 'src/assets/css/pdf/page-content.scss';
</style>