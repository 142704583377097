<template>
    <div class="btn" :class="classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Button',
    props: {
        type: {
            type: String,
            default: 'primary',
        },
        size: {
            type: String,
            default: 'large',
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: [],
    computed: {
        classes() {
            return {
                'btn-primary': this.type === 'primary',
                'btn-secondary': this.type === 'secondary',
                'btn-secondary-light': this.type === 'secondary-light',
                'btn-large': this.size === 'large',
                'btn-medium': this.size === 'medium',
                'btn-small': this.size === 'small',
                'btn-small btn-small-rect': this.size === 'small-rect',
                'btn-outline': !!this.outlined,
                'btn-disabled': !!this.disabled,
            };
        }
    },
}
</script>

<style>
.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
}

.btn-primary {
    background-color: #CC0000;
    color: #FFFFFF;
    transition: background-color 0.2s;
}

.btn-primary:hover {
    background-color: #FF3333;
}

.btn-primary:active {
    background: #FF3333;
    box-shadow: 0 0 0 3px #F29D9B;
}

.btn-secondary {
    background-color: #737372;
    color: #FFFFFF;
}

.btn-secondary-light {
    background-color: #D5D6D5;
    color: #FFFFFF;
}

.btn-secondary:hover {
    background-color: #4C5055;
    transition: background-color 0.2s;
}

.btn-large {
    min-height: 52px;
    padding: 16px 32px;
    font-size: 16px;
    line-height: 19px;
    border-radius: 24px;
}

.btn-medium {
    min-height: 40px;
    padding: 10px 24px;
    font-size: 16px;
    line-height: 19px;
    border-radius: 18px;
}

.btn-small {
    min-height: 32px;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 18px;
}

.btn-small-rect {
    border-radius: 6px;
}

.btn-primary.btn-outline {
    background-color: #FFFFFF;
    border: 1px solid #CC0000;
    box-sizing: border-box;
    color: #CC0000;
}

.btn-secondary.btn-outline {
    background-color: #FFFFFF;
    border: 1px solid #737372;
    box-sizing: border-box;
    color:#737372;
}

.btn-disabled {
    background-color: #E8E8E8;
    border: none;
    color: #D5D6D5;
    pointer-events: none;
    cursor: not-allowed;
}
</style>