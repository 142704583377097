<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="row">{{ productCode }}</div>
                        <div class="row text-small sub-header">{{ $t('rectSilencerModal.silencerDetailsView.specifySilencerDetails') }}</div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="tabs-header">
                        <div class="tab-header" @click="switchTab('general')">{{ $t('octaveBandAdjustmentModal.general') }}</div>
                        <div class="tab-header active-tab-header">{{ $t('projectBreakdown.soundPower') }}</div>
                        <div class="tab-header" @click="switchTab('soundAttenuation')">{{ $t('projectBreakdown.attenuation') }}</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:modal-content>
            <OctaveBandContainer :powerLevelKit="inputSoundPower" :title="$t('octaveBandAdjustmentModal.adjustSoundPower')" class="content-filler"></OctaveBandContainer>
            <!-- <OctaveBandRangeEditor :modelValue="product.soundAttenuation" @update:modelValue="setSoundAttenuation({ silencerId: product.id, soundAttenuation: $event })">
                <template v-slot:title>
                    <div class="row">
                        <div class="title justify-start">{{ $t('octaveBandAdjustmentModal.octaveBand') }} (Hz)</div>
                    </div>
                </template>
            </OctaveBandRangeEditor> -->
        </template>
        <template v-slot:modal-actions>
            <div class="row">
                <Button outlined size="medium" class="mr-4" @click="switchTab('general')">{{ $t('commonControls.back') }}</Button>
            </div>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Modal from '../../../general/Modal.vue';
import Button from '../../../general/Button.vue';
// import OctaveBandRangeEditor from '@/components/products/product/OctaveBandRangeEditor.vue';
import OctaveBandContainer from '@/components/products/product/OctaveBandContainer.vue';

export default {
    name: 'SoundPowerView',
    components: {
        Modal,
        Button,
        // OctaveBandRangeEditor,
        OctaveBandContainer,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue', 'close', 'switchTab'],
    computed: {
        ...mapState('rectSilencerModule', [
            'options',
            'optionData',
            'inputSoundPower',
        ]),
        ...mapGetters('rectSilencerModule', ['productCode']),
    },
    methods: {
        switchTab(tabName) {
            this.$emit('switchTab', tabName)
        },
        closeModal() {
            this.$emit('update:modelValue', false);
            this.$emit('close');
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-content {
    min-height: 440px;
    // overflow: visible;
}

.sub-header {
    color: #737372;
    font-weight: bold;
}

.row {
    padding: 0;
}

.modal-content .col {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 150px;

    &:first-of-type {
        min-width: 240px;
    }
}

.modal-header .col {
    &:first-of-type {
        min-width: 325px;
    }
}

.octave-band-container {
    width: 100%;
}

.input-title {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
}

.image-preview {
    width: 200px;
    height: 130px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.tabs-header {
    margin-bottom: -16px;
}
</style>

<style lang="scss">
.octave-band-container.content-filler {
    width: 620px;
    height: 420px;
}
</style>