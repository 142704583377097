<template>
    <div class="calculations-panel mb-5">
        <div class="calculation-result">
            <div class="title mb-0">{{ $t('projectOverview.noiseGenerator') }}:</div>
            <div class="value">Lw: {{ roundNumber(calculationResults.noiseGeneratorTotalSoundPowerLw) }}</div>
            <div class="value">{{ filterCorrectionUnit }}: {{ roundNumber(calculationResults.noiseGeneratorTotalSoundPowerLwA) }}</div>
        </div>
        <div class="calculation-result">
            <div class="title">{{ $t('projectBreakdown.soundPower') }}: Lw</div>
            <div class="value">{{ roundNumber(calculationResults.totalSoundPowerToRoomLw) }}</div>
        </div>
        <div class="calculation-result">
            <div class="title">{{ $t('projectBreakdown.soundPower') }}: {{ filterCorrectionUnit }}</div>
            <div v-if="filterCorrectionType !== 'NoFilter'" class="value">{{ roundNumber(calculationResults.totalSoundPowerToRoomLwA) }}</div>
            <div v-else class="value empty-value">-</div>
        </div>
        <div class="calculation-result">
            <div class="title">{{ $t('projectBreakdown.soundPower') }}: LpA10m2</div>
            <div v-if="attenuationConstantType !== 'NoConstant'" class="value">{{ roundNumber(calculationResults.totalSoundPowerToRoomLpA10m2) }}</div>
            <div v-else class="value empty-value">-</div>
        </div>
        <div class="calculation-result" v-if="navigation">
            <Button type="primary" size="medium" :disabled="!selectedProducts.length" @click="$router.push({ name: 'project-overview' })">{{ $t('productsList.controls.next') }}</Button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Button from '../../general/Button.vue';

export default {
    name: 'CalculationsPanel',
    components: { Button },
    props: {
        navigation: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapState('activeSystemModule', [
            'calculationResults',
            'attenuationConstantType',
            'filterCorrectionType',
            'selectedProducts',
        ]),
        ...mapGetters('activeSystemModule', ['filterCorrectionUnit']),
    },
    methods: {
        roundNumber(value) {
            if (isNaN(value)) {
                return '-';
            }
            
            return Math.round(value);
        }
    },
}
</script>

<style lang="scss">
.calculations-panel {
    position: sticky;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 800px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 0px 0px;
    padding: 25px;
    bottom: 2vh;
    left: 30vw;
    z-index: 1;
    .calculation-result {
        display: flex;
        flex-direction: column;
        flex: 1 1 20%;
        max-height: 45px;
        border-right: none;
        &.bordered {
            border-left: 1px solid #D5D6D5;
            border-right: 1px solid #D5D6D5;
        }
        .title {
            font-family: Helvetica Neue;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #737372;
        }
        .value {
            font-family: Helvetica Neue Bold;
            font-style: normal;
            // font-weight: bold;
            font-size: 14px;
            line-height: 17px;
        }
        .empty-value {
            color: #737372;
        }
    }
}
</style>
