<template>
    <div class="product-properties">
        <div class="product-name">{{ product.name }} {{ resolveTranslationMapValue(product.nameAppendix) }}</div>
        <div class="property">
            <div class="property-name">{{ $t('rectSilencerCard.dimensions') }} (W, H, L):</div>
            <div class="property-value">{{ product.width }}, {{ product.height }}, {{ product.length }}</div>
        </div>
        <div v-if="product.shape === 'bend'" class="property">
            <div class="property-name">{{ $t('rectSilencerCard.dimensions') }} (x, y):</div>
            <div class="property-value">{{ product.dimensionX }}, {{ product.dimensionY }}</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('rectSilencerCard.cassettePiecesAndMaterial') }}:</div>
            <div class="property-value">{{ product.optionData.amountOfCassettes }}x{{ product.optionData.cassetteThickness }}, {{ cassetteMaterial }}</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('rectSilencerData.shellInsulation') }}:</div>
            <div class="property-value">{{ product.shellInsulation }}</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('rectSilencerData.outerCover') }}:</div>
            <div class="property-value">{{ outerCover }}</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('rectSilencerData.innerMaterial') }}:</div>
            <div class="property-value">{{ innerMaterial }}</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('rectSilencerCard.airflow') }}:</div>
            <div class="property-value">{{ product.inputAirflow }} l/s</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('rectSilencerData.pressureDrop') }}:</div>
            <div class="property-value">{{ roundValue(product.optionData.pressureDrop) }} Pa</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('rectSilencerData.connectionProfile') }}:</div>
            <div class="property-value">{{ translateConnectionProfile(product.connectionProfile) }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RectSilencerProperties',
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
    },
    inject: ['resolveTranslationMapValue'],
    computed: {
        manufactureMaterials() {
            return {
                'none': this.$t('rectSilencerData.manufactureMaterial.none'),
                'galvanizedSteel': `${this.$t('rectSilencerData.manufactureMaterial.galvanizedSteel')} ZN275`,
                'stainlessSteel': `${this.$t('rectSilencerData.manufactureMaterial.stainlessSteel')} AISI 316`,
                'magnelis': `${this.$t('rectSilencerData.manufactureMaterial.magnelis')} ZM310`,
            }
        },
        insulationMaterials() {
            return {
                'polyester': this.$t('rectSilencerData.cassetteMaterial.polyester'),
                'mineralWool': this.$t('rectSilencerData.cassetteMaterial.mineralWool'),
                'cleantec': this.$t('rectSilencerData.cassetteMaterial.cleantec'),
            }
        },
        cassetteMaterial() {
            if (!this.product.cassetteMaterial) {
                return '';
            }

            return this.insulationMaterials[this.product.cassetteMaterial] || '';
        },
        outerCover() {
            if (!this.product.outerCover) {
                return '';
            }

            return this.manufactureMaterials[this.product.outerCover] || '';
        },
        innerMaterial() {
            if (!this.product.innerMaterial) {
                return '';
            }

            return this.manufactureMaterials[this.product.innerMaterial] || '';
        },
    },
    methods: {
        roundValue(value) {
            return Math.round(Number(value));
        },
        translateConnectionProfile(connectionProfile) {
            if (connectionProfile !== 'Z-slat') {
                return connectionProfile || '';
            }

            return this.$t('rectSilencerData.connectionProfile.zProfile');
        }
    },
}
</script>

<style lang="scss">
</style>
