<template>
    <!-- System data -->
    <div class="row">
        <InputField class="mr-8" :title="$t('systemSpecificationModal.systemName')" :placeholder="$t('systemSpecificationModal.systemName')" :model-value="name" @update:modelValue="setName"></InputField>
        <InputField :title="$t('systemSpecificationModal.systemNr')" :placeholder="$t('systemSpecificationModal.systemNr')" :model-value="number" @update:modelValue="setNumber"></InputField>
    </div>
    <div class="row mt-4">
        <!-- <InputField class="mr-8 w-full" :title="$t('systemSpecificationModal.author')" :placeholder="$t('systemSpecificationModal.author')" :model-value="author" @update:modelValue="setAuthor"></InputField> -->
        <DateInputField v-if="withDate" class="w-full mr-8" :title="$t('systemSpecificationModal.date')" :placeholder="$t('systemSpecificationModal.date')" :model-value="date" @update:modelValue="setDate"></DateInputField>
        <TextareaField class="w-full" :title="$t('systemSpecificationModal.comment')" :placeholder="$t('systemSpecificationModal.comment')" :model-value="comment" @update:modelValue="setComment"></TextareaField>
    </div>
    <div class="row mt-6">
        <div class="group-title">{{ $t('systemSpecificationModal.systemSpecification') }}</div>
    </div>
    <div class="row mt-6">
        <Select :options="attenuationConstants" :label="$t('systemSpecificationModal.attenuationConstant')" :modelValue="attenuationConstantType" @update:modelValue="setAttenuationConstantType" autoSelection class="mr-8"></Select>
        <Select :options="correctionFilters" :label="$t('systemSpecificationModal.filterCorrection')" :modelValue="filterCorrectionType" @update:modelValue="setFilterCorrectionType" autoSelection></Select>
    </div>
    <div class="row mt-4 p-0">
        <OctaveBandRangeEditor
            :modelValue="inputSoundPowerLevel"
            @octaveBand63HzChanged="updateInputSoundPowerLevel('octaveBand63Hz', $event)"
            @octaveBand125HzChanged="updateInputSoundPowerLevel('octaveBand125Hz', $event)"
            @octaveBand250HzChanged="updateInputSoundPowerLevel('octaveBand250Hz', $event)"
            @octaveBand500HzChanged="updateInputSoundPowerLevel('octaveBand500Hz', $event)"
            @octaveBand1000HzChanged="updateInputSoundPowerLevel('octaveBand1000Hz', $event)"
            @octaveBand2000HzChanged="updateInputSoundPowerLevel('octaveBand2000Hz', $event)"
            @octaveBand4000HzChanged="updateInputSoundPowerLevel('octaveBand4000Hz', $event)"
            @octaveBand8000HzChanged="updateInputSoundPowerLevel('octaveBand8000Hz', $event)"
        >
            <template v-slot:title>
                <div class="row mb-2">
                    <div class="group-title">{{ $t('systemSpecificationModal.noiseGenerator') }} (Hz)</div>
                </div>
            </template>
        </OctaveBandRangeEditor>
        <!-- <InputField title="63" :modelValue="inputSoundPowerLevel.octaveBand63Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand63Hz', $event)" placeholder="0" type="number" class="mr-4"></InputField>
        <InputField title="125" :modelValue="inputSoundPowerLevel.octaveBand125Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand125Hz', $event)" placeholder="0" type="number" class="mr-4"></InputField>
        <InputField title="250" :modelValue="inputSoundPowerLevel.octaveBand250Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand250Hz', $event)" placeholder="0" type="number" class="mr-4"></InputField>
        <InputField title="500" :modelValue="inputSoundPowerLevel.octaveBand500Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand500Hz', $event)" placeholder="0" type="number" class="mr-4"></InputField>
        <InputField title="1000" :modelValue="inputSoundPowerLevel.octaveBand1000Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand1000Hz', $event)" placeholder="0" type="number" class="mr-4"></InputField>
        <InputField title="2000" :modelValue="inputSoundPowerLevel.octaveBand2000Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand2000Hz', $event)" placeholder="0" type="number" class="mr-4"></InputField>
        <InputField title="4000" :modelValue="inputSoundPowerLevel.octaveBand4000Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand4000Hz', $event)" placeholder="0" type="number" class="mr-4"></InputField>
        <InputField title="8000" :modelValue="inputSoundPowerLevel.octaveBand8000Hz" @update:modelValue="updateInputSoundPowerLevel('octaveBand8000Hz', $event)" placeholder="0" type="number"></InputField> -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputField from '../../general/InputField.vue';
import DateInputField from '../../general/DateInputField.vue';
import TextareaField from '../../general/TextareaField.vue';
import Select from '../../general/Select.vue';
import OctaveBandRangeEditor from '@/components/products/product/OctaveBandRangeEditor.vue';

export default {
    name: 'EditActiveSystemData',
    props: {
        withDate: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        InputField,
        DateInputField,
        TextareaField,
        Select,
        OctaveBandRangeEditor,
    },
    data() {
        return {
            attenuationConstants: [
                {
                    title: this.$t('projectBreakdown.constants.noConstant'),
                    value: 'NoConstant',
                },
                {
                    title: 'LpA10m2',
                    value: 'T1',
                },
            ],
            correctionFilters: [
                {
                    title: this.$t('projectBreakdown.filters.noFilter'),
                    value: 'NoFilter',
                },
                {
                    title: 'A - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'AFilter',
                },
                {
                    title: 'B - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'BFilter',
                },
                {
                    title: 'C - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'CFilter',
                },
            ],
        };
    },
    computed: {
        ...mapState('activeSystemModule', [
            'name',
            'number',
            // 'author',
            'date',
            'comment',
            'attenuationConstantType',
            'filterCorrectionType',
            'inputSoundPowerLevel',
            'calculationResults',
        ]),
        ...mapState('activeProjectModule', {
            'projectDate': 'date',
        }),
    },
    methods: {
        ...mapActions('activeSystemModule', [
            'setName',
            'setNumber',
            // 'setAuthor',
            'setDate',
            'setProjectId',
            'setComment',
            'setAttenuationConstantType',
            'setFilterCorrectionType',
            'setInputSoundPowerLevel',
            'resetData',
            'resetProductsData',
            'resetNoiseGeneratorData',
            'saveSystem',
        ]),
        updateInputSoundPowerLevel(bandRange, soundPower) {
            this.setInputSoundPowerLevel({ bandRange, soundPower });
        },
    },
    watch: {
        projectDate(value) {
            if (this.withDate) {
                return;
            }
            this.setDate(value);
        },
    }
}
</script>
