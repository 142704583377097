<template>
    <div class="p-2">
        <p class="mb-2">{{ $t('rectSilencerModal.tooltips.optionSelection.caption') }}:</p>
        <p class="ml-4" v-html="$t('rectSilencerModal.tooltips.optionSelection.option1and2')"></p>
        <p class="ml-4" v-html="$t('rectSilencerModal.tooltips.optionSelection.option3and4')"></p>
        <p class="ml-4" v-html="$t('rectSilencerModal.tooltips.optionSelection.option5and6')"></p>
        <p class="mt-2">(*{{ $t('rectSilencerModal.tooltips.optionSelection.note') }})</p>
    </div>
</template>

<script>
export default {
    name: 'OptionSelection',
}
</script>