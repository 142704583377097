<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ $t('projectSpecificationModal.editProject') }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="row">
                <InputField class="mr-8" :title="$t('projectSpecificationModal.projectName')" :placeholder="$t('projectSpecificationModal.projectName')" v-model="name"></InputField>
                <InputField :title="$t('projectSpecificationModal.projectNr')" :placeholder="$t('projectSpecificationModal.projectNr')" v-model="number"></InputField>
            </div>
            <div class="row mt-12">
                <InputField :title="$t('projectSpecificationModal.address')" :placeholder="$t('projectSpecificationModal.address')" v-model="address"></InputField>
            </div>
            <div class="row mt-12">
                <InputField class="mr-8" :title="$t('projectSpecificationModal.email')" :placeholder="$t('projectSpecificationModal.email')" v-model="email"></InputField>
                <InputField :title="$t('projectSpecificationModal.phoneNumber')" :placeholder="$t('projectSpecificationModal.phoneNumber')" v-model="phone"></InputField>
            </div>
            <div class="row mt-12">
                <InputField class="mr-8" :title="$t('projectSpecificationModal.customer')" :placeholder="$t('projectSpecificationModal.customer')" v-model="contactData"></InputField>
                <DateInputField :title="$t('projectSpecificationModal.date')" :placeholder="$t('projectSpecificationModal.date')" v-model="date"></DateInputField>
            </div>
        </template>
        <template v-slot:modal-actions>
            <Button type="primary" size="medium" @click="save">{{ $t('projectSpecificationModal.save') }}</Button>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputField from '../general/InputField.vue';
import Button from '../general/Button.vue';
import Modal from '../general/Modal.vue';
import DateInputField from '../general/DateInputField.vue';

export default {
    name: 'EditProjectModal',
    components: {
        InputField,
        Button,
        Modal,
        DateInputField,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            name: '',
            number: '',
            address: '',
            email: '',
            phone: '',
            contactData: '',
            date: null,
        };
    },
    created() {
        this.actualize();
    },
    computed: {
        ...mapState('activeProjectModule', {
            'actualName': 'name',
            'actualNumber': 'number',
            'actualAddress': 'address',
            'actualEmail': 'email',
            'actualPhone': 'phone',
            'actualContactData': 'contactData',
            'actualDate': 'date',
        }),
    },
    methods: {
        ...mapActions('activeProjectModule', [
            'setName',
            'setNumber',
            'setAddress',
            'setEmail',
            'setPhone',
            'setContactData',
            'setDate',
            'saveProject',
        ]),
        closeModal() {
            this.$emit('update:modelValue', false);
        },
        reset() {
            this.name = '';
            this.number = '';
            this.address = '';
            this.email = '';
            this.phone = '';
            this.contactData = '';
            this.date = null;
        },
        async save() {
            await this.setName(this.name);
            await this.setNumber(this.number);
            await this.setAddress(this.address);
            await this.setEmail(this.email);
            await this.setPhone(this.phone);
            await this.setContactData(this.contactData);
            await this.setDate(this.date);
            await this.saveProject();
            this.closeModal();
        },
        actualize() {
            this.name = this.actualName;
            this.number = this.actualNumber;
            this.address = this.actualAddress;
            this.email = this.actualEmail;
            this.phone = this.actualPhone;
            this.contactData = this.actualContactData;
            this.date = this.actualDate;
        },
    },
    watch: {
        modelValue(value) {
            if (!value) {
                return;
            }
            this.actualize();
        }
    },
}
</script>

<style>
.group-title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 22px;
    color: #737372;
}
</style>
