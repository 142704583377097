export function transform(product) {
    if (!product.id || !(product.class || product.silencerClass) || !product.optionData) {
        return null;
    }

    return {
        productId: product.id,
        productName: product.name || 'Rect Silencer',
        productType: 'rectSilencer',
        productClass: product.class || product.silencerClass,
        productCode: product.code || '',
        productImageURL: product.imageURL || '',
        // Rect silencer specific attributes:
        width: product.width || 0,
        height: product.height || 0,
        length: product.length || 0,
        dimensionX: product.dimensionX || 150,
        dimensionY: product.dimensionY || 150,
        shape: product.shape || 'straight',
        orientation: product.orientation || '',
        position: product.position || 'supply',
        inputAirflow: product.inputAirflow || 0,
        shellInsulation: product.shellInsulation || '50mm',
        cassetteMaterial: product.cassetteMaterial || 'polyester',
        outerCover: product.outerCover || 'galvanizedSteel',
        innerMaterial: product.innerMaterial || 'galvanizedSteel',
        openable: Boolean(product.openable),
        connectionProfile: product.connectionProfile,
        optionData: {
            cassetteThickness: product.optionData.cassetteThickness || 0,
            amountOfCassettes: product.optionData.amountOfCassettes || 0,
            cassetteSpacing: product.optionData.cassetteSpacing || 0,
            edgeGap: product.optionData.edgeGap || 0,
            silencerGapSpeed: product.optionData.silencerGapSpeed || 0,
            pressureDrop: product.optionData.pressureDrop || 0,
            totalAttenuation: product.optionData.totalAttenuation || 0,
            soundAttenuation: { ...product.optionData.attenuation },
            resultAfterLw: product.optionData.resultAfterLw || 0,
            resultAfterAFilter: product.optionData.resultAfterAFilter || 0,
        },
        soundPowerLevel: { ...product.soundPowerLevel },
        soundAttenuation: { ...product.soundAttenuation },
    };
}

export default transform;