<template>
    <div class="search-box mt-4 mb-4">
        <!-- <div class="title mr-4 mb-0">{{ $t('projectsPage.yourProjects') }}</div> -->
        <div class="row p-0">
            <div class="flex flex-75 items-center justify-start">
                <InputField :placeholder="$t('projectsList.searchProjects')" :modelValue="searchString" @update:modelValue="setSearchString" class="mr-4"></InputField>
            </div>
            <!-- <Button type="primary" size="small" @click="$emit('onCreateProject')">{{ $t('projectsList.addProject') }}</Button> -->
            <div class="flex flex-50 items-center justify-end">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
    <div class="card mt-5" v-for="(project, index) in projectsList" :key="`${project.number}-${index}`">
        <div class="column">
            <div class="row mb-12">
                <div class="project-name">{{ project.name ? capitalize(project.name) : '-' }}</div>
            </div>
            <div class="row">
                <div class="project-data">
                    <!-- <div class="column">
                        <div class="btn download-pdf">PDF<i class="download-icon"></i></div>
                    </div> -->
                    <!-- <div class="column">
                        <div class="title">{{ $t('projectsList.productClass') }}</div>
                        <div class="content">{{ project.productClass }}</div>
                    </div> -->
                    <!-- <div class="column">
                        <div class="title">{{ $t('projectsList.projectName') }}</div>
                        <div class="content">{{ project.name || '-' }}</div>
                    </div> -->
                    <div class="column">
                        <div class="title">{{ $t('projectsList.createdAt') }}</div>
                        <div class="content">{{ convertToDateString(project.createdAt || project.date) || '-' }}</div>
                    </div>
                    <div class="column">
                        <div class="title">{{ $t('projectsList.projectNr') }}</div>
                        <div class="content">{{ project.number || '-' }}</div>
                    </div>
                    <div class="column">
                        <div class="title">{{ $t('projectsList.numberOfSystems') }}</div>
                        <div class="content">{{ project.systems.length || 0 }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="row align-items-center">
                <div class="flex mr-16 relative items-center">
                    <i v-if="project.invitedUsers.length" title="Shared" class="pi pi-user-edit cursor-pointer absolute" @click="openInvitedUsersModal(project)"></i>
                </div>
                <Button type="secondary" size="small" class="mr-16" @click="setProjectAndNavigate(project)">{{ $t('projectsList.details') }}</Button>
                <Button type="secondary" outlined size="small" class="mr-16" @click="openInvitedUsersModal(project)">{{ $t('projectsList.share') }}</Button>
                <div class="action-link mr-16" @click="onEditProject(project)">{{ $t('commonControls.edit') }}</div>
                <div class="flex flex-col justify-between actions-column">
                    <div class="icon icon-large icon-trashbin" @click="onDeleteProject(project)"></div>
                    <div class="icon icon-large icon-download-pdf" @click="onPrintPDF(project)"></div>
                </div>
                <!-- <ProjectDropdownMenu 
                    v-if="project.id" 
                    @onEditProject="onEditProject(project)" 
                    @onDeleteProject="onDeleteProject(project)"
                    @onPrintPDF="onPrintPDF(project)"
                ></ProjectDropdownMenu> -->
            </div>
        </div>
    </div>
    <EditProjectModal size="medium" v-model="editProjectModalOpened"></EditProjectModal>
    <DeleteConfirmationModal 
        size="small" 
        v-model="deleteProjectModalOpened"
        @onConfirm="confirmDeleting"
        @onDecline="declineDeleting"
    ></DeleteConfirmationModal>
    <InvitedUsersModal v-model="invitedUsersModalOpened" :project="selectedProject" @closed="selectedProject = null" />
</template>

<script>
import * as dayjs from 'dayjs';
import { mapActions } from 'vuex';
import Button from '../general/Button.vue';
import InputField from '../general/InputField.vue';
// import ProjectDropdownMenu from './ProjectDropdownMenu.vue';
import EditProjectModal from './EditProjectModal.vue';
import DeleteConfirmationModal from './DeleteConfirmationModal.vue';
import InvitedUsersModal from './InvitedUsersModal.vue';

const debounce = require('lodash.debounce');

export default {
    name: 'ProjectsList',
    components: {
        Button,
        InputField,
        // ProjectDropdownMenu,
        EditProjectModal,
        DeleteConfirmationModal,
        InvitedUsersModal,
    },
    props: {
        projects: {
            type: Array,
            required: true,
        },
    },
    emits: ['onCreateProject', 'onPrintPDF'],
    data() {
        return {
            searchString: '',
            editProjectModalOpened: false,
            deleteProjectModalOpened: false,
            invitedUsersModalOpened: false,
            selectedProject: null,
        };
    },
    computed: {
        projectsList() {
            const projects = !this.searchString 
                ? this.projects
                : this.projects.filter((project) => `${project.name} ${project.number}`.indexOf(this.searchString) >= 0);
            
            return projects.sort((left, right) => (new Date(left.createdAt)) < (new Date(right.createdAt)) ? 1 : -1);
        },
    },
    methods: {
        ...mapActions('activeProjectModule', ['setProject', 'deleteProject', 'resetData']),
        ...mapActions('projectsModule', ['getProjects']),
        async setProjectAndNavigate(project) {
            await this.setProject(project);
            this.$router.push({ name: 'systems' });
        },
        convertToDateString(date) {
            if (!date) {
                return '';
            }

            return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
        },
        setSearchString: debounce(function (searchString) {
            if (!searchString.length) {
                this.searchString = '';
                return;
            }
            if (searchString.length <= 2) {
                return;
            }
            this.searchString = searchString;
        }, 400),
        capitalize(value) {
            const str = String(value);

            return `${str[0].toUpperCase()}${str.substring(1)}`;
        },
        async onEditProject(project) {
            if (!project.id) {
                return;
            }
            await this.setProject(project);
            this.editProjectModalOpened = true;
        },
        async onDeleteProject(project) {
            if (!project.id) {
                return;
            }
            await this.setProject(project);
            this.deleteProjectModalOpened = true;
            // await this.deleteProject();
        },
        async confirmDeleting() {
            await this.deleteProject();
            await this.resetData();
            await this.getProjects();
        },
        async declineDeleting() {
            await this.resetData();
        },
        async onPrintPDF(project) {
            if (!project.id) {
                return;
            }
            await this.setProject(project);
            this.$emit('onPrintPDF');
        },
        openInvitedUsersModal(project) {
            if (!project) {
                return;
            }
            this.selectedProject = project;
            this.invitedUsersModalOpened = true;
        },
    },
    watch: {
        async editProjectModalOpened(value) {
            if (value) {
                return;
            }
            // TODO: extends the "projectsModule" functionality for Edit operation
            await this.resetData();
            await this.getProjects();
        }
    },
}
</script>

<style lang="scss">
.search-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #F8F8F8;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 24px 28px;

    .input-container {
        flex: 0 1 50vw;
    }
}

.project-name {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    // font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #CC0000;

}

.title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    // font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #4C5055;
    margin-bottom: 12px;
}

.card {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 28px;
    box-sizing: border-box;
}

.card .project-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
}

.project-data .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 1 25%;
}

/* .project-data .column:first-of-type {
    flex: 0 1 5%;
}

.project-data .column:last-of-type {
    flex: 0 1 25%;
    align-items: flex-end;
} */

.project-data .column .title {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #737372;
    margin-bottom: 0;
}

.project-data .column .content {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    // font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #737372;
    margin-top: 4px;
}

.project-data .download-pdf {
    background: #DFEBF7;
    border: 1px solid #9DC3E6;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #4D91D1;
}

.project-data .download-pdf i {
    margin-left: 10px;
    width: 12px;
    height: 12px;
    background-image: url('../../assets/icons/singular/download.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.project-data .details {
    background: #4C5055;
    border-radius: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    padding: 8px 32px;
    min-height: 32px;
    box-sizing: border-box;
}
</style>

<style scoped>
.card {
    align-items: center;
    justify-content: space-between;
}

.card .row {
    padding: 0;
}

.card > .column:first-of-type {
    flex: 0 1 100%;
}

.actions-column {
    height: 120px;
}

.icon-download-pdf:before {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.flex-75 {
    flex: 1 1 75%;
}

.flex-50 {
    flex: 1 1 50%;
}

.flex-25 {
    flex: 1 1 25%;
}
</style>