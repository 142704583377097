import { apiService } from '../general/APIService';

const CALCULATIONS_ENDPOINT = 'calculations/sound-power';

const BULK_CALCULATIONS_ENDPOINT = 'calculations/sound-power/bulk';

const PRESSURE_DROP_ENDPOINT = 'calculations/round-silencers/pressure-drop';

const DIM_DATA_REGULAR_SILENCERS_ENDPOINT = '/calculations/round-silencers/input-airflow';

const DIM_DATA_RECT_SILENCERS_ENDPOINT = '/calculations/rect-silencers/input-airflow';

export async function calculateSoundPower(data) {
    const response = await apiService.post(CALCULATIONS_ENDPOINT, {
        inputSoundPowerLevel: data.inputSoundPowerLevel,
        attenuationConstant: data.attenuationConstant,
        filterCorrectionType: data.filterCorrectionType,
        products: data.products,
    });

    return response.data.data;
}

export async function calculateBulkSoundPower(data) {
    const response = await apiService.post(BULK_CALCULATIONS_ENDPOINT, {
        inputSoundPowerLevel: data.inputSoundPowerLevel,
        inputAirflow: data.inputAirflow,
        attenuationConstant: data.attenuationConstant,
        filterCorrectionType: data.filterCorrectionType,
        products: data.products,
    });

    return response.data.data;
}

export async function calculatePressureDrop(data) {
    const {
        silencerClass,
        diameter,
        length,
        inputAirflow,
    } = data;
    if (!silencerClass || !diameter || !length || inputAirflow === undefined) {
        return;
    }
    const response = await apiService.post(PRESSURE_DROP_ENDPOINT, {
        silencerClass,
        diameter,
        length,
        inputAirflow,
    });

    return response.data.data;
}

export async function calculateDimDataForRegularSilencer(data) {
    const {
        silencer,
        minPressureDrop,
        maxPressureDrop,
    } = data || {};
    if (!silencer || Number.isNaN(Number(minPressureDrop)) || Number.isNaN(Number(maxPressureDrop))) {
        return;
    }
    const response = await apiService.post(DIM_DATA_REGULAR_SILENCERS_ENDPOINT, {
        silencerClass: silencer.class,
        diameter: silencer.nominalSizeDiameter,
        length: silencer.length,
        minPressureDrop: Number(minPressureDrop),
        maxPressureDrop: Number(maxPressureDrop),
    });

    return response.data.data;
}

export async function calculateDimDataForRectSilencer(data) {
    const {
        silencer,
        minPressureDrop,
        maxPressureDrop,
    } = data || {};
    if (!silencer || Number.isNaN(Number(minPressureDrop)) || Number.isNaN(Number(maxPressureDrop))) {
        return;
    }
    const response = await apiService.post(DIM_DATA_RECT_SILENCERS_ENDPOINT, {
        width: silencer.width,
        height: silencer.height,
        length: silencer.length,
        cassetteThickness: silencer.optionData.cassetteThickness,
        amountOfCassettes: silencer.optionData.amountOfCassettes,
        minPressureDrop: Number(minPressureDrop),
        maxPressureDrop: Number(maxPressureDrop),
    });

    return response.data.data;
}