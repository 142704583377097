<template>
    <div class="row">
        <div class="col mr-4 materials">
            <Select
                class="mt-3"
                :label="$t('rectSilencerData.shellInsulation')"
                :options="shellInsulationOptions"
                :modelValue="shellInsulation"
                @update:modelValue="setShellInsulation"
            >
                <template v-slot:title-addon>
                    <Tooltip class="ml-1 mb-1">
                        <i class="pi pi-info-circle" />
                        <template v-slot:tooltip-heading>{{ $t('rectSilencerData.shellInsulation') }}</template>
                        <template v-slot:tooltip-content>
                            <ShellInsulationTooltipContent />
                        </template>
                    </Tooltip>
                </template>
            </Select>
            <Select
                class="mt-3"
                :label="$t('rectSilencerData.cassetteMaterial')"
                :options="cassetteMaterialOptions"
                :modelValue="cassetteMaterial"
                @update:modelValue="setCassetteMaterial"
            >
                <template v-slot:title-addon>
                    <Tooltip class="ml-1 mb-1">
                        <i class="pi pi-info-circle" />
                        <template v-slot:tooltip-heading>{{ $t('rectSilencerData.cassetteMaterial') }}</template>
                        <template v-slot:tooltip-content>
                            <CassetteMaterialTooltipContent />
                        </template>
                    </Tooltip>
                </template>
            </Select>
            <Select
                class="mt-3"
                :label="$t('rectSilencerData.outerCover')"
                :options="manufactureMaterialOptions"
                :modelValue="outerCover"
                @update:modelValue="setOuterCover"
            >
                <template v-slot:title-addon>
                    <Tooltip class="ml-1 mb-1">
                        <i class="pi pi-info-circle" />
                        <template v-slot:tooltip-heading>{{ $t('rectSilencerData.outerCover') }}</template>
                        <template v-slot:tooltip-content>
                            <OuterCoverTooltipContent />
                        </template>
                    </Tooltip>
                </template>
            </Select>
            <Select
                class="mt-3"
                :label="$t('rectSilencerData.innerMaterial')"
                :options="manufactureMaterialOptions"
                :modelValue="innerMaterial"
                @update:modelValue="setInnerMaterial"
            >
                <template v-slot:title-addon>
                    <Tooltip class="ml-1 mb-1">
                        <i class="pi pi-info-circle" />
                        <template v-slot:tooltip-heading>{{ $t('rectSilencerData.innerMaterial') }}</template>
                        <template v-slot:tooltip-content>
                            <InnerMaterialTooltipContent />
                        </template>
                    </Tooltip>
                </template>
            </Select>
        </div>
        <div class="col mr-4 dimensions">
            <!-- <InputField
                class="mt-3"
                placeholder="300 - 2500mm"
                type="number"
                :title="$t('rectSilencerData.width')"
                :modelValue="width || ''"
                @update:modelValue="setWidth"
            ></InputField> -->
            <Select
                class="mt-3"
                :placeholder="widthPlaceholder"
                :label="$t('rectSilencerData.width')"
                :options="widthOptions"
                :modelValue="width"
                @update:modelValue="setWidth"
            ></Select>
            <!-- <InputField
                class="mt-3"
                placeholder="200 - 2500mm"
                type="number"
                :title="$t('rectSilencerData.height')"
                :modelValue="height || ''"
                @update:modelValue="setHeight"
            ></InputField> -->
            <Select
                class="mt-3"
                :placeholder="heightPlaceholder"
                :label="$t('rectSilencerData.height')"
                :options="heightOptions"
                :modelValue="height"
                @update:modelValue="setHeight"
            ></Select>
            <!-- <InputField
                class="mt-3"
                placeholder="600 - 2500mm"
                type="number"
                :title="$t('rectSilencerData.length')"
                :modelValue="length || ''"
                @update:modelValue="setLength"
            ></InputField> -->
            <Select
                v-if="shape === 'straight'"
                class="mt-3"
                placeholder="600 - 2500mm"
                :label="$t('rectSilencerData.length')"
                :options="lengthOptions"
                :modelValue="length"
                @update:modelValue="setLength"
            ></Select>
            <div v-else class="column mt-3">
                <div class="title input-title">{{ $t('rectSilencerData.bendDimensions') }} (x,y)</div>
                <div class="row align-items-center">
                    <InputField
                        class="mr-3"
                        placeholder="x (mm)"
                        type="number"
                        min="150"
                        :max="dimensionXUpperLimit"
                        :modelValue="dimensionX"
                        @update:modelValue="setDimensionX"
                    ></InputField>
                    <InputField
                        placeholder="y (mm)"
                        type="number"
                        min="150"
                        :max="dimensionYUpperLimit"
                        :modelValue="dimensionY"
                        @update:modelValue="setDimensionY"
                    ></InputField>
                </div>
            </div>
            <Select
                v-if="!hideShapeOptions"
                class="mt-3"
                :label="$t('rectSilencerData.shape')"
                :options="shapeOptions"
                :modelValue="shape"
                @update:modelValue="setShape"
            ></Select>
            <Select
                v-else
                class="mt-3"
                :label="$t('rectSilencerData.connectionProfile')"
                :options="connectionProfileOptions"
                :modelValue="connectionProfile"
                @update:modelValue="setConnectionProfile"
            >
                <template v-slot:title-addon>
                    <Tooltip class="ml-1 mb-1">
                        <i class="pi pi-info-circle" />
                        <template v-slot:tooltip-heading>{{ $t('rectSilencerData.connectionProfile') }}</template>
                        <template v-slot:tooltip-content>
                            <ConnectionProfileTooltipContent />
                        </template>
                    </Tooltip>
                </template>
            </Select>
        </div>
        <div class="col">
            <InputField
                class="mt-3"
                placeholder="l/s"
                type="number"
                :title="`${$t('rectSilencerData.inputAirflow')} (l/s)`"
                :modelValue="inputAirflow || ''"
                @update:modelValue="onSetInputAirflow"
            ></InputField>
            <Select
                class="mt-3"
                :label="$t('rectSilencerData.position')"
                :options="positionOptions"
                :modelValue="position"
                @update:modelValue="setPosition"
            ></Select>
            <Select
                class="mt-3"
                :label="$t('rectSilencerData.openable')"
                :options="openableOptions"
                :modelValue="openable"
                @update:modelValue="setOpenable"
            ></Select>
            <Select
                class="mt-3"
                :disabled="shape === 'straight' || hideOrientationOptions"
                :label="$t('rectSilencerData.orientation')"
                :options="orientationOptions"
                :modelValue="orientation"
                @update:modelValue="setOrientation"
            ></Select>
        </div>
    </div>
    <div class="row" v-if="!hideOrientationOptions">
        <div class="col">
            <Select
                class="mt-3"
                :label="$t('rectSilencerData.connectionProfile')"
                :options="connectionProfileOptions"
                :modelValue="connectionProfile"
                @update:modelValue="setConnectionProfile"
            >
                <template v-slot:title-addon>
                    <Tooltip class="ml-1 mb-1">
                        <i class="pi pi-info-circle" />
                        <template v-slot:tooltip-heading>{{ $t('rectSilencerData.connectionProfile') }}</template>
                        <template v-slot:tooltip-content>
                            <ConnectionProfileTooltipContent />
                        </template>
                    </Tooltip>
                </template>
            </Select>
        </div>
        <div class="col"></div>
        <div class="col"></div>
    </div>
    <div class="row mt-8">
        <p class="title mb-0">{{ $t('rectSilencerData.noiseLevelsBefore') }} (Hz)</p>
    </div>
    <div class="row mb-3">
        <InputSoundPowerEditor v-if="mode === 'modelling'"/>
        <OctaveBandContainer
            v-else
            :powerLevelKit="inputSoundPower"
            :lwa="totalSoundPowerLwA"
            lwaTitle="dB(A)"
        ></OctaveBandContainer>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Select from '@/components/general/Select.vue';
import InputField from '@/components/general/InputField.vue';
import OctaveBandContainer from '@/components/products/product/OctaveBandContainer.vue';
import InputSoundPowerEditor from '@/components/pages/modelling/rect-silencer/InputSoundPowerEditor.vue';
import Tooltip from '@/components/general/Tooltip.vue';
import ShellInsulationTooltipContent from '@/components/products/rect-silencers/modal-parts/tooltips-content/ShellInsulation.vue';
import CassetteMaterialTooltipContent from '@/components/products/rect-silencers/modal-parts/tooltips-content/CassetteMaterial.vue';
import OuterCoverTooltipContent from '@/components/products/rect-silencers/modal-parts/tooltips-content/OuterCover.vue';
import InnerMaterialTooltipContent from '@/components/products/rect-silencers/modal-parts/tooltips-content/InnerMaterial.vue';
import ConnectionProfileTooltipContent from '@/components/products/rect-silencers/modal-parts/tooltips-content/ConnectionProfile.vue';


export default {
    name: 'SilencerDetails',
    components: {
        Select,
        InputField,
        OctaveBandContainer,
        InputSoundPowerEditor,
        Tooltip,
        ShellInsulationTooltipContent,
        CassetteMaterialTooltipContent,
        OuterCoverTooltipContent,
        InnerMaterialTooltipContent,
        ConnectionProfileTooltipContent,
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: 'regular',
        },
        hideShapeOptions: {
            type: Boolean,
            default: false,
        },
        hideOrientationOptions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultShellInsulationOptions: [
                {
                    title: 'None',
                    value: '0mm',
                },
                {
                    title: 'S50',
                    value: '50mm',
                },
                {
                    title: 'S100',
                    value: '100mm',
                },
                {
                    title: 'M30',
                    value: 'M30',
                },
                {
                    title: 'M50',
                    value: 'M50',
                },
                {
                    title: 'M100',
                    value: 'M100',
                },
                {
                    title: 'EI120',
                    value: 'EI120',
                },
            ],
        };
    },
    computed: {
        ...mapState('rectSilencerModule', [
            'shellInsulation',
            'cassetteMaterial',
            'outerCover',
            'innerMaterial',
            'width',
            'height',
            'length',
            'dimensionX',
            'dimensionY',
            'shape',
            'inputAirflow',
            'position',
            'openable',
            'connectionProfile',
            'orientation',
            'inputSoundPower',
        ]),
        ...mapState('activeSystemModule', ['calculationResults']),
        widthPlaceholder() {
            return this.shape === 'straight' ? '300 - 2500mm' : '300 - 2350mm';
        },
        heightPlaceholder() {
            return this.shape === 'straight' ? '300 - 2500mm' : '300 - 2350mm';
        },
        widthOptions() {
            const result = [];
            const upperLimit = this.shape === 'straight' ? 2500 : 2350;
            for (let i = 300; i <= upperLimit; i += 50) {
                result.push({
                    title: `${i}mm`,
                    value: i,
                });
            }

            return result;
        },
        heightOptions() {
            const result = [];
            const upperLimit = this.shape === 'straight' ? 2500 : 2350;
            for (let i = 200; i <= upperLimit; i += 50) {
                result.push({
                    title: `${i}mm`,
                    value: i,
                });
            }

            return result;
        },
        lengthOptions() {
            const result = [];
            for (let i = 600; i <= 2500; i += 50) {
                result.push({
                    title: `${i}mm`,
                    value: i,
                });
            }

            return result;
        },
        dimensionXUpperLimit() {
            return 2500 - this.width;
        },
        dimensionYUpperLimit() {
            return 2500 - this.height;
        },
        cassetteMaterialOptions() {
            return [
                {
                    title: this.$t('rectSilencerData.cassetteMaterial.polyester'),
                    value: 'polyester',
                },
                {
                    title: this.$t('rectSilencerData.cassetteMaterial.mineralWool'),
                    value: 'mineralWool',
                },
                {
                    title: this.$t('rectSilencerData.cassetteMaterial.cleantec'),
                    value: 'cleantec',
                },
            ];
        },
        manufactureMaterialOptions() {
            if (this.openable) {
                return [
                    {
                        title: this.$t('rectSilencerData.manufactureMaterial.none'),
                        value: 'none',
                    },
                    {
                        title: `${this.$t('rectSilencerData.manufactureMaterial.galvanizedSteel')} ZN275`,
                        value: 'galvanizedSteel',
                    },
                    {
                        title: `${this.$t('rectSilencerData.manufactureMaterial.magnelis')} ZM310`,
                        value: 'magnelis',
                    },
                ];
            }

            return [
                {
                    title: this.$t('rectSilencerData.manufactureMaterial.none'),
                    value: 'none',
                },
                {
                    title: `${this.$t('rectSilencerData.manufactureMaterial.galvanizedSteel')} ZN275`,
                    value: 'galvanizedSteel',
                },
                {
                    title: `${this.$t('rectSilencerData.manufactureMaterial.stainlessSteel')} AISI 316`,
                    value: 'stainlessSteel',
                },
                {
                    title: `${this.$t('rectSilencerData.manufactureMaterial.magnelis')} ZM310`,
                    value: 'magnelis',
                },
            ];
        },
        shapeOptions() {
            return [
                {
                    title: this.$t('rectSilencerData.shape.straight'),
                    value: 'straight',
                },
                {
                    title: this.$t('rectSilencerData.shape.bend'),
                    value: 'bend',
                },
            ];
        },
        positionOptions() {
            return [
                {
                    title: this.$t('rectSilencerData.position.supply'),
                    value: 'supply',
                },
                {
                    title: this.$t('rectSilencerData.position.extract'),
                    value: 'extract',
                },
                {
                    title: this.$t('rectSilencerData.position.intake'),
                    value: 'intake',
                },
                {
                    title: this.$t('rectSilencerData.position.exhaust'),
                    value: 'exhaust',
                },
            ];
        },
        openableOptions() {
            return [
                {
                    title: this.$t('rectSilencerData.openable.yes'),
                    value: true,
                },
                {
                    title: this.$t('rectSilencerData.openable.no'),
                    value: false,
                },
            ];
        },
        connectionProfileOptions() {
            if (this.openable) {
                return [
                    {
                        title: 'E20',
                        value: 'E20',
                    },
                    {
                        title: 'E30',
                        value: 'E30',
                    },
                ];
            }

            return [
                {
                    title: this.$t('rectSilencerData.connectionProfile.zProfile'),
                    value: 'Z-slat',
                },
                {
                    title: 'E20',
                    value: 'E20',
                },
                {
                    title: 'E30',
                    value: 'E30',
                },
            ];
        },
        orientationOptions() {
            return [
                {
                    title: this.$t('rectSilencerData.orientation.horizontal'),
                    value: 'horizontal',
                },
                {
                    title: this.$t('rectSilencerData.orientation.vertical'),
                    value: 'vertical',
                },
            ];
        },
        shellInsulationOptions() {
            if (!this.openable) {
                return this.defaultShellInsulationOptions;
            }

            return [
                {
                    title: 'S50',
                    value: '50mm',
                },
            ];
        },
        totalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.calculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return 0;
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA) || 0;
        },
    },
    methods: {
        ...mapActions('rectSilencerModule', [
            'setShellInsulation',
            'setCassetteMaterial',
            'setOuterCover',
            'setInnerMaterial',
            'setWidth',
            'setHeight',
            'setLength',
            'setDimensionX',
            'setDimensionY',
            'setShape',
            'setInputAirflow',
            'setPosition',
            'setOpenable',
            'setConnectionProfile',
            'setOrientation',
        ]),
        ...mapActions('mcadCalculationsModule', { 'mcadSetInputAirflow': 'setInputAirflow' }),
        async onSetInputAirflow(inputAirflow) {
            await this.setInputAirflow(inputAirflow);
            if (this.mode === 'modelling') {
                await this.mcadSetInputAirflow(inputAirflow);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.row {
    padding: 0;
}

.col {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 150px;

    &:first-of-type {
        min-width: 240px;
    }
}

.octave-band-container {
    width: 100%;
}

.input-title {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
}
</style>

<style lang="scss">
.dimensions .select-container {
    max-height: 150px;
    .custom-select {
        max-height: 150px;
        .items {
            max-height: 150px;
            overflow-y: auto;
        }
    }
}

.materials .select-container {
    .items {
        overflow-y: auto;
        max-height: 300px;
    }
}
</style>