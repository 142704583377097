<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ $t('systemSpecificationModal.editSystem') }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="row">
                <InputField class="mr-8" :title="$t('systemSpecificationModal.systemName')" :placeholder="$t('systemSpecificationModal.systemName')" v-model="name"></InputField>
                <InputField :title="$t('systemSpecificationModal.systemNr')" :placeholder="$t('systemSpecificationModal.systemNr')" v-model="number"></InputField>
            </div>
            <div class="row mt-12">
                <!-- <InputField class="mr-8 w-full" :title="$t('systemSpecificationModal.author')" :placeholder="$t('systemSpecificationModal.author')" v-model="author"></InputField> -->
                <DateInputField class="w-full mr-8" :title="$t('systemSpecificationModal.date')" :placeholder="$t('systemSpecificationModal.date')" v-model="date"></DateInputField>
                <TextareaField class="w-full" :title="$t('systemSpecificationModal.comment')" :placeholder="$t('systemSpecificationModal.comment')" v-model="comment"></TextareaField>
            </div>
            <div class="row mt-8 mb-6">
                <div class="group-title">{{ $t('systemSpecificationModal.systemSpecification') }}</div>
            </div>
            <div class="row mt-12">
                <Select :options="attenuationConstants" :label="$t('systemSpecificationModal.attenuationConstant')" v-model="attenuationConstantType" autoSelection class="mr-8"></Select>
                <Select :options="correctionFilters" :label="$t('systemSpecificationModal.filterCorrection')" v-model="filterCorrectionType" autoSelection></Select>
            </div>
            <OctaveBandRangeEditor v-model="inputSoundPowerLevel">
                <template v-slot:title>
                    <div class="row mt-6">
                        <div class="group-title">{{ $t('systemSpecificationModal.noiseGenerator') }} (Hz)</div>
                    </div>
                </template>
            </OctaveBandRangeEditor>
            <!-- <div class="row mt-6 mb-2">
                <div class="group-title">{{ $t('systemSpecificationModal.noiseGenerator') }} (Hz)</div>
            </div>
            <div class="row mt-12">
                <InputField title="63" v-model="inputSoundPowerLevel.octaveBand63Hz" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="125" v-model="inputSoundPowerLevel.octaveBand125Hz" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="250" v-model="inputSoundPowerLevel.octaveBand250Hz" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="500" v-model="inputSoundPowerLevel.octaveBand500Hz" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="1000" v-model="inputSoundPowerLevel.octaveBand1000Hz" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="2000" v-model="inputSoundPowerLevel.octaveBand2000Hz" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="4000" v-model="inputSoundPowerLevel.octaveBand4000Hz" placeholder="0" class="mr-16" type="number"></InputField>
                <InputField title="8000" v-model="inputSoundPowerLevel.octaveBand8000Hz" placeholder="0" type="number"></InputField>
            </div> -->
        </template>
        <template v-slot:modal-actions>
            <Button type="primary" size="medium" @click="save">{{ $t('systemSpecificationModal.save') }}</Button>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DateInputField from '../general/DateInputField.vue';
import TextareaField from '../general/TextareaField.vue';
import InputField from '../general/InputField.vue';
import Button from '../general/Button.vue';
import Select from '../general/Select.vue';
import Modal from '../general/Modal.vue';
import OctaveBandRangeEditor from '@/components/products/product/OctaveBandRangeEditor.vue';

export default {
    name: 'EditSystemModal',
    components: {
        InputField,
        DateInputField,
        TextareaField,
        Button,
        Modal,
        Select,
        OctaveBandRangeEditor,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            name: '',
            number: '',
            // author: '',
            comment: '',
            date: '',
            attenuationConstantType: 'T1',
            filterCorrectionType: 'AFilter',
            inputSoundPowerLevel: {
                octaveBand63Hz: 0,
                octaveBand125Hz: 0,
                octaveBand250Hz: 0,
                octaveBand500Hz: 0,
                octaveBand1000Hz: 0,
                octaveBand2000Hz: 0,
                octaveBand4000Hz: 0,
                octaveBand8000Hz: 0,
            },
            attenuationConstants: [
                {
                    title: this.$t('projectBreakdown.constants.noConstant'),
                    value: 'NoConstant',
                },
                {
                    title: 'LpA10m2',
                    value: 'T1',
                },
            ],
            correctionFilters: [
                {
                    title: this.$t('projectBreakdown.filters.noFilter'),
                    value: 'NoFilter',
                },
                {
                    title: 'A - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'AFilter',
                },
                {
                    title: 'B - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'BFilter',
                },
                {
                    title: 'C - ' + this.$t('projectBreakdown.filters.filter'),
                    value: 'CFilter',
                },
            ],
        };
    },
    created() {
        this.actualize();
    },
    computed: {
        ...mapState('activeSystemModule', {
            'actualName': 'name',
            'actualNumber': 'number',
            // 'actualAuthor': 'author',
            'actualDate': 'date',
            'actualComment': 'comment',
            'actualAttenuationConstantType': 'attenuationConstantType',
            'actualFilterCorrectionType': 'filterCorrectionType',
            'actualInputSoundPowerLevel': 'inputSoundPowerLevel',
        }),
    },
    methods: {
        ...mapActions('activeSystemModule', [
            'setName',
            'setNumber',
            // 'setAuthor',
            'setDate',
            'setComment',
            'setAttenuationConstantType',
            'setFilterCorrectionType',
            'setInputSoundPowerLevel',
            'saveSystem',
        ]),
        closeModal() {
            this.$emit('update:modelValue', false);
        },
        updateInputSoundPowerLevel(bandRange, soundPower) {
            this.setInputSoundPowerLevel({ bandRange, soundPower });
        },
        reset() {
            this.name = '';
            this.number = '';
            // this.author = '';
            this.comment = '';
            this.date = '';
            this.attenuationConstantType = 'T1';
            this.filterCorrectionType = 'AFilter';
            this.inputSoundPowerLevel = {
                octaveBand63Hz: 0,
                octaveBand125Hz: 0,
                octaveBand250Hz: 0,
                octaveBand500Hz: 0,
                octaveBand1000Hz: 0,
                octaveBand2000Hz: 0,
                octaveBand4000Hz: 0,
                octaveBand8000Hz: 0,
            };
        },
        async save() {
            await this.setName(this.name);
            await this.setNumber(this.number);
            // await this.setAuthor(this.author);
            await this.setDate(this.date);
            await this.setComment(this.comment);
            await this.setAttenuationConstantType(this.attenuationConstantType);
            await this.setFilterCorrectionType(this.filterCorrectionType);
            this.updateInputSoundPowerLevel('octaveBand63Hz', this.inputSoundPowerLevel.octaveBand63Hz);
            this.updateInputSoundPowerLevel('octaveBand125Hz', this.inputSoundPowerLevel.octaveBand125Hz);
            this.updateInputSoundPowerLevel('octaveBand250Hz', this.inputSoundPowerLevel.octaveBand250Hz);
            this.updateInputSoundPowerLevel('octaveBand500Hz', this.inputSoundPowerLevel.octaveBand500Hz);
            this.updateInputSoundPowerLevel('octaveBand1000Hz', this.inputSoundPowerLevel.octaveBand1000Hz);
            this.updateInputSoundPowerLevel('octaveBand2000Hz', this.inputSoundPowerLevel.octaveBand2000Hz);
            this.updateInputSoundPowerLevel('octaveBand4000Hz', this.inputSoundPowerLevel.octaveBand4000Hz);
            this.updateInputSoundPowerLevel('octaveBand8000Hz', this.inputSoundPowerLevel.octaveBand8000Hz);
            await this.saveSystem();
            this.closeModal();
        },
        actualize() {
            this.name = this.actualName;
            this.number = this.actualNumber;
            // this.author = this.actualAuthor;
            this.date = this.actualDate;
            this.comment = this.actualComment;
            this.attenuationConstantType = this.actualAttenuationConstantType;
            this.filterCorrectionType = this.actualFilterCorrectionType;
            this.inputSoundPowerLevel = {
                octaveBand63Hz: this.actualInputSoundPowerLevel.octaveBand63Hz,
                octaveBand125Hz: this.actualInputSoundPowerLevel.octaveBand125Hz,
                octaveBand250Hz: this.actualInputSoundPowerLevel.octaveBand250Hz,
                octaveBand500Hz: this.actualInputSoundPowerLevel.octaveBand500Hz,
                octaveBand1000Hz: this.actualInputSoundPowerLevel.octaveBand1000Hz,
                octaveBand2000Hz: this.actualInputSoundPowerLevel.octaveBand2000Hz,
                octaveBand4000Hz: this.actualInputSoundPowerLevel.octaveBand4000Hz,
                octaveBand8000Hz: this.actualInputSoundPowerLevel.octaveBand8000Hz,
            };
        },
    },
    watch: {
        modelValue(value) {
            if (!value) {
                return;
            }
            this.actualize();
        }
    },
}
</script>

<style>
.group-title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 22px;
    color: #737372;
}
</style>
