<template>
  <Header></Header>
  <router-view v-if="canProceed"></router-view>
  <div v-else class="loader-container">
    <div class="loader big-loader"></div>
  </div>
  <Footer></Footer>
</template>

<script>
import { mapActions } from 'vuex';
import Header from './general/Header.vue';
import Footer from './general/Footer.vue';

export default {
  name: 'MainContent',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      canProceed: false,
    };
  },
  async created() {
    try {
      await this.initUser();
      await Promise.all([
        this.getSilencers(),
        this.getDiffusers(),
        this.getAbstractProducts(),
        this.getSilencerClasses(),
        this.getDiffuserClasses(),
        this.getAbstractClasses(),
      ]);
      this.canProceed = true;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    ...mapActions('silencersModule', ['getSilencers']),
    ...mapActions('diffusersModule', ['getDiffusers']),
    ...mapActions('abstractProductsModule', ['getAbstractProducts']),
    ...mapActions('silencerClassesModule', ['getSilencerClasses']),
    ...mapActions('diffuserClassesModule', ['getDiffuserClasses']),
    ...mapActions('abstractClassesModule', ['getAbstractClasses']),
    ...mapActions('userModule', ['initUser']),
  },
}
</script>

<style lang="scss">
@use 'src/assets/css/main.scss';

.row {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
}

.column {
    display: flex;
    flex-direction: column;
}
// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('./assets/fonts/HelveticaNeue.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Helvetica Neue Bold';
//   src: url('./assets/fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
// }

// div, p, span {
//   font-family: Helvetica Neue Bold;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 20px;
// }

#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 100vw;
  padding-top: 56px;
}

body {
  margin: 0;
  position: relative;
  overflow-x: hidden;
}
</style>
