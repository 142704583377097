import * as ProjectsService from '@/services/projects';
import ProjectsInputTransformer from '@/transformers/input/projects';

export default {
    namespaced: true,
    state: () => ({
        projects: [],
    }),
    getters: {
        projects({ projects }) {
            return projects;
        },
    },
    mutations: {
        setProjects(state, projects) {
            if (!Array.isArray(projects) || !projects.length) {
                return;
            }
            state.projects = projects;
        },
        deleteProject({ projects }, projectId) {
            if (!projectId) {
                return;
            }
            const index = projects.findIndex((project) => project.id === projectId);
            if (index < 0) {
                return;
            }
            projects.splice(index, 1);
        },
        addUser({ projects }, { project, user }) {
            const foundProject = projects.find((item) => item.id === project.id);
            if (!foundProject) {
                return;
            }
            if (foundProject.invitedUsers.find((item) => item.id === user.id)) {
                return;
            }
            foundProject.invitedUsers.push(user);
        },
        removeUser({ projects }, { project, user }) {
            const foundProject = projects.find((item) => item.id === project.id);
            if (!foundProject) {
                return;
            }
            const index = foundProject.invitedUsers.findIndex((item) => item.id === user.id);
            if (index < 0) {
                return;
            }
            foundProject.invitedUsers.splice(index, 1);
        },
    },
    actions: {
        async getProjects({ commit, rootGetters }) {
            if (!rootGetters['userModule/userAuthorized']) {
                return;
            }
            const projects = await ProjectsService.getProjects();
            const transformed = ProjectsInputTransformer.transform(projects);
            commit('setProjects', transformed);
        },
        deleteProject({ commit }, projectId) {
            if (!projectId) {
                return;
            }
            commit('deleteProject', projectId);
        },
        async addUser({ commit }, { project, user }) {
            if (!project || !project.id || !user || !user.id) {
                return;
            }
            await ProjectsService.addUser(user.id, project.id);
            commit('addUser', { project, user });
        },
        async removeUser({ commit }, { project, user }) {
            if (!project || !project.id || !user || !user.id) {
                return;
            }
            await ProjectsService.removeUser(user.id, project.id);
            commit('removeUser', { project, user });
        },
    },
}
