import store from '@/store';
import transformRectSilencer from '../rect-silencer';
import { emptyOctaveBandRange } from '../../../utils';
import ProjectTransformer from '../projects';
import SilencerTransformer from '../silencers';
import DiffuserTransformer from '../diffusers';

function transformAbstractProduct(systemItem) {
    return {
        id: systemItem.productId || `abstract-${Date.now().toString()}`,
        name: systemItem.productName || 'Abstract Product',
        type: 'abstract',
        class: systemItem.productClass || 'abstract',
        code: systemItem.productCode || '',
        imageURL: systemItem.productImageURL || '',
        defaultSoundPowerLevel: emptyOctaveBandRange(),
        soundPowerLevel: systemItem.soundPowerLevel
            ? systemItem.soundPowerLevel
            : emptyOctaveBandRange(),
        defaultSoundAttenuation: emptyOctaveBandRange(),
        soundAttenuation: systemItem.soundAttenuation
            ? systemItem.soundAttenuation
            : emptyOctaveBandRange(),
    };
}

function transformCustomProduct(systemItem) {
    return {
        id: systemItem.productId || `custom-${Date.now().toString()}`,
        name: systemItem.productName || 'Custom Product',
        type: 'custom',
        class: systemItem.productClass || 'custom',
        code: systemItem.productCode || '',
        imageURL: systemItem.productImageURL || '',
        defaultSoundPowerLevel: emptyOctaveBandRange(),
        soundPowerLevel: systemItem.soundPowerLevel
            ? systemItem.soundPowerLevel
            : emptyOctaveBandRange(),
        defaultSoundAttenuation: emptyOctaveBandRange(),
        soundAttenuation: systemItem.soundAttenuation
            ? systemItem.soundAttenuation
            : emptyOctaveBandRange(),
    };
}

function transformSystemItem(systemItem, silencersMap, diffusersMap) {
    if (!systemItem.productId || !systemItem.productClass) {
        return null;
    }
    // "productClass" is used here for backward compatibility:
    if (systemItem.productType === 'abstract' || systemItem.productClass === 'abstract') {
        return transformAbstractProduct(systemItem);
    }
    if (systemItem.productType === 'custom') {
        return transformCustomProduct(systemItem);
    }
    if (systemItem.productType === 'rectSilencer') {
        return transformRectSilencer(systemItem);
    }
    let product = null;
    // TODO: these noodles have to be replaced
    if (systemItem.productType === 'silencer') {
        if (systemItem.product) {
            product = SilencerTransformer.transform([systemItem.product]).silencers[0] || null;
        } else if (systemItem.productId in silencersMap) {
            product = { ...silencersMap[systemItem.productId] };
        }
    }
    if (systemItem.productType === 'diffuser') {
        if (systemItem.product) {
            product = DiffuserTransformer.transform([systemItem.product]).diffusers[0] || null;
        } else if (systemItem.productId in diffusersMap) {
            product = { ...diffusersMap[systemItem.productId] };
        }
    }
    if (!product) {
        return null;
    }

    product.soundPowerLevel = systemItem.soundPowerLevel;
    product.soundAttenuation = systemItem.soundAttenuation;

    if (systemItem.productType === 'silencer' || systemItem.productClass === 'silencer') {
        product.inputAirflow = !Number.isNaN(Number(systemItem.inputAirflow)) ? Number(systemItem.inputAirflow) : 0;
        product.pressureDrop = !Number.isNaN(Number(systemItem.pressureDrop)) ? Number(systemItem.pressureDrop) : 0;
    }

    return product;
}

function transformSystemItems(systemItems) {
    if (!Array.isArray(systemItems) || !systemItems.length) {
        return [];
    }
    const {
        state: {
            silencersModule: { silencersMap = {} } = {},
            diffusersModule: { diffusersMap = {} } = {},
        } = {},
    } = store;
    const result = [];
    systemItems.forEach((systemItem) => {
        if (!systemItem.productId) {
            return;
        }
        const transformed = transformSystemItem(systemItem, silencersMap, diffusersMap);
        if (!transformed) {
            return;
        }
        // backward compatibility for deprecated "quantity" attribute:
        if (!Number.isNaN(Number(systemItem.quantity))) {
            for (let i = 0; i < Number(systemItem.quantity); i++) {
                result.push(transformed);
            }
        }
        else {
            result.push(transformed);
        }
    });

    return result;
}

function transformProductQuantities(selectedProducts) {
    if (!Array.isArray(selectedProducts) || !selectedProducts.length) {
        return {};
    }
    const result = {};
    selectedProducts.forEach((product) => {
        result[product.id] = (product.id in result) ? result[product.id] + 1 : 1;
    });

    return result;
}

function transformInputSoundPowerLevel(inputSoundPowerLevel) {
    if (typeof inputSoundPowerLevel !== 'object' || !Object.keys(inputSoundPowerLevel).length) {
        return {
            octaveBand63Hz: 0,
            octaveBand125Hz: 0,
            octaveBand250Hz: 0,
            octaveBand500Hz: 0,
            octaveBand1000Hz: 0,
            octaveBand2000Hz: 0,
            octaveBand4000Hz: 0,
            octaveBand8000Hz: 0,
        };
    }

    return {
        octaveBand63Hz: inputSoundPowerLevel.octaveBand63Hz,
        octaveBand125Hz: inputSoundPowerLevel.octaveBand125Hz,
        octaveBand250Hz: inputSoundPowerLevel.octaveBand250Hz,
        octaveBand500Hz: inputSoundPowerLevel.octaveBand500Hz,
        octaveBand1000Hz: inputSoundPowerLevel.octaveBand1000Hz,
        octaveBand2000Hz: inputSoundPowerLevel.octaveBand2000Hz,
        octaveBand4000Hz: inputSoundPowerLevel.octaveBand4000Hz,
        octaveBand8000Hz: inputSoundPowerLevel.octaveBand8000Hz,
    };
}

function transformSystem(system) {
    if (!system._id || !system.projectId) {
        return null;
    }
    const selectedProducts = transformSystemItems(system.items);

    let project = null;
    if (Array.isArray(system.project) && system.project.length) {
        project = ProjectTransformer.transform(system.project[0]);
    }

    return {
        id: system._id,
        projectId: system.projectId,
        name: system.name || '',
        number: system.number || '',
        author: system.author || '',
        comment: system.comment || '',
        date: system.date ? new Date(system.date) : new Date(),
        createdAt: system.createdAt ? new Date(system.createdAt) : null,
        updatedAt: system.updatedAt ? new Date(system.updatedAt) : null,
        attenuationConstantType: system.attenuationConstantType || 'T1',
        filterCorrectionType: system.filterCorrectionType || 'AFilter',
        inputSoundPowerLevel: transformInputSoundPowerLevel(system.inputSoundPowerLevel),
        selectedProducts,
        productQuantitiesMap: transformProductQuantities(selectedProducts),
        calculationResults: {},
        project,
    };
}

export default {
    transform(systems) {
        if (!Array.isArray(systems) || !systems.length) {
            return [];
        }
        const result = [];
        systems.forEach((system) => {
            const transformed = transformSystem(system);
            if (!transformed) {
                return;
            }
            result.push(transformed);
        });

        return result;
    },
}