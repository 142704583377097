export default {
  address: {
    line1: 'Peterburi tee 53',
    line2: '11415 Tallinn',
    line3: 'Estonia'
  },
  phone: '+372 680 7360',
  email: 'info@etsnord.ee',
  title: 'ETS NORD',
  supportEmail: 'support@etsnord.ee',
  website: 'www.etsnord.com'
}
