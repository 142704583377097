import * as SystemsService from '@/services/systems';
import SystemsInputTransformer from '@/transformers/input/systems';
import * as CalculationsService from '@/services/calculations';

export default {
    namespaced: true,
    state: () => ({
        systems: [],
        perSystemCalculations: [],
    }),
    getters: {
        systems({ systems }) {
            return systems;
        },
        perSystemCalculations({ perSystemCalculations }) {
            return perSystemCalculations;
        },
    },
    mutations: {
        setSystems(state, systems) {
            if (!Array.isArray(systems) || !systems.length) {
                state.systems = [];
                return;
            }
            state.systems = systems;
            state.perSystemCalculations = [];
        },
        addSystem({ systems }, system) {
            if (!system || !system.id) {
                return;
            }
            if (systems.find((item) => item.id === system.id)) {
                return;
            }
            systems.push(system);
        },
        updateSystem({ systems }, system) {
            const index = systems.findIndex((item) => item.id === system.id);
            if (index < 0) {
                return;
            }
            systems[index] = system;
        },
        deleteSystem({ systems }, systemId) {
            if (!systemId) {
                return;
            }
            const index = systems.findIndex((system) => system.id === systemId);
            if (index < 0) {
                return;
            }
            systems.splice(index, 1);
        },
        setPerSystemCalculations(state, perSystemCalculations) {
            state.perSystemCalculations = perSystemCalculations;
        },
    },
    actions: {
        async getSystems({ commit, rootGetters }, { projectId }) {
            if (!projectId || !rootGetters['userModule/userAuthorized']) {
                return;
            }
            const systems = await SystemsService.getSystems(projectId);
            const transformed = SystemsInputTransformer.transform(systems);
            commit('setSystems', transformed);
        },
        addSystem({ commit, state }, { system }) {
            if (!system || !system.id) {
                return;
            }
            const index = state.systems.findIndex((item) => item.id === system.id);
            if (index >= 0) {
                commit('updateSystem', system);
            } else {
                commit('addSystem', system);
            }
        },
        async copySystem({ commit }, { projectId, system }) {
            if (!projectId || !system) {
                return;
            }
            const systemId = (typeof system === 'string') ? system : system.id;
            const copiedSystem = await SystemsService.copySystem(projectId, systemId);
            if (!copiedSystem) {
                return;
            }
            const transformed = SystemsInputTransformer.transform([copiedSystem])[0];
            if (!transformed) {
                return;
            }
            commit('addSystem', transformed);
        },
        updateSystem({ commit }, { system }) {
            if (!system || !system.id) {
                return;
            }
            commit('updateSystem', system);
        },
        deleteSystem({ commit }, { systemId }) {
            if (!systemId) {
                return;
            }
            commit('deleteSystem', systemId);
        },
        async getPerSystemCalculations({ state: { systems }, commit }) {
            if (!systems.length) {
                return [];
            }
            const promises = systems.map((system) => CalculationsService.calculateSoundPower({
                inputSoundPowerLevel: system.inputSoundPowerLevel,
                attenuationConstant: system.attenuationConstantType,
                filterCorrectionType: system.filterCorrectionType,
                products: prepareProducts(system.selectedProducts),
            }));
            const perSystemCalculations = await Promise.all(promises);
            commit('setPerSystemCalculations', perSystemCalculations);
        },
    },
}

// TODO: to transformer
function prepareProducts(products) {
    const result = [];
    products.forEach((product) => {
        if (!product.quantity) {
            result.push(prepareProduct(product));
            return;
        }
        for (let i = 1; i <= product.quantity; i++) {
            result.push(prepareProduct(product));
        }
    });

    return result;
}

// TODO: to transformer
function prepareProduct(product) {
    return {
        id: product.id,
        type: product.type,
        class: product.class || 'abstract',
        name: product.name || 'Abstract',
        imageURL: product.imageURL || '',
        code: product.code || '',
        soundAttenuation: product.soundAttenuation,
        soundPowerLevel: product.soundPowerLevel,
    };
}