<template>
    <!-- Project data -->
    <div class="row">
        <InputField class="mr-8" :title="$t('projectSpecificationModal.projectName')" :placeholder="$t('projectSpecificationModal.projectName')" :model-value="name" @update:modelValue="setName"></InputField>
        <InputField :title="$t('projectSpecificationModal.projectNr')" :placeholder="$t('projectSpecificationModal.projectNr')" :model-value="number" @update:modelValue="setNumber"></InputField>
    </div>
    <div class="row mt-4">
        <InputField :title="$t('projectSpecificationModal.address')" :placeholder="$t('projectSpecificationModal.address')" :model-value="address" @update:modelValue="setAddress"></InputField>
    </div>
    <div class="row mt-4">
        <InputField class="mr-8" :title="$t('projectSpecificationModal.email')" :placeholder="$t('projectSpecificationModal.email')" :model-value="email" @update:modelValue="setEmail"></InputField>
        <InputField :title="$t('projectSpecificationModal.phoneNumber')" :placeholder="$t('projectSpecificationModal.phoneNumber')" :model-value="phone" @update:modelValue="setPhone"></InputField>
    </div>
    <div class="row mt-4">
        <InputField class="mr-8" :title="$t('projectSpecificationModal.customer')" :placeholder="$t('projectSpecificationModal.customer')" :model-value="contactData" @update:modelValue="setContactData"></InputField>
        <DateInputField :title="$t('projectSpecificationModal.date')" :placeholder="$t('projectSpecificationModal.date')" :model-value="date" @update:modelValue="setDate"></DateInputField>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputField from '../../general/InputField.vue';
import DateInputField from '../../general/DateInputField.vue';

export default {
    name: 'EditActiveProjectData',
    components: {
        InputField,
        DateInputField,
    },
    computed: {
        ...mapState('activeProjectModule', {
            'projectId': 'id',
        }),
        ...mapState('activeProjectModule', [
            'name',
            'number',
            'address',
            'email',
            'phone',
            'contactData',
            'date',
        ]),
    },
    methods: {
        ...mapActions('activeProjectModule', [
            'setName',
            'setNumber',
            'setAddress',
            'setEmail',
            'setPhone',
            'setContactData',
            'setDate',
        ]),
    },
}
</script>