export function toValidNumber(value, defaultValue = 0) {
    return !Number.isNaN(Number(value)) ? Number(value) : defaultValue;
}

export function emptyOctaveBandRange() {
    return {
        octaveBand63Hz: 0,
        octaveBand125Hz: 0,
        octaveBand250Hz: 0,
        octaveBand500Hz: 0,
        octaveBand1000Hz: 0,
        octaveBand2000Hz: 0,
        octaveBand4000Hz: 0,
        octaveBand8000Hz: 0,
    };
}

export function isOctaveBandRangeEmpty(octaveBandRange) {
    return Object.values(octaveBandRange).every((octaveBand) => octaveBand === 0);
}

export function createOctaveBandRangeFromObject(data) {
    return {
        octaveBand63Hz: toValidNumber(data.octaveBand63Hz),
        octaveBand125Hz: toValidNumber(data.octaveBand125Hz),
        octaveBand250Hz: toValidNumber(data.octaveBand250Hz),
        octaveBand500Hz: toValidNumber(data.octaveBand500Hz),
        octaveBand1000Hz: toValidNumber(data.octaveBand1000Hz),
        octaveBand2000Hz: toValidNumber(data.octaveBand2000Hz),
        octaveBand4000Hz: toValidNumber(data.octaveBand4000Hz),
        octaveBand8000Hz: toValidNumber(data.octaveBand8000Hz),
    };
}

export function roundOctaveBandRange(octaveBandRange, precision = 0) {
    return {
        octaveBand63Hz: Number(Number(octaveBandRange.octaveBand63Hz).toFixed(precision)),
        octaveBand125Hz: Number(Number(octaveBandRange.octaveBand125Hz).toFixed(precision)),
        octaveBand250Hz: Number(Number(octaveBandRange.octaveBand250Hz).toFixed(precision)),
        octaveBand500Hz: Number(Number(octaveBandRange.octaveBand500Hz).toFixed(precision)),
        octaveBand1000Hz: Number(Number(octaveBandRange.octaveBand1000Hz).toFixed(precision)),
        octaveBand2000Hz: Number(Number(octaveBandRange.octaveBand2000Hz).toFixed(precision)),
        octaveBand4000Hz: Number(Number(octaveBandRange.octaveBand4000Hz).toFixed(precision)),
        octaveBand8000Hz: Number(Number(octaveBandRange.octaveBand8000Hz).toFixed(precision)),
    };
}

export function cloneOctaveBandRange(octaveBandRange) {
    return createOctaveBandRangeFromObject(octaveBandRange);
}

export function resolveRectSilencerImage(silencerClass, orientation = '') {
    switch (silencerClass) {
        case 'MSK':
        case 'MSP':
        case 'MSC':
            return 'https://storage.googleapis.com/category-images/silencers/MSK_MSP.png';
        case 'MSKA':
        case 'MSPA':
        case 'MSCA':
        case 'MRKA':
        case 'MRPA':
        case 'MRCA':
            return 'https://storage.googleapis.com/category-images/silencers/MSKA_MSPA.png';
        case 'MSKI':
        case 'MSPI':
        case 'MSCI':
            return 'https://storage.googleapis.com/category-images/silencers/MSKI_MSPI.png';
        case 'MPK':
        case 'MPP':
        case 'MPC':
            return orientation === 'horizontal'
                ? 'https://storage.googleapis.com/category-images/silencers/rectangular/bend/MPK_MPP_horizontal.png'
                : 'https://storage.googleapis.com/category-images/silencers/rectangular/bend/MPK_MPP_vertical.png';
        case 'MPPI':
        case 'MPKI':
        case 'MPCI':
            return orientation === 'horizontal'
                ? 'https://storage.googleapis.com/category-images/silencers/rectangular/bend/MPKI_horizontal.png'
                : 'https://storage.googleapis.com/category-images/silencers/rectangular/bend/MPKI_vertical.png';

        default:
            return '';
    }
}

export async function isIncognito() {
    const { memory: { jsHeapSizeLimit = 1073741824 } = {} } = window.navigator.performance || {};
    const { quota = 0 } = await window.navigator.storage.estimate();

    return quota < jsHeapSizeLimit;
}
