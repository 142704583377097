<template>
    <div class="project-breakdown" ref="projectBreakdown">
        <!--First page-->
        <ProjectPage ref="projectPage" :pagesQuantity="pagesTotal">
            <template v-slot:additional-data>
                <div class="row p-0 mt-8">
                    <MaterialsTable :products="productsPerPages[0] || []"/>
                </div>
            </template>
        </ProjectPage>
        <!-- List of products (BOM table) -->
        <template v-for="(products, index) in productsPerPages" :key="index">
            <Page v-if="index > 0" :pagesTotal="pagesTotal">
                <template v-slot:header>
                    <div class="row section-header pt-1 pb-4 header-project-code">{{ $t('systemsPage.billOfMaterials') }}</div>
                </template>
                <template v-slot:content>
                    <div class="row p-0 mt-8">
                        <MaterialsTable :products="products"/>
                    </div>
                </template>
            </Page>
        </template>
        <!--Product pages-->
        <template v-for="(system, systemIndex) in systemsList" :key="system.id">
            <template v-for="(product, productIndex) in system.selectedProducts" :key="product.id">
                <Page :showFooter="drawFooter && productIndex === selectedProducts.length - 1" :pagesTotal="pagesTotal">
                    <template v-slot:header>
                        <div class="row section-header pt-1 header-project-code">{{ system.name }}</div>
                        <div class="row section-header pb-4 header-project-code">{{ system.number }}</div>
                    </template>
                    <template v-slot:content>
                        <ProductData
                            :product="product"
                            :productIndex="productIndex"
                            :calculationResults="perSystemCalculations[systemIndex]"
                            :inputSoundPowerLevel="system.inputSoundPowerLevel"
                        />
                    </template>
                </Page>
            </template>
        </template>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import ProjectPage from '../ProjectPage.vue';
import MaterialsTable from './MaterialsTable.vue';
import Page from '../../../pdf/general/Page.vue';
import ProductData from '@/components/systems/pdf/product/ProductData.vue';

export default {
    name: 'BillOfMaterials',
    components: {
        ProjectPage,
        MaterialsTable,
        Page,
        ProductData,
    },
    updated() {
        this.pagesTotal = window.document.querySelectorAll('.page').length;
    },
    data() {
        return {
            pagesTotal: 1,
        };
    },
    computed: {
        ...mapGetters('systemsModule', ['systems', 'perSystemCalculations']),
        todayDate() {
            return dayjs().format('DD.MM.YYYY');
        },
        systemsList() {
            const systems = Array.from(this.systems);

            return systems;
            // return systems.sort((left, right) => (new Date(left.createdAt)) < (new Date(right.createdAt)) ? 1 : -1);
        },
        productsList() {
            const result = [];
            this.systemsList.forEach((system) => {
                if (!Array.isArray(system.selectedProducts) || !system.selectedProducts.length) {
                    return;
                }
                system.selectedProducts.forEach((product) => {
                    result.push({
                        ...product,
                        systemName: system.name,
                        systemNr: system.number,
                    });
                });
            });

            return result;
        },
        productsPerPages() {
            const viewportHeight = Number(window.innerHeight);
            const productLineHeight = 40; // 58
            const projectInfo = document.getElementsByClassName('page')[0];
            let projectInfoHeight = 0; // fallback for initial call of computed property (DOM doesn't see first page yet)
            if (projectInfo) {
                // Summing up heights with margins of child div elements of Project Info section
                projectInfoHeight = Array.from(projectInfo.children)
                    .filter((child) => child.tagName === 'DIV')
                    .reduce((sum, child) => {
                        const style = window.getComputedStyle(child);
                        const height = ['height', 'margin-top', 'margin-bottom']
                            .map((key) => parseFloat(style.getPropertyValue(key)))
                            .reduce((previous, current) => previous + current);

                        return sum + height;
                    }, 0);
                projectInfoHeight += 50; // paddings of the page
            }
            let pageIndex = 0;
            const result = {};
            result[pageIndex] = [];
            this.productsList.forEach((product) => {
                const outputSpaceHeight = pageIndex > 0 ? viewportHeight : viewportHeight - projectInfoHeight;
                if ((result[pageIndex].length * productLineHeight) >= outputSpaceHeight) {
                    pageIndex += 1;
                    result[pageIndex] = [];
                }
                result[pageIndex].push(product);
            });

            return Object.values(result);
        },
    },
}
</script>

<style scoped lang="scss">
    @use 'src/assets/css/pdf/page.scss';
    @use 'src/assets/css/pdf/page-content.scss';
</style>


<style lang="scss" scoped>
.project-breakdown {
    // position: absolute;
    // top: 0;
    // left: -10000px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: #FFFFFF;
}

.p-0 {
    padding: 0 !important;
}
</style>
