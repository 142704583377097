import InputDiffuserClassesTransformer from '../../transformers/input/diffuser-classes';
import * as DiffuserClassesService from '../../services/diffuser-classes';

export default {
    namespaced: true,
    state: () => ({
        diffuserClasses: [],
    }),
    getters: {
        imagesMap({ diffuserClasses }) {
            const map = {};
            diffuserClasses.forEach((diffuserClass) => {
                map[diffuserClass.id] = diffuserClass.imageDataURL || diffuserClass.imageURL;
                map[diffuserClass.name] = diffuserClass.imageDataURL || diffuserClass.imageURL;
            });

            return map;
        }
    },
    mutations: {
        setDiffuserClasses(state, diffuserClasses) {
            state.diffuserClasses = diffuserClasses;
        },
    },
    actions: {
        async getDiffuserClasses({ state, commit }, { rewrite = false } = {}) {
            if (state.diffuserClasses.length && !rewrite) {
                return;
            }
            const diffuserClasses = await DiffuserClassesService.getDiffuserClasses();
            commit('setDiffuserClasses', InputDiffuserClassesTransformer.transform(diffuserClasses));
        },
    },
}