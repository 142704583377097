import * as VueRouter from 'vue-router';
import store from '@/store';
import App from '../App.vue';
import Authorize from '../Authorize.vue';
import Silencers from '../components/products/silencers/Silencers.vue';
import ProductsList from '../components/products/ProductsList.vue';
import Projects from '../components/projects/Projects.vue';
import Systems from '../components/systems/Systems.vue';
import ProjectOverview from '../components/projects/ProjectOverview.vue';
import SignIn from '../components/auth/SignIn.vue';
import SignUp from '../components/auth/SignUp.vue';
import PrintSystem from '../components/pdf/PrintSystem.vue';
import PrintProject from '../components/pdf/PrintProject.vue';
import PrintMaterials from '../components/pdf/PrintMaterials.vue';
import MainContent from '../components/MainContent.vue';
import RectSilencerModellingPage from '../components/pages/modelling/rect-silencer/Page.vue';
import SystemsBulkExportPage from '../components/systems/export/BulkExport.vue';
import ModellingPage from '../components/pages/modelling/ModellingPage.vue';
import SilencerClassesSelectorPage from '../components/pages/modelling/silencer-classes-selector/Page.vue';
import RegularSilencersPage from '../components/pages/modelling/regular-silencers/Page.vue';

// function checkAuthorization(to, from, next) {
//     if (!store.getters['userModule/userAuthorized']) {
//         next({ name: 'sign-in' });
//         return;
//     }
//     next();
// }

function redirectIfAuthorized(to, from, next) {
    if (store.getters['userModule/userAuthorized']) {
        next({ name: 'home' });
        return;
    }
    next();
}

// eslint-disable-next-line no-unused-vars
function redirectIfNotAuthorized(to, from, next) {
    if (!store.getters['userModule/userAuthorized']) {
        next({ name: 'home' });
        return;
    }
    next();
}

const routes = [
    {
        path: '/authorize/:loginIdentifier',
        name: 'authorize',
        component: Authorize,
    },
    {
        path: '/',
        name: 'home',
        component: App,
        redirect: { name: 'main' },
        // beforeEnter: checkAuthorization,
    },
    {
        path: '/app',
        name: 'main',
        component: MainContent,
        redirect: { name: 'projects' },
        children: [
            {
                path: 'projects',
                name: 'projects',
                component: Projects,
                // beforeEnter: checkAuthorization,
            },
            {
                path: 'systems',
                name: 'systems',
                component: Systems,
                // beforeEnter: checkAuthorization,
            },
            {
                path: 'silencers',
                name: 'silencers',
                component: Silencers,
                // beforeEnter: [checkAuthorization],
            },
            {
                path: 'products-list',
                name: 'products-list',
                component: ProductsList,
                // beforeEnter: [checkAuthorization],
            },
            {
                path: 'project-overview',
                name: 'project-overview',
                component: ProjectOverview,
                // beforeEnter: [checkAuthorization],
            },
            {
                path: 'sign-in',
                name: 'sign-in',
                component: SignIn,
                beforeEnter: redirectIfAuthorized,
            },
            {
                path: 'sign-up',
                name: 'sign-up',
                component: SignUp,
                beforeEnter: redirectIfAuthorized,
            },
            {
                path: 'systems/export',
                name: 'export-systems',
                component: SystemsBulkExportPage,
                // beforeEnter: redirectIfNotAuthorized,
            },
        ],
    },
    {
        path: '/modelling',
        name: 'modelling',
        component: ModellingPage,
        // redirect: { name: 'silencer-classes-selector' },
        children: [
            {
                path: 'silencer-classes',
                name: 'silencer-classes-selector',
                component: SilencerClassesSelectorPage,
            },
            {
                path: 'rect-silencer',
                name: 'rect-silencer-modelling',
                component: RectSilencerModellingPage,
            },
            {
                path: 'regular-silencers',
                name: 'regular-silencers',
                component: RegularSilencersPage,
            },
        ],
    },
    {
        path: '/printing/system',
        name: 'print-system',
        component: PrintSystem,
    },
    {
        path: '/printing/project',
        name: 'print-project',
        component: PrintProject,
    },
    {
        path: '/printing/materials',
        name: 'print-materials',
        component: PrintMaterials,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'main' },
    },
];

const router = VueRouter.createRouter({
    // history: VueRouter.createWebHashHistory(),
    history: VueRouter.createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

// router.beforeEach((to, from, next) => {
//     console.log('before each');
//     window.scroll(0, 0);
//     next();
// });

export default router;