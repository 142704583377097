import { emptyOctaveBandRange, createOctaveBandRangeFromObject, toValidNumber } from '../../../utils';
import { emptyTranslationMap } from '../__general';

const RECTANGULAR_SILENCERS = ['MSK', 'MSP', 'MSC', 'MSKI', 'MSPI', 'MSCI'];
const RECTANGULAR_SILENCER_APPENDIX = {
    english: 'Rectangular silencer',
    estonian: 'Kandiline mürasummuti',
    finnish: 'Suorakaideäänenvaimennin',
    swedish: 'Ljuddämpare',
    danish: 'Rectangular silencer',
};
const RECTANGULAR_OPENABLE_SILENCERS = ['MRKA', 'MRPA', 'MRCA'];
const RECTANGULAR_OPENABLE_APPENDIX = {
    english: 'Rectangular openable silencer',
    estonian: 'Kandiline avatav mürasummuti',
    finnish: 'Avattava suorakaideäänenvaimennin',
    swedish: 'Öppningsbara rektangulära ljuddämpare',
    danish: 'Rectangular openable silencer',
};
const RECTANGULAR_BEND_SILENCERS = ['MPK', 'MPP', 'MPC', 'MPKI', 'MPPI', 'MPCI'];
const RECTANGULAR_BEND_APPENDIX = {
    english: 'Rectangular bend silencer',
    estonian: 'Kandiline põlvmürasummuti',
    finnish: 'Kulmamallinen suorakaideäänenvaimennin',
    swedish: 'Böjda rektangulära ljuddämpare',
    danish: 'Rectangular bend silencer',
};

export function getNameAppendixByClass(silencerClass) {
    if (RECTANGULAR_SILENCERS.includes(silencerClass)) {
        return RECTANGULAR_SILENCER_APPENDIX;
    }
    if (RECTANGULAR_OPENABLE_SILENCERS.includes(silencerClass)) {
        return RECTANGULAR_OPENABLE_APPENDIX;
    }
    if (RECTANGULAR_BEND_SILENCERS.includes(silencerClass)) {
        return RECTANGULAR_BEND_APPENDIX;
    }

    return emptyTranslationMap();
}

export function transformOption(option) {
    const {
        cassetteThickness = 0,
        amountOfCassettes = 0,
        cassetteSpacing = 0,
        edgeGap = 0,
        silencerEndSpeed = 0,
        silencerGapSpeed = 0,
        pressureDrop = 0,
        dampingValuesOfDamperWithoutAirflow: { soundPowerLevel: soundAttenuation },
        attenuatorValuesOfDamperWithAirflow: {
            total: totalAttenuationWithAirflow = 0,
            soundPowerLevel: soundAttenuationWithAirflow,
        },
        linearSoundPowerLevelAfterSilencerWithAirflow: {
            total: resultAfterLw = 0,
            soundPowerLevel: resultSoundPowerAfterLw,
        },
        aFilterSoundPowerLevelAfterSilencerWithAirflow: { total: resultAfterAFilter = 0 },
    } = option;
    if (!option.cassetteThickness || !option.amountOfCassettes) {
        return null;
    }

    return {
        cassetteThickness: toValidNumber(cassetteThickness),
        amountOfCassettes: toValidNumber(amountOfCassettes),
        cassetteSpacing: toValidNumber(cassetteSpacing),
        edgeGap: toValidNumber(edgeGap),
        silencerEndSpeed: toValidNumber(silencerEndSpeed),
        silencerGapSpeed: toValidNumber(silencerGapSpeed),
        pressureDrop: toValidNumber(pressureDrop),
        soundAttenuation: soundAttenuation
            ? createOctaveBandRangeFromObject(soundAttenuation)
            : emptyOctaveBandRange(),
        totalAttenuationWithAirflow: toValidNumber(totalAttenuationWithAirflow),
        soundAttenuationWithAirflow: soundAttenuationWithAirflow
            ? createOctaveBandRangeFromObject(soundAttenuationWithAirflow)
            : emptyOctaveBandRange(),
        resultAfterLw: toValidNumber(resultAfterLw),
        resultSoundPowerAfterLw: createOctaveBandRangeFromObject(resultSoundPowerAfterLw),
        resultAfterAFilter: toValidNumber(resultAfterAFilter),
    };
}

export function transformOptions(options) {
    if (!Array.isArray(options) || !options.length) {
        return [];
    }
    const result = [];
    options.forEach((option) => {
        const transformed = transformOption(option);
        if (!transformed) {
            return;
        }
        result.push(transformed);
    });

    return result;
}

export function transform(data) {
    if (!data.productId || !data.productClass || !data.optionData) {
        return null;
    }

    return {
        id: data.productId,
        name: data.productName || 'Rect Silencer',
        nameAppendix: getNameAppendixByClass(data.productClass),
        type: 'rectSilencer',
        class: data.productClass,
        code: data.productCode || '',
        imageURL: data.productImageURL || '',
        // Rect silencer specific attributes:
        width: data.width || 0,
        height: data.height || 0,
        length: data.length || 0,
        dimensionX: data.dimensionX || 150,
        dimensionY: data.dimensionY || 150,
        shape: data.shape || 'straight',
        orientation: data.orientation || '',
        position: data.position || 'supply',
        inputAirflow: data.inputAirflow || 0,
        shellInsulation: data.shellInsulation || '50mm',
        cassetteMaterial: data.cassetteMaterial || 'polyester',
        outerCover: data.outerCover || 'galvanizedSteel',
        innerMaterial: data.innerMaterial || 'galvanizedSteel',
        openable: Boolean(data.openable),
        connectionProfile: !data.openable ? 'Z-slat' : (data.connectionProfile === 'Z-slat' ? 'E20' : data.connectionProfile),
        optionData: {
            cassetteThickness: data.optionData.cassetteThickness || 0,
            amountOfCassettes: data.optionData.amountOfCassettes || 0,
            cassetteSpacing: data.optionData.cassetteSpacing || 0,
            edgeGap: data.optionData.edgeGap || 0,
            silencerGapSpeed: data.optionData.silencerGapSpeed || 0,
            pressureDrop: data.optionData.pressureDrop || 0,
            totalAttenuation: data.optionData.attenuation || 0,
            soundAttenuation: data.optionData.soundAttenuation
            ? createOctaveBandRangeFromObject(data.optionData.soundAttenuation)
            : emptyOctaveBandRange(),
            resultAfterLw: data.optionData.resultAfterLw || 0,
            resultAfterAFilter: data.optionData.resultAfterAFilter || 0,
        },
        defaultSoundPowerLevel: emptyOctaveBandRange(),
        soundPowerLevel: data.soundPowerLevel
            ? createOctaveBandRangeFromObject(data.soundPowerLevel)
            : emptyOctaveBandRange(),
        defaultSoundAttenuation: emptyOctaveBandRange(),
        soundAttenuation: data.soundAttenuation
            ? createOctaveBandRangeFromObject(data.soundAttenuation)
            : emptyOctaveBandRange(),
    };
}

export default transform;
