<template>
    <div class="viewport" v-if="canProceed">
        <NoContent v-if="noContent || !activeProject" />
        <ProjectBreakdown v-else></ProjectBreakdown>
    </div>
    <div v-else class="loader-container">
        <div class="loader big-loader"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectBreakdown from '@/components/projects/pdf/ProjectBreakdown.vue';
import * as ProjectService from '@/services/projects';
import NoContent from './NoContent.vue';

export default {
    name: 'PrintProject',
    components: {
        ProjectBreakdown,
        NoContent,
    },
    data() {
        return {
            noContent: false,
            canProceed: false,
        };
    },
    async created() {
        try {
            await this.obtainBasicData();
            const { query: { projectId = '' } = {} } = this.$route;
            if (!projectId) {
                this.noContent = true;
                return;
            }
            await this.processProjectId(projectId);
        } catch (error) {
            console.log(error);
        } finally {
            this.canProceed = true;
        }
    },
    computed: {
        ...mapGetters('activeProjectModule', ['activeProject']),
    },
    methods: {
        ...mapActions('userModule', ['initUser']),
        ...mapActions('silencersModule', ['getSilencers']),
        ...mapActions('diffusersModule', ['getDiffusers']),
        ...mapActions('abstractProductsModule', ['getAbstractProducts']),
        ...mapActions('silencerClassesModule', ['getSilencerClasses']),
        ...mapActions('diffuserClassesModule', ['getDiffuserClasses']),
        ...mapActions('abstractClassesModule', ['getAbstractClasses']),
        ...mapActions('activeProjectModule', ['setProject']),
        ...mapActions('systemsModule', ['getSystems', 'getPerSystemCalculations']),
        async obtainBasicData() {
            await this.initUser();
            await Promise.all([
                this.getSilencers(),
                this.getDiffusers(),
                this.getAbstractProducts(),
                this.getSilencerClasses(),
                this.getDiffuserClasses(),
                this.getAbstractClasses(),
            ]);
            this.canProceed = true;
        },
        async processProjectId(projectId) {
            try {
                if (!projectId) {
                    console.error('Project id is empty');
                    this.noContent = true;
                    return;
                }
                const project = await ProjectService.getProject(projectId);
                if (!project) {
                    console.error('Project has not been obtained');
                    this.noContent = true;
                    return;
                }
                await this.setProject(project);
                await this.getSystems({ projectId });
                await this.getPerSystemCalculations();
                setTimeout(() => {
                    window.print();
                }, 1000);
            } catch (error) {
                console.log(error);
                this.noContent = true;
            } finally {
                // URL.revokeObjectURL(objectUrl);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    .viewport {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        width:  fit-content;
        height: fit-content;
        padding: 0px;
        margin-top: -56px !important;
    }
</style>
