<template>
    <table>
        <thead>
            <th v-for="(header, index) in headers" :key="index" v-html="header.title" class="text-bold"></th>
        </thead>
        <tbody>
            <tr v-for="(product, index) in products" :key="index">
                <td class="text-left text-regular pl-4">{{ product.name }} {{ resolveTranslationMapValue(product.nameAppendix) }}</td>
                <td class="text-center text-regular">{{ product.vendorCode || '-' }}</td>
                <td class="text-center text-regular">{{ parseNumber({ value: product.inputAirflow }) }}</td>
                <td class="text-center text-regular">{{ parseNumber({ value: product.pressureDrop, toFixed: 3 }) }}</td>
                <td class="text-center text-regular">{{ Number(product.lwBeforeProduct).toFixed(2) }}</td>
                <td class="text-center text-regular">{{ Number(product.lwAfterProduct).toFixed(2) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'ProductInventoryTable',
    props: {
        products: {
            type: Array,
            required: true,
        },
    },
    inject: ['resolveTranslationMapValue'],
    data() {
        return {
            headers: [
                {
                    title: this.$t('pdf.productInventoryTable.productName'),
                },
                {
                    title: this.$t('pdf.productInventoryTable.productLabel'),
                },
                {
                    title: `[l/s]<br>${this.$t('pdf.productInventoryTable.flow')}`,
                },
                {
                    title: `[Pa]<br>${this.$t('pdf.productInventoryTable.pressureDrop')}`,
                },
                {
                    title: `dB(A)<br>${this.$t('pdf.productInventoryTable.lwBeforeProduct')}`,
                },
                {
                    title: `dB(A)<br>${this.$t('pdf.productInventoryTable.lwAfterProduct')}`,
                },
            ],
        };
    },
    methods: {
        parseNumber({ value, defaultValue = '-', toFixed = 0 }) {
            if (Number.isNaN(Number(value))) {
                return defaultValue;
            }

            return (toFixed > 0) ? Number(value).toFixed(toFixed) : Number(value);
        },
    },
}
</script>

<style scoped lang="scss">
    // @use 'src/assets/css/pdf/page.scss';
    @import 'src/assets/css/pdf/_variables.scss';

    table {
        width: 100%;
    }

    thead {
        background-color: $red-color;
        height: 48px;
        color: $white-color;
        font-weight: bold;
    }

    th, td {
        border-bottom: 1px solid #E0E3E7;
        min-width: 100px;
    }

    th {
        height: 48px;
        color: $white-color !important;
    }

    td {
        height: 58px;
        color: $gray-color !important;
    }
</style>