<template>
    <div class="product-quantity-selector" v-if="modelValue">
        <div class="controls" @click="decreaseQuantity" v-if="!readOnly">
            <div class="icon icon-medium icon-minus"></div>
        </div>
        <div class="quantity">{{ modelValue }}</div>
        <div class="controls" @click="increaseQuantity" v-if="!readOnly">
            <div class="icon icon-medium icon-plus"></div>
        </div>
    </div>
    <Button v-else type="primary" size="medium" @click="increaseQuantity">{{ $t('productsList.controls.add') }}</Button>
</template>

<script>
import Button from '../../general/Button.vue';

export default {
    name: 'ProductQuantitySelector',
    components: { Button },
    props: {
        modelValue: {
            type: Number,
            default: 0,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'change', 'increased', 'decreased'],
    methods: {
        decreaseQuantity() {
            if (this.modelValue === 0) {
                return;
            }
            this.$emit('update:modelValue', this.modelValue - 1);
            this.$emit('change', this.modelValue - 1);
            this.$emit('decreased');
        },
        increaseQuantity() {
            this.$emit('update:modelValue', this.modelValue + 1);
            this.$emit('change', this.modelValue + 1);
            this.$emit('increased');
        },
    },
}
</script>

<style scoped>
.product-quantity-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 128px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 100px;
    padding: 4px 8px;
}

.product-quantity-selector .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    width: 32px;
    height: 32px;
    font-family: Helvetica Neue;
    font-size: 28px;
    color: #737372;
}

.product-quantity-selector .quantity {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    font-family: Helvetica Neue;
    font-size: 28px;
    line-height: 33px;
    color: #737372;
    user-select: none;
}

.icon-minus:before, .icon-plus:before {
    width: 16px;
    height: 16px;
}
</style>
