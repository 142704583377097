<template>
    <div class="p-2">
        <p v-html="$t('rectSilencerModal.tooltips.connectionProfile.zProfile')"></p>
        <p v-html="$t('rectSilencerModal.tooltips.connectionProfile.e20')"></p>
        <p v-html="$t('rectSilencerModal.tooltips.connectionProfile.e30')"></p>
    </div>
</template>

<script>
export default {
    name: 'ConnectionProfile',
}
</script>