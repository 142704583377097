import { transformTranslationMap, emptyTranslationMap } from "../__general";

function transformDiffuserClass(diffuserClass) {
    if (!diffuserClass._id || !diffuserClass.name) {
        return null;
    }

    return {
        id: diffuserClass._id,
        name: diffuserClass.name,
        parentClassId: diffuserClass.parentClassId || null,
        title: diffuserClass.title ? transformTranslationMap(diffuserClass.title) : emptyTranslationMap(),
        imageURL: diffuserClass.imageURL,
    };
}

export default {
    transform(diffuserClasses) {
        const result = {};
        diffuserClasses.forEach((diffuserClass) => {
            const transformed = transformDiffuserClass(diffuserClass);
            if (!transformed) {
                return;
            }
            result[transformed.id] = transformed;
        });
        Object.keys(result).forEach((classId) => {
            const diffuserClass = result[classId];
            diffuserClass.parent = null;
            if (!diffuserClass.parentClassId) {
                return;
            }
            diffuserClass.parent = result[diffuserClass.parentClassId] || null;
        });

        return Object.values(result);
    }
}
