<template>
    <div class="product-properties">
        <div class="product-name">{{ product.name }} {{ resolveTranslationMapValue(product.nameAppendix) }}</div>
        <div class="property">
            <div class="property-name">{{ $t('productsList.productSpecs.diameter') }}, D:</div>
            <div class="property-value">{{ product.nominalSizeDiameter }} mm</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('productsList.productSpecs.length') }}, L:</div>
            <div class="property-value">{{ product.length }} mm</div>
        </div>
        <div class="property">
            <div class="property-name">{{ $t('productsList.productSpecs.absorptionMaterial') }}:</div>
            <div class="property-value">{{ $t(`productAttributes.attenuationMaterial.${product.attenuationMaterial.replace(" ", "")}`) }}</div>
        </div>
        <div class="property" v-if="product.inputAirflow !== undefined">
            <div class="property-name">{{ $t('rectSilencerData.inputAirflow') }}, l/s:</div>
            <div class="property-value"><b>{{ Math.round(inputAirflow) }}</b></div>
        </div>
        <div class="property" v-if="product.pressureDrop !== undefined">
            <div class="property-name">{{ $t('rectSilencerData.pressureDrop') }}, Pa:</div>
            <div class="property-value"><b :class="pressureDropClasses">{{ resolvePressureDropValue() }}</b></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SilencerProperties',
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
        pressureDrop: {
            type: Number,
            default: 0,
        },
    },
    inject: ['resolveTranslationMapValue'],
    computed: {
        ...mapState('mcadCalculationsModule', ['inputAirflow']),
        pressureDropClasses() {
            return {
                'exceeded-value': this.pressureDrop > 50,
            };
        },
    },
    methods: {
        resolvePressureDropValue() {
            if (this.product.class === 'NRFV' || this.product.class === 'NRPV') {
                return '-';
            }

            return Math.round(this.pressureDrop);
        },
    },
}
</script>

<style scoped lang="scss">
.exceeded-value {
    color: #8F0000 !important;
}
</style>
