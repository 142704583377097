<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ $t('projectSharingModal.modalTitle') }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="row justify-center colored-text">
                <p class="main-title">{{ $t('projectSharingModal.content.title') }}</p>
            </div>
            <div class="row justify-center colored-text mt-2">
                <p class="sub-title">{{ $t('projectSharingModal.content.fullAccessShared') }}</p>
            </div>
            <div class="row justify-center mt-4 colored-text">
                <InputField class="mr-8" placeholder="Email" :modelValue="inviteeEmail" @update:modelValue="setEmail"></InputField>
                <Button type="primary" size="medium" :disabled="!canInvite" @click="onInvite">{{ $t('projectSharingModal.actions.invite') }}</Button>
            </div>
            <div v-if="errorMessage" class="row mt-1"><i class="error-message">{{ errorMessage }}</i></div>
            <div v-if="project" class="row mt-4 colored-text"><b>{{ $t('projectSharingModal.content.sharedWith') }}</b></div>
            <div v-if="project" class="flex flex-col invitees-list mt-2 colored-text">
                <div v-if="project.creator" class="row">{{ project.creator.email }}&nbsp;<i>({{ $t('projectSharingModal.content.author') }})</i></div>
                <div v-else class="row"><i>{{ $t('projectSharingModal.content.authorUnknown') }}</i></div>
                <hr>
                <template v-for="user in project.invitedUsers" :key="user.id">
                    <div class="row justify-between items-center mt-2">
                        <p>
                            {{ user.email }}
                            <span v-if="user.email === email">&nbsp;<i>(You)</i></span>
                        </p>
                        <i class="pi pi-times cursor-pointer" @click="onRemoveUser(user)"></i>
                    </div>
                    <hr>
                </template>
            </div>
        </template>
        <template v-slot:modal-actions>
            <Button type="primary" outlined size="medium" @click="closeModal">{{ $t('projectSharingModal.actions.done') }}</Button>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import Button from '../general/Button.vue';
import Modal from '../general/Modal.vue';
import InputField from '../general/InputField.vue';

export default {
    name: 'InvitedUsersModal',
    components: {
        Button,
        Modal,
        InputField,
    },
    props: {
        modelValue: Boolean,
        project: {
            type: Object,
            required: false,
            default: null,
        },
    },
    emits: ['update:modelValue', 'closed'],
    data() {
        return {
            inviteeEmail: '',
            inviteeEmailValid: false,
            errorMessage: '',
            invitee: null,
        };
    },
    computed: {
        ...mapState('userModule', ['email']),
        canInvite() {
            if (!this.project || !this.invitee) {
                return false;
            }
            if (Array.isArray(this.project.invitedUsers)) {
                if (this.project.invitedUsers.find((user) => user.id === this.invitee.id)) {
                    return false;
                }
            }
            if (this.invitee.email === this.email) {
                return false;
            }

            return true;
        },
    },
    methods: {
        ...mapActions('userModule', ['lookup']),
        ...mapActions('projectsModule', ['addUser', 'removeUser']),
        confirmOperation() {
            this.$emit('onConfirm');
            this.closeModal();
        },
        declineOperation() {
            this.$emit('onDecline');
            this.closeModal();
        },
        closeModal() {
            this.$emit('update:modelValue', false);
            this.resetData();
            this.$emit('closed');
        },
        setEmail: debounce(async function (inviteeEmail) {
            this.invitee = null;
            this.errorMessage = '';
            if (!inviteeEmail || !this.project) {
                this.inviteeEmailValid = false;
                return;
            }
            // eslint-disable-next-line no-useless-escape
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inviteeEmail))) {
                this.inviteeEmailValid = false;
                this.errorMessage = this.$t('projectSharingModal.errors.invalidEmailFormat');
                return;
            }
            this.inviteeEmailValid = true;
            this.inviteeEmail = inviteeEmail;
            const invitee = await this.lookup(this.inviteeEmail);
            if (!invitee) {
                this.errorMessage = this.$t('projectSharingModal.errors.userDoesNotExist');

                return;
            }
            if (invitee.email === this.email) {
                this.errorMessage = this.$t('projectSharingModal.errors.cannotAddYourself');

                return;
            }
            if (Array.isArray(this.project.invitedUsers) && this.project.invitedUsers.length) {
                if (this.project.invitedUsers.find((user) => user.id === invitee.id)) {
                    this.errorMessage = this.$t('projectSharingModal.errors.alreadyInvited');

                    return;
                }
            }
            this.invitee = invitee;
        }, 300),
        resetData() {
            this.inviteeEmail = '';
            this.inviteeEmailValid = '';
            this.errorMessage = '';
            this.invitee = null;
        },
        async onInvite() {
            if (!this.project || !this.invitee) {
                return;
            }
            await this.addUser({
                project: this.project,
                user: this.invitee,
            });
            this.resetData();
        },
        async onRemoveUser(user) {
            if (!this.project || !user) {
                return;
            }
            await this.removeUser({
                project: this.project,
                user,
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.invitees-list {
    max-height: 200px;
    overflow-y: auto;
}

.error-message {
    color: rgb(145, 2, 2);
}

.main-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sub-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.colored-text {
    color: rgba(115, 115, 114, 1);
}
</style>