<template>
  <div :class="{ detailed: detailed }" class="w-full">
    <div v-if="silencers.length" class="products-list" :class="layoutClass">
      <template v-for="product in silencers" :key="product.id">
            <ProductCard class="mb-6" :direction="cardDirection">
                <template v-slot:product-image>
                    <img :src="resolveSilencerClassImage(product.class)" alt="Silencer Image">
                </template>
                <template v-slot:product-properties>
                    <SilencerProperties :product="product" class="mb-3"/>
                </template>
                <template v-slot:product-sound-adjustment>
                    <div class="action-link"></div>
                    <!-- <div class="action-link" @click="adjustSoundPower(product)">{{ $t('productsList.controls.adjustSoundPower') }}</div> -->
                    <!-- <div class="action-link" @click="adjustSoundAttenuation(product)">{{ $t('productsList.controls.attenuation') }}</div> -->
                </template>
                <template v-slot:product-actions>
                    <div class="row" :class="{ 'mt-5': layout === 'grid' }">
                        <div class="column mr-4">
                            <InputField
                                class="airflow-input"
                                placeholder="l/s"
                                type="number"
                                :title="`${$t('rectSilencerData.inputAirflow')} (l/s)`"
                                :modelValue="product.inputAirflow"
                                @update:modelValue="updateInputAirflow({ product, inputAirflow: $event })"
                            ></InputField>
                            <div v-if="product.pressureDrop" class="pressure-drop-value mt-2">{{ $t('rectSilencerData.pressureDrop') }}: {{ Math.round(product.pressureDrop) }} Pa</div>
                        </div>
                        <div class="column card-controls-container items-center">
                            <Button size="medium" type="primary" @click="onSelectProduct(product)">{{ $t('commonControls.add') }}</Button>
                            <div v-if="productQuantity(product)" class="quantity-badge">{{ productQuantity(product) }}</div>
                            <div class="sound-data-control action-link mt-2" @click="openSilencerEditingModal(product)">{{ $t('productsList.productSpecs.soundData') }}</div>
                        </div>
                    </div>

                    <!-- <div class="column align-items-center" :class="{ 'mt-5': layout === 'grid' }">
                        <div class="row pr-0 items-center card-controls-container">
                            <InputField
                                class="airflow-input mr-4"
                                placeholder="l/s"
                                type="number"
                                :title="`${$t('rectSilencerData.inputAirflow')} (l/s)`"
                                :modelValue="product.inputAirflow"
                                @update:modelValue="updateInputAirflow({ product, inputAirflow: $event })"
                            ></InputField>
                            <Button size="medium" type="primary" @click="onSelectProduct(product)">{{ $t('commonControls.add') }}</Button>
                            <div v-if="productQuantity(product)" class="quantity-badge">{{ productQuantity(product) }}</div>
                        </div>
                        <div class="row mt-2">
                            <div v-if="product.pressureDrop" class="pressure-drop-value mr-4">{{ $t('rectSilencerData.pressureDrop') }}: {{ Math.round(product.pressureDrop) }} Pa</div>
                            <div class="sound-data-control action-link" @click="openSilencerEditingModal(product)">Sound data</div>
                        </div>
                    </div> -->
                        <!-- <ProductQuantitySelector :modelValue="productQuantity(product)" @increased="onSelectProduct(product)" @decreased="onUnselectProduct(product)"></ProductQuantitySelector> -->
                </template>
            </ProductCard>
        </template>
    </div>
    <OctaveBandAdjustmentModal
        v-model="adjustmentModalOpened"
        :title="adjustmentModalTitle"
        :productId="adjustingProductId"
        :productName="adjustingProductName"
        :defaultOctaveBandRange="adjustingDefaultOctaveBandRange"
        :octaveBandRange="adjustingOctaveBandRange"
        :readOnly="readOnlyMode"
        @close="onAdjustmentModalClosed"
        @change="onOctaveBandRangeChanged"
    ></OctaveBandAdjustmentModal>
    <EditSilencerModal
        v-model="editSilencerModalOpened"
        :product="adjustingProduct"
        @confirm="onConfirmInputAirflow"
        @close="adjustingProduct = null"
    ></EditSilencerModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InputField from '@/components/general/InputField.vue';
import Button from '@/components/general/Button.vue';
import ProductCard from '@/components/products/product/ProductCard.vue';
// import ProductQuantitySelector from '@/components/products/product/ProductQuantitySelector.vue';
import SilencerProperties from '@/components/products/silencers/SilencerProperties.vue';
import OctaveBandAdjustmentModal from '@/components/products/OctaveBandAdjustmentModal.vue';
import EditSilencerModal from '@/components/products/silencers/EditSilencerModal.vue';
import { isOctaveBandRangeEmpty } from '@/utils';
// import * as EOApiPlugin from '@/plugins/essential-objects/EOApiPlugin.js';

// eslint-disable-next-line no-unused-vars
const debounce = require('lodash.debounce');


export default {
    name: 'SilencersList',
    components: {
        InputField,
        Button,
        ProductCard,
        // ProductQuantitySelector,
        SilencerProperties,
        OctaveBandAdjustmentModal,
        EditSilencerModal,
    },
    props: {
        silencers: {
            type: Array,
            default: () => ([]),
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        detailed: {
          type: Boolean,
          default: false,
        },
        layout: {
            type: String,
            default: 'linear',
            validator: (value) => (['linear', 'grid'].includes(value)),
        },
    },
    data() {
        return {
            adjustmentModalOpened: false,
            editSilencerModalOpened: false,
            adjustmentModalTitle: '',
            adjustmentType: '',
            adjustingProduct: null,
            readOnlyMode: false,
        };
    },
    computed: {
        ...mapState('activeSystemModule', ['productQuantitiesMap']),
        ...mapGetters('silencerClassesModule', ['imagesMap']),
        adjustingDefaultOctaveBandRange() {
            if (!this.adjustingProduct || !this.adjustmentType) {
                return null;
            }

            return this.adjustmentType === 'soundPower'
                ? this.adjustingProduct.defaultSoundPowerLevel
                : this.adjustingProduct.defaultSoundAttenuation;
        },
        adjustingOctaveBandRange() {
            if (!this.adjustingProduct || !this.adjustmentType) {
                return null;
            }
            
            return this.adjustmentType === 'soundPower'
                ? this.adjustingProduct.soundPowerLevel
                : this.adjustingProduct.soundAttenuation;
        },
        adjustingProductId() {
            if (!this.adjustingProduct) {
                return '';
            }

            return this.adjustingProduct.id;
        },
        adjustingProductName() {
            if (!this.adjustingProduct) {
                return '';
            }

            return this.adjustingProduct.name;
        },
        layoutClass() {
            return {
                'linear-layout': this.layout === 'linear',
                'grid-layout': this.layout === 'grid',
            };
        },
        cardDirection() {
            return this.layout === 'linear' ? 'horizontal' : 'vertical';
        },
    },
    methods: {
        ...mapActions('silencersModule', [
            'setSoundPowerLevel',
            'setSoundAttenuation',
            'setInputAirflow',
        ]),
        ...mapActions('activeSystemModule', [
            'addProduct',
            'removeProductById',
            'calculateSummary',
            'saveSystem',
        ]),
        adjustSoundPower(product) {
            this.adjustingProduct = product;
            this.adjustmentModalTitle = this.$t('octaveBandAdjustmentModal.adjustSoundPower');
            this.adjustmentType = 'soundPower';
            this.readOnlyMode = false;
            this.adjustmentModalOpened = true;
        },
        adjustSoundAttenuation(product) {
            this.adjustingProduct = product;
            this.adjustmentModalTitle = this.$t('octaveBandAdjustmentModal.attenuation');
            this.adjustmentType = 'attenuation';
            this.readOnlyMode = !isOctaveBandRangeEmpty(this.adjustingDefaultOctaveBandRange);
            this.adjustmentModalOpened = true;
        },
        onAdjustmentModalClosed() {
            this.adjustingProduct = null;
            this.adjustmentModalTitle = '';
            this.readOnlyMode = false;
            this.adjustmentType = '';
        },
        async onOctaveBandRangeChanged({ productId, octaveBandRange }) {
            if (!this.adjustmentType) {
                return;
            }
            if (this.adjustmentType === 'soundPower') {
                await this.setSoundPowerLevel({ silencerId: productId, soundPowerLevel: octaveBandRange });
            }
            else {
                await this.setSoundAttenuation({ silencerId: productId, soundAttenuation: octaveBandRange });
            }
            await this.saveSystem();
            await this.calculateSummary();
        },
        async onSelectProduct(product) {
            // if (!this.productQuantity(product)) {
            //     this.adjustingProduct = product;
            //     this.editSilencerModalOpened = true;
            // }

            // if (this.$route.matched.length && this.$route.matched[0].name === 'modelling') {
            //     await EOApiPlugin.insertProduct(product);
            // } else {
            //     this.selectProduct(product);
            // }
            this.selectProduct(product);
        },
        async onConfirmInputAirflow(data) {
            if (!data || !this.adjustingProduct) {
                return;
            }
            const { inputAirflow, pressureDrop } = data;
            if (inputAirflow === undefined || pressureDrop === undefined) {
                return;
            }
            await this.addProduct({
                product: this.adjustingProduct,
                inputAirflow,
                pressureDrop,
            });
            this.adjustingProduct = null;
        },
        selectProduct(product) {
            if (!product) {
                return;
            }
            this.addProduct({ product });
        },
        // onSelectProduct(product) {
        //     if (!product) {
        //         return;
        //     }
        //     this.addProduct({ product });
        // },
        onUnselectProduct(product) {
            if (!product || !product.id) {
                return;
            }
            this.removeProductById({ productId: product.id });
        },
        resolveSilencerClassImage(silencerClass) {
            return this.imagesMap[silencerClass] || '';
        },
        productQuantity(product) {
            if (!product || !product.id || !this.productQuantitiesMap) {
                return 0;
            }
            if (product.id in this.productQuantitiesMap) {
                return this.productQuantitiesMap[product.id];
            }

            return 0;
        },
        openSilencerEditingModal(product) {
            if (!product) {
                return;
            }
            this.adjustingProduct = product;
            this.editSilencerModalOpened = true;
        },
        updateInputAirflow: debounce(async function ({ product, inputAirflow }) {
            if (!product || !product.id) {
                return;
            }
            if (Number.isNaN(Number(inputAirflow))) {
                return;
            }
            await this.setInputAirflow({
                silencer: product,
                inputAirflow: Number(inputAirflow),
            });
        }, 600),
    },
}
</script>

<style lang="scss" scoped>
    .card-controls-container {
        position: relative;
    }

    .airflow-input {
        width: 100px;
        margin-top: -23px; // height of the input's label
    }
    
    .quantity-badge {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        min-width: 18px;
        min-height: 18px;
        border: 2px solid #737372;
        color: #737372;
        border-radius: 50%;
        right: -5px;
        top: -23px;
        font-size: 12px;
        line-height: 14px;
    }

    // .sound-data-control {
    //     align-self: end;
    // }

    .pressure-drop-value {
        font-family: Helvetica Neue Bold;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #4C5055;
    }

    .grid-layout {
        .airflow-input {
            flex: 0 1 auto;
        }
    }
</style>
