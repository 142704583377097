<template>
    <OctaveBandRangeEditor
        :modelValue="inputSoundPower"
        @update:modelValue="onUpdateInputSoundPower"
    >
        <template v-slot:appendix>
            <div class="ml-4 flex flex-col total-value">
                <p class="mb-3 total-value-title"><b>dB(A)</b></p>
                <p>{{ totalSoundPowerLwA }}</p>
            </div>
        </template>
    </OctaveBandRangeEditor>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OctaveBandRangeEditor from '@/components/products/product/OctaveBandRangeEditor.vue';

const debounce = require('lodash.debounce');


export default {
    name: 'InputSoundPowerEditor',
    components: {
        OctaveBandRangeEditor,
    },
    computed: {
        ...mapState('mcadCalculationsModule', ['inputSoundPower', 'inputSoundPowerCalculationResults']),
        totalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.inputSoundPowerCalculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return 0;
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA) || 0;
        },
    },
    methods: {
        ...mapActions('mcadCalculationsModule', ['setInputSoundPower']),
        ...mapActions('rectSilencerModule', { 'setRectSilencerInputSoundPower': 'setInputSoundPower' }),
        onUpdateInputSoundPower: debounce(async function (inputSoundPower) {
            await this.setInputSoundPower(inputSoundPower);
            await this.setRectSilencerInputSoundPower(inputSoundPower);
        }, 500),
    },
}
</script>

<style lang="scss" scoped>
.total-value-title {
    margin-top: -4px;
    color: #4C5055;
}
</style>