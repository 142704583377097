<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ title }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="header-container">
                <div class="text-medium text-center mr-4">{{ $t('octaveBandAdjustmentModal.octaveBand') }} (Hz)</div>
                <div class="column">
                    <div class="text-medium text-right">{{ productName }}</div>
                    <div class="text-small text-right">{{ $t('octaveBandAdjustmentModal.attenuation') }} dB</div>
                </div>
            </div>
            <div class="row no-wrap mt-4">
                <span class="title">63</span>
                <InputField type="number" :modelValue="Math.round(octaveBand63Hz)" @update:modelValue="setOctaveBandValue('octaveBand63Hz', $event)" :readOnly="readOnly" placeholder="0"></InputField>
            </div>
            <div class="row no-wrap mt-4">
                <span class="title">125</span>
                <InputField type="number" :modelValue="Math.round(octaveBand125Hz)" @update:modelValue="setOctaveBandValue('octaveBand125Hz', $event)" :readOnly="readOnly" placeholder="0"></InputField>
            </div>
            <div class="row no-wrap mt-4">
                <span class="title">250</span>
                <InputField type="number" :modelValue="Math.round(octaveBand250Hz)" @update:modelValue="setOctaveBandValue('octaveBand250Hz', $event)" :readOnly="readOnly" placeholder="0"></InputField>
            </div>
            <div class="row no-wrap mt-4">
                <span class="title">500</span>
                <InputField type="number" :modelValue="Math.round(octaveBand500Hz)" @update:modelValue="setOctaveBandValue('octaveBand500Hz', $event)" :readOnly="readOnly" placeholder="0"></InputField>
            </div>
            <div class="row no-wrap mt-4">
                <span class="title">1000</span>
                <InputField type="number" :modelValue="Math.round(octaveBand1000Hz)" @update:modelValue="setOctaveBandValue('octaveBand1000Hz', $event)" :readOnly="readOnly" placeholder="0"></InputField>
            </div>
            <div class="row no-wrap mt-4">
                <span class="title">2000</span>
                <InputField type="number" :modelValue="Math.round(octaveBand2000Hz)" @update:modelValue="setOctaveBandValue('octaveBand2000Hz', $event)" :readOnly="readOnly" placeholder="0"></InputField>
            </div>
            <div class="row no-wrap mt-4">
                <span class="title">4000</span>
                <InputField type="number" :modelValue="Math.round(octaveBand4000Hz)" @update:modelValue="setOctaveBandValue('octaveBand4000Hz', $event)" :readOnly="readOnly" placeholder="0"></InputField>
            </div>
            <div class="row no-wrap mt-4">
                <span class="title">8000</span>
                <InputField type="number" :modelValue="Math.round(octaveBand8000Hz)" @update:modelValue="setOctaveBandValue('octaveBand8000Hz', $event)" :readOnly="readOnly" placeholder="0"></InputField>
            </div>
        </template>
        <template v-slot:modal-actions>
            <div class="column">
                <div class="row">
                    <InlineDialog type="success">{{ $t('octaveBandAdjustmentModal.projectLevelChanges') }}</InlineDialog>
                </div>
                <div class="row mt-4 justify-flex-end">
                    <Button v-if="!readOnly" type="secondary" size="medium" outlined class="mr-4 flex-50" @click="resetToDefault">{{ $t('octaveBandAdjustmentModal.resetToDefault') }}</Button>
                    <Button v-if="!readOnly" type="primary" size="medium" class="flex-50" @click="applyChanges">{{ $t('octaveBandAdjustmentModal.set') }}</Button>
                    <Button v-if="readOnly" type="secondary" outlined size="medium" @click="closeModal">{{ $t('octaveBandAdjustmentModal.close') }}</Button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import InputField from '../general/InputField.vue';
import Button from '../general/Button.vue';
import Modal from '../general/Modal.vue';
import InlineDialog from '../general/InlineDialog.vue';

export default {
    name: 'OctaveBandAdjustmentModal',
    components: {
        InputField,
        Button,
        Modal,
        InlineDialog,
    },
    props: {
        modelValue: Boolean,
        productId: String,
        productName: String,
        title: String,
        defaultOctaveBandRange: Object,
        octaveBandRange: Object,
        readOnly: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:modelValue', 'change', 'close'],
    data() {
        return {
            octaveBand63Hz: 0,
            octaveBand125Hz: 0,
            octaveBand250Hz: 0,
            octaveBand500Hz: 0,
            octaveBand1000Hz: 0,
            octaveBand2000Hz: 0,
            octaveBand4000Hz: 0,
            octaveBand8000Hz: 0,
        };
    },
    methods: {
        closeModal() {
            this.$emit('update:modelValue', false);
            this.$emit('close');
        },
        syncOctaveBandRange(octaveBandRange) {
            if (!octaveBandRange) {
                return;
            }
            this.octaveBand63Hz = Number(octaveBandRange.octaveBand63Hz);
            this.octaveBand125Hz = Number(octaveBandRange.octaveBand125Hz);
            this.octaveBand250Hz = Number(octaveBandRange.octaveBand250Hz);
            this.octaveBand500Hz = Number(octaveBandRange.octaveBand500Hz);
            this.octaveBand1000Hz = Number(octaveBandRange.octaveBand1000Hz);
            this.octaveBand2000Hz = Number(octaveBandRange.octaveBand2000Hz);
            this.octaveBand4000Hz = Number(octaveBandRange.octaveBand4000Hz);
            this.octaveBand8000Hz = Number(octaveBandRange.octaveBand8000Hz);
        },
        resetToZeroOctaveBandRange() {
            this.octaveBand63Hz = 0;
            this.octaveBand125Hz = 0;
            this.octaveBand250Hz = 0;
            this.octaveBand500Hz = 0;
            this.octaveBand1000Hz = 0;
            this.octaveBand2000Hz = 0;
            this.octaveBand4000Hz = 0;
            this.octaveBand8000Hz = 0;
        },
        resetToDefaultOctaveBandRange() {
            if (!this.defaultOctaveBandRange) {
                this.resetToZeroOctaveBandRange();
                return;
            }
            this.octaveBand63Hz = Number(this.defaultOctaveBandRange.octaveBand63Hz);
            this.octaveBand125Hz = Number(this.defaultOctaveBandRange.octaveBand125Hz);
            this.octaveBand250Hz = Number(this.defaultOctaveBandRange.octaveBand250Hz);
            this.octaveBand500Hz = Number(this.defaultOctaveBandRange.octaveBand500Hz);
            this.octaveBand1000Hz = Number(this.defaultOctaveBandRange.octaveBand1000Hz);
            this.octaveBand2000Hz = Number(this.defaultOctaveBandRange.octaveBand2000Hz);
            this.octaveBand4000Hz = Number(this.defaultOctaveBandRange.octaveBand4000Hz);
            this.octaveBand8000Hz = Number(this.defaultOctaveBandRange.octaveBand8000Hz);
        },
        emitChangeEvent() {
            this.$emit('change', {
                productId: this.productId,
                octaveBandRange: {
                    octaveBand63Hz: Number(this.octaveBand63Hz),
                    octaveBand125Hz: Number(this.octaveBand125Hz),
                    octaveBand250Hz: Number(this.octaveBand250Hz),
                    octaveBand500Hz: Number(this.octaveBand500Hz),
                    octaveBand1000Hz: Number(this.octaveBand1000Hz),
                    octaveBand2000Hz: Number(this.octaveBand2000Hz),
                    octaveBand4000Hz: Number(this.octaveBand4000Hz),
                    octaveBand8000Hz: Number(this.octaveBand8000Hz),
                },
            });
        },
        applyChanges() {
            this.emitChangeEvent();
            this.closeModal();
        },
        resetToDefault() {
            this.resetToDefaultOctaveBandRange();
            this.emitChangeEvent();
            // this.closeModal();
        },
        setOctaveBandValue(octaveBand, value) {
            if (Number.isNaN(Number(value)) || !(octaveBand in this)) {
                return;
            }
            this[octaveBand] = Number(value);
        },
    },
    watch: {
        octaveBandRange: {
            deep: true,
            handler(octaveBandRange) {
                this.syncOctaveBandRange(octaveBandRange);
            }
        }
    }
}
</script>

<style scoped>
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    border-top: 1px solid #CC0000;
    border-bottom: 1px solid #CC0000;
    padding: 8px 0;
}

.header-container div {
    flex: 1 1 auto;
    color: #737372;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.no-wrap {
    flex-wrap: nowrap;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex: 1 1 auto;
    width: 140px;
}

.input-container {
    max-width: 220px;
}

.flex-50 {
    flex: 1 1 50%;
}

.justify-flex-end {
    justify-content: flex-end;
}
</style>