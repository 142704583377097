export function emptyTranslationMap() {
    return {
        estonian: '',
        english: '',
        finnish: '',
        danish: '',
        swedish: '',
    };
}

export function transformTranslationMap(translationMap) {
    if (!translationMap) {
        return emptyTranslationMap();
    }

    return {
        estonian: translationMap.est || '',
        english: translationMap.eng || '',
        finnish: translationMap.fin || '',
        danish: translationMap.den || '',
        swedish: translationMap.swe || '',
    };
}
