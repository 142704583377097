import { render } from "./ProductsList.vue?vue&type=template&id=1a87e061&scoped=true"
import script from "./ProductsList.vue?vue&type=script&lang=js"
export * from "./ProductsList.vue?vue&type=script&lang=js"

import "./ProductsList.vue?vue&type=style&index=0&id=1a87e061&lang=scss"
import "./ProductsList.vue?vue&type=style&index=1&id=1a87e061&scoped=true&lang=css"
import "./ProductsList.vue?vue&type=style&index=2&id=1a87e061&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1a87e061"

export default script