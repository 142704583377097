<template>
    <Modal :modelValue="true" :showOverlap="false" :showCloseIcon="false" size="medium">
        <template v-slot:modal-header>
            <div class="col">
                <div class="row">{{ productCode }}</div>
                <div class="row text-small sub-header">{{
                        $t('rectSilencerModal.silencerOptionsView.recommendationsAreBelow')
                }}</div>
                <div class="row mt-2">
                    <div class="tabs-header">
                        <div class="tab-header active-tab-header">{{ $t('octaveBandAdjustmentModal.general') }}</div>
                        <div class="tab-header disabled-header">{{ $t('projectBreakdown.soundPower') }}</div>
                        <div class="tab-header disabled-header">{{ $t('projectBreakdown.attenuation') }}</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:modal-content>
            <SilencerOptions mode="modelling" @selectedOptionChanged="selectedOptionIndex = $event.index" />
        </template>
        <template v-slot:modal-actions>
            <div class="row p-0">
                <Button outlined size="medium" class="mr-4" @click="goBack">{{ $t('commonControls.back') }}</Button>
                <Button size="medium" :disabled="selectedOptionIndex < 0" @click="exportData">Export to MagiCAD</Button>
                <!-- <Button v-if="mode === 'create'" size="medium" :disabled="selectedOptionIndex < 0" @click="addProduct">{{ $t('commonControls.add') }}</Button> -->
                <!-- <Button v-if="mode === 'edit'" size="medium" :disabled="selectedOptionIndex < 0" @click="replaceProduct">{{ $t('commonControls.edit') }}</Button> -->
            </div>
        </template>
    </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/general/Modal.vue';
import SilencerOptions from '@/components/products/rect-silencers/modal-parts/SilencerOptions.vue';
import Button from '@/components/general/Button.vue';
import { insertProduct } from '@/plugins/essential-objects/EOApiPlugin';

export default {
    name: 'SilencerOptionsView',
    components: {
        Modal,
        SilencerOptions,
        Button,
    },
    emits: ['detailsSelectionRequested', 'switchTab'],
    data() {
        return {
            selectedOptionIndex: -1,
        };
    },
    computed: {
        ...mapGetters('rectSilencerModule', ['rectSilencer', 'productCode']),
    },
    methods: {
        goBack() {
            this.$emit('detailsSelectionRequested')
        },
        switchTab(tabName) {
            this.$emit('switchTab', tabName);
        },
        async exportData() {
            if (!this.rectSilencer || !this.rectSilencer.id) {
                return;
            }
            console.log(this.rectSilencer);
            await insertProduct(this.rectSilencer);
        },
    },
}
</script>

<style lang="scss" scoped>
.sub-header {
    color: #737372;
    font-weight: bold;
}

.row {
    &.option-data {
        width: 100%;
        justify-content: center;
    }
}

.col {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 150px;

    &:first-of-type {
        min-width: 200px;
    }

    .row {
        padding: 0;
    }
}

.font-bold {
    font-family: Helvetica Neue;
    // font-family: Helvetica Neue Bold;
    // color: #737372;
}

.tabs-header {
    margin-bottom: -16px;
}

.tab-header.disabled-header {
    color: #D5D6D5;
}
</style>