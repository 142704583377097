<template>
    <div class="auth-container">
        <div class="images-container">
            <img :style="{ 'background-image': `url(${imageUrl})` }" class="background-image"/>
            <!-- <img src="@/assets/logo_small_white.png" class="logo-image"/> -->
        </div>
        <div class="auth-form-container">
            <div class="auth-form">
                <p class="heading-large-bold pb-10" v-if="title">{{ title }}</p>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
const IMAGE_URL = require(`@/assets/auth_background_2.png`);

export default {
    name: 'AuthContainer',
    props: {
        title: String,
    },
    data() {
        return {
            imageUrl: IMAGE_URL,
        };
    },
}
</script>

<style lang="scss">
.auth-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100vw;
    height: 100vh;

    .images-container {
        display: flex;
        flex: 1 1 65%;
        flex-direction: column;
        align-items: center;

        img.background-image {
            display: flex;
            position: relative;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: unset;
        }

        img.logo-image {
            display: flex;
            position: absolute;
            margin-top: 20%;
        }
    }

    .auth-form-container {
        display: flex;
        flex: 1 1 35%;
        flex-direction: column;
        align-items: center;

        .auth-form {
            display: flex;
            flex-direction: column;
            padding: 115px 52px 0 52px;
            align-items: center;

            .heading-large-bold {
                color: #4C5055;
            }

            .input-container {
                width: 100%;
            }

            .row {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                width: 100%;

                &.justify-space-between {
                    justify-content: space-between;
                }

                &.align-center {
                    align-items: center;
                }
            }

            .link {
                text-decoration-line: underline;
                color: #4D91D1;
            }
        }

        @media screen and (min-width: 1281px) {
            .auth-form {
                width: 75%;
            }
        }

        @media screen and (max-width: 1280px) {
            .auth-form {
                width: 85%;
            }
        }
    }
}
</style>