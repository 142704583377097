<template>
    <OctaveBandContainer
        :title="title"
        :powerLevelKit="inputSoundPower"
        lwaTitle="db(A)"
        :lwa="totalSoundPowerLwA"
    ></OctaveBandContainer>
</template>

<script>
import { mapState } from 'vuex';
import OctaveBandContainer from '@/components/products/product/OctaveBandContainer.vue';

export default {
    name: 'InputSoundPowerContainer',
    components: {
        OctaveBandContainer,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        ...mapState('mcadCalculationsModule', ['inputSoundPower', 'inputSoundPowerCalculationResults']),
        totalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.inputSoundPowerCalculationResults || {};
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return 0;
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA) || 0;
        },
    },
}
</script>
