<template>
    <div class="header">
        <div class="logo-container">
          <router-link :to="{ name: 'projects' }">
            <img class="header-logo" src="../../assets/logo_small.svg" alt="Logo Small">
          </router-link>
        </div>
        <div class="controls-container">
            <Select class="language-selector" :options="languages" :modelValue="locale" @update:modelValue="changeLocale"></Select>
            <span v-if="showAuthOptions">
                <Button v-if="userAuthorized" outlined type="secondary" size="small" class="ml-4" @click="onSignOut" :disabled="requestPending">
                    <div v-if="requestPending" class="loader small-loader"></div>
                    <span v-else>{{ $t('commonControls.signOut') }}</span>
                </Button>
                <Button outlined type="secondary" size="small" class="ml-4" @click="useSSO" v-else>{{ $t('commonControls.signIn') }}</Button>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Select from './Select.vue';
import Button from './Button';
// import { isIncognito } from '../../utils';

export default {
    name: 'Header',
    components: {
        Select,
        Button,
    },
    props: {
        showAuthOptions: {
            type: Boolean,
            default: true,
        },
    },
    created() {
        this.locale = localStorage.getItem('locale') || 'english';
    },
    data() {
        return {
            locale: '',
            languages: [
                {
                    title: 'English',
                    value: 'english',
                },
                {
                    title: 'Eesti',
                    value: 'estonian',
                },
                {
                    title: 'Svenska',
                    value: 'swedish',
                },
                {
                    title: 'Suomi',
                    value: 'finnish',
                },
                {
                    title: 'Dansk',
                    value: 'danish',
                },
            ],
            requestPending: false,
        };
    },
    computed: {
        ...mapGetters('userModule', ['userAuthorized']),
    },
    methods: {
        ...mapActions('userModule', ['signOut']),
        changeLocale(locale) {
            localStorage.setItem('locale', locale);
            this.locale = locale;
            this.$i18n.locale = locale;
        },
        async useSSO() {
            // const browserUsesIncognito = await isIncognito();
            // if (!navigator || !navigator.cookieEnabled || browserUsesIncognito) {
            //     alert('Sign In is not available because you view the page in Incognito Mode or Cookies are disabled in your browser. For security reasons, and in order to let you Sign In, please open this page in Regular Mode or enable Cookies in your browser.');

            //     return;
            // }
            // const redirectUrl = btoa(window.location.href);
            // const returnUrl = btoa(`${window.location.origin}/authorize?redirectUrl=${redirectUrl}`);
            localStorage.setItem('redirectUrl', window.location.href);
            const returnUrl = btoa(`${window.location.origin}/authorize`);
            window.location.href = `https://login.etsnord.com/?language=en&returnurl=${returnUrl}`;
        },
        toSignIn() {
            this.$router.push({ name: 'sign-in' });
        },
        async onSignOut() {
            try {
                this.requestPending = true;
                await this.signOut();
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },
    }
}
</script>

<style scoped>
.language-selector {
    width: 100px;
    flex: 0 1 auto;
}

.header {
    position: fixed;
    display: flex;
    width: 100%;
    height: 56px;
    top: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    padding: 12px 120px;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    z-index: 11;
}

.logo-container {
    display: flex;
    flex: 0 1 auto;
}

.controls-container {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: flex-end;
}

.header-logo {
    width: 175px;
    height: 24px;
}
</style>
