import { apiService } from '@/services/general/APIService';

const PROJECTS_ENDPOINT = 'webshop/projects';
const EXPORT_ENDPOINT = 'webshop/export-systems';

export async function getProjectData(projectId) {
    if (!projectId) {
        throw new Error('Project id is empty');
    }
    const response = await apiService.get(`${PROJECTS_ENDPOINT}/${projectId}`, {
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    });
    if (!response || !response.data || !response.data.success || !response.data.data) {
        throw new Error('External project data has not been obtained');
    }

    return response.data.data;
}

function resolveLocale() {
    const locale = localStorage.getItem('locale');
    switch (locale) {
        case 'english':
            return 'eng';
        case 'estonian':
            return 'est';
        case 'swedish':
            return 'swe';
        case 'finnish':
            return 'fin';
        case 'danish':
            return 'den';
        default:
            return 'eng';
    }
}

export async function exportSystems(projectId, systems) {
    if (!projectId) {
        throw new Error('Project id is empty');
    }
    if (!Array.isArray(systems) || !systems.length) {
        throw new Error('Array of systems is required');
    }
    const systemIds = systems.map((system) => system.id);
    const response = await apiService.post(EXPORT_ENDPOINT, {
        projectId,
        systemIds,
        locale: resolveLocale(),
    }, {
        withCredentials: true,
        credentials: 'include',
    });
    if (!response || !response.data || !response.data.success) {
        throw new Error('Systems have not been exported');
    }
}
