<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ $t('commonCaptions.confirmAction') }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="row justify-center">
                <div class="icon icon-warning"></div>
            </div>
            <div class="row justify-center">
                <div class="text-medium">{{ $t('deleteProjectModal.deleteProject') }}</div>
            </div>
            <div class="row justify-center">
                <div class="text">{{ $t('deleteProjectModal.areYouSure') }}</div>
            </div>
        </template>
        <template v-slot:modal-actions>
            <Button type="secondary" size="medium" outlined class="mr-4" @click="declineOperation">{{ $t('commonControls.cancel') }}</Button>
            <Button type="primary" size="medium" @click="confirmOperation">{{ $t('deleteProjectModal.confirmDeleting') }}</Button>
        </template>
    </Modal>
</template>

<script>
import Button from '../general/Button.vue';
import Modal from '../general/Modal.vue';

export default {
    name: 'DeleteConfirmationModal',
    components: {
        Button,
        Modal,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue', 'onConfirm', 'onDecline'],
    methods: {
        confirmOperation() {
            this.$emit('onConfirm');
            this.closeModal();
        },
        declineOperation() {
            this.$emit('onDecline');
            this.closeModal();
        },
        closeModal() {
            this.$emit('update:modelValue', false);
        },
    },
}
</script>

<style scoped>
.icon-warning {
    font-size: 120px;
}

.icon-warning:before {
    color: #FFDC00;
}
</style>
