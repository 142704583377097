<template>
    <InputField v-model="email" :title="$t('signIn.email')" :placeholder="$t('signIn.email')" class="mb-6"></InputField>
    <InputField v-model="password" :title="$t('signIn.password')" :placeholder="$t('signIn.password')" type="password" class="mb-6"></InputField>
    <!-- <div class="row justify-space-between align-center mb-8 p-0">
        <Checkbox :label="$t('signIn.rememberMe')"></Checkbox>
        <a href="javascript:void(0)" class="text-small">{{ $t('signIn.forgotPassword') }}</a>
    </div> -->
    <div class="row mb-8 p-0 items-center justify-between">
        <Button :disabled="!canSignIn || requestPending" @click="login">
            <span v-if="!requestPending">{{ $t('signIn.login') }}</span>
            <div v-else class="loader"></div>
        </Button>
        <p class="action-link text-center" @click="useSSO">or use SSO</p>
    </div>
    <!-- <div class="row p-0">
        <span>{{ $t('signIn.dontHaveAccount') }}</span>&nbsp;<a href="javascript:void(0)" @click="toSignUp" class="link">{{ $t('signIn.registerHere') }}</a>
    </div> -->
    <InlineDialog class="row" type="error" v-if="errorMessage">{{ errorMessage }}</InlineDialog>
</template>

<script>
import { mapActions } from 'vuex';
import InputField from '@/components/general/InputField.vue';
// import Checkbox from '@/components/general/Checkbox.vue';
import Button from '@/components/general/Button.vue';
import InlineDialog from '@/components/general/InlineDialog.vue';
// import { isIncognito } from '../../utils';

export default {
    name: 'SignInForm',
    components: { InputField, Button, InlineDialog },
    data() {
        return {
            email: '',
            password: '',
            errorMessage: '',
            requestPending: false,
        };
    },
    computed: {
        canSignIn() {
            return this.email && this.password;
        },
    },
    methods: {
        ...mapActions('userModule', ['signIn']),
        toSignUp() {
            this.$router.push({ name: 'sign-up' });
        },
        async login() {
            try {
                if (!this.canSignIn) {
                    return;
                }
                this.requestPending = true;
                this.errorMessage = '';
                await this.signIn({
                    email: this.email,
                    password: this.password,
                });
            } catch (error) {
                console.error(error);
                this.errorMessage = this.getErrorMessage(error.code);
            } finally {
                this.requestPending = false;
            }
        },
        getErrorMessage(errorCode) {
            if (!errorCode) {
                return this.$t('signIn.authFailed');
            }
            switch (errorCode) {
                case 1000:
                    return this.$t('signIn.authFailed');
                case 1001:
                    return this.$t('signIn.userNotFound');
                case 1002:
                    return this.$t('signIn.wrongPassword');
                default:
                    return this.$t('signIn.authFailed');
            }
        },
        async useSSO() {
            // const browserUsesIncognito = await isIncognito();
            // if (!navigator || !navigator.cookieEnabled || browserUsesIncognito) {
            //     alert('Sign In is not available because you view the page in Incognito Mode or Cookies are disabled in your browser. For security reasons, and in order to let you Sign In, please open this page in Regular Mode or enable Cookies in your browser.');

            //     return;
            // }
            const returnUrl = btoa(`${window.location.origin}/authorize`);
            window.location.href = `https://login.etsnord.com/?language=en&returnurl=${returnUrl}`;
        },
    },
}
</script>

<style lang="scss" scoped>
.loader {
    width: 20px;
    height: 20px;
    border: .2em solid white;
    border-bottom-color: transparent;
}

</style>
