import { transformTranslationMap, emptyTranslationMap } from "../__general";

function transformAbstractClass(abstractClass) {
    if (!abstractClass._id || !abstractClass.name) {
        return null;
    }

    return {
        id: abstractClass._id,
        name: abstractClass.name,
        parentClassId: abstractClass.parentClassId || null,
        title: abstractClass.title ? transformTranslationMap(abstractClass.title) : emptyTranslationMap(),
        imageURL: abstractClass.imageURL,
    };
}

export default {
    transform(abstractClasses) {
        const result = {};
        abstractClasses.forEach((abstractClass) => {
            const transformed = transformAbstractClass(abstractClass);
            if (!transformed) {
                return;
            }
            result[transformed.id] = transformed;
        });
        Object.keys(result).forEach((classId) => {
            const abstractClass = result[classId];
            abstractClass.parent = null;
            if (!abstractClass.parentClassId) {
                return;
            }
            abstractClass.parent = result[abstractClass.parentClassId] || null;
        });

        return Object.values(result);
    }
}
