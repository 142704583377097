<template>
    <div class="w-full">
        <div v-if="silencers.length" class="products-list" :class="layoutClass">
            <template v-for="product in silencers" :key="product.id">
                <ProductCard class="mb-6" :direction="cardDirection" v-if="getCalculationAttribute('pressureDrop', product) <= 100">
                    <template v-slot:product-image>
                        <img :src="resolveSilencerClassImage(product.class)" alt="Silencer Image">
                    </template>
                    <template v-slot:product-properties>
                        <SilencerProperties
                            class="mb-3"
                            :product="product"
                            :pressureDrop="getCalculationAttribute('pressureDrop', product)"
                        >
                        </SilencerProperties>
                    </template>
                    <template v-slot:product-sound-adjustment>
                        <div class="flex flex-col w-full">
                            <OctaveBandContainer
                                :title="$t('octaveBandAdjustmentModal.attenuation')"
                                :powerLevelKit="product.soundAttenuation"
                                :lw="'    '"
                                lwTitle="    "
                            />
                            <OctaveBandContainer
                                :title="$t('projectBreakdown.result')"
                                :powerLevelKit="getCalculationAttribute('soundPowerLevelToRoomLw', product, emptyOctaveBandRange)"
                                :lwa="Math.round(getCalculationAttribute('totalSoundPowerToRoomLwA', product))"
                                :negativeAsZero="true"
                                lwaTitle="dB(A)"
                            />
                        </div>
                    </template>
                    <template v-slot:product-actions>
                        <div class="row" :class="{ 'mt-5': layout === 'grid' }">
                            <div class="column card-controls-container items-center">
                                <Button size="medium" type="primary" @click="onSelectProduct(product)">{{ $t('commonControls.add') }}</Button>
                                <div class="sound-data-control action-link mt-2" @click="openSilencerEditingModal(product)">{{ $t('productsList.productSpecs.soundData') }}</div>
                            </div>
                        </div>
                    </template>
                </ProductCard>
            </template>
        </div>
        <OctaveBandAdjustmentModal
            v-model="adjustmentModalOpened"
            :title="adjustmentModalTitle"
            :productId="adjustingProductId"
            :productName="adjustingProductName"
            :defaultOctaveBandRange="adjustingDefaultOctaveBandRange"
            :octaveBandRange="adjustingOctaveBandRange"
            :readOnly="readOnlyMode"
            @close="onAdjustmentModalClosed"
            @change="onOctaveBandRangeChanged"
        ></OctaveBandAdjustmentModal>
        <EditSilencerModal
            v-model="editSilencerModalOpened"
            :product="adjustingProduct"
            @close="adjustingProduct = null"
        ></EditSilencerModal>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Button from '@/components/general/Button.vue';
import ProductCard from '@/components/products/product/ProductCard.vue';
import SilencerProperties from './SilencerProperties.vue';
import OctaveBandAdjustmentModal from '@/components/products/OctaveBandAdjustmentModal.vue';
import EditSilencerModal from '@/components/products/silencers/EditSilencerModal.vue';
import OctaveBandContainer from '@/components/products/product/OctaveBandContainer.vue';
import { isOctaveBandRangeEmpty } from '@/utils';
import { emptyOctaveBandRange } from '@/utils';
import * as EOApiPlugin from '@/plugins/essential-objects/EOApiPlugin.js';

// eslint-disable-next-line no-unused-vars
const debounce = require('lodash.debounce');


export default {
    name: 'SilencersList',
    components: {
        Button,
        ProductCard,
        SilencerProperties,
        OctaveBandAdjustmentModal,
        EditSilencerModal,
        OctaveBandContainer,
    },
    props: {
        silencers: {
            type: Array,
            default: () => ([]),
        },
        layout: {
            type: String,
            default: 'linear',
            validator: (value) => (['linear', 'grid'].includes(value)),
        },
    },
    data() {
        return {
            adjustmentModalOpened: false,
            editSilencerModalOpened: false,
            adjustmentModalTitle: '',
            adjustmentType: '',
            adjustingProduct: null,
            readOnlyMode: false,
            emptyOctaveBandRange: emptyOctaveBandRange(),
        };
    },
    computed: {
        ...mapState('activeSystemModule', ['productQuantitiesMap']),
        ...mapState('mcadCalculationsModule', ['inputAirflow']),
        ...mapGetters('silencerClassesModule', ['imagesMap']),
        ...mapGetters('mcadCalculationsModule', ['productCalculationsMap']),
        adjustingDefaultOctaveBandRange() {
            if (!this.adjustingProduct || !this.adjustmentType) {
                return null;
            }

            return this.adjustmentType === 'soundPower'
                ? this.adjustingProduct.defaultSoundPowerLevel
                : this.adjustingProduct.defaultSoundAttenuation;
        },
        adjustingOctaveBandRange() {
            if (!this.adjustingProduct || !this.adjustmentType) {
                return null;
            }
            
            return this.adjustmentType === 'soundPower'
                ? this.adjustingProduct.soundPowerLevel
                : this.adjustingProduct.soundAttenuation;
        },
        adjustingProductId() {
            if (!this.adjustingProduct) {
                return '';
            }

            return this.adjustingProduct.id;
        },
        adjustingProductName() {
            if (!this.adjustingProduct) {
                return '';
            }

            return this.adjustingProduct.name;
        },
        layoutClass() {
            return {
                'linear-layout': this.layout === 'linear',
                'grid-layout': this.layout === 'grid',
            };
        },
        cardDirection() {
            return this.layout === 'linear' ? 'horizontal' : 'vertical';
        },
    },
    methods: {
        ...mapActions('silencersModule', [
            'setSoundPowerLevel',
            'setSoundAttenuation',
        ]),
        adjustSoundPower(product) {
            this.adjustingProduct = product;
            this.adjustmentModalTitle = this.$t('octaveBandAdjustmentModal.adjustSoundPower');
            this.adjustmentType = 'soundPower';
            this.readOnlyMode = false;
            this.adjustmentModalOpened = true;
        },
        adjustSoundAttenuation(product) {
            this.adjustingProduct = product;
            this.adjustmentModalTitle = this.$t('octaveBandAdjustmentModal.attenuation');
            this.adjustmentType = 'attenuation';
            this.readOnlyMode = !isOctaveBandRangeEmpty(this.adjustingDefaultOctaveBandRange);
            this.adjustmentModalOpened = true;
        },
        onAdjustmentModalClosed() {
            this.adjustingProduct = null;
            this.adjustmentModalTitle = '';
            this.readOnlyMode = false;
            this.adjustmentType = '';
        },
        async onOctaveBandRangeChanged({ productId, octaveBandRange }) {
            if (!this.adjustmentType) {
                return;
            }
            if (this.adjustmentType === 'soundPower') {
                await this.setSoundPowerLevel({ silencerId: productId, soundPowerLevel: octaveBandRange });
            }
            else {
                await this.setSoundAttenuation({ silencerId: productId, soundAttenuation: octaveBandRange });
            }
        },
        async onSelectProduct(product) {
            await EOApiPlugin.insertProduct({
                ...product,
                inputAirflow: this.inputAirflow,
                pressureDrop: this.getCalculationAttribute('pressureDrop', product) || 0,

            });
        },
        resolveSilencerClassImage(silencerClass) {
            return this.imagesMap[silencerClass] || '';
        },
        openSilencerEditingModal(product) {
            if (!product) {
                return;
            }
            this.adjustingProduct = product;
            this.editSilencerModalOpened = true;
        },
        updateInputAirflow: debounce(async function ({ product, inputAirflow }) {
            if (!product || !product.id) {
                return;
            }
            if (Number.isNaN(Number(inputAirflow))) {
                return;
            }
            await this.setInputAirflow({
                silencer: product,
                inputAirflow: Number(inputAirflow),
            });
        }, 600),
        getCalculationAttribute(attributeName, product, defaultValue = 0) {
            if (!attributeName || !product || !product.id) {
                return defaultValue;
            }
            const calculation = this.productCalculationsMap[product.id] || null;
            if (!calculation || !(attributeName in calculation)) {
                return defaultValue;
            }

            return calculation[attributeName];
        },
    },
}
</script>

<style lang="scss" scoped>
    .card-controls-container {
        position: relative;
    }

    .airflow-input {
        width: 100px;
        margin-top: -23px; // height of the input's label
    }
    
    .quantity-badge {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        min-width: 18px;
        min-height: 18px;
        border: 2px solid #737372;
        color: #737372;
        border-radius: 50%;
        right: -5px;
        top: -23px;
        font-size: 12px;
        line-height: 14px;
    }

    // .sound-data-control {
    //     align-self: end;
    // }

    .pressure-drop-value {
        font-family: Helvetica Neue Bold;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #4C5055;
    }

    .grid-layout {
        .airflow-input {
            flex: 0 1 auto;
        }
    }
</style>
