import { apiService } from '../general/APIService';
import InputTransformer from '@/transformers/input/projects';

const PROJECTS_ENDPOINT = 'projects';

export async function getProjects() {
    const response = await apiService.get(`${PROJECTS_ENDPOINT}?withSystems=true`, {
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    });

    return response.data.data;
}

export async function getProject(projectId) {
    if (!projectId) {
        throw new Error('Project id is required');
    }
    const { data: response } = await apiService.get(`${PROJECTS_ENDPOINT}/${projectId}`, {
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    });
    if (!response.success) {
        throw new Error('Project cannot be obtained');
    }
    if (!response.data) {
        return null;
    }

    return InputTransformer.transform([response.data])[0] || null;
}

export async function createProject(data) {
    const response = await apiService.post(PROJECTS_ENDPOINT, data, {
        withCredentials: true,
        credentials: 'include',
    });

    return response.data.data;
}

export async function saveProject(data) {
    const projectId = data.id;
    if (!projectId) {
        return;
    }
    const response = await apiService.put(`${PROJECTS_ENDPOINT}/${projectId}`, data, {
        withCredentials: true,
        credentials: 'include',
    });

    return response.data.data;
}

export async function deleteProject(projectId) {
    if (!projectId) {
        return;
    }
    await apiService.delete(`${PROJECTS_ENDPOINT}/${projectId}`, {
        withCredentials: true,
        credentials: 'include',
    });
}

export async function addUser(userId, projectId) {
    if (!userId) {
        throw new Error('User id is required');
    }
    if (!projectId) {
        throw new Error('Project id is required');
    }
    await apiService.post(`${PROJECTS_ENDPOINT}/${projectId}/users`, { invitedUserId: userId }, {
        withCredentials: true,
        credentials: 'include',
    });
}

export async function removeUser(userId, projectId) {
    if (!userId) {
        throw new Error('User id is required');
    }
    if (!projectId) {
        throw new Error('Project id is required');
    }
    await apiService.delete(`${PROJECTS_ENDPOINT}/${projectId}/users/${userId}`, {
        withCredentials: true,
        credentials: 'include',
    });
}
