import SystemsTransformer from '../systems';
import UserTransformer from '@/transformers/input/user';

function transformSystemRef(systemRef) {
    if (!systemRef.id) {
        return null;
    }

    return {
        id: systemRef.id,
        name: systemRef.name,
        number: systemRef.number,
        createdAt: systemRef.createdAt ? new Date(systemRef.createdAt) : null,
        updatedAt: systemRef.updatedAt ? new Date(systemRef.updatedAt) : null,
    };
}

function transformSystemRefs(systemRefs) {
    if (!Array.isArray(systemRefs) || !systemRefs.length) {
        return [];
    }
    const result = [];
    systemRefs.forEach((systemRef) => {
        const transformed = transformSystemRef(systemRef);
        if (!transformed) {
            return;
        }
        result.push(transformed);
    });

    return result;
}

function transformInvitedUsers(project) {
    if (!project) {
        return [];
    }
    if (!Array.isArray(project.invitedUsers) || !project.invitedUsers.length) {
        return [];
    }
    const result = [];
    project.invitedUsers.forEach((user) => {
        const transformed = UserTransformer.transform(user);
        if (!transformed) {
            return;
        }
        result.push(transformed);
    });

    return result;
}

function transformProject(project) {
    if (!project._id) {
        return null;
    }

    return {
        id: project._id,
        name: project.name || project.projectName || '',
        number: project.number || project.projectNr || '',
        address: project.address || '',
        email: project.email || '',
        phone: project.phone || '',
        contactData: project.contactData || '',
        author: project.author || '',
        date: project.date ? new Date(project.date) : new Date(),
        createdAt: project.createdAt ? new Date(project.createdAt) : null,
        updatedAt: project.updatedAt ? new Date(project.updatedAt) : null,
        systemRefs: transformSystemRefs(project.systemRefs),
        systems: SystemsTransformer.transform(project.systems),
        invitedUsers: transformInvitedUsers(project),
        creator: (Array.isArray(project.author) && project.author.length)
            ? UserTransformer.transform(project.author[0])
            : null,
    };
}

export default {
    transform(projects) {
        if (!Array.isArray(projects) || !projects.length) {
            return [];
        }
        const result = [];
        projects.forEach((project) => {
            const transformed = transformProject(project);
            if (!transformed) {
                return;
            }
            result.push(transformed);
        });

        return result;
    },
}