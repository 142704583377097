import axios from 'axios';
import { apiService } from '@/services/general/APIService';
import config from '@/constants/config';

const AUTHORIZATION_ENDPOINT = 'sso-auth';
const AUTHORIZE_ENDPOINT = 'authorize';
const REFRESH_ENDPOINT = 'refresh';
const LOGOUT_ENDPOINT = 'logout';
const FLUSH_ENDPOINT = 'flush';
const USER_ENDPOINT = 'user';

// eslint-disable-next-line no-unused-vars
function extractTokenPayload(token) {
    if (!token) {
        return null;
    }
    const segments = token.split('.');
    if (!segments.length) {
        return null;
    }
    const payloadString = atob(segments[1]);
    if (!payloadString) {
        return null;
    }

    return JSON.parse(payloadString);
}

function cleanAuthData() {
    apiService.removeHeader('it');
    apiService.removeHeader('authorization');
    apiService.removeHeader('rt');

    localStorage.removeItem('it');
    localStorage.removeItem('at');
    localStorage.removeItem('rt');
}

export async function authorize(loginIdentifier) {
    if (!loginIdentifier) {
        throw new Error('Login identifier is empty');
    }
    // const response = await apiService.get(`${AUTHORIZATION_ENDPOINT}/${AUTHORIZE_ENDPOINT}/${loginIdentifier}`, {
    //     withCredentials: true,
    // });
    const response = await apiService.get(`${AUTHORIZATION_ENDPOINT}/${AUTHORIZE_ENDPOINT}/${loginIdentifier}`, {
        withCredentials: true,
        credentials: 'include',
    });
    const {
        data: {
            success,
            data: {
                accessToken = '',
                idToken = '',
                refreshToken = '',
            } = {},
        } = {},
    } = response;
    if (!success) {
        console.error(response.data);
        throw new Error('Authorization failed');
    }
    if (!accessToken || !idToken || !refreshToken) {
        throw new Error('Authorization failed');
    }

    apiService.setHeader('it', idToken);
    apiService.setHeader('authorization', `Bearer ${accessToken}`);
    apiService.setHeader('rt', refreshToken);

    localStorage.setItem('it', idToken);
    localStorage.setItem('at', accessToken);
    localStorage.setItem('rt', refreshToken);
}

export async function refresh() {
    const response = await apiService.get(`${AUTHORIZATION_ENDPOINT}/${REFRESH_ENDPOINT}`, {
        withCredentials: true,
        credentials: 'include',
    });
    const {
        data: {
            success,
            data: {
                accessToken = '',
                idToken = '',
                refreshToken = '',
            } = {},
        } = {},
    } = response;
    if (!success) {
        console.error(response.data);
        throw new Error('Refresh failed');
    }
    if (!accessToken || !idToken || !refreshToken) {
        throw new Error('Refresh failed');
    }

    apiService.setHeader('it', idToken);
    apiService.setHeader('authorization', `Bearer ${accessToken}`);
    apiService.setHeader('rt', refreshToken);

    localStorage.setItem('it', idToken);
    localStorage.setItem('at', accessToken);
    localStorage.setItem('rt', refreshToken);
}

export async function logout() {
    const { data: response = {} } = await apiService.get(`${AUTHORIZATION_ENDPOINT}/${LOGOUT_ENDPOINT}`, {
        withCredentials: true,
        credentials: 'include',
    });
    if (!response || !response.success) {
        throw new Error('Logout failed');
    }
    cleanAuthData();
}

export async function delayedLogout() {
    try {
        await fetch(`${config.apiURL}/${AUTHORIZATION_ENDPOINT}/${LOGOUT_ENDPOINT}`, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            keepalive: true,
        });
        cleanAuthData();
    } catch (error) {
        console.error(error);
    }
}

export function logoutSync() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${config.apiURL}/${AUTHORIZATION_ENDPOINT}/${LOGOUT_ENDPOINT}`, false);
    xhr.withCredentials = true;
    xhr.send(null);
    cleanAuthData();
}

export async function flush() {
    const { data: response = {} } = await apiService.get(`${AUTHORIZATION_ENDPOINT}/${FLUSH_ENDPOINT}`, {
        withCredentials: true,
        credentials: 'include',
    });
    if (!response || !response.success) {
        throw new Error('Flush failed');
    }
    cleanAuthData();
}

export async function getUserData() {
    if (!localStorage.getItem('it')) {
        return null;
    }
    const { data: response = {} } = await apiService.get(`${AUTHORIZATION_ENDPOINT}/${USER_ENDPOINT}`, {
        withCredentials: true,
        credentials: 'include',
    }) || {};
    if (!response || !response.success) {
        throw new Error('User data have not been obtained');
    }

    return response.data || null;
}

axios.interceptors.response.use(function (response) {
    // If tokens are refreshed at the back-end side, we should actualize them at the front-end side:
    if (response.headers.it && (apiService.getHeader('it') !== response.headers.it)) {
        apiService.setHeader('it', response.headers.it);
        localStorage.setItem('it', response.headers.it);
    }
    if (response.headers.authorization && (apiService.getHeader('authorization') !== response.headers.authorization)) {
        apiService.setHeader('authorization', response.headers.authorization);
        localStorage.setItem('at', response.headers.authorization.split(' ')[1]);
    }
    if (response.headers.rt && (apiService.getHeader('rt') !== response.headers.rt)) {
        apiService.setHeader('rt', response.headers.rt);
        localStorage.setItem('rt', response.headers.rt);
    }

    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const { response: { status = 0 } = {} } = error;
    const alreadyRedirected = !!localStorage.getItem('alreadyRedirected');
    if (status === 400 || status === 401) {
        cleanAuthData();
        if (alreadyRedirected) {
            localStorage.removeItem('alreadyRedirected');

            return;
        }
        // const redirectUrl = btoa(window.location.href);
        // const returnUrl = btoa(`${window.location.origin}/authorize?redirectUrl=${redirectUrl}`);
        // window.location.href = `https://login.etsnord.com/?language=en&returnurl=${returnUrl}`;
        localStorage.setItem('redirectUrl', window.location.href);
        const returnUrl = btoa(`${window.location.origin}/authorize`);
        window.location.href = `https://login.etsnord.com/?language=en&fast=true&returnurl=${returnUrl}`;
        localStorage.setItem('alreadyRedirected', 1);
    }

    return Promise.reject(error);
});