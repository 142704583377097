import InputSilencerClassesTransformer from '../../transformers/input/silencer-classes';
import * as SilencerClassesService from '../../services/silencer-classes';
import CUSTOM_IMAGE_URLS from '../../constants/customImageURLs';

export default {
    namespaced: true,
    state: () => ({
        silencerClasses: [],
    }),
    getters: {
        rootClasses({ silencerClasses }) {
            return silencerClasses.filter((silencerClass) => silencerClass.parentClassId === null || silencerClass.groupingClass);
        },
        selectedRootClasses({ silencerClasses }) {
            return silencerClasses.filter((silencerClass) => (silencerClass.parentClassId === null || silencerClass.groupingClass) && silencerClass.selected);
        },
        silencerClasses({ silencerClasses }) {
            return silencerClasses.filter((silencerClass) => !silencerClass.groupingClass);
        },
        selectedSilencerClasses({ silencerClasses }) {
            return silencerClasses.filter((silencerClass) => !silencerClass.groupingClass && silencerClass.selected);
        },
        imagesMap({ silencerClasses }) {
            const map = {};
            silencerClasses.forEach((silencerClass) => {
                // const data = {
                //     imageURL: silencerClass.imageURL,
                //     dataURL: silencerClass.imageDataURL || '',
                // };
                map[silencerClass.id] = silencerClass.imageDataURL || silencerClass.imageURL;
                map[silencerClass.name] = silencerClass.imageDataURL || silencerClass.imageURL;
            });

            return map;
        },
        customImageURLs() {
            return CUSTOM_IMAGE_URLS;
        }
    },
    mutations: {
        setSilencerClasses(state, silencerClasses) {
            state.silencerClasses = silencerClasses;
        },
        toggleSilencerClassSelection({ silencerClasses }, identity) {
            const silencerClass = silencerClasses.find((item) => item.id === identity || item.name === identity);
            if (!silencerClass) {
                return;
            }
            silencerClass.selected = !silencerClass.selected;
            // if this is a parent class - toggle selection for children as well
            if (silencerClass.parentClassId === null) {
                silencerClasses.forEach((item) => {
                    if (item.parentClassId !== silencerClass.id) {
                        return;
                    }
                    item.selected = !item.selected;
                });
                return;
            }
            // auto select parent if at least one child is selected:
            if (silencerClass.selected) {
                silencerClass.parent.selected = true;
                return;
            }
            // unselect parent if there are no selected children left:
            const selectedChild = silencerClasses.find((item) => item.parentClassId === silencerClass.parentClassId && item.selected);
            if (!selectedChild) {
                silencerClass.parent.selected = false;
            }
        },
        clearSelection({ silencerClasses }) {
            silencerClasses.forEach((silencerClass) => {
                silencerClass.selected = false;
            });
        },
        setImageDataURL({ silencerClasses }, { id, url }) {
            const silencerClass = silencerClasses.find((item) => item.id === id);
            if (!silencerClass) {
                return;
            }
            silencerClass.imageDataURL = url;
        }
    },
    actions: {
        async getSilencerClasses({ state, commit }, { rewrite = false } = {}) {
            if (state.silencerClasses.length && !rewrite) {
                return;
            }
            const silencerClasses = await SilencerClassesService.getSilencerClasses();
            commit('setSilencerClasses', InputSilencerClassesTransformer.transform(silencerClasses));
            // dispatch('fetchImages');
        },
        toggleSilencerClassSelection({ commit }, { identity }) {
            commit('toggleSilencerClassSelection', identity);
        },
        clearSelection({ commit }) {
            commit('clearSelection');
        },
        async fetchImages({ state: { silencerClasses }, commit }) {
            const fetchImage = async (silencerClass) => {
                const blob = await fetch(silencerClass.imageURL).then(response => response.blob());
                const dataURL = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                });
                commit('setImageDataURL', { id: silencerClass.id, url: dataURL });
            };
            
            const promises = [];
            silencerClasses.forEach((silencerClass) => {
                if (!silencerClass.imageURL) {
                    return;
                }
                promises.push(fetchImage(silencerClass));
            });

            await Promise.all(promises);
        },
    },
}
