<template>
    <div class="p-2">
        <p v-html="$t('rectSilencerModal.tooltips.cassetteMaterial.polyester')"></p>
        <p v-html="$t('rectSilencerModal.tooltips.cassetteMaterial.mineralWool')"></p>
        <p v-html="$t('rectSilencerModal.tooltips.cassetteMaterial.cleantec')"></p>
    </div>
</template>

<script>
export default {
    name: 'CassetteMaterial',
}
</script>