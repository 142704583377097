import * as AbstractProductsService from '../../services/abstract-products';
import AbstractProductsInputTransformer from '../../transformers/input/abstract-products';

export default {
    namespaced: true,
    state: () => ({
        abstractProducts: [],
        abstractProductsMap: {},
    }),
    mutations: {
        setAbstractProducts(state, abstractProducts) {
            state.abstractProducts = abstractProducts;
            state.abstractProductsMap = {};
            abstractProducts.forEach((abstractProduct) => {
                state.abstractProductsMap[abstractProduct.id] = abstractProduct;
            });
        },
        setSoundPowerLevel({ abstractProducts }, { abstractProductId, soundPowerLevel }) {
            const abstractProduct = abstractProducts.find((item) => item.id === abstractProductId);
            if (!abstractProduct) {
                return;
            }
            abstractProduct.soundPowerLevel = soundPowerLevel;
        },
        setSoundAttenuation({ abstractProducts }, { abstractProductId, soundAttenuation }) {
            const abstractProduct = abstractProducts.find((item) => item.id === abstractProductId);
            if (!abstractProduct) {
                return;
            }
            abstractProduct.soundAttenuation = soundAttenuation;
        },
        setAbstractProductAttribute({ abstractProducts }, { productId, attributeName, attributeValue }) {
            abstractProducts.forEach((selectedProduct) => {
                if (selectedProduct.id !== productId) { // || !(selectedProduct.type === 'abstract' || selectedProduct.type === 'rectSilencer')
                    return;
                }
                selectedProduct[attributeName] = attributeValue;
            });
        },
    },
    actions: {
        async getAbstractProducts({ state, commit }, { rewrite = false } = {}) {
            if (state.abstractProducts.length && !rewrite) {
                return;
            }
            const abstractProducts = await AbstractProductsService.getAbstractProducts();
            const transformed = AbstractProductsInputTransformer.transform(abstractProducts);
            commit('setAbstractProducts', transformed);
        },
        setSoundPowerLevel({ commit }, { abstractProductId, soundPowerLevel }) {
            commit('setSoundPowerLevel', { abstractProductId, soundPowerLevel });
        },
        setSoundAttenuation({ commit }, { abstractProductId, soundAttenuation }) {
            commit('setSoundAttenuation', { abstractProductId, soundAttenuation });
        },
        setAbstractProductAttribute({ commit, dispatch }, { productId, attributeName, attributeValue }) {
            commit('setAbstractProductAttribute', { productId, attributeName, attributeValue });
            dispatch('activeSystemModule/saveSystem', {}, { root: true });
        },
    }
}