import { apiService } from '../general/APIService';
import UserTransformer from '@/transformers/input/user';

export async function lookupUser(email) {
    if (!email) {
        throw new Error('Email is empty');
    }
    const { data: response } = await apiService.get('/users/lookup', {
        withCredentials: true,
        credentials: 'include',
        queryParams: { email },
    });

    if (!response || !response.success || !response.data) {
        return null;
    }

    return UserTransformer.transform(response.data);
}
