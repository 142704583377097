<template>
    <div class="page-content">
        <div v-if="requestPending" class="loader-container">
            <div class="loader big-loader"></div>
        </div>
        <div class="column w-full">
            <div class="navigation text-small mt-4" @click="navigateBack"><i class="icon icon-small icon-chevron-left mr-2"></i>{{ $t('silencersPage.selectSilencerTypes') }}</div>
            <div class="card column mt-4 filter-card">
                <div class="search-container">
                    <InputField :placeholder="$t('productsList.searchProducts')" v-model="filters.searchString"></InputField>
                </div>
                <div class="filters-container mt-5">
                    <MultiSelect
                        :options="silencerTypes"
                        :label="$t('productsList.productSpecs.silencerType')"
                        :placeholder="$t('productsList.filters.showAll')"
                        :modelValue="selectedSilencerTypes"
                        explicitSelection
                        @update:modelValue="toggleSilencerTypesSelection"
                        class="flex-15 mr-8"
                    ></MultiSelect>
                    <MultiSelect
                        class="flex-15 mr-8"
                        :options="silencerFilterOptions.shapes"
                        :label="$t('productsList.productSpecs.shape')"
                        :placeholder="$t('productsList.filters.showAll')"
                        :disabled="!silencerFilterOptions.shapes.length"
                        explicitSelection
                        v-model="filters.shape"
                    ></MultiSelect>
                    <MultiSelect
                        class="flex-15 mr-8"
                        :options="silencerFilterOptions.attenuationMaterials"
                        :label="$t('productsList.productSpecs.attenuationMaterial')"
                        :placeholder="$t('productsList.filters.showAll')"
                        :disabled="!silencerFilterOptions.attenuationMaterials.length"
                        explicitSelection
                        v-model="filters.attenuationMaterial"
                    ></MultiSelect>
                    <MultiSelect
                        class="flex-15 mr-4"
                        :options="silencerFilterOptions.nominalSizes"
                        :label="$t('productsList.productSpecs.nominalSize')"
                        :placeholder="$t('productsList.filters.showAll')"
                        :disabled="!silencerFilterOptions.nominalSizes.length"
                        explicitSelection
                        v-model="filters.nominalSize"
                    ></MultiSelect>
                    <span class="text-small mb-3 mr-4">mm</span>
                    <MultiSelect
                        class="flex-15 mr-4"
                        :options="silencerFilterOptions.lengths"
                        :label="$t('productsList.productSpecs.length')"
                        :placeholder="$t('productsList.filters.showAll')"
                        :disabled="!silencerFilterOptions.lengths.length"
                        explicitSelection
                        v-model="filters.length"
                    ></MultiSelect>
                    <span class="text-small mb-3 mr-4">mm</span>
                    <MultiSelect
                        class="flex-15 mr-4"
                        :options="silencerFilterOptions.openable"
                        :label="$t('productsList.productSpecs.openable')"
                        :placeholder="$t('productsList.filters.showAll')"
                        :disabled="!silencerFilterOptions.openable.length"
                        explicitSelection
                        v-model="filters.openable"
                    ></MultiSelect>
                </div>
            </div>
            <CalculationParamsPanel class="mt-4"/>

            <div class="small-title products-count mt-8 mb-8 flex w-full justify-between items-center">
                <div><b>{{ $t('productsList.controls.showingProductsAmount', [silencers.length, displayableSilencers.length]) }}</b></div>
                <div class="row p-0 items-center">
                    <p class="mr-2">Sorting</p>
                    <Select
                    class="sorting-controls mr-6"
                    :options="sortingOptions"
                    v-model="selectedSorting"
                    ></Select>
                    <LayoutControls v-model="layout"/>
                </div>
            </div>

            <hr class="w-full mb-6">
            <SilencersList :silencers="displayableSilencers" :layout="layout"></SilencersList>
        </div>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InputField from '../../../general/InputField.vue';
import MultiSelect from '../../../general/MultiSelect.vue';
import Select from '../../../general/Select.vue';
import SilencersList from './SilencersList.vue';
import LayoutControls from '@/components/products/product/LayoutControls.vue';
import CalculationParamsPanel from './CalculationParamsPanel.vue';

export default {
    name: 'ProductsList',
    components: {
        InputField,
        MultiSelect,
        Select,
        SilencersList,
        LayoutControls,
        CalculationParamsPanel,
    },
    inject: ['resolveTranslationMapValue'],
    async mounted() {
        if (Number(this.$route.query.diameter)) {
            this.filters.nominalSize.push(Number(this.$route.query.diameter));
        }
        await this.applyAllSelections();
        await this.calculateInputSoundPowerSummary();
    },
    data() {
        return {
            layout: 'linear',
            displayableSilencers: [],
            filters: {
                searchString: '',
                shape: [],
                attenuationMaterial: [],
                nominalSize: [],
                length: [],
                openable: [],
            },
            selectedSorting: 'totalSoundPowerToRoomLwA_asc',
            requestPending: false,
        };
    },
    computed: {
        ...mapState('silencersModule', {
            'silencers': 'silencers',
            'silencerFilters': 'filters',
        }),
        ...mapState('mcadCalculationsModule', ['inputSoundPower', 'inputAirflow']),
        ...mapGetters('silencerClassesModule', ['silencerClasses', 'selectedSilencerClasses']),
        ...mapGetters('activeSystemModule', ['selectedProductsMap']),
        ...mapGetters('mcadCalculationsModule', ['productCalculationsMap']),
        silencerTypes() {
            return this.silencerClasses.map((silencerClass) => ({
                title: this.resolveTranslationMapValue(silencerClass.title),
                value: silencerClass.name, // .id
            }));
        },
        selectedSilencerTypes() {
            return this.selectedSilencerClasses.map((silencerClass) => silencerClass.name);
        },
        silencerFilterOptions() {
            const filterOptions = {
                shapes: new Set(),
                attenuationMaterials: new Set(),
                nominalSizes: new Set(),
                lengths: new Set(),
                openable: new Set(),
            };
            const filters = [];
            const silencerTypes = this.selectedSilencerTypes.length
                ? this.selectedSilencerTypes
                : this.silencerClasses.map((silencerClass) => silencerClass.name);
            silencerTypes.forEach((silencerClass) => {
                if (!(silencerClass in this.silencerFilters)) {
                    return;
                }
                filters.push(this.silencerFilters[silencerClass]);
            });
            filters.forEach((filter) => {
                filterOptions.shapes.add(filter.shape);
                filterOptions.attenuationMaterials.add(filter.attenuationMaterial);
                filterOptions.openable.add(filter.openable);
                filter.nominalSizes.forEach(filterOptions.nominalSizes.add, filterOptions.nominalSizes);
                filter.lengths.forEach(filterOptions.lengths.add, filterOptions.lengths);
            });

            return {
                shapes: Array.from(filterOptions.shapes)
                             .map((shape) => ({ title: this.$t(`productAttributes.shape.${shape.replace(" ", "")}`), value: shape })),
                attenuationMaterials: Array.from(filterOptions.attenuationMaterials)
                                            .map((attenuationMaterial) => ({ title: this.$t(`productAttributes.attenuationMaterial.${attenuationMaterial.replace(" ", "")}`), value: attenuationMaterial })),
                nominalSizes: Array.from(filterOptions.nominalSizes)
                                   .sort(this.ascendingSort)
                                   .map((nominalSize) => ({ title: String(nominalSize), value: Number(nominalSize) })),
                lengths: Array.from(filterOptions.lengths)
                              .sort(this.ascendingSort)
                              .map((length) => ({ title: String(length), value: Number(length) })),
                openable: Array.from(filterOptions.openable)
                               .map((openable) => ({ title: openable ? this.$t('productAttributes.openable') : this.$t('productAttributes.notOpenable'), value: openable })),
            };
        },
        sortingOptions() {
            return [
                {
                    title: 'None',
                    value: 'none',
                },
                {
                    title: `${this.$t('rectSilencerData.pressureDrop')} ↑`,
                    value: 'pressureDrop_asc',
                },
                {
                    title: `${this.$t('rectSilencerData.pressureDrop')} ↓`,
                    value: 'pressureDrop_desc',
                },
                {
                    title: 'db(A) ↑',
                    value: 'totalSoundPowerToRoomLwA_asc',
                },
                {
                    title: 'db(A) ↓',
                    value: 'totalSoundPowerToRoomLwA_desc',
                },
            ];
        },
    },
    methods: {
        ...mapActions('silencerClassesModule', [
            'toggleSilencerClassSelection',
            'clearSelection',
        ]),
        ...mapActions('mcadCalculationsModule', [
            'performCalculationsFor',
            'performCalculationsImmediatelyFor',
            'calculateInputSoundPowerSummary',
        ]),
        toggleSilencerTypesSelection(selectedTypes) {
            if (!selectedTypes.length) {
                this.clearSelection();
                return;
            }
            const typesToToggle = this.symmetricDifference(this.selectedSilencerTypes, selectedTypes);
            if (!typesToToggle.length) {
                return;
            }
            typesToToggle.forEach((type) => this.toggleSilencerClassSelection({ identity: type }));
        },
        applyFilters() {
            const filtered = this.silencers.filter((silencer) => {
                if (this.selectedSilencerTypes.length && !this.selectedSilencerTypes.includes(silencer.class)) {
                    return false;
                }
                if (this.filters.searchString.length >= 2 && silencer.name.toLowerCase().indexOf(this.filters.searchString.toLowerCase()) < 0) {
                    return false;
                }
                if (this.filters.shape.length && !this.filters.shape.includes(silencer.shape)) {
                    return false;
                }
                if (this.filters.attenuationMaterial.length && !this.filters.attenuationMaterial.includes(silencer.attenuationMaterial)) {
                    return false;
                }
                if (this.filters.nominalSize.length && !this.filters.nominalSize.includes(silencer.nominalSizeDiameter)) {
                    return false;
                }
                if (this.filters.length.length && !this.filters.length.includes(silencer.length)) {
                    return false;
                }
                if (this.filters.openable.length && !this.filters.openable.includes(silencer.openable)) {
                    return false;
                }

                return true;
            });
            const filteredGroups = {};
            filtered.forEach((product) => {
                if (product.class in filteredGroups) {
                    filteredGroups[product.class].push(product);
                    return;
                }
                filteredGroups[product.class] = [ product ];
            });
            // eslint-disable-next-line no-unused-vars
            // Object.entries(filteredGroups).forEach(([ productClass, products ]) => {
            //     products.sort(this.ascendingProductsSort);
            // });

            // this.displayableSilencers = [].concat(...Object.values(filteredGroups));
            return [].concat(...Object.values(filteredGroups));
        },
        symmetricDifference(array1, array2) {
            return array1
                .filter((item) => !array2.includes(item))
                .concat(array2.filter((item) => !array1.includes(item)));
        },
        capitalize(value) {
            const str = String(value);
            
            return `${str[0].toUpperCase()}${str.substring(1)}`;
        },
        ascendingSort(a, b) {
            return a < b ? -1 : 1;
        },
        ascendingProductsSort(left, right) {
            const leftDiameter = left.nominalSize || left.nominalSizeDiameter || 0;
            if (!leftDiameter) {
                return 0;
            }
            const rightDiameter = right.nominalSize || right.nominalSizeDiameter || 0;
            if (!rightDiameter) {
                return 0;
            }
            if (leftDiameter < rightDiameter) {
                return -1;
            }
            if (leftDiameter > rightDiameter) {
                return 1;
            }

            return (left.length || 0) < (right.length || 0) ? -1 : 1;
        },
        navigateBack() {
            this.$router.push({ name: 'silencer-classes-selector' });
        },
        getCalculationAttribute(attributeName, product, defaultValue = 0) {
            if (!attributeName || !product || !product.id) {
                return defaultValue;
            }
            const calculation = this.productCalculationsMap[product.id] || null;
            if (!calculation || !(attributeName in calculation)) {
                return defaultValue;
            }

            return calculation[attributeName];
        },
        determineSortAlgorithm() {
            if (this.selectedSorting === 'none') {
                return this.ascendingProductsSort;
            }
            const [ attribute, sortingType ] = this.selectedSorting.split('_');
            if (!attribute || !sortingType) {
                return this.ascendingProductsSort;
            }

            return (left, right) => {
                const attributeValueLeft = this.getCalculationAttribute(attribute, left);
                const attributeValueRight = this.getCalculationAttribute(attribute, right);

                if (sortingType === 'asc') {
                    return attributeValueLeft < attributeValueRight ? -1 : 1;
                } else {
                    return attributeValueLeft > attributeValueRight ? -1 : 1;
                }
            };
        },
        applySorting(silencers) {
            return silencers.sort(this.determineSortAlgorithm());
        },
        async applyAllSelections() {
            try {
                this.requestPending = true;
                const filtered = this.applyFilters();
                await this.performCalculationsImmediatelyFor(filtered);
                this.displayableSilencers = this.applySorting(filtered);
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                // this.applyFilters();
                // this.performCalculationsFor(this.displayableSilencers);
                this.applyAllSelections();
            },
        },
        selectedSilencerTypes() {
            // this.applyFilters();
            this.applyAllSelections();
        },
        silencers() {
            // this.applyFilters();
            this.applyAllSelections();
        },
        async inputSoundPower() {
            // TODO: eliminate duplication
            try {
                this.requestPending = true;
                await this.performCalculationsImmediatelyFor(this.displayableSilencers);
                this.applySorting(this.displayableSilencers);
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },
        async inputAirflow() {
            // TODO: eliminate duplication
            try {
                this.requestPending = true;
                await this.performCalculationsImmediatelyFor(this.displayableSilencers);
                this.applySorting(this.displayableSilencers);
            } catch (error) {
                console.error(error);
            } finally {
                this.requestPending = false;
            }
        },
        selectedSorting() {
            this.applySorting(this.displayableSilencers);
        },
    }
}
</script>

<style lang="scss">
@use 'src/assets/css/product/productsList.scss';
</style>

<style scoped>
.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.card .search-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
}

.card .filters-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
}

.card .search-container .input-container {
    flex: 1 1 auto;
    margin-right: 6px;
}

.card .search-container .btn {
    background: #4C5055;
    border-radius: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 8px 32px;
    min-height: 32px;
    box-sizing: border-box;
}

.card .filters-container .input-container {
    flex: 1 1 auto;
    margin-right: 6px;
}

.card .filters-container .input-container:last-of-type {
    margin-right: 0;
}

.small-title {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
    line-height: 14px;
    color: #737372;
}

.switch-container .title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 14px;
    line-height: 17px;
    color: #4C5055;
    margin-bottom: 8px;
    user-select: none;
}

.w-90 {
    max-width: 90px;
    width: 90px;
}

.flex-15 {
    flex: 0 1 15% !important;
}

.products-count {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 12px;
    line-height: 14px;
    color: #737372;
}

.filter-card {
    position: sticky;
    top: 60px;
    z-index: 10;
}

.diffuser-filters {
    z-index: 1;
}
</style>

<style lang="scss" scoped>
    .heading-small-bold {
        font-size: 18px;
        text-transform: uppercase;
        color: #737372;

    }

    .sorting-controls {
        width: 200px;
    }

    .loader-container {
        top: 0;
        left: 0;
        position: fixed;
        background-color: #a3a3a3;
        opacity: 0.5;
        width: 100vw;
        height: 100vh;
        z-index: 15000;
    }
</style>