import { createStore } from 'vuex';
import SilencersModule from './silencers';
import DiffusersModule from './diffusers';
import AbstractProductsModule from './abstract-products';
import SilencerClassesModule from './silencer-classes';
import DiffuserClassesModule from './diffuser-classes';
import AbstractClassesModule from './abstract-classes';
import ActiveProjectModule from './active-project';
import ActiveSystemModule from './active-system';
import ProjectsModule from './projects';
import SystemsModule from './systems';
import UserModule from './user';
import RectSilencerModule from './rect-silencer';
import MCADCalculationsModule from './mcad-calculations';


const store = createStore({
    modules: {
        silencersModule: SilencersModule,
        diffusersModule: DiffusersModule,
        abstractProductsModule: AbstractProductsModule,
        silencerClassesModule: SilencerClassesModule,
        diffuserClassesModule: DiffuserClassesModule,
        abstractClassesModule: AbstractClassesModule,
        activeProjectModule: ActiveProjectModule,
        activeSystemModule: ActiveSystemModule,
        projectsModule: ProjectsModule,
        systemsModule: SystemsModule,
        userModule: UserModule,
        rectSilencerModule: RectSilencerModule,
        mcadCalculationsModule: MCADCalculationsModule,
    }
});

export default store;
