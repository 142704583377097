<template>
    <Modal :modelValue="modelValue" @update:modelValue="closeModal">
        <template v-slot:modal-header>
            <div>{{ $t('projectSpecificationModal.newProject') }}</div>
        </template>
        <template v-slot:modal-content>
            <div class="row">
                <InputField class="mr-8" :title="$t('projectSpecificationModal.projectName')" :placeholder="$t('projectSpecificationModal.projectName')" :model-value="name" @update:modelValue="setName"></InputField>
                <InputField :title="$t('projectSpecificationModal.projectNr')" :placeholder="$t('projectSpecificationModal.projectNr')" :model-value="number" @update:modelValue="setNumber"></InputField>
            </div>
            <div class="row mt-12">
                <InputField :title="$t('projectSpecificationModal.address')" :placeholder="$t('projectSpecificationModal.address')" :model-value="address" @update:modelValue="setAddress"></InputField>
            </div>
            <div class="row mt-12">
                <InputField class="mr-8" :title="$t('projectSpecificationModal.email')" :placeholder="$t('projectSpecificationModal.email')" :model-value="email" @update:modelValue="setEmail"></InputField>
                <InputField :title="$t('projectSpecificationModal.phoneNumber')" :placeholder="$t('projectSpecificationModal.phoneNumber')" :model-value="phone" @update:modelValue="setPhone"></InputField>
            </div>
            <div class="row mt-12">
                <InputField class="mr-8" :title="$t('projectSpecificationModal.customer')" :placeholder="$t('projectSpecificationModal.customer')" :model-value="contactData" @update:modelValue="setContactData"></InputField>
                <DateInputField :title="$t('projectSpecificationModal.date')" :placeholder="$t('projectSpecificationModal.date')" :model-value="date" @update:modelValue="setDate"></DateInputField>
            </div>
        </template>
        <template v-slot:modal-actions>
            <Button type="secondary" size="medium" outlined class="mr-16" @click="resetData">{{ $t('projectSpecificationModal.clear') }}</Button>
            <Button type="primary" size="medium" @click="saveAndProceed">{{ $t('projectSpecificationModal.next') }}</Button>
        </template>
    </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputField from '../general/InputField.vue';
import Button from '../general/Button.vue';
import Modal from '../general/Modal.vue';
import DateInputField from '../general/DateInputField.vue';

export default {
    name: 'CreateProjectModal',
    components: {
        InputField,
        Button,
        Modal,
        DateInputField,
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    computed: {
        ...mapState('activeProjectModule', [
            'name',
            'number',
            'address',
            'email',
            'phone',
            'contactData',
            'date',
        ]),
    },
    methods: {
        ...mapActions('activeProjectModule', [
            'setName',
            'setNumber',
            'setAddress',
            'setEmail',
            'setPhone',
            'setContactData',
            'setDate',
            'resetData',
            'saveProject',
        ]),
        closeModal() {
            this.$emit('update:modelValue', false);
        },
        openSystemsPage() {
            this.closeModal();
            this.$router.push({ name: 'systems' });
        },
        async saveAndProceed() {
            await this.saveProject();
            this.openSystemsPage();
        },
    },
    watch: {
        modelValue(value) {
            if (!value) {
                return;
            }
            this.resetData();
        }
    },
}
</script>

<style>
.group-title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 22px;
    color: #737372;
}
</style>
