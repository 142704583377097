<template>
    <div class="system-info">
        <div class="row info-title section-title mt-8">
            {{ $t('projectBreakdown.systemInfo') }}
        </div>
        <div class="project-attributes">
            <div class="row mt-6">
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.systemName') }}</div>
                    <div class="attribute-value text-regular">{{ system.name || '' }}</div>
                </div>
            </div>
            <div class="row mt-6">
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.createdAt') }}</div>
                    <div class="attribute-value text-regular">{{ createdAt }}</div>
                </div>
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.systemNr') }}</div>
                    <div class="attribute-value text-regular">{{ system.number || '' }}</div>
                </div>
            </div>
            <!-- <div class="row mt-6">
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.filterCorrection') }}</div>
                    <div class="attribute-value text-regular">{{ resolveCorrectionFilter(system.filterCorrectionType) }}</div>
                </div>
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.attenuationConstant') }}</div>
                    <div class="attribute-value text-regular">{{ resolveAttenuationConstant(system.attenuationConstantType) }}</div>
                </div>
            </div> -->
            <div class="row mt-6">
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectSpecificationModal.comment') }}</div>
                    <div class="attribute-value text-regular">{{ system.comment || '' }}</div>
                </div>
            </div>
            <div class="row mt-6">
                <OctaveBandContainer
                    :title="$t('projectOverview.noiseGenerator')"
                    :power-level-kit="system.inputSoundPowerLevel"
                    :lw="noiseGeneratorTotalSoundPowerLw"
                    :lwa="noiseGeneratorTotalSoundPowerLwA"
                />
            </div>
        </div>
        <div class="filler"></div>
        <CalculationResults :calculationResults="calculationResults"/>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import OctaveBandContainer from "@/components/products/product/OctaveBandContainer";
import CalculationResults from './CalculationResults.vue';

export default {
    name: 'SystemInfo',
    components: {
        OctaveBandContainer,
        CalculationResults,
    },
    props: {
        system: {
            type: Object,
            required: true,
        },
        calculationResults: {
            type: Object,
            required: true,
        },
    },
    computed: {
        noiseGeneratorTotalSoundPowerLw() {
            const { noiseGeneratorTotalSoundPowerLw } = this.calculationResults;
            if (!noiseGeneratorTotalSoundPowerLw) {
                return '';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLw);
        },
        noiseGeneratorTotalSoundPowerLwA() {
            const { noiseGeneratorTotalSoundPowerLwA } = this.calculationResults;
            if (!noiseGeneratorTotalSoundPowerLwA) {
                return '';
            }

            return Math.round(noiseGeneratorTotalSoundPowerLwA);
        },
        createdAt() {
            return dayjs(this.system.date || this.system.createdAt || new Date()).format('DD.MM.YYYY');
        },
    },
    methods: {
        resolveAttenuationConstant(value) {
            if (!value) {
                return '';
            }

            return value === 'T1'
                ? 'LpA10m2'
                : this.$t('projectBreakdown.constants.noConstant');
        },
        resolveCorrectionFilter(value) {
            if (!value) {
                return '';
            }
            switch (value) {
                case 'NoFilter':
                    return this.$t('projectBreakdown.filters.noFilter');
                case 'AFilter':
                    return 'A - ' + this.$t('projectBreakdown.filters.filter');
                case 'BFilter':
                    return 'B - ' + this.$t('projectBreakdown.filters.filter');
                case 'CFilter':
                    return 'C - ' + this.$t('projectBreakdown.filters.filter');
                default:
                    return this.$t('projectBreakdown.filters.noFilter');
            }
        },
    },
}
</script>

<style scoped lang="scss">
@use 'src/assets/css/pdf/page.scss';
@use 'src/assets/css/pdf/page-content.scss';

.system-info {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}
</style>
