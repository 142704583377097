<template>
    <div></div>
</template>

<script>
import * as AuthService from '@/services/auth/sso';

export default {
    name: 'Authorize',
    async created() {
        const { loginIdentifier = '' } = this.$route.params;
        if (!loginIdentifier) {
            throw new Error('Login identifier is empty');
        }
        await AuthService.authorize(loginIdentifier);
        const redirectUrl = localStorage.getItem('redirectUrl');
        if (redirectUrl) {
            localStorage.removeItem('redirectUrl');
            window.location.href = redirectUrl;
        } else {
            this.$router.push({ name: 'projects' });
        }
    },
}
</script>
