<template>
    <table>
        <thead>
            <th v-for="(header, index) in headers" :key="index" v-html="header.title" class="text-bold"></th>
        </thead>
        <tbody>
            <tr v-for="(product, index) in products" :key="index">
                <td class="text-left text-regular pl-4">
                    <div>{{ product.name }} {{ resolveTranslationMapValue(product.nameAppendix) }}</div>
                </td>
                <td class="text-center text-regular">{{ product.systemName || '-' }}</td>
                <td class="text-center text-regular">{{ product.systemNr || '-' }}</td>
                <!-- <td class="text-center text-regular">{{ product.vendorCode || '-' }}</td> -->
                <td class="text-center text-regular">{{ parseNumber({ value: product.inputAirflow }) }}</td>
                <td class="text-center text-regular">{{ resolvePressureDrop(product) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'MaterialsTable',
    props: {
        products: {
            type: Array,
            required: true,
        },
    },
    inject: ['resolveTranslationMapValue'],
    data() {
        return {
            headers: [
                {
                    title: this.$t('pdf.productInventoryTable.productName'),
                },
                {
                    title: this.$t('projectOverview.system'),
                },
                {
                    title: this.$t('projectOverview.systemNr'),
                },
                // {
                //     title: this.$t('pdf.productInventoryTable.productLabel'),
                // },
                {
                    title: `[l/s]<br>${this.$t('pdf.productInventoryTable.flow')}`,
                },
                {
                    title: `[Pa]<br>${this.$t('pdf.productInventoryTable.pressureDrop')}`,
                },
            ],
        };
    },
    methods: {
        parseNumber({ value, defaultValue = '-', toFixed = 0 }) {
            if (Number.isNaN(Number(value))) {
                return defaultValue;
            }

            return (toFixed > 0) ? Number(value).toFixed(toFixed) : Number(value);
        },
        roundValue(value) {
            if (Number.isNaN(Number(value))) {
                return 0;
            }

            return Math.round(Number(value));
        },
        resolvePressureDrop(product) {
            if (!product) {
                return 0;
            }
            if (product.type === 'silencer') {
                return this.roundValue(product.pressureDrop);
            }
            if (product.type === 'rectSilencer') {
                return this.roundValue(product.optionData.pressureDrop);
            }

            return 0;
        },
    },
}
</script>

<style scoped lang="scss">
    @use 'src/assets/css/pdf/page.scss';
    @import 'src/assets/css/pdf/_variables.scss';

    table {
        width: 100%;
    }

    thead {
        background-color: $red-color;
        height: 48px;
        color: $white-color;
        font-weight: bold;
    }

    th, td {
        border-bottom: 2px solid #E0E3E7;
        min-width: 100px;
    }

    th {
        height: 48px;
        color: $white-color !important;
    }

    td {
        color: $gray-color !important;

        div {
            display: flex;
            align-items: center;
            height: 40px !important;
            max-height: 40px !important;
            overflow-y: hidden;
        }
    }
</style>