<template>
    <SilencerDetailsView
        v-if="activeTab === 'general' && showDetailsView"
        :modelValue="modelValue"
        @update:modelValue="closeModal"
        @optionSelectionRequested="showDetailsView = false"
        @switchTab="activeTab = $event"
    />
    <SilencerOptionsView
        v-else-if="activeTab === 'general' && !showDetailsView"
        :modelValue="modelValue"
        :mode="mode"
        @update:modelValue="closeModal"
        @detailsSelectionRequested="showDetailsView = true"
        @switchTab="activeTab = $event"
    />
    <SoundPower
        v-else-if="activeTab === 'soundPower'"
        :modelValue="modelValue"
        @update:modelValue="closeModal"
        @switchTab="activeTab = $event"
    />
    <SoundAttenuation
        v-else-if="activeTab === 'soundAttenuation'"
        :modelValue="modelValue"
        @update:modelValue="closeModal"
        @switchTab="activeTab = $event"
    />
</template>

<script>
import { mapActions } from 'vuex';
import SilencerDetailsView from './modal-parts/SilencerDetailsView.vue';
import SilencerOptionsView from './modal-parts/SilencerOptionsView.vue';
import SoundPower from './modal-parts/SoundPower.vue';
import SoundAttenuation from './modal-parts/SoundAttenuation.vue';

export default {
    name: 'OctaveBandAdjustmentModal',
    components: {
        SilencerDetailsView,
        SilencerOptionsView,
        SoundPower,
        SoundAttenuation,
    },
    props: {
        modelValue: Boolean,
        mode: {
            type: String,
            default: 'create',
            validation: (value) => (['create', 'edit'].includes(value)),
        },
    },
    emits: ['update:modelValue', 'close'],
    data() {
        return {
            showDetailsView: true,
            activeTab: 'general',
        };
    },
    methods: {
        ...mapActions('rectSilencerModule', ['resetData']),
        closeModal() {
            this.$emit('update:modelValue', false);
            this.$emit('close');
            this.resetData();
            this.showDetailsView = true;
            this.activeTab = 'general';
        },
    },
}
</script>

<style scoped>
</style>