<template>
    <div class="p-2">
        <p>{{ $t('rectSilencerModal.tooltips.shellInsulation.caption') }}:</p>
        <p class="ml-4" v-html="$t('rectSilencerModal.tooltips.shellInsulation.thermalInsulation')"></p>
        <p class="ml-4" v-html="$t('rectSilencerModal.tooltips.shellInsulation.soundInsulation')"></p>
        <p class="ml-4" v-html="$t('rectSilencerModal.tooltips.shellInsulation.fireInsulation')"></p>
    </div>
</template>

<script>
export default {
    name: 'ShellInsulation',
}
</script>