<template>
    <div class="row pdf-footer">
        <div class="footer-inner">
            <div class="footer-column">
                <p class="text-footer">ETS NORD</p>
                <p class="text-footer">{{ $t('footer.generalInfo.address') }}</p>
                <p class="text-footer">{{ $t('footer.generalInfo.zip') }}</p>
                <p class="text-footer">{{ $t('footer.generalInfo.country') }}</p>
            </div>
            <div class="footer-column">
                <p class="text-footer">{{ $t('footer.generalInfo.phoneNumber') + ": " + $t('footer.generalInfo.phone') }}</p>
                <p class="text-footer">
                    <a :href="`mailto:${$t('footer.generalInfo.supportEmail')}`">{{ $t('footer.generalInfo.supportEmail') }}</a>
                </p>
            </div>
            <div class="footer-column">
                <p class="text-footer float-right">
                    <a :href="$t('footer.generalInfo.website')">{{ $t('footer.generalInfo.website') }}</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import etsContactData from "@/constants/etsContactData";

export default {
    name: 'Footer',
    data() {
        return {
            etsContactData: etsContactData,
        }
    },
}
</script>

<style lang="scss" scoped>
@use 'src/assets/css/pdf/page.scss';
@use 'src/assets/css/pdf/page-content.scss';

.pdf-footer {
    // position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 !important;

    .footer-inner {
        padding: 12px 30px;
        // border-top: 2px solid var(--neutral-color-gray-2);
        border-top: 1px solid #717275;
        width: 100%;
        box-sizing: content-box;
        display: flex;
        .footer-column {
            flex: 1;
        }
    }
}
</style>
