import * as RectSilencerService from '../../services/rect-silencer';
import { cloneOctaveBandRange, emptyOctaveBandRange, resolveRectSilencerImage, toValidNumber } from '../../utils';
import { getNameAppendixByClass } from '../../transformers/input/rect-silencer';


// TODO: move it all to a separate util file:
function getMaterialAbbreviation(material) {
    switch (material) {
        case 'galvanizedSteel':
            return ''; // 'ZN275'
        case 'stainlessSteel':
            return 'H'; // 'AISI 316'
        case 'magnelis':
            return 'ZM'; // 'ZM310'
        default:
            return '';
    }
}

// eslint-disable-next-line no-unused-vars
function getShellInsulationAbbreviation(shellInsulation) {
    switch (shellInsulation) {
        case '0mm':
            return '';
        case '50mm':
            return 'S50';
        case '100mm':
            return 'S100';
        case 'M30':
            return 'M30';
        case 'M50':
            return 'M50';
        case 'M100':
            return 'M100';
        case 'EI60':
            return 'EI60';
        case 'EI120':
            return 'EI120';
        default:
            return '';
    }
}

export default {
    namespaced: true,
    state: () => ({
        id: '',
        name: '',
        class: '',
        code: '',
        imageURL: '',
        width: 0,
        height: 0,
        length: 0,
        dimensionX: 150,
        dimensionY: 150,
        shape: 'straight',
        orientation: 'horizontal',
        position: 'supply',
        inputAirflow: 0,
        shellInsulation: '0mm',
        cassetteMaterial: 'mineralWool',
        outerCover: 'none',
        innerMaterial: 'galvanizedSteel',
        openable: false,
        connectionProfile: 'Z-slat',
        options: [],
        requestPending: false,
        inputSoundPower: emptyOctaveBandRange(),
        optionData: {
            cassetteThickness: 0,
            amountOfCassettes: 0,
            cassetteSpacing: 0,
            edgeGap: 0,
            silencerGapSpeed: 0,
            silencerEndSpeed: 0,
            pressureDrop: 0,
            soundAttenuation: emptyOctaveBandRange(),
            totalAttenuationWithAirflow: 0,
            soundAttenuationWithAirflow: emptyOctaveBandRange(),
            resultAfterLw: 0,
            resultAfterAFilter: 0,
        },
        soundAttenuation: emptyOctaveBandRange(),
        soundPowerLevel: emptyOctaveBandRange(),
    }),
    getters: {
        allDimensionsValid(state) {
            if (state.width < 300) {
                return false;
            }
            if (state.height < 200) {
                return false;
            }
            if (state.length < 600) {
                return false;
            }
            if (state.shape === 'straight') {
                 if (state.width > 2500 || state.height > 2500 || state.length > 2500) {
                    return false;
                }
            } else {
                if (state.width > 2350 || state.height > 2350) {
                    return false;
                }
                if (state.width + state.dimensionX > 2500) {
                    return false;
                }
                if (state.height + state.dimensionY > 2500) {
                    return false;
                }
                if (state.dimensionX < 150 || state.dimensionY < 150) {
                    return false;
                }
                if (state.dimensionX > state.dimensionXUpperLimit || state.dimensionY > state.dimensionYUpperLimit) {
                    return false;
                }
            }

            return true;
        },
        //     let result = 'M';
        //     if (state.shape === 'bend') {
        //         result += 'P';
        //     } else {
        //         result += state.openable ? 'R' : 'S';
        //     }
        //     switch (state.cassetteMaterial) {
        //         case 'polyester':
        //             result += 'P';
        //             break;
        //         case 'mineralWool':
        //             result += 'K';
        //             break;
        //         case 'cleantec':
        //             result += 'C';
        //             break;
        //         default:
        //             return result;
        //     }
        //     if (state.openable) {
        //         result += 'A';
        //     } else if (state.shellInsulation > 0) {
        //         result += 'I';
        //     }

        //     return result;
        // },
        silencerClass(state) {
            let result = 'M';
            if (state.shape === 'bend') {
                result += 'P';
                switch (state.cassetteMaterial) {
                    case 'polyester':
                        result += 'P';
                        break;
                    case 'cleantec':
                        result += 'C';
                        break;
                    case 'mineralWool':
                        result += 'K';
                        break;
                    default:
                        return result;
                }
                if (state.outerCover !== 'none') { //  && state.cassetteMaterial === 'mineralWool'
                    result += 'I';
                }
            } else {
                result += state.openable ? 'R' : 'S';
                switch (state.cassetteMaterial) {
                    case 'polyester':
                        result += 'P';
                        break;
                    case 'mineralWool':
                        result += 'K';
                        break;
                    case 'cleantec':
                        result += 'C';
                        break;
                    default:
                        return result;
                }
                if (state.openable) {
                    result += 'A';
                } else if (state.shellInsulation !== '0mm') {
                    result += 'I';
                }
            }

            return result;
        },
        // productCode(state, getters) {
        //     return `${getters.silencerClass} ${state.width}x${state.height}-${state.length}-${state.optionData.cassetteThickness}x${state.optionData.amountOfCassettes}`;
        // },
        // TODO: separate methods (util)
        productCode(state, getters) {
            const innerMaterial = getMaterialAbbreviation(state.innerMaterial) ? `-${getMaterialAbbreviation(state.innerMaterial)}` : '';
            const outerMaterial = getMaterialAbbreviation(state.outerCover) ? `-${getMaterialAbbreviation(state.outerCover)}` : '';
            const shellInsulation = getShellInsulationAbbreviation(state.shellInsulation) ? `-${getShellInsulationAbbreviation(state.shellInsulation)}` : '';
            const connectionProfile = state.connectionProfile === 'Z-slat' ? '' : `-${state.connectionProfile}`;
            const orientation = state.orientation === 'horizontal' ? '-1' : '-2';
            switch (getters.silencerClass) {
                case 'MPK':
                case 'MPP':
                case 'MPC':
                    return `${getters.silencerClass}${orientation}${innerMaterial} ${state.width}x${state.height}-${state.dimensionX}-${state.dimensionY}-${state.optionData.cassetteThickness}x${state.optionData.amountOfCassettes}${connectionProfile}`;
                case 'MPKI':
                case 'MPPI':
                case 'MPCI':
                    return `${getters.silencerClass}${orientation}${innerMaterial}${shellInsulation}${outerMaterial} ${state.width}x${state.height}-${state.dimensionX}-${state.dimensionY}-${state.optionData.cassetteThickness}x${state.optionData.amountOfCassettes}${connectionProfile}`;
                case 'MSKI':
                case 'MSPI':
                case 'MSCI':
                    return `${getters.silencerClass}${innerMaterial}${shellInsulation}${outerMaterial} ${state.width}x${state.height}-${state.length}-${state.optionData.cassetteThickness}x${state.optionData.amountOfCassettes}${connectionProfile}`;
                    // if (outerMaterial) {
                    //     return `${getters.silencerClass}${innerMaterial}${shellInsulation}${outerMaterial} ${state.width}x${state.height}-${state.length}-${state.optionData.cassetteThickness}x${state.optionData.amountOfCassettes}${connectionProfile}`;
                    // } else {
                    //     return `${getters.silencerClass}${innerMaterial}${shellInsulation}-1${outerMaterial} ${state.width}x${state.height}-${state.dimensionX}-${state.dimensionY}-${state.optionData.cassetteThickness}x${state.optionData.amountOfCassettes}${connectionProfile}`;
                    // }
                case 'MSK':
                case 'MSP':
                case 'MSC':
                    return `${getters.silencerClass}${innerMaterial} ${state.width}x${state.height}-${state.length}-${state.optionData.cassetteThickness}x${state.optionData.amountOfCassettes}${connectionProfile}`;
                case 'MRKA':
                case 'MRPA':
                case 'MRCA':
                    return `${getters.silencerClass}${innerMaterial} ${state.width}x${state.height}-${state.length}-${state.optionData.cassetteThickness}x${state.optionData.amountOfCassettes}${connectionProfile}`;
                default:
                    return '';
            }
        },
        productNameAppendix(state, getters) {
            return getNameAppendixByClass(getters.silencerClass);
        },
        productName(state, getters) {
            const locale = sessionStorage.getItem('locale') || 'english';
            const appendix = getters.productNameAppendix[locale] || '';

            return `${getters.silencerClass} ${getters.productCode} ${appendix}`;
        },
        optionRecommendation({ options }) {
            const result = {
                minPressureDrop: 0,
                minPressureDropOptionIndex: -1,
                maxAttenuation: 0,
                maxAttenuationOptionIndex: -1,
                minResultAfterLw: 0,
                minResultAfterLwOptionIndex: -1,
                minResultAfterAFilter: 0,
                minResultAfterAFilterOptionIndex: -1,
                recommendedOptionIndex: -1,
            };
            if (!options.length) {
                return result;
            }
            options.forEach((option, index) => {
                if (index === 0) {
                    result.minPressureDrop = option.pressureDrop;
                    result.minPressureDropOptionIndex = 0;
                    result.maxAttenuation = option.totalAttenuationWithAirflow;
                    result.maxAttenuationOptionIndex = 0;
                    result.minResultAfterLw = option.resultAfterLw;
                    result.minResultAfterLwOptionIndex = 0;
                    result.minResultAfterAFilter = option.resultAfterAFilter;
                    result.minResultAfterAFilterOptionIndex = 0;

                    return;
                }
                if (option.pressureDrop < result.minPressureDrop) {
                    result.minPressureDrop = option.pressureDrop;
                    result.minPressureDropOptionIndex = index;
                }
                if (option.totalAttenuationWithAirflow > result.maxAttenuation) {
                    result.maxAttenuation = option.totalAttenuationWithAirflow;
                    result.maxAttenuationOptionIndex = index;
                }
                if (option.resultAfterLw < result.minResultAfterLw) {
                    result.minResultAfterLw = option.resultAfterLw;
                    result.minResultAfterLwOptionIndex = index;
                }
                if (option.resultAfterAFilter < result.minResultAfterAFilter) {
                    result.minResultAfterAFilter = option.resultAfterAFilter;
                    result.minResultAfterAFilterOptionIndex = index;
                }
            });
            const indexes = [
                result.minPressureDropOptionIndex,
                result.maxAttenuationOptionIndex,
                result.minResultAfterLwOptionIndex,
                result.minResultAfterAFilterOptionIndex,
            ];
            const score = {};
            let maxScore = 1;
            indexes.forEach((index) => {
                score[index] = (index in score) ? score[index] + 1 : 1;
                if (score[index] > maxScore) {
                    maxScore = score[index];
                    result.recommendedOptionIndex = index;
                }
            });

            return result;
        },
        rectSilencer(state, getters) {
            const id = `rectSilencer-${Date.now()}`;
            const rectSilencer = {
                ...state,
                id: state.id || id,
                name: getters['productCode'],
                code: getters['productCode'],
                class: getters['silencerClass'],
                imageURL: resolveRectSilencerImage(getters['silencerClass'], state.orientation),
                nameAppendix: getters['productNameAppendix'],
            };
            rectSilencer.type = 'rectSilencer';
            rectSilencer.soundAttenuation = cloneOctaveBandRange(rectSilencer.optionData.soundAttenuation);
            rectSilencer.defaultSoundAttenuation = cloneOctaveBandRange(rectSilencer.optionData.soundAttenuation);
            rectSilencer.defaultSoundPowerLevel = emptyOctaveBandRange();
            delete rectSilencer.requestPending;
            delete rectSilencer.options;
            delete rectSilencer.inputSoundPower;
            
            return rectSilencer;
        },
    },
    mutations: {
        setId(state, id) {
            state.id = id;
        },
        setName(state, name) {
            state.name = name;
        },
        setClass(state, silencerClass) {
            state.class = silencerClass;
        },
        setCode(state, code) {
            state.code = code;
        },
        setImageURL(state, imageURL) {
            state.imageURL = imageURL;
        },
        setWidth(state, width) {
            state.width = toValidNumber(width);
        },
        setHeight(state, height) {
            state.height = toValidNumber(height);
        },
        setLength(state, length) {
            state.length = toValidNumber(length);
        },
        setDimensionX(state, dimensionX) {
            state.dimensionX = toValidNumber(dimensionX);
        },
        setDimensionY(state, dimensionY) {
            state.dimensionY = toValidNumber(dimensionY);
        },
        setShape(state, shape) {
            state.shape = shape;
        },
        setOrientation(state, orientation) {
            state.orientation = orientation;
        },
        setPosition(state, position) {
            state.position = position;
        },
        setInputAirflow(state, inputAirflow) {
            state.inputAirflow = toValidNumber(inputAirflow);
        },
        setShellInsulation(state, shellInsulation) {
            state.shellInsulation = shellInsulation;
        },
        setCassetteMaterial(state, cassetteMaterial) {
            state.cassetteMaterial = cassetteMaterial;
        },
        setOuterCover(state, outerCover) {
            state.outerCover = outerCover;
        },
        setInnerMaterial(state, innerMaterial) {
            state.innerMaterial = innerMaterial;
        },
        setOpenable(state, openable) {
            state.openable = Boolean(openable);
        },
        setConnectionProfile(state, connectionProfile) {
            state.connectionProfile = connectionProfile;
        },
        setOptions(state, options) {
            if (!Array.isArray(options)) {
                return;
            }
            state.options = Array.from(options);
        },
        setRequestPending(state, requestPending) {
            state.requestPending = Boolean(requestPending);
        },
        setOptionData(state, option) {
            state.optionData = {
                cassetteThickness: option.cassetteThickness,
                amountOfCassettes: option.amountOfCassettes,
                cassetteSpacing: option.cassetteSpacing,
                edgeGap: option.edgeGap,
                silencerGapSpeed: option.silencerGapSpeed,
                silencerEndSpeed: option.silencerEndSpeed,
                pressureDrop: option.pressureDrop,
                soundAttenuation: cloneOctaveBandRange(option.soundAttenuation),
                totalAttenuationWithAirflow: option.totalAttenuationWithAirflow,
                soundAttenuationWithAirflow: cloneOctaveBandRange(option.soundAttenuationWithAirflow),
                resultAfterLw: option.resultAfterLw,
                resultSoundPowerAfterLw: cloneOctaveBandRange(option.resultSoundPowerAfterLw),
                resultAfterAFilter: option.resultAfterAFilter,
            };
        },
        resetOptionData(state) {
            state.optionData = {
                cassetteThickness: 0,
                amountOfCassettes: 0,
                cassetteSpacing: 0,
                edgeGap: 0,
                silencerGapSpeed: 0,
                silencerEndSpeed: 0,
                pressureDrop: 0,
                soundAttenuation: emptyOctaveBandRange(),
                totalAttenuationWithAirflow: 0,
                soundAttenuationWithAirflow: emptyOctaveBandRange(),
                resultAfterLw: 0,
                resultAfterAFilter: 0,
            };
        },
        resetData(state) {
            state.id = '';
            state.name = '';
            state.class = '';
            state.code = '';
            state.imageURL = '';
            state.width = 0;
            state.height = 0;
            state.length = 0;
            state.dimensionX = 150;
            state.dimensionY = 150;
            state.shape = 'straight';
            state.orientation = 'horizontal';
            state.position = 'supply';
            state.inputAirflow = 0;
            state.shellInsulation = '0mm';
            state.cassetteMaterial = 'mineralWool';
            state.outerCover = 'none';
            state.innerMaterial = 'galvanizedSteel';
            state.openable = false;
            state.connectionProfile = 'Z-slat';
            state.options = [];
            state.requestPending = false;
            state.optionData = {
                cassetteThickness: 0,
                amountOfCassettes: 0,
                cassetteSpacing: 0,
                edgeGap: 0,
                silencerGapSpeed: 0,
                silencerEndSpeed: 0,
                pressureDrop: 0,
                soundAttenuation: emptyOctaveBandRange(),
                totalAttenuationWithAirflow: 0,
                soundAttenuationWithAirflow: emptyOctaveBandRange(),
                resultAfterLw: 0,
                resultAfterAFilter: 0,
            };
            state.inputSoundPower = emptyOctaveBandRange();
            state.soundAttenuation = emptyOctaveBandRange();
            state.soundPowerLevel = emptyOctaveBandRange();
        },
        setData(state, data) {
            if (!data.id) {
                return;
            }
            const { optionData = {} } = data;
            state.id = data.id;
            state.name = data.name || '';
            state.class = data.class || '';
            state.code = data.code || '';
            state.imageURL = data.imageURL || '';
            state.width = data.width || 0;
            state.height = data.height || 0;
            state.length = data.length || 0;
            state.dimensionX = data.dimensionX || 150;
            state.dimensionY = data.dimensionY || 150;
            state.shape = data.shape || 'straight';
            state.orientation = data.orientation || 'horizontal';
            state.position = data.position || 'supply';
            state.inputAirflow = data.inputAirflow || 0;
            state.shellInsulation = data.outerCover === 'none' ? '0mm' : (data.shellInsulation || '50mm');
            state.cassetteMaterial = data.cassetteMaterial || 'mineralWool';
            state.outerCover = state.shellInsulation === '0mm' ? 'none' : (data.outerCover || 'galvanizedSteel');
            state.innerMaterial = data.innerMaterial || 'galvanizedSteel';
            state.openable = Boolean(data.openable); // !(data.shellInsulation === '0mm' || data.outerCover === 'none'); // data.openable || false; // (state.shellInsulation > 0 || state.outerCover !== 'none');
            state.connectionProfile = !state.openable ? 'Z-slat' : (data.connectionProfile === 'Z-slat' ? 'E20' : data.connectionProfile);
            state.options = [];
            state.requestPending = false;
            state.optionData = {
                cassetteThickness: optionData.cassetteThickness || 0,
                amountOfCassettes: optionData.amountOfCassettes || 0,
                cassetteSpacing: optionData.cassetteSpacing || 0,
                edgeGap: optionData.edgeGap || 0,
                silencerGapSpeed: optionData.silencerGapSpeed || 0,
                silencerEndSpeed: optionData.silencerEndSpeed || 0,
                pressureDrop: optionData.pressureDrop || 0,
                soundAttenuation: optionData.soundAttenuation ? { ...optionData.soundAttenuation } : emptyOctaveBandRange(),
                totalAttenuationWithAirflow: optionData.totalAttenuationWithAirflow || 0,
                soundAttenuationWithAirflow: optionData.soundAttenuationWithAirflow ? { ...optionData.soundAttenuationWithAirflow } : emptyOctaveBandRange(),
                resultAfterLw: optionData.resultAfterLw || 0,
                resultAfterAFilter: optionData.resultAfterAFilter || 0,
            };
            state.inputSoundPower = emptyOctaveBandRange();
            state.soundAttenuation = data.soundAttenuation ? { ...data.soundAttenuation } : emptyOctaveBandRange();
            state.soundPowerLevel = data.soundPowerLevel ? { ...data.soundPowerLevel } : emptyOctaveBandRange();
        },
        setInputSoundPower(state, inputSoundPower) {
            state.inputSoundPower = cloneOctaveBandRange(inputSoundPower);
        },
    },
    actions: {
        setId({ commit }, id) {
            commit('setId', id);
        },
        setName({ commit }, name) {
            commit('setName', name);
        },
        setClass({ commit }, silencerClass) {
            commit('setClass', silencerClass);
        },
        setCode({ commit }, code) {
            commit('setCode', code);
        },
        setImageURL({ commit }, imageURL) {
            commit('setImageURL', imageURL);
        },
        async setWidth({ state, commit, dispatch }, width) {
            commit('setWidth', width);
            if (state.shape === 'bend') {
                if (state.dimensionX > 2500 - width) {
                    dispatch('setDimensionX', 2500 - width);
                }
            }
            await dispatch('calculateLength');
        },
        async setHeight({ state, commit, dispatch }, height) {
            commit('setHeight', height);
            if (state.shape === 'bend') {
                if (state.dimensionY > 2500 - height) {
                    dispatch('setDimensionY', 2500 - height);
                }
            }
            await dispatch('calculateLength');
        },
        setLength({ commit }, length) {
            commit('setLength', length);
        },
        calculateLength({ state, commit }) {
            if (state.shape !== 'bend') {
                return;
            }
            const length = state.orientation === 'horizontal'
                ? state.width + state.dimensionX + state.dimensionY
                : state.height + state.dimensionX + state.dimensionY;
            commit('setLength', length);
        },
        async setDimensionX({ state, commit, dispatch }, dimensionX) {
            if (String(dimensionX).length < 3 || Number.isNaN(Number(dimensionX))) {
                return;
            }
            let value = Number(dimensionX);
            if (Number(dimensionX) < 150) {
                value = 150;
            }
            if (state.width + value > 2500) {
                value = 2500 - state.width;
            }

            commit('setDimensionX', value);
            await dispatch('calculateLength');
        },
        async setDimensionY({ state, commit, dispatch }, dimensionY) {
            if (String(dimensionY).length < 3 || Number.isNaN(Number(dimensionY))) {
                return;
            }
            let value = Number(dimensionY);
            if (Number(dimensionY) < 150) {
                value = 150;
            }
            if (state.height + value > 2500) {
                value = 2500 - state.height;
            }

            commit('setDimensionY', value);
            await dispatch('calculateLength');
        },
        async setShape({ commit, dispatch }, shape) {
            commit('setShape', shape);
            if (shape === 'bend') {
                await dispatch('setOpenable', false);
            }
            await dispatch('calculateLength');
        },
        setOrientation({ commit }, orientation) {
            commit('setOrientation', orientation);
        },
        setPosition({ commit }, position) {
            commit('setPosition', position);
        },
        setInputAirflow({ commit }, inputAirflow) {
            commit('setInputAirflow', inputAirflow);
        },
        setShellInsulation({ commit, state }, shellInsulation) {
            commit('setShellInsulation', shellInsulation);
            if (shellInsulation !== '0mm') {
                if (state.outerCover === 'none') {
                    commit('setOuterCover', 'galvanizedSteel');
                }

                return;
            }
            commit('setOuterCover', 'none');
            commit('setOpenable', false);
        },
        setCassetteMaterial({ commit }, cassetteMaterial) {
            commit('setCassetteMaterial', cassetteMaterial);
        },
        setOuterCover({ commit, state }, outerCover) {
            if (outerCover === 'stainlessSteel' && state.openable) {
                return;
            }
            commit('setOuterCover', outerCover);
            if (outerCover !== 'none') {
                if (state.shellInsulation === '0mm') {
                    commit('setShellInsulation', '50mm');
                }
                if (state.openable) {
                    commit('setInnerMaterial', outerCover);
                }

                return;
            }
            commit('setShellInsulation', '0mm');
            commit('setOpenable', false);
        },
        async setInnerMaterial({ commit, state, dispatch }, innerMaterial) {
            if (innerMaterial === 'stainlessSteel' && state.openable) {
                return;
            }
            commit('setInnerMaterial', innerMaterial);
            if (state.openable) {
                await dispatch('setOuterCover', innerMaterial === 'none' ? 'none' : innerMaterial);
            }
        },
        async setOpenable({ commit, dispatch, state }, openable) {
            commit('setOpenable', openable);
            // if (!openable) {
            //     commit('setShellInsulation', 0);
            //     commit('setOuterCover', 'none');

            //     return;
            // }
            if (openable === true) {
                await dispatch('setShape', 'straight');
            }
            if (openable && state.shellInsulation === '0mm') {
                commit('setShellInsulation', '50mm');
            }
            if (state.outerCover === 'none') {
                await dispatch('setOuterCover', 'galvanizedSteel');
                await dispatch('setInnerMaterial', 'galvanizedSteel');
            } else if (state.outerCover === 'stainlessSteel') {
                await dispatch('setOuterCover', 'galvanizedSteel');
                await dispatch('setInnerMaterial', 'galvanizedSteel');
            } else {
                await dispatch('setInnerMaterial', state.outerCover);
            }
            if (openable && state.connectionProfile === 'Z-slat') {
                commit('setConnectionProfile', 'E20');
            }
        },
        setConnectionProfile({ commit, state }, connectionProfile) {
            if (!['Z-slat', 'E20', 'E30'].includes(connectionProfile)) {
                return;
            }
            if (connectionProfile === 'Z-slat' && state.openable) {
                return;
            }
            commit('setConnectionProfile', connectionProfile);
        },
        resetData({ commit }) {
            commit('resetData');
        },
        async calculateOptions({ commit, state }) {
            try {
                if (state.width < 300 || state.width > 2500) {
                    return false;
                }
                if (state.height < 200 || state.height > 2500) {
                    return false;
                }
                if (state.length < 600 || (state.shape === 'straight' && state.length > 2500)) {
                    return false;
                }
                commit('setRequestPending', true);
                const options = await RectSilencerService.calculateOptions({
                    width: state.width,
                    height: state.height,
                    length: state.length,
                    inputAirflow: state.inputAirflow,
                    inputSoundPower: cloneOctaveBandRange(state.inputSoundPower),
                    cassetteMaterial: state.cassetteMaterial,
                });
                if (!Array.isArray(options) || !options.length) {
                    return;
                }
                commit('setOptions', options);
                commit('setOptionData', options[0]);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                commit('setRequestPending', false);
            }
        },
        setOption({ commit, state }, optionIndex) {
            if (optionIndex < 0 || !(optionIndex in state.options)) {
                return;
            }
            commit('setOptionData', state.options[optionIndex]);
        },
        addToActiveSystem({ dispatch, commit, state, getters }, { mode = 'create' }) {
            if (!state.id) {
                commit('setId', `rectSilencer-${Date.now()}`);
            }
            commit('setName', getters['productCode']);
            commit('setCode', getters['productCode']);
            commit('setClass', getters['silencerClass']);
            commit('setImageURL', resolveRectSilencerImage(getters['silencerClass'], state.orientation));
            const rectSilencer = {
                ...state,
                nameAppendix: getters['productNameAppendix'],
            };
            rectSilencer.type = 'rectSilencer';
            rectSilencer.soundAttenuation = cloneOctaveBandRange(rectSilencer.optionData.soundAttenuation);
            rectSilencer.defaultSoundAttenuation = cloneOctaveBandRange(rectSilencer.optionData.soundAttenuation);
            rectSilencer.defaultSoundPowerLevel = emptyOctaveBandRange();
            delete rectSilencer.requestPending;
            delete rectSilencer.options;
            delete rectSilencer.inputSoundPower;
            if (mode === 'create') {
                dispatch('activeSystemModule/addProduct', { product: rectSilencer }, { root: true });
            }
            else {
                dispatch('activeSystemModule/replaceProduct', { product: rectSilencer }, { root: true });
            }
        },
        setData({ commit, dispatch }, data) {
            commit('setData', data);
            dispatch('calculateLength');
        },
        setInputSoundPower({ commit }, inputSoundPower) {
            commit('setInputSoundPower', inputSoundPower);
        }
    },
}
