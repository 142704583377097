<template>
    <div class="product-attributes">
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('rectSilencerCard.dimensions') }} (A, B, L):</div>
            <div class="attribute-value text-regular">{{ product.width }} mm, {{ product.height }} mm, {{ product.length }} mm</div>
        </div>
        <div class="product-attribute" v-if="product.shape === 'bend'">
            <div class="attribute-title text-regular">{{ $t('rectSilencerCard.dimensions') }} (x, y):</div>
            <div class="attribute-value text-regular">{{ product.dimensionX }} mm, {{ product.dimensionY }} mm</div>
        </div>
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('pdf.productData.cassetteWidthAndGap') }}:</div>
            <div class="attribute-value text-regular">{{ product.optionData.cassetteThickness }} mm, {{ roundValue(product.optionData.cassetteSpacing) }} mm</div>
        </div>
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('rectSilencerData.edgeGap') }} (z/2):</div>
            <div class="attribute-value text-regular">{{ roundValue(product.optionData.edgeGap) }} mm</div>
        </div>
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('rectSilencerCard.cassettePiecesAndMaterial') }}:</div>
            <div class="attribute-value text-regular">{{ product.optionData.amountOfCassettes }}, {{ cassetteMaterial }}</div>
        </div>
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('rectSilencerData.shellInsulation') }} (C):</div>
            <div class="attribute-value text-regular">{{ product.shellInsulation }}</div>
        </div>
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('rectSilencerData.outerCover') }}:</div>
            <div class="attribute-value text-regular">{{ outerCover }}</div>
        </div>
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('rectSilencerData.innerMaterial') }}:</div>
            <div class="attribute-value text-regular">{{ innerMaterial }}</div>
        </div>
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('rectSilencerCard.airflow') }}:</div>
            <div class="attribute-value text-regular">{{ product.inputAirflow }} l/s</div>
        </div>
        <div class="product-attribute">
            <div class="attribute-title text-regular">{{ $t('rectSilencerData.pressureDrop') }}:</div>
            <div class="attribute-value text-regular">{{ roundValue(product.optionData.pressureDrop) }} Pa</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RectSilencerProperties',
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        manufactureMaterials() {
            return {
                'none': this.$t('rectSilencerData.manufactureMaterial.none'),
                'galvanizedSteel': `${this.$t('rectSilencerData.manufactureMaterial.galvanizedSteel')} ZN275`,
                'stainlessSteel': `${this.$t('rectSilencerData.manufactureMaterial.stainlessSteel')} AISI 316`,
                'magnelis': `${this.$t('rectSilencerData.manufactureMaterial.magnelis')} ZM310`,
            }
        },
        insulationMaterials() {
            return {
                'polyester': this.$t('rectSilencerData.cassetteMaterial.polyester'),
                'mineralWool': this.$t('rectSilencerData.cassetteMaterial.mineralWool'),
                'cleantec': this.$t('rectSilencerData.cassetteMaterial.cleantec'),
            }
        },
        cassetteMaterial() {
            if (!this.product.cassetteMaterial) {
                return '';
            }

            return this.insulationMaterials[this.product.cassetteMaterial] || '';
        },
        outerCover() {
            if (!this.product.outerCover) {
                return '';
            }

            return this.manufactureMaterials[this.product.outerCover] || '';
        },
        innerMaterial() {
            if (!this.product.innerMaterial) {
                return '';
            }

            return this.manufactureMaterials[this.product.innerMaterial] || '';
        },
    },
    methods: {
        roundValue(value) {
            return Math.round(Number(value));
        },
    },
}
</script>

<style lang="scss">
@use 'src/assets/css/pdf/page.scss';
@use 'src/assets/css/pdf/page-content.scss';
</style>