<template>
    <div class="footer">
        <div class="column logo-and-contacts">
            <img class="footer-logo" src="../../assets/logo_small_white.svg" alt="Logo Small White">
            <div class="title">{{ $t('footer.generalInfo.title') }}</div>
            <div class="text">{{ $t('footer.generalInfo.address') }}<br>{{ $t('footer.generalInfo.zip') }}</div>
            <div class="text">{{ $t('footer.generalInfo.phone') }}</div>
            <div class="text">{{ $t('footer.generalInfo.infoEmail') }}</div>
        </div>
        <!-- <div class="column products">
            <div class="title">{{ $t('footer.products') }}</div>
            <div class="text">
                <a :href="$t('footer.links.nordDiffuser')" target="_blank">NORDdiffuser</a>
                </div>
            <div class="text">
                <a :href="$t('footer.links.nordGrille')" target="_blank">NORDgrille</a>
                </div>
            <div class="text">
                <a :href="$t('footer.links.nordFire')" target="_blank">NORDfire</a>
                </div>
            <div class="text">
                <a :href="$t('footer.links.nordSilencer')" target="_blank">NORDsilencer</a>
                </div>
            <div class="text">
                <a :href="$t('footer.links.nordDamper')" target="_blank">NORDdamper</a>
                </div>
            <div class="text">
                <a :href="$t('footer.links.nordExternal')" target="_blank">NORDexternal</a>
                </div>
            <div class="text">
                <a :href="$t('footer.links.nordRoof')" target="_blank">NORDroof</a>
                </div>
            <div class="text">
                <a :href="$t('footer.links.nordCanopy')" target="_blank">NORDcanopy</a>
                </div>
        </div> -->
        <div class="column company">
            <div class="title">{{ $t('footer.company') }}</div>
            <div class="text">
                <a :href="$t('footer.links.aboutUs')" target="_blank">{{ $t('footer.aboutUs') }}</a>
            </div>
            <div class="text">
                <a :href="$t('footer.links.products')" target="_blank">{{ $t('footer.products') }}</a>
            </div>
            <div class="text">
                <a :href="$t('footer.links.contactUs')" target="_blank">{{ $t('footer.contactUs') }}</a>
            </div>
            <div class="text">
                <a :href="$t('footer.links.privacy')" target="_blank">{{ $t('footer.privacy') }}</a>
            </div>
            <div class="text">
                <a :href="$t('footer.links.jobVacancies')" target="_blank">{{ $t('footer.carrers') }}</a>
            </div>
        </div>
        <!-- <div class="column guide-and-help">
            <div class="title">{{ $t('footer.guideAndHelp') }}</div>
            <div class="text">
                <a href="https://www.etsnord.fi/content/uploads/2021/01/ETS_NORD_Privacy_Statement_en.pdf" target="_blank">{{ $t('footer.privacy') }}</a>
            </div>
        </div> -->
        <div class="column get-in-touch">
            <div class="title">{{ $t('footer.getInTouch') }}</div>
            <div class="icons-container">
                <a :href="$t('footer.links.facebook')" target="_blank">
                    <img src="../../assets/icons/singular/facebook.svg" width="32" height="32" alt="Facebook logo" class="mr-4">
                </a>
                <a :href="$t('footer.links.instagram')" target="_blank">
                    <img src="../../assets/icons/singular/instagram.svg" width="32" height="32" alt="Instagram logo" class="mr-4">
                </a>
                <a :href="$t('footer.links.linkedin')" target="_blank">
                    <img src="../../assets/icons/singular/linkedin.svg" width="32" height="32" alt="LinkedIn logo" class="mr-4">
                </a>
                <a :href="$t('footer.links.youtube')" target="_blank">
                    <img src="../../assets/icons/singular/youtube.svg" width="32" height="32" alt="YouTube logo">
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import contactData from "@/constants/etsContactData";

export default {
    name: 'Footer',
    data() {
        return {
            contactData: contactData
        }
    }
}
</script>

<style scoped>
.footer {
    display: flex;
    width: 100%;
    height: 300px;
    left: 0;
    background: #4C5055;
    padding: 48px 120px;
    box-sizing: border-box;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.footer .footer-logo {
    width: 175px;
    height: 24px;
    margin-bottom: 40px;
}

.footer .title {
    color: #FFFFFF;
    margin-bottom: 16px;
}

.footer .text {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.footer .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.footer .logo-and-contacts .text {
    margin-bottom: 14px;
}

.footer .get-in-touch .text {
margin-bottom: 16px;
}

.footer .get-in-touch .text img {
margin-right: 16px;
}

.icons-container {
     display: flex;
     flex-wrap: nowrap;
     align-items: center;
}

.icons-container img {
    display: flex;
}
</style>
