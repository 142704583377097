<template>
    <ProductCard class="mb-6" :direction="direction">
        <template v-slot:product-image>
            <CustomImageDropdown @imageSelected="onImageSelected">
                <template v-slot:main-image>
                    <img :src="imageURL(product)" alt="Custom Product Image">
                </template>
            </CustomImageDropdown>
        </template>
        <template v-slot:product-properties>
            <div class="product-properties">
                <InputField :placeholder="$t('productsList.productSpecs.productName')" v-model="product.name"></InputField>
                <InputField :placeholder="$t('productsList.productSpecs.productCode')" v-model="product.code" class="mt-3"></InputField>
            </div>
        </template>
        <template v-slot:product-sound-adjustment>
            <div class="action-link"></div>
        </template>
        <template v-slot:product-actions>
            <div class="column align-items-center">
                <Button type="primary" size="medium" @click="onAddProduct">{{ $t('productsList.controls.add') }}</Button>
            </div>
        </template>
    </ProductCard>
    <EditSoundDataModal
        v-model="editSoundDataModalOpened"
        :title="product.name || 'Custom product'"
        :product="product"
        @onSoundPowerChanged="onSoundPowerChanged"
        @onSoundAttenuationChanged="onSoundAttenuationChanged"
    ></EditSoundDataModal>
</template>

<script>
import { mapActions } from 'vuex';
import ProductCard from '@/components/products/product/ProductCard.vue';
import InputField from '@/components/general/InputField.vue';
import Button from '@/components/general/Button.vue';
import CustomImageDropdown from '@/components/products/custom/CustomImageDropdown.vue';
import EditSoundDataModal from '@/components/products/abstract/EditSoundDataModal.vue';
import { emptyOctaveBandRange } from '@/utils';

const DEFAULT_IMAGE_URL = require(`@/assets/abstract_product.svg`);

function productId() {
    return `custom-${(new Date()).valueOf()}`;
}

function generateEmptyProduct() {
    return {
        id: productId(),
        type: 'custom',
        name: '',
        class: 'custom',
        code: '',
        quantity: 0,
        imageURL: '', // DEFAULT_IMAGE_URL,
        defaultSoundAttenuation: emptyOctaveBandRange(),
        soundAttenuation: emptyOctaveBandRange(),
        defaultSoundPowerLevel: emptyOctaveBandRange(),
        soundPowerLevel: emptyOctaveBandRange(),
    }
}

export default {
    name: 'CustomProductInsertion',
    components: {
        ProductCard,
        InputField,
        Button,
        CustomImageDropdown,
        EditSoundDataModal,
    },
    props: {
        direction: {
            type: String,
            default: 'horizontal',
            validator: (value) => (['horizontal', 'vertical'].includes(value)) 
        },
    },
    data() {
        return {
            editSoundDataModalOpened: false,
            product: generateEmptyProduct(),
        };
    },
    methods: {
        ...mapActions('activeSystemModule', ['addProduct']),
        resetProduct() {
            this.product = generateEmptyProduct();
        },
        async onAddProduct() {
            if (!this.product || !this.product.id) {
                return;
            }
            await this.addProduct({ product: this.product });
            this.resetProduct();
        },
        imageURL(product) {
            if (product.imageURL === '') {
                return DEFAULT_IMAGE_URL;
            }

            return product.imageURL;
        },
        onImageSelected(imageURL) {
            this.product.imageURL = imageURL;
        },
        onSoundPowerChanged(soundPower) {
            this.product.soundPowerLevel = soundPower;
        },
        onSoundAttenuationChanged(soundAttenuation) {
            this.product.soundAttenuation = soundAttenuation;
        },
    },
}
</script>
