<template>
    <div class="tooltip-box" @mouseleave="closeTooltip">
        <span @mouseenter="openTooltip">
            <slot />
        </span>
        <div ref="tooltip" class="tooltip" :class="positionClass">
            <div class="tooltip-heading">
                <slot name="tooltip-heading" />
                <i class="close-tooltip" @click.stop="closeTooltip" />
            </div>
            <div v-if="text" v-html="text" class="tooltip-content"></div>
            <slot v-else name="tooltip-content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tooltip",
    props: {
        position: {
            type: String,
            default: "right",
        },
        text: {
            type: String,
        }
    },
    data() {
        return {
            show: false,
            overflow: false,
        };
    },
    computed: {
        positionClass() {
            return {
                'top': this.position == 'top',
                'bottom': this.position == 'bottom' || this.overflow,
                'left': this.position == 'left',
                'right': this.position == 'right' && !this.overflow,
                'hidden': this.show == false,
            }
        },
    },
    methods: {
        openTooltip() {
            this.tooltipOverflow();
            this.show = true;
        },
        closeTooltip() {
            this.tooltipOverflow();
            this.show = false;
        },
        tooltipOverflow() {
            if (this.$refs.tooltip.getBoundingClientRect().right > window.innerWidth) {
                this.overflow = true;
            } else {
                this.overflow = false;
            }
        }
    },
};
</script>

<style scoped>
.tooltip-box {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    cursor: default;
    display: flex;
    flex-direction: column;
    z-index: 30000;
    align-items: center;
    min-height: 20px;
    width: max-content;
    max-width: 350px;
    background-color: #ffffff;
    border-radius: 4px;
    font-family: Helvetica Neue;
    font-size: 16px;
    line-height: 18px;
    color: #4c5055;
    box-shadow: 0px 2px 8px rgba(76, 80, 85, 0.16);
    opacity: 1;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
}

.tooltip.hidden {
    visibility: hidden;
    opacity: 0;
}

.tooltip-heading {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    background-color: #CC0000;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 10px;
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    z-index: 3;
}

.tooltip-heading i.close-tooltip {
    display: flex;
    cursor: pointer;
    width: 14px;
    height: 14px;
    background-image: url('../../assets/icons/singular/cross_light.svg');
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 3px;
}

.tooltip-content {
    padding: 15px 10px;
    position: relative;
    border: 1px solid #4c5055;
    border-top: none;
    border-radius: 4px;
    margin-top: -4px;
    width: 100%;
}

.tooltip.top {
    bottom: 100%;
    left: 50%;
    margin-left: -30px;
    margin-bottom: 5px;
}

.tooltip.bottom {
    top: 100%;
    right: 0%;
    margin-right: -150px;
    margin-top: 3px;
}

.tooltip.right {
    top: 0%;
    left: 100%;
    margin-left: 3px;
    margin-top: -68px;
}

.tooltip.left {
    bottom: 0%;
    right: 100%;
    margin-right: 5px;
}

.tooltip.right .tooltip-content::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0px;
    margin-left: -12px;
    top: 30px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-right-color: #4c5055;
}

.tooltip.right .tooltip-content::after {
    content: ' ';
    display: block;
    position: absolute;
    margin-left: -20px;
    top: 31px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-color: #ffffff;
}

.tooltip.bottom::after {
    content: ' ';
    display: block;
    position: absolute;
    margin-left: 25px;
    top: -10px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: #CC0000;
}
</style>
