import { createI18n } from 'vue-i18n';
import englishTranslations from './english.json';
import estonianTranslations from './estonian.json';
import swedishTranslations from './swedish.json';
import finnishTranslations from './finnish.json';
import danishTranslations from './danish.json';

let locale = localStorage.getItem('locale');
if (!locale) {
    locale = 'english';
    localStorage.setItem('locale', locale);
}

const i18n = createI18n({
    locale,
    messages: {
        english: englishTranslations,
        estonian: estonianTranslations,
        swedish: swedishTranslations,
        finnish: finnishTranslations,
        danish: danishTranslations,
    },
    fallbackLocale: 'english', //en-US
});

export default i18n;
