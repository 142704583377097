<template>
    <div class="row mt-4" v-if="calculations.input">
        <OctaveBandContainer
            class="flex-1"
            :title="$t('projectBreakdown.products.soundPowerChange.input')"
            :power-level-kit="roundOctaveBandRange(calculations.input)"
        />
    </div>
    <div class="row mt-4" v-if="calculations.attenuation">
        <OctaveBandContainer
            class="flex-1"
            :title="$t('projectBreakdown.products.soundPowerChange.attenuation')"
            :power-level-kit="roundOctaveBandRange(calculations.attenuation)"
        />
    </div>
    <div class="row mt-4" v-if="calculations.soundPower">
        <OctaveBandContainer
            class="flex-1"
            :title="$t(`projectBreakdown.products.soundPowerChange.soundPower`)"
            :power-level-kit="roundOctaveBandRange(calculations.soundPower)"
        />
    </div>
    <div class="row mt-4" v-if="calculations.output">
        <OctaveBandContainer
            class="flex-1"
            :title="$t(`projectBreakdown.products.soundPowerChange.output`)"
            :power-level-kit="roundOctaveBandRange(calculations.output)"
        />
    </div>
</template>

<script>
import OctaveBandContainer from '@/components/products/product/OctaveBandContainer';

export default {
    name: 'SoundPowerChange',
    components: { OctaveBandContainer },
    props: {
        calculations: {
            type: Object,
            required: true,
        }
    },
    methods: {
        roundOctaveBandRange(octaveBandRange) {
            return {
                octaveBand63Hz: Math.round(octaveBandRange.octaveBand63Hz),
                octaveBand125Hz: Math.round(octaveBandRange.octaveBand125Hz),
                octaveBand250Hz: Math.round(octaveBandRange.octaveBand250Hz),
                octaveBand500Hz: Math.round(octaveBandRange.octaveBand500Hz),
                octaveBand1000Hz: Math.round(octaveBandRange.octaveBand1000Hz),
                octaveBand2000Hz: Math.round(octaveBandRange.octaveBand2000Hz),
                octaveBand4000Hz: Math.round(octaveBandRange.octaveBand4000Hz),
                octaveBand8000Hz: Math.round(octaveBandRange.octaveBand8000Hz),
            };
        },
    },
}
</script>
