import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import store from './store';
import router from './routes';
import translations from './translations';
import './assets/css/main.scss';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import primevueConfig from './config/primevueConfig';
import { apiService } from '@/services/general/APIService';


// TODO: isolate, refine, reuse
const idToken = localStorage.getItem('it');
if (idToken) {
    apiService.setHeader('it', idToken);
}
const accessToken = localStorage.getItem('at');
if (accessToken) {
    apiService.setHeader('authorization', `Bearer ${accessToken}`);
}
const refreshToken = localStorage.getItem('rt');
if (refreshToken) {
    apiService.setHeader('rt', refreshToken);
}


const app = createApp(App);
app.use(store);
app.use(router);
app.use(translations);

app.use(PrimeVue, primevueConfig);
app.component('Calendar', Calendar);

app.mount('#app');


// TODO: move it to the separate file with injections
app.provide('resolveTranslationMapValue', function (translationMap) {
    if (!translationMap) {
        return '';
    }
    if (typeof translationMap === 'string') {
        return translationMap;
    }
    if (!(this.$i18n.locale in translationMap)) {
        return '';
    }

    return translationMap[this.$i18n.locale];
});
