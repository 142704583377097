<template>
    <div class="project-info">
        <div class="row info-title section-title mt-8">
            {{ $t('projectBreakdown.projectInfo') }}
        </div>
        <div class="project-attributes">
            <div class="row mt-6">
                <div class="project-attribute column">
                    <div class="attribute-title text-bold">{{ $t('projectSpecificationModal.customer') }}</div>
                    <div class="attribute-value text-regular">{{ contactData || '' }}</div>
                </div>
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.projectName') }}</div>
                    <div class="attribute-value text-regular">{{ name || '' }}</div>
                </div>
            </div>
            <div class="row mt-6">
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.createdAt') }}</div>
                    <div class="attribute-value text-regular">{{ createdAt || todayDate }}</div>
                </div>
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.projectNr') }}</div>
                    <div class="attribute-value text-regular">{{ number || '' }}</div>
                </div>
            </div>
            <div class="row mt-6">
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.email') }}</div>
                    <div class="attribute-value text-regular">{{ email || '' }}</div>
                </div>
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.address') }}</div>
                    <div class="attribute-value text-regular">{{ address || '' }}</div>
                </div>
            </div>
            <div class="row mt-6">
                <div class="project-attribute">
                    <div class="attribute-title text-bold">{{ $t('projectOverview.phoneNumber') }}</div>
                    <div class="attribute-value text-regular">{{ phone || '' }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'ProjectInfo',
    computed: {
        ...mapState('activeProjectModule', [
            'customer',
            'name',
            'number',
            'address',
            'email',
            'phone',
            'contactData',
            'date',
            'comment',
            'createdAt',
        ]),
        ...mapGetters('systemsModule', ['systems']),
        todayDate() {
            return dayjs().format('DD.MM.YYYY');
        },
        createdAt() {
            return dayjs(this.date || this.createdAt || new Date()).format('DD.MM.YYYY');
        },
    },
}
</script>

<style scoped>
.project-info {
    display: flex;
    flex-direction: column;
    /* flex: 1 1 auto; */
}

.row {
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
    padding-right: 2rem;
}
</style>