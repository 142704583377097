import { mapProductData } from '../../transformers/input/mcad';
import { mapRectSilencerData, mapRegularSilencerData } from '../../transformers/output/mcad';
import * as CalculationService from '../../services/calculations';
import store from '../../store';
import router from '../../routes';

// eslint-disable-next-line no-undef
export const EOApiPlugin = (typeof (eoapi) === 'undefined') ? null : eoapi;


async function processRectSilencerData(data) {
    await store.dispatch('rectSilencerModule/setShape', data.shape);
    await store.dispatch('rectSilencerModule/setWidth', data.width);
    await store.dispatch('rectSilencerModule/setHeight', data.height);
    await store.dispatch('rectSilencerModule/setInputAirflow', data.inputAirflow);
    await store.dispatch('rectSilencerModule/setInputSoundPower', data.soundPower);
    await store.dispatch('mcadCalculationsModule/setInputAirflow', data.inputAirflow);
    await store.dispatch('mcadCalculationsModule/setInputSoundPower', data.soundPower);
    if (window.firstPluginMessageTimeout) {
        clearTimeout(window.firstPluginMessageTimeout);
    }
    await router.push({ name: 'rect-silencer-modelling' });
}

async function processRoundSilencerData(data) {
    const queryParams = {};
    if (data.diameter) {
        queryParams.diameter = data.diameter;
    }
    await store.dispatch('mcadCalculationsModule/setInputAirflow', data.inputAirflow);
    await store.dispatch('mcadCalculationsModule/setInputSoundPower', data.soundPower);
    if (window.firstPluginMessageTimeout) {
        clearTimeout(window.firstPluginMessageTimeout);
    }
    await router.push({ name: 'regular-silencers', query: queryParams });
}

// registering methods that will be called by MagiCAD:
export const WebAppPluginObject = {
    async WebAppInsertProduct() {
        if (arguments.length < 3) {
            // alert('WebAppInsertProduct: incoming data has invalid format or structure');
            return;
        }
        const productData = await mapProductData(arguments[2]);
        if (!productData) {
            return;
        }
        if (productData.type === 'rectSilencer') {
            await processRectSilencerData(productData);
        } else {
            await processRoundSilencerData(productData);
        }
    },
};

// window.insert = (shape = 1, diameter = 200, width = 400, height = 250, airflow = 160) => WebAppPluginObject.WebAppInsertProduct(
//     '20221201',
//     'MCREVIT',
//     `
//     <?xml version='1.0'?>
//     <inputParams>
//         <shape>${shape}</shape>
//         <diameter>${diameter}</diameter>
//         <width>${width}</width>
//         <height>${height}</height>
//         <airflow>${airflow}</airflow>
//         <sound>43.1 38.9 34.4 28.4 24.6 22.6 18.5 12.0</sound>
//     </inputParams>
//     `,
// );

export function webAppLoaded() {
    if (!EOApiPlugin) {
        alert('EOApiPlugin is not registered');

        return;
    }
    if (window.webAppLoaded) {
        return;
    }
    EOApiPlugin.extInvoke('MCPluginWebAppLoaded', WebAppPluginObject);
    window.webAppLoaded = true;
}

export async function insertProduct(product) {
    if (!EOApiPlugin) {
        alert('EOApiPlugin is not registered');

        return;
    }
    if (!product) {
        alert('Product is not provided for export');

        return;
    }
    if (product.type === 'rectSilencer') {
        const dimData = await CalculationService.calculateDimDataForRectSilencer({
            silencer: product,
            minPressureDrop: 1,
            maxPressureDrop: 100,
        });
        if (!dimData) {
            alert('Dim data has not been calculated for rectangular silencer');

            return;
        }
        product.dimData = dimData;
        // console.log(mapRectSilencerData(product));
        EOApiPlugin.extInvoke('MCPluginInsertProduct', mapRectSilencerData(product));
    } else if (product.type === 'silencer') {
        const dimData = await CalculationService.calculateDimDataForRegularSilencer({
            silencer: product,
            minPressureDrop: 1,
            maxPressureDrop: 100,
        });
        if (!dimData) {
            alert('Dim data has not been calculated for round silencer');

            return;
        }
        product.dimData = dimData;
        // console.log(mapRegularSilencerData(product));
        EOApiPlugin.extInvoke('MCPluginInsertProduct', mapRegularSilencerData(product));
    } else {
        alert('Provided product is currently unsupported and cannot be exported');
    }
}
