<template>
    <div class="p-2">
        <p v-html="$t('rectSilencerModal.tooltips.innerMaterial')"></p>
    </div>
</template>

<script>
export default {
    name: 'InnerMaterial',
}
</script>