<template>
    <template v-for="(productsList, index) in productsPerPages" :key="index">
        <Page :pagesTotal="pagesTotal">
            <template v-slot:header>
                <div class="row section-header pt-1 pb-4 header-project-code">{{ title }}</div>
            </template>
            <template v-slot:content>
                <div class="row mt-8 p-0">
                    <ProductInventoryTable :products="productsList"/>
                </div>
            </template>
        </Page>
    </template>
</template>

<script>
import Page from '@/components/pdf/general/Page.vue';
import ProductInventoryTable from './ProductInventoryTable.vue';

export default {
    name: 'ProductInventoryPages',
    components: {
        Page,
        ProductInventoryTable,
    },
    props: {
        title: {
            type: String,
            default: 'Product Inventory Table',
        },
        products: {
            type: Array,
            required: true,
        },
        calculationResults: {
            type: Object,
            required: true,
        },
        pagesTotal: Number,
    },
    computed: {
        perProductCalculations() {
            const { perProductCalculations = [] } = this.calculationResults || {};

            return perProductCalculations;
        },
        productsPerPages() {
            const viewportHeight = Number(window.innerHeight);
            const productLineHeight = 58;
            let pageIndex = 0;
            const result = {};
            result[pageIndex] = [];
            this.products.forEach((product, productIndex) => {
                if ((result[pageIndex].length * productLineHeight) >= viewportHeight) {
                    pageIndex += 1;
                    result[pageIndex] = [];
                }
                result[pageIndex].push({
                    ...product,
                    lwBeforeProduct: this.lwBeforeProduct({
                        product,
                        index: productIndex,
                    }),
                    lwAfterProduct: this.lwAfterProduct({
                        product,
                        index: productIndex,
                    }),
                });
            });

            return Object.values(result);
        },
    },
    methods: {
        lwBeforeProduct({ product, index }) {
            if (!product || !product.id) {
                return 0;
            }
            if (!(index in this.perProductCalculations)) {
                return 0;
            }
            const calculationResult = this.perProductCalculations[index - 1];
            if (!calculationResult) {
                return this.calculationResults.noiseGeneratorTotalSoundPowerLw;
            }

            return calculationResult.totalSoundPowerToRoomLw;
        },
        lwAfterProduct({ product, index }) {
            if (!product || !product.id) {
                return 0;
            }
            if (!(index in this.perProductCalculations)) {
                return 0;
            }
            const calculationResult = this.perProductCalculations[index];
            if (!calculationResult) {
                return this.calculationResults.totalSoundPowerToRoomLw;
            }

            return calculationResult.totalSoundPowerToRoomLw;
        },
    },
}
</script>
