<template>
    <div class="background-image"></div>
</template>

<script>

export default {
    name: 'BackgroundImage',
}
</script>

<style scoped>
.background-image {
    background-image: url('../../assets/background_image_4.png');
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>
