<template>
    <div class="flex-col flex">
        <div v-if="title" class="row p-0">
            <div class="group-title">{{ title }}</div>
        </div>
        <slot v-else name="title"></slot>
        <div class="row p-0 mt-4">
            <InputField
                title="63"
                placeholder="0"
                type="number"
                class="mr-4"
                :readOnly="readonly"
                :modelValue="octaveBandRange.octaveBand63Hz"
                @update:modelValue="updateOctaveBand('octaveBand63Hz', $event)"
                @keyup="onKeyUp($event, 'octaveBand63Hz')"
            ></InputField>
            <InputField
                title="125"
                placeholder="0"
                type="number"
                class="mr-4"
                :readOnly="readonly"
                :modelValue="octaveBandRange.octaveBand125Hz"
                @update:modelValue="updateOctaveBand('octaveBand125Hz', $event)"
                @keyup="onKeyUp($event, 'octaveBand125Hz')"
            ></InputField>
            <InputField
                title="250"
                placeholder="0"
                type="number"
                class="mr-4"
                :readOnly="readonly"
                :modelValue="octaveBandRange.octaveBand250Hz"
                @update:modelValue="updateOctaveBand('octaveBand250Hz', $event)"
                @keyup="onKeyUp($event, 'octaveBand250Hz')"
            ></InputField>
            <InputField
                title="500"
                placeholder="0"
                type="number"
                class="mr-4"
                :readOnly="readonly"
                :modelValue="octaveBandRange.octaveBand500Hz"
                @update:modelValue="updateOctaveBand('octaveBand500Hz', $event)"
                @keyup="onKeyUp($event, 'octaveBand500Hz')"
            ></InputField>
            <InputField
                title="1000"
                placeholder="0"
                type="number"
                class="mr-4"
                :readOnly="readonly"
                :modelValue="octaveBandRange.octaveBand1000Hz"
                @update:modelValue="updateOctaveBand('octaveBand1000Hz', $event)"
                @keyup="onKeyUp($event, 'octaveBand1000Hz')"
            ></InputField>
            <InputField
                title="2000"
                placeholder="0"
                type="number"
                class="mr-4"
                :readOnly="readonly"
                :modelValue="octaveBandRange.octaveBand2000Hz"
                @update:modelValue="updateOctaveBand('octaveBand2000Hz', $event)"
                @keyup="onKeyUp($event, 'octaveBand2000Hz')"
            ></InputField>
            <InputField
                title="4000"
                placeholder="0"
                type="number"
                class="mr-4"
                :readOnly="readonly"
                :modelValue="octaveBandRange.octaveBand4000Hz"
                @update:modelValue="updateOctaveBand('octaveBand4000Hz', $event)"
                @keyup="onKeyUp($event, 'octaveBand4000Hz')"
            ></InputField>
            <InputField
                title="8000"
                placeholder="0"
                type="number"
                :readOnly="readonly"
                :modelValue="octaveBandRange.octaveBand8000Hz"
                @update:modelValue="updateOctaveBand('octaveBand8000Hz', $event)"
                @keyup="onKeyUp($event, 'octaveBand8000Hz')"
            ></InputField>
            <slot name="appendix"></slot>
        </div>
    </div>
</template>

<script>
import InputField from '@/components/general/InputField.vue';

export default {
    name: 'OctaveBandRangeEditor',
    components: {
        InputField,
    },
    props: {
        title: String,
        modelValue: {
            type: Object,
            default: () => ({
                octaveBand63Hz: 0,
                octaveBand125Hz: 0,
                octaveBand250Hz: 0,
                octaveBand500Hz: 0,
                octaveBand1000Hz: 0,
                octaveBand2000Hz: 0,
                octaveBand4000Hz: 0,
                octaveBand8000Hz: 0,
            }),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'update:modelValue',
        'octaveBand63HzChanged',
        'octaveBand125HzChanged',
        'octaveBand250HzChanged',
        'octaveBand500HzChanged',
        'octaveBand1000HzChanged',
        'octaveBand2000HzChanged',
        'octaveBand4000HzChanged',
        'octaveBand8000HzChanged',
    ],
    data() {
        return {
            octaveBandRange: {
                octaveBand63Hz: 0,
                octaveBand125Hz: 0,
                octaveBand250Hz: 0,
                octaveBand500Hz: 0,
                octaveBand1000Hz: 0,
                octaveBand2000Hz: 0,
                octaveBand4000Hz: 0,
                octaveBand8000Hz: 0,
            },
        };
    },
    created() {
        this.syncData();
    },
    methods: {
        syncData() {
            this.octaveBandRange.octaveBand63Hz = Number(this.modelValue.octaveBand63Hz);
            this.octaveBandRange.octaveBand125Hz = Number(this.modelValue.octaveBand125Hz);
            this.octaveBandRange.octaveBand250Hz = Number(this.modelValue.octaveBand250Hz);
            this.octaveBandRange.octaveBand500Hz = Number(this.modelValue.octaveBand500Hz);
            this.octaveBandRange.octaveBand1000Hz = Number(this.modelValue.octaveBand1000Hz);
            this.octaveBandRange.octaveBand2000Hz = Number(this.modelValue.octaveBand2000Hz);
            this.octaveBandRange.octaveBand4000Hz = Number(this.modelValue.octaveBand4000Hz);
            this.octaveBandRange.octaveBand8000Hz = Number(this.modelValue.octaveBand8000Hz);
        },
        updateOctaveBand(octaveBand, value) {
            if (!(octaveBand in this.octaveBandRange)) {
                return;
            }
            if (Number.isNaN(Number(value))) {
                return;
            }
            this.octaveBandRange[octaveBand] = Number(value);
            this.$emit('update:modelValue', { ...this.octaveBandRange });
            this.$emit(`${octaveBand}Changed`, Number(value));
        },
        async onKeyUp(event, octaveBand) {
            if (!event && !(octaveBand in this.octaveBandRange)) {
                return;
            }
            if (!(event.ctrlKey && event.code === 'KeyV')) {
                return;
            }
            if (!navigator || !('clipboard' in navigator)) {
                return;
            }
            if (!('readText' in navigator.clipboard)) {
                return;
                // await navigator.permissions.query({name: 'clipboard-read'});
                // await navigator.permissions.query({name: 'dom.events.asyncClipboard.read'});
            }
            const status = await navigator.permissions.query({ name: 'clipboard-read' });
            if (status.state === 'denied') {
                return;
            }
            const clipboardText = await navigator.clipboard.readText();
            if (!clipboardText) {
                return;
            }
            const chunks = clipboardText.match(/[+-]?\d+(\.\d+)?/g).map((value) => parseFloat(value));
            if (!chunks.length) {
                return;
            }
            const keys = Object.keys(this.octaveBandRange);
            let keyIndex = keys.findIndex((key) => key === octaveBand);
            if (keyIndex < 0) {
                return;
            }
            chunks.forEach((chunk) => {
                if (Number.isNaN(Number(chunk))) {
                    return;
                }
                if (!(keyIndex in keys)) {
                    return;
                }
                const key = keys[keyIndex];
                this.octaveBandRange[key] = Number(chunk);
                this.$emit(`${key}Changed`, Number(chunk));

                keyIndex += 1;
            });
            this.$emit('update:modelValue', { ...this.octaveBandRange });
        }
    },
    watch: {
        modelValue: {
            deep: true,
            handler() {
                this.syncData();
            },
        },
    },
}
</script>
