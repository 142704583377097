<template>
    <div class="input-container">
        <label>
            <span v-if="title" class="title">{{ title }}</span>
            <input
                :type="type"
                :disabled="disabled"
                :readonly="readOnly"
                :placeholder="placeholder"
                :min="min"
                :max="max"
                :value="modelValue"
                @input="emitChange"
                @keyup="onKeyUp"
            />
        </label>
    </div>
</template>

<script>
export default {
    name: 'InputField',
    props: {
        title: String,
        placeholder: String,
        type: {
            type: String,
            default: "text",
        },
        modelValue: [String, Number],
        disabled: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        min: [Number, String],
        max: [Number, String],
    },
    emits: ['update:modelValue', 'keyup'],
    methods: {
        emitChange(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        onKeyUp(event) {
            this.$emit('keyup', event);
        },
    },
}
</script>

<style scoped>
.input-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.input-container .title {
    display: flex;
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 14px;
    line-height: 17px;
    color: #4C5055;
    margin-bottom: 6px;
    user-select: none;
}

.input-container input {
    display: flex;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 0 1px #D5D6D5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 12px;
    appearance: none;
    outline: none;
}

.input-container input:focus {
    box-shadow: 0 0 0 2px #737372, 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.input-container input:disabled {
    background-color: #E8E8E8;
    box-shadow: none;
}

.input-container input.error {
    box-shadow: 0 0 0 1px #FD545A;
}

.input-container input::placeholder {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #737372;
}
</style>
