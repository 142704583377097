<template>
    <div class="message">{{ $t('commonCaptions.noContentAvailable') }}</div>
</template>

<script>
export default {}
</script>

<style scoped>
    .message {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-weight: bolder;
    }
</style>