// const debounce = require('lodash.debounce');
import * as ProjectService from '@/services/projects';
import ProjectOutputTransformer from '@/transformers/output/projects';

export default {
    namespaced: true,
    state: () => ({
        id: '',
        name: '',
        number: '',
        address: '',
        email: '',
        phone: '',
        contactData: '',
        author: '',
        date: null,
        createdAt: null,
        updatedAt: null,
        systemRefs: [],
    }),
    getters: {
        systemRefs({ systemRefs }) {
            return systemRefs;
        },
        activeProject(state) {
            return state;
        },
    },
    mutations: {
        setId(state, id) {
            state.id = id;
        },
        setName(state, name) {
            state.name = name;
        },
        setNumber(state, number) {
            state.number = number;
        },
        setAddress(state, address) {
            state.address = address;
        },
        setEmail(state, email) {
            state.email = email;
        },
        setPhone(state, phone) {
            state.phone = phone;
        },
        setContactData(state, contactData) {
            state.contactData = contactData;
        },
        setAuthor(state, author) {
            state.author = author;
        },
        setComment(state, comment) {
            state.comment = comment;
        },
        setDate(state, date) {
            state.date = date;
        },
        setCreatedAt(state, createdAt) {
            state.createdAt = createdAt;
        },
        setUpdatedAt(state, updatedAt) {
            state.updatedAt = updatedAt;
        },
        setSystemRefs(state, systemRefs) {
            state.systemRefs = systemRefs;
        },
        resetData(state) {
            state.id = '';
            state.name = '';
            state.number = '';
            state.address = '';
            state.email = '';
            state.phone = '';
            state.contactData = '';
            state.author = '';
            state.comment = '';
            state.date = null;
            state.createdAt = null;
            state.updatedAt = null;
            state.systemRefs = [];
        },
        setProject(state, data) {
            // if (!data.id) {
            //     return;
            // }
            state.id = data.id || '';
            state.name = data.name || '';
            state.number = data.number || '';
            state.address = data.address || '';
            state.email = data.email || '';
            state.phone = data.phone || '';
            state.contactData = data.contactData || '';
            state.author = data.author || '';
            state.comment = data.comment || '';
            state.date = data.date || null;
            state.createdAt = data.createdAt || null;
            state.updatedAt = data.updatedAt || null;
            state.systemRefs = data.systemRefs || [];
        },
        addSystemRef({ systemRefs }, systemRef) {
            systemRefs.push(systemRef);
        },
        updateSystemRef({ systemRefs }, systemRef) {
            const index = systemRefs.findIndex((item) => item.id === systemRef.id);
            if (index < 0) {
                return;
            }
            systemRefs[index] = systemRef;
        },
        deleteSystemRef({ systemRefs }, systemId) {
            const index = systemRefs.findIndex((systemRef) => systemRef.id === systemId);
            if (index < 0) {
                return;
            }
            systemRefs.splice(index, 1);
        },
    },
    actions: {
        addProduct({ commit, dispatch }, { product }) {
            commit('addProduct', product);
            dispatch('saveProject');
            dispatch('calculateSummary');
        },
        removeProduct({ commit, dispatch }, { productId }) {
            commit('removeProduct', productId);
            dispatch('saveProject');
            dispatch('calculateSummary');
        },
        setName({ commit }, name) {
            commit('setName', name);
        },
        setNumber({ commit }, number) {
            commit('setNumber', number);
        },
        setAddress({ commit }, address) {
            commit('setAddress', address);
        },
        setEmail({ commit }, email) {
            commit('setEmail', email);
        },
        setPhone({ commit }, phone) {
            commit('setPhone', phone);
        },
        setContactData({ commit }, contactData) {
            commit('setContactData', contactData);
        },
        setAuthor({ commit }, author) {
            commit('setAuthor', author);
        },
        setComment({ commit }, comment) {
            commit('setComment', comment);
        },
        setDate({ commit }, date) {
            commit('setDate', date);
        },
        setCreatedAt({ commit }, createdAt) {
            commit('createdAt', createdAt);
        },
        resetData({ commit }) {
            commit('resetData');
        },
        setProject({ commit }, data) {
            commit('setProject', data);
        },
        setSystemRefs({ commit }, systemRefs) {
            commit('setSystemRefs', systemRefs);
        },
        // createProject: debounce(async ({ state, commit, rootGetters }) => {
        createProject: async ({ state, commit, rootGetters }) => {
            if (!rootGetters['userModule/userAuthorized']) {
                return;
            }
            const result = await ProjectService.createProject(ProjectOutputTransformer.transform(state));
            if (!result._id) {
                return;
            }
            commit('setId', result._id);
        // }, 400),
        },
        // saveProject: debounce(async ({ state, dispatch, rootGetters }) => {
        saveProject: async ({ state, dispatch, rootGetters }) => {
            if (!rootGetters['userModule/userAuthorized']) {
                return;
            }
            if (!state.id) {
                await dispatch('createProject');
                return;
            }
            await ProjectService.saveProject(ProjectOutputTransformer.transform(state));
        // }, 400),
        },
        // deleteProject: debounce(async ({ state, dispatch, rootGetters }) => {
        deleteProject: async ({ state, dispatch, rootGetters }) => {
            if (!rootGetters['userModule/userAuthorized']) {
                return;
            }
            if (!state.id) {
                return;
            }
            await ProjectService.deleteProject(state.id);
            await dispatch('projectsModule/deleteProject', state.id, { root: true });
            await dispatch('resetData');
        // }, 400),
        },
        addSystemRef({ commit, state }, { systemRef }) {
            if (!systemRef || !systemRef.id) {
                return;
            }
            // TODO: use transformer:
            const ref = {
                id: systemRef.id,
                name: systemRef.name || '',
                number: systemRef.number || '',
                createdAt: systemRef.createdAt ? new Date(systemRef.createdAt) : null,
                updatedAt: systemRef.updatedAt ? new Date(systemRef.updatedAt) : null,
            };
            const index = state.systemRefs.findIndex((item) => item.id === systemRef.id);
            if (index >= 0) {
                commit('updateSystemRef', ref);
            } else {
                commit('addSystemRef', ref);
            }
        },
        updateSystemRef({ commit }, { systemRef }) {
            if (!systemRef || !systemRef.id) {
                return;
            }
            // TODO: use transformer:
            const ref = {
                id: systemRef.id,
                name: systemRef.name || '',
                number: systemRef.number || '',
                createdAt: systemRef.createdAt ? new Date(systemRef.createdAt) : null,
                updatedAt: systemRef.updatedAt ? new Date(systemRef.updatedAt) : null,
            };
            commit('updateSystemRef', ref);
        },
        deleteSystemRef({ commit }, { systemId }) {
            if (!systemId) {
                return;
            }
            commit('deleteSystemRef', systemId);
        },
    },
}
