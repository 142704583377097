<template>
    <div class="card column mb-8">
        <div class="row heading-small-bold mb-4">{{ $t('productsList.filters.filterOther') }}</div>
        <div class="row abstract-filters-container">
            <MultiSelect
                :options="productClasses"
                label="Class"
                explicitSelection
                class="flex-15 mr-8"
                v-model="selectedProductClasses"
            ></MultiSelect>
            <MultiSelect
                class="flex-15 mr-4"
                :options="productNames"
                :disabled="!selectedProductClasses.length"
                label="Name"
                explicitSelection
                v-model="selectedProductIds"
            ></MultiSelect>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import MultiSelect from '@/components/general/MultiSelect.vue';

export default {
    name: 'AbstractProductsFilter',
    components: {
        MultiSelect,
    },
    emits: ['filtered'],
    data() {
        return {
            selectedProductClasses: [],
            selectedProductIds: [],
        };
    },
    computed: {
        ...mapState('abstractClassesModule', ['abstractClasses']),
        ...mapState('abstractProductsModule', ['abstractProducts']),
        productClasses() {
            return this.abstractClasses.map((productClass) => ({
                title: productClass.name,
                value: productClass.name,
            }));
        },
        // productNamesPerSelectedClasses() {
        productNames() {
            const result = [];
            this.abstractProducts.forEach((product) => {
                if (!this.selectedProductClasses.includes(product.class)) {
                    return;
                }
                result.push({
                    title: product.name,
                    value: product.id,
                });
            });

            return result;
        },
    },
    methods: {
        filterProducts() {
            const filtered = this.abstractProducts.filter((product) => {
                if (!(this.selectedProductClasses.includes(product.class))) {
                    return false;
                }
                if (this.selectedProductIds.length && !(this.selectedProductIds.includes(product.id))) {
                    return false;
                }

                return true;
            });
            this.$emit('filtered', filtered);
        },
    },
    watch: {
        selectedProductClasses() {
            this.filterProducts();
        },
        selectedProductIds() {
            this.filterProducts();
        },
    },
}
</script>

<style lang="scss">
    .abstract-filters-container {
        z-index: 0;

        .select-container {
            max-width: 215px;

            .custom-select {
                .items {
                    overflow-y: auto;
                    max-height: 450px;
                    
                    div {
                        box-sizing: content-box;
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    .heading-small-bold {
        font-size: 18px;
        text-transform: uppercase;
        color: #737372;

    }
</style>