<template>
    <div class="page-content">
        <div class="card column mb-8 mt-8">
            <div class="card-header">
                <div class="main-title">{{ $t('silencersPage.selectSilencerTypes') }}</div>
                <div class="selector-info" v-html="$t('silencersPage.selectedAmount', [selectedSilencerClasses.length + selectedRectSilencerClasses.length, allowedSilencerClasses.length])"></div>
            </div>
            <div class="card-content">
                <div
                    class="category"
                    v-for="silencerClass in allowedSilencerClasses"
                    :key="silencerClass.id"
                    :class="{ 'selected': silencerClass.selected }"
                    @click="onToggleSilencerClass(silencerClass)">
                    <img :src="silencerClass.imageURL" alt="Silencer Class Image">
                    <div class="title mt-4">{{ resolveTranslationMapValue(silencerClass.title) }}</div>
                    <tooltip v-if="silencerClass.content !== null" position="right" class="category-tooltip" :text="mapLanguage(silencerClass)">
                        <i class="information-icon" />
                        <template v-slot:tooltip-heading>
                            {{ resolveTranslationMapValue(silencerClass.title) }}
                        </template>
                    </tooltip>
                </div>
            </div>
            <div class="card-content">
                <div
                    class="category"
                    v-for="silencerClass in rectSilencerClasses"
                    :key="silencerClass.id"
                    :class="{ 'selected': silencerClass.selected }"
                    @click="onToggleRectSilencerClass(silencerClass)">
                    <img :src="silencerClass.imageURL" alt="Rect Silencer Class Image">
                    <div class="title mt-4">{{ resolveTranslationMapValue(silencerClass.title) }}</div>
                    <tooltip v-if="silencerClass.content !== null" position="right" class="category-tooltip" :text="mapLanguage(silencerClass)">
                        <i class="information-icon" />
                        <template v-slot:tooltip-heading>
                            {{ resolveTranslationMapValue(silencerClass.title) }}
                        </template>
                    </tooltip>
                </div>
            </div>
            <div class="card-footer">
                <Button 
                    size="medium" 
                    class="flex-15"
                    :disabled="!silencersPresent"
                    @click="navigateUser" 
                >{{ $t('silencersPage.controls.next') }}</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import Button from '../../../general/Button.vue';
import Tooltip from '../../../general/Tooltip.vue';
import rectSilencerClassesData from '@/mocks/rect_silencer_categories.json';

export default {
    name: 'Page',
    components: {
        Button,
        Tooltip,
    },
    inject: ['resolveTranslationMapValue'],
    computed: {
        ...mapState('silencersModule', ['silencers']),
        ...mapGetters('silencerClassesModule', {
            'silencerClasses': 'rootClasses',
            'selectedSilencerClasses': 'selectedRootClasses',
        }),
        allowedSilencerClasses() {
            return this.silencerClasses.filter((silencerClass) => this.allowedSilencerClassNames.includes(silencerClass.name));
        },
        silencersPresent() {
            return this.silencers.length > 0;
        },
        selectedRectSilencerClasses() {
            return this.rectSilencerClasses
                .filter((silencerClass) => silencerClass.selected);
        },
        rectSilencerClasses() {
            return rectSilencerClassesData
                .filter((silencerClass) => silencerClass.name !== 'MPK/MPP/MPC')
                .map((silencerClass) => reactive(silencerClass));
        },
    },
    data() {
        return {
            openedTooltipIndex: null,
            rectSilencerClassesData,
            allowedSilencerClassNames: [
                'NKE',
                'NKL',
                'NKU',
                'NRF100/NRP100',
                'NRF50/NRP50',
                'NRFB100/NRPB100',
                'NRPV/NRFV',
                'NTP/NTF',
                'NTPA/NTFA',
            ],
        };
    },
    methods: {
        ...mapActions('silencerClassesModule', ['toggleSilencerClassSelection', 'clearSelection']),
        ...mapActions('rectSilencerModule', ['setShellInsulation', 'setOpenable']),
        toggleClassSelection(silencerClass) {
            this.toggleSilencerClassSelection({ identity: silencerClass.id });
        },
        mapLanguage(silencerClass) {
            let locale = localStorage.getItem('locale');
            let description = null;

            try {
                switch (locale) {
                case "english":
                    description = silencerClass.content.eng.description;
                    break;
                case "estonian":
                    description = silencerClass.content.est.description;
                    break;
                case "finnish":
                    description = silencerClass.content.fin.description;
                    break;
                case "swedish":
                    description = silencerClass.content.swe.description;
                    break;
                case "danish":
                    description = silencerClass.content.den.description;
                    break;
                }
            } catch (e) {
                description = null;
            }
            return description;
        },
        async navigateUser() {
            if (this.selectedRectSilencerClasses.length) {
                const [ silencerClass = {} ] = this.selectedRectSilencerClasses;
                await this.preselectRectSilencerAttributesForClass(silencerClass);
                this.$router.push({ name: 'rect-silencer-modelling' });

                return;
            }
            this.$router.push({ name: 'regular-silencers' });
        },
        async preselectRectSilencerAttributesForClass(silencerClass) {
            if (!silencerClass) {
                return;
            }
            const silencerClassName = (silencerClass.name || '').split('/')[0];
            if (!silencerClassName) {
                return;
            }
            await this.setOpenable(false);
            await this.setShellInsulation('0mm');
            if (silencerClassName[silencerClassName.length - 1] === 'A') {
                await this.setOpenable(true);
            } else if (silencerClassName[silencerClassName.length - 1] === 'I') {
                await this.setShellInsulation('50mm');
            }
        },
        deselectSilencerClasses() {
            this.clearSelection();
        },
        deselectRectSilencerClasses() {
            this.rectSilencerClasses.forEach((silencerClass) => {
                silencerClass.selected = false;
            });
        },
        onToggleSilencerClass(silencerClass) {
            if (this.selectedRectSilencerClasses.length) {
                this.deselectRectSilencerClasses();
            }
            this.toggleClassSelection(silencerClass);
        },
        onToggleRectSilencerClass(silencerClass) {
            if (this.selectedSilencerClasses.length) {
                this.deselectSilencerClasses();
            }
            this.deselectRectSilencerClasses();
            silencerClass.selected = !silencerClass.selected;
        },
    }
}
</script>

<style>
.card .card-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.card .card-header .main-title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 28px;
    line-height: 33px;
    color: #4C5055;
}

.card .card-header .title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 22px;
    color: #4C5055;
}

.card .card-header .selector-info {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4C5055;
}

.column {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.card .card-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
}

.card .card-content .category {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 186px;
    min-height: 172px;
    margin: 12px;
    flex: 0 0 15%;
}

.card .card-content .category.selected, .card .card-content .category:hover {
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(76, 80, 85, 0.16);
    border-radius: 8px;
    box-sizing: border-box;
}

.card .card-content .category.selected, .card .card-content .category.selected:hover {
    border: 1px solid #737372;
}

.card .card-content .category:hover {
    border: 1px solid #D5D6D5;
}

/* .card .card-content .category.selected div {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4C5055;
} */

.card .card-content .category .category-tooltip {
    position: absolute;
    margin: 14px 14px 0 0;
    top: 0;
    right: 0;
}

.card .card-content .category .category-tooltip .information-icon {
    visibility: hidden;
    display: flex;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url('../../../../assets/icons/singular/info_outline-black.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.card .card-content .category:hover .information-icon {
    visibility: visible;
}

.card .card-content .category img {
    width: 92px;
    height: 70px;
}

.card .card-content .category .title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4C5055;
}

.card .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-15 {
    flex: 0 1 15%;
}
</style>
