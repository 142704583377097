<template>
    <AuthContainer :title="$t('signIn.welcomeBack')">
        <SignInForm></SignInForm>
    </AuthContainer>
</template>

<script>
import AuthContainer from './AuthContainer.vue';
import SignInForm from './SignInForm.vue';

export default {
    name: 'SignIn',
    components: { AuthContainer, SignInForm },
}
</script>

<style lang="scss" scoped>
</style>