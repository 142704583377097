import * as AuthorizationService from '@/services/auth/sso';
import * as UserService from '@/services/users';
import UserInputTransformer from '@/transformers/input/user';

// async function onUnload(event) {
//     event.preventDefault();
//     event.returnValue = '';
//     console.log('on unload');
//     const pageReloaded = (window.performance.navigation && window.performance.navigation.type === 1) ||
//         window.performance
//         .getEntriesByType('navigation')
//         .map((nav) => nav.type)
//         .includes('reload');
//     console.log('pageReloaded', pageReloaded);
//     window.performance
//         .getEntriesByType('navigation')
//         .forEach((nav) => console.log(nav));
//     if (pageReloaded) {
//         return null;
//     }

//     return null;
//     // await AuthorizationService.delayedLogout();
// }

export default {
    namespaced: true,
    state: () => ({
        id: '',
        fullName: '',
        email: '',
    }),
    getters: {
        userAuthorized({ id }) {
            return !!id;
        },
    },
    mutations: {
        resetData(state) {
            state.id = '';
            state.fullName = '';
            state.email = '';
        },
        setData(state, userData) {
            state.id = userData.id;
            state.fullName = userData.fullName;
            state.email = userData.email;
        },
        setId(state, id) {
            state.id = id;
        },
        setFullName(state, fullName) {
            state.fullName = fullName;
        },
        setEmail(state, email) {
            state.email = email;
        },
    },
    actions: {
        async initUser({ commit }) {
            try {
                const userData = await AuthorizationService.getUserData();
                if (!userData) {
                    return;
                }
                const transformed = UserInputTransformer.transform(userData);
                commit('setData', transformed);
            } catch (error) {
                console.error(error);
            }
            // window.addEventListener('beforeunload', onUnload);
            // window.onbeforeunload = onUnload;
        },
        // async signIn({ commit }, credentials) {
        //     if (!credentials.email || !credentials.password) {
        //         return;
        //     }
        //     const userData = await AuthorizationService.signIn(credentials.email, credentials.password);
        //     if (!userData) {
        //         throw new Error('Authorization failed');
        //     }
        //     const transformed = UserInputTransformer.transform(userData);
        //     commit('setData', transformed);
        //     window.location.href = '/';
        // },
        async signOut({ commit }) {
            await AuthorizationService.logout();
            commit('resetData');
            // window.removeEventListener('beforeunload', onUnload);
            window.location.href = '/';
        },
        lookup(_store, email) {
            if (!email) {
                return false;
            }
            return UserService.lookupUser(email);
        },
    },
}
