import { emptyOctaveBandRange } from '../../utils';
import * as CalculationService from '../../services/calculations';

const debounce = require('lodash.debounce');

function mapProducts(products) {
    if (!Array.isArray(products) || !products.length) {
        return [];
    }
    const result = [];
    products.forEach((product) => {
        if (!product.id || !product.class || !product.nominalSizeDiameter) { // || !product.length
            return;
        }
        result.push({
            id: product.id,
            name: product.name,
            class: product.class,
            soundAttenuation: product.soundAttenuation,
            soundPowerLevel: product.soundPowerLevel,
            length: product.length || 0,
            diameter: product.nominalSizeDiameter,
        });
    });

    return result;
}

export default {
    namespaced: true,
    state: () => ({
        inputAirflow: 0,
        inputSoundPower: emptyOctaveBandRange(),
        productCalculations: [],
        inputSoundPowerCalculationResults: {},
    }),
    getters: {
        productCalculationsMap({ productCalculations }) {
            const result = [];
            productCalculations.forEach((calculation) => {
                result[calculation.id] = calculation;
            });

            return result;
        }
    },
    mutations: {
        setInputAirflow(state, inputAirflow) {
            state.inputAirflow = inputAirflow;
        },
        setInputSoundPower(state, inputSoundPower) {
            state.inputSoundPower = inputSoundPower;
        },
        setProductCalculations(state, productCalculations) {
            state.productCalculations = productCalculations;
        },
        replaceProductCalculation({ productCalculations }, calculation) {
            const index = productCalculations.find((item) => item.id === calculation.id);
            if (index < 0) {
                productCalculations.push(calculation);
            } else {
                productCalculations[index] = calculation;
            }
        },
        resetData(state) {
            state.inputAirflow = 0;
            state.inputSoundPower = emptyOctaveBandRange();
            state.productCalculations = [];
            state.inputSoundPowerCalculationResults = {};
        },
        setInputSoundPowerCalculationResults(state, results) {
            state.inputSoundPowerCalculationResults = results || {};
        }
    },
    actions: {
        setInputAirflow({ commit }, inputAirflow) {
            commit('setInputAirflow', inputAirflow);
        },
        async setInputSoundPower({ commit, dispatch }, inputSoundPower) {
            commit('setInputSoundPower', inputSoundPower);
            await dispatch('calculateInputSoundPowerSummary');
        },
        setProductCalculations({ commit }, productCalculations) {
            commit('setProductCalculations', productCalculations);
        },
        replaceProductCalculation({ commit }, calculation) {
            commit('replaceProductCalculation', calculation);
        },
        resetData({ commit }) {
            commit('resetData');
        },
        performCalculationsFor: debounce(async ({ dispatch }, products) => {
            await dispatch('performCalculationsImmediatelyFor', products);
        }, 400),
        async performCalculationsImmediatelyFor({ state, commit }, products) {
            if (!Array.isArray(products) || !products.length) {
                return;
            }
            const calculations = await CalculationService.calculateBulkSoundPower({
                inputSoundPowerLevel: state.inputSoundPower,
                inputAirflow: state.inputAirflow,
                attenuationConstant: 'NoConstant',
                filterCorrectionType: 'AFilter', // 'NoFilter',
                products: mapProducts(products),
            });
            if (!Array.isArray(calculations) || !calculations.length) {
                return;
            }
            commit('setProductCalculations', calculations);
        },
        calculateInputSoundPowerSummary: debounce(async ({ state, commit }) => {
            const calculationResults = await CalculationService.calculateSoundPower({
                inputSoundPowerLevel: state.inputSoundPower,
                attenuationConstant: 'NoConstant',
                filterCorrectionType: 'AFilter', // 'NoFilter',
                products: [],
            });
            commit('setInputSoundPowerCalculationResults', calculationResults);
        }, 400),
    },
}
