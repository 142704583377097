import config from '../../constants/config';
import HTTPService from './HTTPService';

export const apiService = new HTTPService({
    baseURL: config.apiURL,
});

// const accessToken = localStorage.getItem('token');
// if (accessToken) {
//     apiService.setHeader('authorization', `Bearer ${accessToken}`);
// }

// const idToken = localStorage.getItem('it');
// if (idToken) {
//     apiService.setHeader('it', idToken);
// }

// const refreshToken = localStorage.getItem('rt');
// if (refreshToken) {
//     apiService.setHeader('rt', refreshToken);
// }

export default apiService;