const debounce = require('lodash.debounce');
import * as CalculationsService from '../../services/calculations';
import * as SystemService from '@/services/systems';
import SystemOutputTransformer from '@/transformers/output/systems';
import { cloneOctaveBandRange, emptyOctaveBandRange, toValidNumber } from '../../utils';

export default {
    namespaced: true,
    state: () => ({
        id: '',
        projectId: '',
        name: '',
        number: '',
        author: '',
        comment: '',
        date: null,
        createdAt: null,
        updatedAt: null,
        attenuationConstantType: 'T1',
        filterCorrectionType: 'AFilter',
        inputSoundPowerLevel: {
            octaveBand63Hz: 0,
            octaveBand125Hz: 0,
            octaveBand250Hz: 0,
            octaveBand500Hz: 0,
            octaveBand1000Hz: 0,
            octaveBand2000Hz: 0,
            octaveBand4000Hz: 0,
            octaveBand8000Hz: 0,
        },
        selectedProducts: [],
        productQuantitiesMap: {},
        calculationResults: {},
        // product insertion:
        productInsertionParams: {
            productIndex: -1,
            type: 'after',
            productId: '',
        },
    }),
    getters: {
        selectedProductsMap({ selectedProducts }) {
            const result = {};
            selectedProducts.forEach((product) => {
                result[product.id] = product;
            })

            return result;
        },
        perProductCalculationsMap({ calculationResults: { perProductCalculations = [] } }) {
            if (!perProductCalculations.length) {
                return {};
            }
            const map = {};
            perProductCalculations.forEach((calculationResult) => {
                map[calculationResult.id] = calculationResult;
            })

            return map;
        },
        calculationStepsSplitted({ calculationResults: { calculationSteps = [] } }) {
            if (!calculationSteps.length) {
                return [];
            }
            const result = [];
            calculationSteps.forEach((calculationStep) => {
                result.push({
                    name: `- ATT* (${calculationStep.name.english || calculationStep.name})`,
                    soundPowerLevel: calculationStep.attenuation,
                });
                if (!calculationStep.soundPowerLevel) {
                    return;
                }
                result.push({
                    name: `+ SPL* (${calculationStep.name.english || calculationStep.name})`,
                    soundPowerLevel: calculationStep.soundPowerLevel,
                });
            });

            return result;
        },
        activeSystem(state) {
            return { ...state };
        },
        soundPowerLevelData(state) {
            const {
                productInsertionParams: {
                    productIndex = -1, type,
                } = {},
                calculationResults: { perProductCalculations = [] }
            } = state;
            if (!perProductCalculations.length || (productIndex === 0 && type === 'before')) {
                return {
                    total: 0,
                    soundPowerLevel: cloneOctaveBandRange(state.inputSoundPowerLevel),
                };
            }
            const indexOffset = (type === 'before') ? -1 : 0;
            const calculationResultIndex = (productIndex < 0) ? perProductCalculations.length - 1 : productIndex + indexOffset;
            const calculationResult = perProductCalculations[calculationResultIndex];

            return {
                total: calculationResult.totalSoundPowerToRoomLw,
                soundPowerLevel: calculationResult.soundPowerLevelToRoomLw,
            };
        },
        soundPowerLevelDataByIndex(state) {
            return (productIndex) => {
                const { calculationResults: { perProductCalculations = [] } } = state;
                if (!perProductCalculations.length || productIndex <= 0 || !((productIndex - 1) in perProductCalculations)) {
                    return {
                        total: 0,
                        soundPowerLevel: cloneOctaveBandRange(state.inputSoundPowerLevel),
                    };
                }
                const calculationResult = perProductCalculations[productIndex - 1];
    
                return {
                    total: calculationResult.totalSoundPowerToRoomLw,
                    soundPowerLevel: calculationResult.soundPowerLevelToRoomLw,
                };
            };
        },
        filterCorrectionUnit({ filterCorrectionType }) {
            switch (filterCorrectionType) {
                case 'AFilter':
                    return 'LwA';
                case 'BFilter':
                    return 'LwB';
                case 'CFilter':
                    return 'LwC';
                default:
                    return 'Lx';
            }
        },
    },
    mutations: {
        addProduct({ selectedProducts, productQuantitiesMap }, { product }) {
            if (!product || !product.id) {
                return;
            }
            const quantity = (product.id in productQuantitiesMap) ? productQuantitiesMap[product.id] : 0;
            productQuantitiesMap[product.id] = quantity + 1;
            selectedProducts.push(product);
        },
        addProductBefore({ selectedProducts, productQuantitiesMap }, { product, index }) {
            if (!product || !product.id) {
                return;
            }
            if (Number.isNaN(Number(index)) || !(index in selectedProducts)) {
                return;
            }
            const quantity = (product.id in productQuantitiesMap) ? productQuantitiesMap[product.id] : 0;
            productQuantitiesMap[product.id] = quantity + 1;
            selectedProducts.splice(
                (index - 1) >= 0 ? index - 1 : 0,
                0,
                product,
            );
        },
        addProductAfter({ selectedProducts, productQuantitiesMap }, { product, index }) {
            if (!product || !product.id) {
                return;
            }
            if (Number.isNaN(Number(index)) || !(index in selectedProducts)) {
                return;
            }
            const quantity = (product.id in productQuantitiesMap) ? productQuantitiesMap[product.id] : 0;
            productQuantitiesMap[product.id] = quantity + 1;
            selectedProducts.splice(index + 1, 0, product);
        },
        removeProduct({ selectedProducts, productQuantitiesMap }, index) {
            if (Number.isNaN(Number(index)) || !(index in selectedProducts)) {
                return;
            }
            const product = selectedProducts[index];
            if (!product || !product.id) {
                return;
            }
            const quantity = (product.id in productQuantitiesMap) ? productQuantitiesMap[product.id] : 0;
            if (quantity - 1 > 0) {
                productQuantitiesMap[product.id] = quantity - 1;
            } else {
                delete productQuantitiesMap[product.id];
                delete product.inputAirflow;
                delete product.pressureDrop;
            }
            selectedProducts.splice(index, 1);
        },
        removeProductById({ selectedProducts, productQuantitiesMap }, productId) {
            if (!productId) {
                return;
            }
            const index = selectedProducts.reverse().findIndex((product) => product.id === productId);
            if (index < 0) {
                return;
            }
            const quantity = (productId in productQuantitiesMap) ? productQuantitiesMap[productId] : 0;
            if (quantity - 1 > 0) {
                productQuantitiesMap[productId] = quantity - 1;
            } else {
                const product = selectedProducts[index];
                delete productQuantitiesMap[productId];
                delete product.inputAirflow;
                delete product.pressureDrop;
            }
            selectedProducts.splice(index, 1);
        },
        swapProducts({ selectedProducts }, { sourceIndex, targetIndex }) {
            if (Number.isNaN(Number(sourceIndex)) || !(sourceIndex in selectedProducts)) {
                return;
            }
            if (Number.isNaN(Number(targetIndex)) || !(targetIndex in selectedProducts)) {
                return;
            }
            const temp = selectedProducts[targetIndex];
            selectedProducts[targetIndex] = selectedProducts[sourceIndex];
            selectedProducts[sourceIndex] = temp;
        },
        replaceProduct(state, product) {
            if (!product || !product.id) {
                return;
            }
            const selectedProducts = Array.from(state.selectedProducts);
            for (let index in state.selectedProducts) {
                if (state.selectedProducts[index].id !== product.id) {
                    continue;
                }
                selectedProducts.splice(index, 1, product);
            }
            state.selectedProducts = selectedProducts;
        },
        setId(state, id) {
            state.id = id;
        },
        setProjectId(state, projectId) {
            state.projectId = projectId;
        },
        setName(state, name) {
            state.name = name;
        },
        setNumber(state, number) {
            state.number = number;
        },
        setAuthor(state, author) {
            state.author = author;
        },
        setComment(state, comment) {
            state.comment = comment;
        },
        setDate(state, date) {
            state.date = date;
        },
        setCreatedAt(state, createdAt) {
            state.createdAt = createdAt;
        },
        setUpdatedAt(state, updatedAt) {
            state.updatedAt = updatedAt;
        },
        setAttenuationConstantType(state, attenuationConstantType) {
            state.attenuationConstantType = attenuationConstantType;
        },
        setFilterCorrectionType(state, filterCorrectionType) {
            state.filterCorrectionType = filterCorrectionType;
        },
        setInputSoundPowerLevel(state, { bandRange, soundPower }) {
            if (!(bandRange in state.inputSoundPowerLevel)) {
                return;
            }
            if (Number.isNaN(Number(soundPower))) {
                return;
            }
            // Vue.$set(state.inputSoundPowerLevel, bandRange, soundPower);
            state.inputSoundPowerLevel[bandRange] = Number(soundPower);
        },
        setCalculationResults(state, calculationResults) {
            state.calculationResults = calculationResults;
        },
        setAbstractProductAttribute({ selectedProducts }, { productId, attributeName, attributeValue }) {
            selectedProducts.forEach((selectedProduct) => {
                if (selectedProduct.id !== productId) { // || !(selectedProduct.type === 'abstract' || selectedProduct.type === 'rectSilencer')
                    return;
                }
                selectedProduct[attributeName] = attributeValue;
            });
        },
        resetData(state) {
            state.id = '';
            state.projectId = '';
            state.name = '';
            state.number = '';
            state.author = '';
            state.comment = '';
            state.date = null;
            state.createdAt = null;
            state.updatedAt = null;
            state.attenuationConstantType = 'T1';
            state.filterCorrectionType = 'AFilter';
            state.inputSoundPowerLevel = {
                octaveBand63Hz: 0,
                octaveBand125Hz: 0,
                octaveBand250Hz: 0,
                octaveBand500Hz: 0,
                octaveBand1000Hz: 0,
                octaveBand2000Hz: 0,
                octaveBand4000Hz: 0,
                octaveBand8000Hz: 0,
            };
            state.selectedProducts = [];
            state.productQuantitiesMap = {};
            state.calculationResults = {};
            state.productInsertionParams = {
                productIndex: -1,
                type: 'after',
                productId: '',
            };
        },
        setSystem(state, data) {
            // if (!data.id || !data.projectId) {
            //     return;
            // }
            state.id = data.id;
            state.projectId = data.projectId;
            state.name = data.name || '';
            state.number = data.number || '';
            state.author = data.author || '';
            state.comment = data.comment || '';
            state.date = data.date || null;
            state.createdAt = data.createdAt || null;
            state.updatedAt = data.updatedAt || null;
            state.attenuationConstantType = data.attenuationConstantType || 'T1';
            state.filterCorrectionType = data.filterCorrectionType || 'AFilter';
            state.inputSoundPowerLevel = {
                octaveBand63Hz: data.inputSoundPowerLevel.octaveBand63Hz,
                octaveBand125Hz: data.inputSoundPowerLevel.octaveBand125Hz,
                octaveBand250Hz: data.inputSoundPowerLevel.octaveBand250Hz,
                octaveBand500Hz: data.inputSoundPowerLevel.octaveBand500Hz,
                octaveBand1000Hz: data.inputSoundPowerLevel.octaveBand1000Hz,
                octaveBand2000Hz: data.inputSoundPowerLevel.octaveBand2000Hz,
                octaveBand4000Hz: data.inputSoundPowerLevel.octaveBand4000Hz,
                octaveBand8000Hz: data.inputSoundPowerLevel.octaveBand8000Hz,
            };
            state.selectedProducts = data.selectedProducts;
            state.productQuantitiesMap = data.productQuantitiesMap;
            state.calculationResults = {};
            state.productInsertionParams = {
                productIndex: -1,
                type: 'after',
                productId: '',
            };
        },
        resetProductsData(state) {
            state.selectedProducts = [];
            state.productQuantitiesMap = {};
            state.calculationResults = {};
            state.productInsertionParams = {
                productIndex: -1,
                type: 'after',
                productId: '',
            };
        },
        resetNoiseGeneratorData(state) {
            state.inputSoundPowerLevel = {
                octaveBand63Hz: 0,
                octaveBand125Hz: 0,
                octaveBand250Hz: 0,
                octaveBand500Hz: 0,
                octaveBand1000Hz: 0,
                octaveBand2000Hz: 0,
                octaveBand4000Hz: 0,
                octaveBand8000Hz: 0,
            };
            state.calculationResults = {};
        },
        setProductInsertionParams(state, { productIndex = -1, type = 'after', productId = '' }) {
            state.productInsertionParams = {
                productIndex: toValidNumber(productIndex),
                type,
                productId: productId || '',
            };
        },
    },
    actions: {
        addProduct({ state, commit, dispatch }, { product }) { // , inputAirflow, pressureDrop
            const { productInsertionParams: insertionParams } = state;
            const productToInsert = {
                ...product,
                soundPowerLevel: product.soundPowerLevel ? { ...product.soundPowerLevel } : emptyOctaveBandRange(),
                soundAttenuation: product.soundAttenuation ? { ...product.soundAttenuation } : emptyOctaveBandRange(),
            };
            if (product.type === 'rectSilencer') {
                productToInsert.inputSoundPowerLevel = product.inputSoundPowerLevel ? { ...product.inputSoundPowerLevel } : emptyOctaveBandRange();
            }
            // if (product.type === 'silencer') {
            //     if (inputAirflow !== undefined && pressureDrop !== undefined) {
            //         productToInsert.inputAirflow = inputAirflow;
            //         productToInsert.pressureDrop = pressureDrop;
            //     }
            // }
            let action = () => commit('addProduct', { product: productToInsert });
            if (insertionParams.productIndex >= 0) {
                if (insertionParams.productId && insertionParams.productId !== product.id) {
                    commit('setProductInsertionParams', { productIndex: -1, type: 'after', productId: '' });
                }
                else {
                    if (insertionParams.type === 'before') {
                        action = () => commit('addProductBefore', { index: insertionParams.productIndex, product: productToInsert });
                    }
                    else {
                        action = () => commit('addProductAfter', { index: insertionParams.productIndex, product: productToInsert });
                    }
                    commit('setProductInsertionParams', {
                        productIndex: insertionParams.productIndex,
                        type: insertionParams.type,
                        productId: product.id,
                    });
                }
            }
            action();
            dispatch('saveSystem');
            dispatch('calculateSummary');
        },
        addProductBefore({ commit }, { product, index }) {
            commit('addProductBefore', { product, index });
        },
        addProductAfter({ commit }, { product, index }) {
            commit('addProductAfter', { product, index });
        },
        removeProduct({ commit, dispatch }, { index }) {
            commit('removeProduct', index);
            dispatch('saveSystem');
            dispatch('calculateSummary');
        },
        removeProductById({ commit, dispatch }, { productId }) {
            commit('removeProductById', productId);
            dispatch('saveSystem');
            dispatch('calculateSummary');
        },
        swapProducts({ commit, dispatch }, { sourceIndex, targetIndex }) {
            commit('swapProducts', { sourceIndex, targetIndex });
            dispatch('saveSystem');
            dispatch('calculateSummary');
        },
        setProductInsertionParams({ commit }, { productIndex = -1, type = 'after', productId = '' }) {
            commit('setProductInsertionParams', { productIndex, type, productId });
        },
        replaceProduct({ commit, dispatch }, { product }) {
            if (!product || !product.id) {
                return;
            }
            commit('replaceProduct', product);
            dispatch('saveSystem');
            dispatch('calculateSummary');
        },
        setProjectId({ commit }, projectId) {
            commit('setProjectId', projectId);
        },
        setName({ commit }, name) {
            commit('setName', name);
        },
        setNumber({ commit }, number) {
            commit('setNumber', number);
        },
        setAuthor({ commit }, author) {
            commit('setAuthor', author);
        },
        setComment({ commit }, comment) {
            commit('setComment', comment);
        },
        setDate({ commit }, date) {
            commit('setDate', date);
        },
        setCreatedAt({ commit }, createdAt) {
            commit('createdAt', createdAt);
        },
        setAttenuationConstantType({ commit }, attenuationConstantType) {
            commit('setAttenuationConstantType', attenuationConstantType);
        },
        setFilterCorrectionType({ commit }, filterCorrectionType) {
            commit('setFilterCorrectionType', filterCorrectionType);
        },
        setInputSoundPowerLevel({ commit, dispatch }, { bandRange, soundPower }) {
            commit('setInputSoundPowerLevel', { bandRange, soundPower });
            dispatch('calculateSummary');
        },
        setAbstractProductAttribute({ commit, dispatch }, { productId, attributeName, attributeValue }) {
            commit('setAbstractProductAttribute', { productId, attributeName, attributeValue });
            dispatch('saveSystem');
        },
        resetData({ commit }) {
            commit('resetData');
        },
        resetProductsData({ commit }) {
            commit('resetProductsData');
        },
        resetNoiseGeneratorData({ commit }) {
            commit('resetNoiseGeneratorData');
        },
        async calculateSummaryImmediately({ state, commit }) {
            if (!state.attenuationConstantType) {
                return;
            }
            if (!state.filterCorrectionType) {
                return;
            }
            const calculationResults = await CalculationsService.calculateSoundPower({
                inputSoundPowerLevel: state.inputSoundPowerLevel,
                attenuationConstant: state.attenuationConstantType,
                filterCorrectionType: state.filterCorrectionType,
                products: prepareProducts(Object.values(state.selectedProducts)),
            });
            commit('setCalculationResults', calculationResults);
        },
        calculateSummary: debounce(async ({ dispatch }) => {
            await dispatch('calculateSummaryImmediately');
        }, 400),
        async setSystem({ commit, dispatch }, data) {
            await dispatch('silencerClassesModule/clearSelection', null, { root: true });
            commit('setSystem', data);
        },
        // createSystem: debounce(async ({ state, commit, dispatch, rootGetters }) => {
        createSystem: async ({ state, commit, dispatch, rootGetters }) => {
            if (!rootGetters['userModule/userAuthorized'] || !state.projectId) {
                return;
            }
            const system = { ...state };
            const result = await SystemService.createSystem(state.projectId, SystemOutputTransformer.transform(system));
            if (!result._id) {
                return;
            }
            commit('setId', result._id);
            system.id = result._id;
            await dispatch('systemsModule/addSystem', { system }, { root: true });
            await dispatch('activeProjectModule/addSystemRef', { systemRef: system }, { root: true });
        // }, 400),
        },
        // saveSystem: debounce(async ({ state, dispatch, rootGetters }) => {
        saveSystem: async ({ state, dispatch, rootGetters }) => {
            if (!rootGetters['userModule/userAuthorized'] || !state.projectId) {
                return;
            }
            if (!state.id) {
                dispatch('createSystem');
                return;
            }
            const system = { ...state };
            await SystemService.saveSystem(state.projectId, SystemOutputTransformer.transform(system));
            await dispatch('systemsModule/updateSystem', { system }, { root: true });
            await dispatch('activeProjectModule/updateSystemRef', { systemRef: system }, { root: true });
        // }, 400),
        },
        // deleteSystem: debounce(async ({ state, dispatch, rootGetters }) => {
        deleteSystem: async ({ state, dispatch, rootGetters }) => {
            if (!rootGetters['userModule/userAuthorized'] || !state.projectId) {
                return;
            }
            if (!state.id) {
                return;
            }
            const systemId = state.id;
            const projectId = state.projectId;
            await SystemService.deleteSystem(projectId, systemId);
            await dispatch('systemsModule/deleteSystem', { systemId }, { root: true });
            await dispatch('activeProjectModule/deleteSystemRef', { systemId }, { root: true });
            await dispatch('resetData');
        // }, 400),
        },
    },
}

// TODO: to transformer
function prepareProducts(products) {
    const result = [];
    products.forEach((product) => {
        if (!product.quantity) {
            result.push(prepareProduct(product));
            return;
        }
        for (let i = 1; i <= product.quantity; i++) {
            result.push(prepareProduct(product));
        }
    });

    return result;
}

// TODO: to transformer
function prepareProduct(product) {
    return {
        id: product.id,
        type: product.type,
        class: product.class || 'abstract',
        name: product.name || 'Abstract',
        imageURL: product.imageURL || '',
        code: product.code || '',
        soundAttenuation: product.soundAttenuation,
        soundPowerLevel: product.soundPowerLevel,
    };
}
