import ClassTransformer from '../silencer-classes';
import { transformTranslationMap } from '../__general';

function resolveNumber(value, fallback = 0) {
    if (isNaN(value)) {
        return fallback;
    }

    return Number(value);
}

// eslint-disable-next-line no-unused-vars
function resolveName(name) {
    const match = (/\d+/is).exec(name);
    if (!match || match.index <= 0) {
        return name;
    }
    if (name[match.index - 1] === ' ') {
        return name;
    }
    const leftSide = name.substring(0, match.index);
    const rightSide = name.substring(match.index);

    return `${leftSide} ${rightSide}`;
}

function transformDiagrams(diagramsMap) {
    if (!diagramsMap || !Object.keys(diagramsMap).length) {
        return {};
    }
    const fallback = Array.isArray(diagramsMap.eng) && diagramsMap.eng.length ? diagramsMap.eng : [];

    return {
        estonian: Array.isArray(diagramsMap.est) && diagramsMap.est.length ? diagramsMap.est : fallback,
        english: Array.isArray(diagramsMap.eng) && diagramsMap.eng.length ? diagramsMap.eng : fallback,
        finnish: Array.isArray(diagramsMap.fin) && diagramsMap.fin.length ? diagramsMap.fin : fallback,
        danish: Array.isArray(diagramsMap.den) && diagramsMap.den.length ? diagramsMap.den : fallback,
        swedish: Array.isArray(diagramsMap.swe) && diagramsMap.swe.length ? diagramsMap.swe : fallback,
    };
}

function transformClassData(data) {
    if (!data) {
        return null;
    }

    return ClassTransformer.transform([data])[0] || null;
}

function transformDimensions(data) {
    if (!data) {
        return null;
    }

    return {
        A: data.A ? Number(data.A) : undefined,
        B: data.B ? Number(data.B) : undefined,
        d: data.d ? Number(data.d) : undefined,
        D: data.D ? Number(data.D) : undefined,
        l: data.l ? Number(data.l) : undefined,
        L: data.I ? Number(data.I) : undefined,
    };
}

function transformGeometry(data) {
    if (!data.geometry) {
        return null;
    }

    return {
        modelId: data.geometry.modelId || '',
        modelParameters: data.geometry.modelParameters || '',
        modelVersion: data.geometry.modelVersion || '',
    };
}

function transformSilencer(silencer) {
    if (!silencer._id || !silencer.class) {
        return null;
    }
    const transformed = {
        id: silencer._id,
        type: 'silencer',
        name: resolveName(silencer.vendorCode),
        nameAppendix: transformTranslationMap(silencer.nameAppendix),
        class: silencer.class,
        classData: transformClassData(silencer.classData),
        code: silencer.code || '',
        vendorCode: silencer.vendorCode,
        weight: Number(silencer.weight),
        shape: silencer.shape,
        attenuationMaterial: silencer.attenuationMaterial,
        nominalSizeDiameter: Number(silencer.nominalSizeDiameter),
        length: Number(silencer.length),
        openable: Boolean(silencer.openable),
        dimensions: transformDimensions(silencer.dimensions),
        diagrams: transformDiagrams(silencer.diagrams),
        quantity: 0,
        inputAirflow: 0,
        pressureDrop: 0,
        geometry: transformGeometry(silencer),
    };
    const { soundAttenuation = {}, soundPowerLevel = {} } = silencer;
    transformed.defaultSoundAttenuation = {
        octaveBand63Hz: resolveNumber(soundAttenuation.octaveBand63Hz, 0),
        octaveBand125Hz: resolveNumber(soundAttenuation.octaveBand125Hz, 0),
        octaveBand250Hz: resolveNumber(soundAttenuation.octaveBand250Hz, 0),
        octaveBand500Hz: resolveNumber(soundAttenuation.octaveBand500Hz, 0),
        octaveBand1000Hz: resolveNumber(soundAttenuation.octaveBand1000Hz, 0),
        octaveBand2000Hz: resolveNumber(soundAttenuation.octaveBand2000Hz, 0),
        octaveBand4000Hz: resolveNumber(soundAttenuation.octaveBand4000Hz, 0),
        octaveBand8000Hz: resolveNumber(soundAttenuation.octaveBand8000Hz, 0),
    };
    transformed.soundAttenuation = { ...transformed.defaultSoundAttenuation };
    transformed.defaultSoundPowerLevel = {
        octaveBand63Hz: resolveNumber(soundPowerLevel.octaveBand63Hz, 0),
        octaveBand125Hz: resolveNumber(soundPowerLevel.octaveBand125Hz, 0),
        octaveBand250Hz: resolveNumber(soundPowerLevel.octaveBand250Hz, 0),
        octaveBand500Hz: resolveNumber(soundPowerLevel.octaveBand500Hz, 0),
        octaveBand1000Hz: resolveNumber(soundPowerLevel.octaveBand1000Hz, 0),
        octaveBand2000Hz: resolveNumber(soundPowerLevel.octaveBand2000Hz, 0),
        octaveBand4000Hz: resolveNumber(soundPowerLevel.octaveBand4000Hz, 0),
        octaveBand8000Hz: resolveNumber(soundPowerLevel.octaveBand8000Hz, 0),
    }
    transformed.soundPowerLevel = { ...transformed.defaultSoundPowerLevel };

    return transformed;
}

function composeFilters(silencers) {
    const result = {};
    silencers.forEach((silencer) => {
        let filter = result[silencer.class];
        if (!filter) {
            filter = {
                shape: silencer.shape,
                attenuationMaterial: silencer.attenuationMaterial,
                nominalSizes: new Set(),
                lengths: new Set(),
                openable: silencer.openable,
            };
            result[silencer.class] = filter;
        }
        filter.nominalSizes.add(silencer.nominalSizeDiameter);
        filter.lengths.add(silencer.length);
    });

    return result;
}

export default {
    transform(silencers) {
        const result = [];
        silencers.forEach((silencer) => {
            const transformed = transformSilencer(silencer);
            if (!transformed) {
                return;
            }
            result.push(transformed);
        });

        return {
            silencers: result,
            filters: composeFilters(result),
        };
    }
}
