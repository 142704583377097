<template>
    <div class="page-content">
        <Breadcrumbs class="mt-4 mb-4" :items="breadcrumbs"></Breadcrumbs>
        <div class="card column mb-8">
            <div class="card-header">
                <div class="main-title">{{ $t('silencersPage.selectSilencerTypes') }}</div>
                <div class="selector-info" v-html="$t('silencersPage.selectedAmount', [selectedSilencerClasses.length + selectedRectSilencerClasses, silencerClasses.length + 2])"></div>
            </div>
            <div class="card-content">
                <div
                    class="category"
                    v-for="silencerClass in silencerClasses"
                    :key="silencerClass.id"
                    :class="{ 'selected': silencerClass.selected }"
                    @click="toggleClassSelection(silencerClass)">
                    <img :src="silencerClass.imageURL" alt="Silencer Class Image">
                    <div class="title mt-4">{{ resolveTranslationMapValue(silencerClass.title) }}</div>
                    <tooltip v-if="silencerClass.content !== null" position="right" class="category-tooltip" :text="mapLanguage(silencerClass)">
                        <i class="information-icon" />
                        <template v-slot:tooltip-heading>
                            {{ resolveTranslationMapValue(silencerClass.title) }}
                        </template>
                    </tooltip>
                </div>
            </div>
            <div class="card-content">
                <div
                    class="category"
                    v-for="silencerClass in rectSilencerClasses"
                    :key="silencerClass.id"
                    :class="{ 'selected': silencerClass.selected }"
                    @click="silencerClass.selected = !silencerClass.selected">
                    <img :src="silencerClass.imageURL" alt="Rect Silencer Class Image">
                    <div class="title mt-4">{{ resolveTranslationMapValue(silencerClass.title) }}</div>
                    <tooltip v-if="silencerClass.content !== null" position="right" class="category-tooltip" :text="mapLanguage(silencerClass)">
                        <i class="information-icon" />
                        <template v-slot:tooltip-heading>
                            {{ resolveTranslationMapValue(silencerClass.title) }}
                        </template>
                    </tooltip>
                </div>
            </div>
            <div class="card-footer">
                <Button 
                    size="medium" 
                    class="flex-15"
                    :disabled="!silencersPresent"
                    @click="$router.push({ name: 'products-list' })" 
                >{{ $t('silencersPage.controls.next') }}</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Breadcrumbs from '@/components/general/Breadcrumbs.vue';
import Button from '../../general/Button.vue';
import Tooltip from '../../general/Tooltip.vue';
import rectSilencerClassesData from '@/mocks/rect_silencer_categories.json';

export default {
    name: 'Silencers',
    components: {
        Breadcrumbs,
        Button,
        Tooltip,
    },
    inject: ['resolveTranslationMapValue'],
    computed: {
        ...mapState('activeSystemModule', {
            'systemName': 'name',
        }),
        ...mapState('activeProjectModule', {
            'projectName': 'name',
        }),
        ...mapState('silencersModule', ['silencers']),
        ...mapGetters('silencerClassesModule', {
            'silencerClasses': 'rootClasses',
            'selectedSilencerClasses': 'selectedRootClasses',
        }),
        silencersPresent() {
            return this.silencers.length > 0;
        },
        selectedRectSilencerClasses() {
            let total = 0;
            this.rectSilencerClasses
                .forEach((silencerClass) => total += silencerClass.selected ? 1 : 0);

            return total;
        },
        breadcrumbs() {
            return [
              {
                title: this.$t('productsList.breadcrumbs.home'),
                link: { name: 'projects' },
              },
              {
                title: this.projectName || this.$t('breadcrumbs.activeProject'),
                link: { name: 'systems' },
              },
              {
                title: this.systemName || this.$t('breadcrumbs.activeSystem'),
                // link: { name: 'silencers' },
              },
            ];
        },
    },
    data() {
        return {
            openedTooltipIndex: null,
            rectSilencerModalOpened: false,
            rectSilencerClasses: rectSilencerClassesData,
        };
    },
    methods: {
        ...mapActions('silencerClassesModule', ['toggleSilencerClassSelection']),
        toggleClassSelection(silencerClass) {
            this.toggleSilencerClassSelection({ identity: silencerClass.id });
        },
        mapLanguage(silencerClass) {
            let locale = localStorage.getItem('locale');
            let description = null;

            try {
                switch (locale) {
                case "english":
                    description = silencerClass.content.eng.description;
                    break;
                case "estonian":
                    description = silencerClass.content.est.description;
                    break;
                case "finnish":
                    description = silencerClass.content.fin.description;
                    break;
                case "swedish":
                    description = silencerClass.content.swe.description;
                    break;
                case "danish":
                    description = silencerClass.content.den.description;
                    break;
                }
            } catch (e) {
                description = null;
            }
            return description;
        },
    }
}
</script>

<style>
.breadcrumbs-container {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.breadcrumbs-container span.breadcrumb {
    display: flex;
    flex: 0 1 auto;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #8b8b8b;
}

.breadcrumbs-container span.breadcrumb-separator {
    color: #8b8b8b;
    margin: 0 8px;
}

.breadcrumbs-container span:first-of-type {
    margin-left: 0;
}

.breadcrumbs-container span:last-of-type {
    margin-right: 0;
}

.breadcrumbs-container span.active-breadcrumb {
    color: #4C5055;
}

.card .card-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.card .card-header .main-title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 28px;
    line-height: 33px;
    color: #4C5055;
}

.card .card-header .title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 22px;
    color: #4C5055;
}

.card .card-header .selector-info {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4C5055;
}

.column {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.card .card-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
}

.card .card-content .category {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 186px;
    min-height: 172px;
    margin: 12px;
    flex: 0 0 15%;
}

.card .card-content .category.selected, .card .card-content .category:hover {
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(76, 80, 85, 0.16);
    border-radius: 8px;
    box-sizing: border-box;
}

.card .card-content .category.selected, .card .card-content .category.selected:hover {
    border: 1px solid #737372;
}

.card .card-content .category:hover {
    border: 1px solid #D5D6D5;
}

/* .card .card-content .category.selected div {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4C5055;
} */

.card .card-content .category .category-tooltip {
    position: absolute;
    margin: 14px 14px 0 0;
    top: 0;
    right: 0;
}

.card .card-content .category .category-tooltip .information-icon {
    visibility: hidden;
    display: flex;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/icons/singular/info_outline-black.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.card .card-content .category:hover .information-icon {
    visibility: visible;
}

.card .card-content .category img {
    width: 92px;
    height: 70px;
}

.card .card-content .category .title {
    font-family: Helvetica Neue Bold;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4C5055;
}

.card .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-15 {
    flex: 0 1 15%;
}
</style>
