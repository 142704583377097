/* eslint-disable no-unused-vars */
import xml2js from 'xml2js';
import { emptyOctaveBandRange } from '../../../utils';

function generateRange(min, max, step) {
    const result = [];
    for (let value = min; value <= max; value += step) {
        result.push(value);
    }

    return result;
}

function findClosestItemInRange(range, needle) {
    return range.find((item) => item >= needle) || range[range.length - 1];
}

const RECT_STRAIGHT_SILENCER_WIDTH_RANGE = generateRange(300, 2500, 50);
const RECT_STRAIGHT_SILENCER_HEIGHT_RANGE = generateRange(200, 2500, 50);
const RECT_BEND_SILENCER_WIDTH_RANGE = generateRange(300, 2350, 50);
const RECT_BEND_SILENCER_HEIGHT_RANGE = generateRange(200, 2350, 50);

function determineType(data) {
    const { shape: [shape = ''] } = data || {};
    if (!shape) {
        return null;
    }
    switch (shape) {
        case '1':
            return 'silencer';
        case '2':
            return 'rectSilencer';
        default:
            return null;
    }
}

function determineShape(data) {
    const { shape: [shape = ''] } = data || {};
    if (!shape) {
        return null;
    }
    switch (shape) {
        case '1':
            return 'circular';
        case '2':
            return 'straight';
        default:
            return null;
    }
}

function determineSoundPower(data) {
    const { sound: [sound = ''] } = data || {};
    if (!sound) {
        return emptyOctaveBandRange();
    }
    const soundPower = sound.split(' ');
    if (soundPower.length < 8) {
        return emptyOctaveBandRange();
    }

    return {
        octaveBand63Hz: soundPower[0] ? Number(Number(soundPower[0]).toFixed(1)) : 0,
        octaveBand125Hz: soundPower[1] ? Number(Number(soundPower[1]).toFixed(1)) : 0,
        octaveBand250Hz: soundPower[2] ? Number(Number(soundPower[2]).toFixed(1)) : 0,
        octaveBand500Hz: soundPower[3] ? Number(Number(soundPower[3]).toFixed(1)) : 0,
        octaveBand1000Hz: soundPower[4] ? Number(Number(soundPower[4]).toFixed(1)) : 0,
        octaveBand2000Hz: soundPower[5] ? Number(Number(soundPower[5]).toFixed(1)) : 0,
        octaveBand4000Hz: soundPower[6] ? Number(Number(soundPower[6]).toFixed(1)) : 0,
        octaveBand8000Hz: soundPower[7] ? Number(Number(soundPower[7]).toFixed(1)) : 0,
    };
}

export async function mapProductData(data) {
    try {
        if (!data) {
            // alert('Product data transformer: incoming data is empty');
            return null;
        }
        const { inputParams = null } = await xml2js.parseStringPromise(data) || {};
        if (!inputParams) {
            // alert('Product data transformer: "inputParams" is empty');
            return null;
        }
        const type = determineType(inputParams);
        if (!type) {
            // alert('Product data transformer: product type is not recognized');
            return null;
        }
        const shape = determineShape(inputParams);
        if (!shape) {
            // alert('Product data transformer: product shape is not recognized');
            return null;
        }

        let width = inputParams.width ? Math.abs(Math.round(Number(inputParams.width))) : 0;
        let height = inputParams.height ? Math.abs(Math.round(Number(inputParams.height))) : 0;
        if (type === 'rectSilencer') {
            const widthRange = shape === 'straight' ? RECT_STRAIGHT_SILENCER_WIDTH_RANGE : RECT_BEND_SILENCER_WIDTH_RANGE;
            const heightRange = shape === 'straight' ? RECT_STRAIGHT_SILENCER_HEIGHT_RANGE : RECT_BEND_SILENCER_HEIGHT_RANGE;
            if (!widthRange.includes(width) || !heightRange.includes(height)) {
                const closestWidth = findClosestItemInRange(widthRange, width);
                const closestHeight = findClosestItemInRange(heightRange, height);
                alert(`No suitable silencer for selected duct size: ${width}x${height}. Duct connection rounded to nearest silencer size: ${closestWidth}x${closestHeight}`);
                width = closestWidth;
                height = closestHeight;
            }
        }

        return {
            type,
            shape,
            diameter: inputParams.diameter ? Math.abs(Math.round(Number(inputParams.diameter))) : 0,
            width,
            height,
            inputAirflow: inputParams.airflow ? Math.abs(Math.round(Number(inputParams.airflow))) : 0,
            soundPower: determineSoundPower(inputParams),
        };
    } catch (error) {
        alert('Product data transformer: an error occurred', error.message);
        console.error(error);

        return null;
    }
}